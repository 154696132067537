import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { UpsService } from '../ups.service'
import { MatSnackBar } from '@angular/material'
import { Up } from '../up.model'

@Component({
	selector: 'app-dialog-up',
	templateUrl: './dialog-up.component.html',
	styleUrls: ['./dialog-up.component.scss']
})
export class DialogUpComponent implements OnInit {
	color: any
	public form: FormGroup
	public saving: Boolean = false
	public title: String = 'Crear Dirección'
	public buttonText: String = 'Crear'
	public coordinations: any[] = []

	constructor(
		private formBuilder: FormBuilder,
		private service: UpsService,
		private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<DialogUpComponent>,
		@Inject(MAT_DIALOG_DATA) private data: { up: Up; coordination: any[] }
	) {
		dialogRef.disableClose = true
		this.coordinations = data.coordination
		if (this.data.up.id > 0) {
			this.title = 'Actualizar Dirección'
			this.buttonText = 'Actualizar'
		}
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			id: [this.data.up.id],
			name: [this.data.up.name, Validators.required],
			titular: [this.data.up.titular, Validators.required],
			email: [this.data.up.email, [Validators.email]],
			color: [
				this.data.up.color,
				[Validators.pattern(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)]
			],
			// government_id: [this.data.up.government_id],
			coordination_id: [this.data.up.coordination_id, Validators.required]
		})
	}

	changeColor(event): void {
		this.form.get('color').setValue(event)
	}

	save(): void {
		this.saving = true
		if (this.data.up.id > 0) {
			this.service.update(this.form.value).subscribe(
				(data: any) => {
					this.dialogRef.close(true)
					this.snackBar.open('Up Actualizada', 'Ok', {
						duration: 2000
					})
				},
				(error) => {
					this.errorMessage(error)
				}
			)
		} else {
			this.service.create(this.form.value).subscribe(
				(data: any) => {
					this.dialogRef.close(true)
					this.snackBar.open('Up Creada', 'Ok', {
						duration: 2000
					})
				},
				(error) => {
					this.errorMessage(error)
				}
			)
		}
	}

	public errorMessage(error: any): void {
		const keys = Object.keys(error.error)
		let message = 'Ocurrió un error'
		if (keys.length > 0) {
			message = error.error[keys[0]]
		}
		this.snackBar.open(message, 'Cerrar', {
			duration: 2000
		})
		this.saving = false
	}
}
