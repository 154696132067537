import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';
import { Globals } from '../globals';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  public url: string;
  private module: string;
  private httpOptions: any;
  private httpOptionsAsync: any;

  constructor(
    private http: HttpClient,
    private global: Globals
  ) { 
    this.url = global.api;
    this.module = 'tasks';
    this.httpOptions = global.httpOptions;
    this.httpOptionsAsync = global.httpOptionsAsync;
  }

  index(subproyectId: number): Observable<any> {
    return this.http.get(this.url + 'subprojects/' + subproyectId + '/' + this.module, this.httpOptions);
  }

  /*store(subproyectId: number, form: FormGroup): Observable<any> {
    return this.http.post(this.url + 'subprojects/' + subproyectId + '/' + this.module, form.value, this.httpOptions);
  }*/

  store(subproyectId: number, form: FormGroup): Observable<any> {
    return this.http.post(this.url + 'subprojects/' + subproyectId + '/' + this.module, form.value, this.httpOptions);
  }

  save(task_id: string, files: Set<File>): { [key: string]: Observable<number> } {
    const status = {};

    files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('taskId', task_id);
      formData.append('file', file);

      const req = new HttpRequest('POST', this.url + this.module + '/document', formData, this.httpOptionsAsync);
      const progress = new Subject<number>();
      const path_file = new Subject<string>();

      this.http.request(req).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          path_file.next((event.body as any).path + (event.body as any).name);
          progress.complete();
        }
      }, error => {
        progress.error(error.status);
      });

      status[file.name] = {
        progress: progress.asObservable(),
        file: file,
        path_file: path_file.asObservable()
      };
    });

    return status;
  }

  edit(subproyectId: number, id: number): Observable<any>{
    return this.http.get(this.url + this.module + '/' + id, this.httpOptions);
  }

  checkExists(subproyectId: number, id: number): Observable<any>{
    return this.http.get(this.url + this.module + '/' + subproyectId + '/exists/' + id, this.httpOptions);
  }

  update(form: FormGroup, id: number): Observable<any> {
    return this.http.put(this.url + this.module + '/' + id, form.value, this.httpOptions);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.url + this.module + '/' + id, this.httpOptions);
  }

  notifyComunication(id: number): Observable<any> {
    return this.http.get(this.url + this.module + '/email/' + id, this.httpOptions);
  }

  getTaskFile(id: Number, imagesProgress: any): Observable<any> {
    return this.http.post(this.url + this.module + '/' + id + '/downloadtask', {
      'id': id,
      'imagesProgress': imagesProgress,
    }, { responseType: 'blob' });
  }
}
