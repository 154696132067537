import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DetailProjectsComponent } from './detail-projects.component';
import { DetailStagesComponent } from '../../stages/detail-stages/detail-stages.component';
import { DetailProjectsService } from './detail-projects.service';
import { DetailSubprojectsComponent } from '../../subprojects/detail-subprojects/detail-subprojects.component';
import { DetailsTasksComponent } from '../../tasks/details-tasks/details-tasks.component';

import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';

const projectsRoutes: Routes = [
  {
    path: 'proyectos/:projectId',
    component: DetailProjectsComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: DetailProjectsService
    },
    children: [
      {
        path: 'etapa/:stageId',
        component: DetailStagesComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: DetailProjectsService
        }
      },
      {
        path: 'etapa/:stageId/accion/:actionId',
        component: DetailSubprojectsComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: DetailProjectsService
        }
      },
      {
        path: 'etapa/:stageId/accion/:actionId/actividad/:taskId',
        component: DetailsTasksComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: DetailProjectsService
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(projectsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DetailProjectsRoutingModule { }
