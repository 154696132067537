import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DonutGraphComponent } from './donut-graph.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ChartsModule,
    MatIconModule
  ],
  declarations: [
    DonutGraphComponent
  ],
  exports: [
    DonutGraphComponent
  ],
  entryComponents: [
  ],
  providers: [],
})
export class DonutGraphModule { }
