import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatChipsModule, 
  MatFormFieldModule, 
  MatIconModule,
  MatInputModule, 
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { IndexMembersModule } from './index-members/index-members.module';

@NgModule({
  imports: [
    CommonModule,    
    IndexMembersModule
  ],
  declarations: []
})
export class MembersModule { }
