import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  resetPasswordFormErrors: any;
  _unsubscribeAll: Subject<any>;
  showPass: Boolean = false;
  showPass1: Boolean = false;
  loading = false;
  id_user: any = null;
  dataMember: any = null;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private changePasswordService: ChangePasswordService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.dataMember = this.data.member;
    this.id_user = this.dataMember.id;
    
    this.resetPasswordFormErrors = {
      id_user: this.id_user,
      password: {},
      passwordConfirm: {}
    };
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      id_user: [this.id_user],
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, this.confirmPassword, Validators.minLength(8)]]
    });

    this.resetPasswordForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onResetPasswordFormValuesChanged();
      });
  }

  onResetPasswordFormValuesChanged(): void {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.resetPasswordFormErrors[field] = {};
      const control = this.resetPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  confirmPassword(control: AbstractControl): any {
    if (!control.parent || !control) {
      return;
    }
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
      return;
    }
    if (passwordConfirm.value === '') {
      return;
    }
    if (password.value !== passwordConfirm.value) {
      return {
        passwordsNotMatch: true
      };
    }
  }

  getName(){
    if (this.dataMember){
      return this.dataMember.name + ' ' + this.dataMember.last_name;
    }
    return '';
  }

  save(): void {
    const formValue = this.resetPasswordForm.value;
    let passChange: Boolean = false;

    if (formValue.password !== '' && formValue.passwordConfirm !== '') {
      passChange = true;
    }

    if (!passChange) {
      this.snackBar.open('Completa los campos', 'OK', {
        duration: 3500
      });
      return;
    }

    if (this.resetPasswordForm.status === 'VALID') {
      this.loading = true;
      this.changePasswordService.update(formValue).subscribe(data => {
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        this.snackBar.open(error.error.data, 'OK', {
          duration: 3500
        });
      });
    }
  }
}
