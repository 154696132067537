import { Injectable } from '@angular/core';

import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Globals } from '../globals';
import { Axis } from './axis.model';

@Injectable({
  providedIn: 'root'
})
export class AxisService {

  private url: string;
  private module: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private global: Globals
  ) {
    this.url = global.api;
    this.module = 'axis';
    this.httpOptions = global.httpOptions;
   }

  public index(): Observable<any> {
    return this.http.get(`${this.url}${this.module}`, this.httpOptions);
  }

  public create(axis: Axis): Observable<any> {
    return this.http.post(`${this.url}${this.module}`, axis, this.httpOptions);
  }

  public update(axis: Axis): Observable<any> {
    return this.http.put(`${this.url}${this.module}/${axis.id}`, axis, this.httpOptions);
  }
}
