import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatStepperModule, MatProgressSpinnerModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ProjectsModule } from './projects/projects.module';
import { SubprojectsModule } from './subprojects/subprojects.module';
import { PortfoliosModule } from './portfolios/portfolios.module';
import { MembersModule } from './members/members.module';
import { TasksModule } from './tasks/tasks.module';
import { StagesModule } from './stages/stages.module';
import { UpsModule } from './ups/ups.module';
import { AxisModule } from './axis/axis.module';
import { ReportsModule } from './reports/reports.module';
import { VisualizerModule } from './visualizer/visualizer.module';

import { AuthGuardService as AuthGuard } from '../app/auth/auth-guard.service';
import { AuthRolePermissionService as AuthRolePermissionGuard } from 'app/auth/auth-role-permission.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { routing } from './app.routing.module';
import { Globals } from './globals';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { GanttModule } from './gantt/gantt.module';
import { ComunicationModule } from './comunication/comunication.module';
import { CoordinationsModule } from './coordinations/coordinations.module';
import { AuthenticatedUserService } from './auth/authenticated-user.service';


@NgModule({
    declarations: [
        AppComponent,
        ConfirmComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ProjectsModule,
        MembersModule,
        ComunicationModule,
        SubprojectsModule,
        TasksModule,
        routing,
        MatStepperModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,

        // App modules
        LayoutModule,
        LoginModule,
        DashboardModule,
        PortfoliosModule,
        CoordinationsModule,
        StagesModule,
        UpsModule,
        AxisModule,
        ReportsModule,
        VisualizerModule,
        GanttModule
    ],
    providers: [
        AuthGuard,
        AuthRolePermissionGuard,
        JwtHelperService,
        Globals,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthenticatedUserService
    ],
    bootstrap: [
        AppComponent
    ], 
    entryComponents: [
        ConfirmComponent
    ]
})
export class AppModule {
}
