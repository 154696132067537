import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Globals} from '../globals';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class StagesService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'stages';
    this.httpOptions = global.httpOptions;
  }

  get(id: number): Observable<any> {
    return this.http.get(this.uri + 'projects' + '/' + id + '/' + this.module, this.httpOptions);
  }

  store(form: Object): Observable<any> {    
    return this.http.post(this.uri + this.module, form, this.httpOptions);    
  }

  getSubAxis(id: number): Observable<any> {
    return this.http.get(this.uri + 'axis' + '/' + id + '/' + 'subaxis', this.httpOptions);
  }

  update(form: Object, id: number): Observable<any> {
    return this.http.put(this.uri + this.module + '/' + id, form, this.httpOptions);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.uri + this.module + '/' + id, this.httpOptions);
  }
}
