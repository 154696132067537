import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  constructor() { }

  private emitChangeSource = new Subject<any>();
  private emitChangeSourceGlobal = new Subject<any>();

  changeEmitted$ = this.emitChangeSource.asObservable();
  changeEmittedGlobal$ = this.emitChangeSourceGlobal.asObservable();

  emitChange(data: any): void {
    this.emitChangeSource.next(data);
  }

  emitChangeGlobal(): void {
    this.emitChangeSourceGlobal.next();
  }
}
