import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ProjectsService } from '../projects.service';
import { Project } from '../../projects/project';
import { 
  MatSnackBar,
  MatDialogRef,
  MatDialog
} from '@angular/material';
import { DialogAddProjectComponent } from '../dialog-add-project/dialog-add-project.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-index-projects',
  templateUrl: './index-projects.component.html',
  styleUrls: ['./index-projects.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class IndexProjectsComponent implements OnInit {
  projects: Project[] = [];
  statusColors: any = {
    green: '#03e54b',
    yellow: '#fff816',
    red: '#cf3737',
    default: '#d8d8d8'
  };
  rangeProgress = [
    { color: 'red', min: 1, max: 29 },
    { color: 'yellow', min: 30, max: 59 },
    { color: 'green', min: 60, max: 100 }
  ];
  dialogProjectForm: any;
  confirmProjectFormDialog: MatDialogRef<FuseConfirmDialogComponent>;
  activeFilter: String = 'all';
  activeLayout: String = 'table';

  constructor(
    private projectsService: ProjectsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {    
    this.getProyects();
  }

  getColorTasksIndicator(project): String {

    let color = 'default';
    const progress = this.getTasksProgress(project);
    const colorIndex = this.rangeProgress.findIndex(x => progress >= x.min && progress <= x.max);

    if (colorIndex >= 0) {
      color = this.rangeProgress[colorIndex].color;
    }

    return color;
  }

  getColorTaskProgress(project: any): String {
    const color = this.getColorTasksIndicator(project);
    return this.statusColors[<any>color];
  }

  countTasks(project: any): Number {

    if (typeof project === 'undefined') {
      return 0;
    }

    if (!project.hasOwnProperty('subprojects')) {
      return 0;
    }

    let total = 0;

    total = project.subprojects.reduce((sum, subproject) => {
      return sum + subproject.tasks_all;
    }, 0);

    return total;

  }

  countTasksFinished(project: any): Number {

    if (typeof project === 'undefined') {
      return 0;
    }

    if (!project.hasOwnProperty('subprojects')) {
      return 0;
    }

    let total = 0;

    total = project.subprojects.reduce((sum, subproject) => {
      return sum + subproject.tasks_finished;
    }, 0);

    return total;

  }

  getTasksProgress(project: any): any {
    const total = <number>this.countTasksFinished(project) * 100 / <number>this.countTasks(project);        
    return isNaN(total) ? 0 : total.toFixed(0);
  }

  openProjectFormDialog(): void {

    this.dialogProjectForm = this.dialog.open(DialogAddProjectComponent, {
      panelClass: 'mat-dialog-system'
    });

    this.dialogProjectForm.afterClosed()
    .subscribe(response => {
      
      if (!response) {
        return false;
      }

      this.snackBar.open('Proyecto agregado', 'OK', {
        duration: 3500
      });

      this.getProyects();

    });

  }

  getProyects(): void {
    this.projectsService.getAll().subscribe(data => {
      this.projects = data;
    });
  }

  isFilterActive(type: String): Boolean {
    return this.activeFilter === type ? true : false;
  }

  applyFilter(type: String): void {
    this.activeFilter = type;
  }

  setLayout(type: String): void {
    this.activeLayout = type;
  }

  isLayoutActive(type: String): Boolean {
    return this.activeLayout === type ? true : false;
  }

  hasAvatar(user: any): boolean {

    if (!user) {
      return false;
    }

    if (typeof user.avatar === 'undefined') {
      return false;
    }
    return user.avatar.length <= 0 ? false : true;
  }

  getImgAvatar(user: any): string {
    return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none';
  }

  getUserFullName(user: any): string {
    return user.name + ' ' + user.last_name + (user.m_last_name ? user.m_last_name : '');
  }

}
