import { Stage } from './stage.model';

export class Project {
    id: number;
    check: Boolean = true;
    indeterminate: Boolean = false;
    children: Stage[];
    dependency: any;
    name: string;
    portfolioId: number;
    type: number;
    nameProject: string;
    responsable: String = '';
    promise = false;
    populationAction: any = [];
  
    constructor(id: number, stages: Stage[], dependency: any, type: number, nameProject: string, portfolioId?: number) {
      this.id = id;
      this.children = stages;
      this.dependency = dependency;
      this.name = dependency.name;
      this.type = type;
      this.nameProject = nameProject;
      this.portfolioId = portfolioId ? portfolioId : 0;
    }
}
