import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { IndexPortfoliosComponent } from './index-portfolios.component'
import { DetailPortfolioComponent } from '../detail-portfolio/detail-portfolio.component'
import { FuseSharedModule } from '@fuse/shared.module'
import { CdkTableModule } from '@angular/cdk/table'
import { DialogAddProjectComponent } from '../../projects/dialog-add-project/dialog-add-project.component'
import { DialogEditPortfolioComponent } from '../../portfolios/dialog-edit-portfolio/dialog-edit-portfolio.component'
import { TagsPortfoliosComponent } from '../tags-portfolios/tags-portfolios.component'
import { DialogDependenciesPortfoliosComponent } from '../dialog-dependencies-portfolios/dialog-dependencies-portfolios.component'
import { DialogParticipantsComponent } from '../dialog-participants/dialog-participants.component'
import { OrderModule } from 'ngx-order-pipe'
import {
	MatIconModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule,
	MatButtonModule,
	MatPaginatorModule,
	MatRippleModule,
	MatSelectModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatSnackBarModule,
	MatMenuModule,
	MatTooltipModule,
	MatChipsModule,
	MatAutocompleteModule,
	MatProgressSpinnerModule,
	MatStepperModule,
	MatDatepickerModule,
	MatBottomSheetModule,
	MatToolbarModule,
	MatSidenavModule
} from '@angular/material'
import {
	SatDatepickerModule,
	SatNativeDateModule,
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from 'saturn-datepicker'
import { RoundProgressModule } from 'angular-svg-round-progressbar'
import { AuthGuardService as AuthGuard } from '../../../app/auth/auth-guard.service'
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from '@angular/material-moment-adapter'

import {} from 'saturn-datepicker'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule } from 'ngx-currency'
import { FuseSidebarModule } from '@fuse/components'
import { CommentsModule } from 'app/comments/comments.module'
import { ProfileModule } from 'app/profile/profile.module'
import { CommentsComponent } from '../../comments/comments.component'
import { UserInfoModule } from 'app/dialogs/user-info/user-info.module'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { ProfileComponent } from 'app/profile/profile.component'
import { DialogEditProjectComponent } from 'app/projects/dialog-edit-project/dialog-edit-project.component'
import { ValidateProjectComponent } from '../validate-project/validate-project.component'
import { GrouperPorfoliosComponent } from '../grouper-porfolios/grouper-porfolios.component'
import { AuthRolePermissionService as AuthRolePermissionGuard } from 'app/auth/auth-role-permission.service'
import { GrouperDirectoratesComponent } from '../grouper-directorates/grouper-directorates.component'

export const customCurrencyMaskConfig = {
	align: 'left',
	allowNegative: false,
	allowZero: true,
	decimal: '.',
	precision: 0,
	prefix: '',
	suffix: '',
	thousands: ',',
	nullable: false
}

const routes: Routes = [
	{
		path: 'portafolios',
		component: GrouperPorfoliosComponent,
		canActivate: [AuthGuard, AuthRolePermissionGuard],
		data: {
			roles: ['admin']
		}
	},
	{
		path: 'portafolios/coordinaciones/:coordinationId/direcciones',
		component: GrouperDirectoratesComponent,
		canActivate: [AuthGuard, AuthRolePermissionGuard],
		data: {
			roles: ['admin', 'coordination']
		}
	},
	{
		path: 'portafolios/coordinaciones/:coordinationId/direcciones/:directorateId',
		component: IndexPortfoliosComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'portafolios/:id',
		component: DetailPortfolioComponent,
		canActivate: [AuthGuard, AuthRolePermissionGuard],
		data: {
			roles: ['admin', 'coordination', 'directorate', 'stages']
		}
	}
]

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatPaginatorModule,
		MatRippleModule,
		MatSelectModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		FuseSharedModule,
		CdkTableModule,
		MatSnackBarModule,
		MatMenuModule,
		RoundProgressModule,
		MatTooltipModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatStepperModule,
		MatDatepickerModule,
		SatDatepickerModule,
		SatNativeDateModule,
		OrderModule,
		BrowserAnimationsModule,
		MatBottomSheetModule,
		NgxMaskModule.forRoot(),
		NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
		MatToolbarModule,
		MatSidenavModule,
		FuseSidebarModule,
		CommentsModule,
		UserInfoModule,
		ProfileModule
	],
	declarations: [
		IndexPortfoliosComponent,
		DialogAddProjectComponent,
		DialogEditProjectComponent,
		DialogEditPortfolioComponent,
		DetailPortfolioComponent,
		TagsPortfoliosComponent,
		DialogDependenciesPortfoliosComponent,
		DialogParticipantsComponent
	],
	entryComponents: [
		DialogAddProjectComponent,
		DialogEditProjectComponent,
		DialogEditPortfolioComponent,
		TagsPortfoliosComponent,
		DialogDependenciesPortfoliosComponent,
		DialogParticipantsComponent,
		CommentsComponent,
		UserInfoComponent,
		ProfileComponent,
		ValidateProjectComponent
	],
	providers: [
		AuthGuard,
		{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class IndexPortfoliosModule {}
