import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarComponent } from './snack-bar.component';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [SnackBarComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
    
  ],
  exports: [
    SnackBarComponent
  ]
})
export class SnackBarModule { }
