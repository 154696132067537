import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IndexPortfoliosModule } from './index-portfolios/index-portfolios.module'
import { CdkTableModule } from '@angular/cdk/table'
import {
	MatButtonModule,
	MatChipsModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatPaginatorModule,
	MatRippleModule,
	MatSelectModule,
	MatSortModule,
	MatProgressSpinnerModule,
	MatTableModule,
	MatTabsModule,
	MatStepperModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MatTooltipModule
} from '@angular/material'
import { FuseConfirmDialogModule } from '@fuse/components'
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from '@angular/material-moment-adapter'
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from '@angular/material/core'
import { NewsPortfoliosModule } from './news-portfolios/news-portfolios.module'
import { ReportsPortfoliosModule } from './reports-portfolios/reports-portfolios.module'
import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule } from 'ngx-currency'
import { ValidateProjectComponent } from './validate-project/validate-project.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AutoCompleteGroupModule } from 'app/general/components/auto-complete-group/auto-complete-group.module'
import { GrouperPorfoliosComponent } from './grouper-porfolios/grouper-porfolios.component'
import { DialogCoordinationComponent } from './dialog-coordination/dialog-coordination.component'
import { MaterialFileInputModule } from 'ngx-material-file-input'
import { GrouperDirectoratesComponent } from './grouper-directorates/grouper-directorates.component'

export const customCurrencyMaskConfig = {
	align: 'right',
	allowNegative: true,
	allowZero: true,
	decimal: '.',
	precision: 2,
	prefix: '',
	suffix: '',
	thousands: ',',
	nullable: false
}

@NgModule({
	imports: [
		CommonModule,
		CdkTableModule,
		MatButtonModule,
		MatChipsModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatPaginatorModule,
		MatRippleModule,
		MatSelectModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatTabsModule,
		MatStepperModule,
		FuseConfirmDialogModule,
		IndexPortfoliosModule,
		NewsPortfoliosModule,
		ReportsPortfoliosModule,
		NgxMaskModule.forRoot(),
		NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatAutocompleteModule,
		AutoCompleteGroupModule,
		MaterialFileInputModule
	],
	declarations: [
		ValidateProjectComponent,
		GrouperPorfoliosComponent,
		DialogCoordinationComponent,
		GrouperDirectoratesComponent,
		GrouperDirectoratesComponent
	],
	entryComponents: [DialogCoordinationComponent]
})
export class PortfoliosModule {}
