import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatChipsModule, 
  MatFormFieldModule, 
  MatIconModule,
  MatInputModule, 
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,  
  MatTableModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatDialogModule
} from '@angular/material';
import { IndexTasksModule } from './index-tasks/index-tasks.module';
import { FormTasksModule } from './form-tasks/form-tasks.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule, 
    MatFormFieldModule, 
    MatIconModule,
    MatInputModule, 
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,  
    MatTableModule,
    MatTabsModule,
    IndexTasksModule,
    FormTasksModule,
    MatDialogModule,
    MatTableModule 
  ],
  declarations: [
  ]  
})
export class TasksModule { }
