import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { MatSidenav } from '@angular/material';
import { CommunicationService } from 'app/services/communication.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  @ViewChild('sidenavprofile', {static: false}) sidenavprofile: MatSidenav;
  fuseConfig: any;
  navigation: any;
  dataProfile: any = 'Hola';
  value = 0;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private communicationService: CommunicationService,
    private authService: AuthService
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    communicationService.changeEmittedGlobal$.subscribe(() => {
      this.value += 1;
      this.sidenavprofile.toggle();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleProfile(): void {
    this.sidenavprofile.toggle();
  }

  close(reason: string): void {
    this.sidenavprofile.disableClose = true;
    setTimeout(() => this.sidenavprofile.disableClose = false);
  }

  checkToken(): any{
    return this.authService.token;
  }
}
