import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatChipsModule, 
  MatFormFieldModule, 
  MatIconModule,
  MatInputModule, 
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,  
  MatTableModule,
  MatTabsModule,
  MatProgressBarModule
} from '@angular/material';
import { FormSubprojectsModule } from './form-subprojects/form-subprojects.module';
import { IndexSubprojectsModule } from './index-subprojects/index-subprojects.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatProgressBarModule,
    FormSubprojectsModule,
    IndexSubprojectsModule
  ],
  declarations: []
})
export class SubprojectsModule { }
