import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'catalogs';
    this.httpOptions = global.httpOptions;
  }

  get ups(): Observable<any>{
    return this.http.get(this.uri + 'up', this.httpOptions);
  }

  membersUp(id: Number): Observable<any>{
    return this.http.get(this.uri + 'members/up/' + id, this.httpOptions);
  }

  potfolioReport(id: Number): Observable<any>{
    return this.http.get(this.uri + 'portfolios/' + id + '/report', this.httpOptions);
  }

  participantsProject(id: number): Observable<any> {
    return this.http.get(this.uri + 'projects/' + id + '/paticipants', this.httpOptions);
  }
}
