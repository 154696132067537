import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CoordinationService } from 'app/coordinations/services/coordination.service'

@Component({
	selector: 'app-dashboard-grouper-coordinations',
	templateUrl: './dashboard-grouper-coordinations.component.html',
	styleUrls: ['./dashboard-grouper-coordinations.component.scss']
})
export class DashboardGrouperCoordinationsComponent implements OnInit {
	groups

	constructor(
		private router: Router,
		private coordinationService: CoordinationService
	) {}

	ngOnInit() {
		this._getCoordinations()
	}

	private _getCoordinations() {
		this.coordinationService.getAll().subscribe((result) => {
			this.groups = result
		})
	}

	public handlerCoordinationSelected(coord) {
		this.router.navigate([
			'/dashboard/coordinaciones/' + coord.id + '/direcciones'
		])
	}
}
