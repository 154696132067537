import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { 
  MatIconModule, 
  MatProgressSpinnerModule, 
  MatExpansionModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule,
  MatButtonModule
} from '@angular/material';
import { LightboxModule } from 'ngx-lightbox';
import { FuseSharedModule } from '@fuse/shared.module';

import { AuthGuardService as AuthGuard } from '../../../app/auth/auth-guard.service';

import { NewsPortfoliosComponent } from './news-portfolios.component';
import { SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';

const routes: Routes = [
  {
    path: 'portafolios/:id/noticias',
    component: NewsPortfoliosComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [NewsPortfoliosComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    FuseSharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    LightboxModule
  ]
})
export class NewsPortfoliosModule { }
