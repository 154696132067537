import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { ActivatedRoute, Params } from '@angular/router'
import { ReportsService } from '../reports.service'
import { MatSnackBar, MatTreeNestedDataSource } from '@angular/material'

import { Portfolio } from '../models/portfolio.model'
import { Project } from '../models/project.model'
import { Stage } from '../models/stage.model'
import { Action } from '../models/action.model'
import { Task } from '../models/task.model'
import { NestedTreeControl } from '@angular/cdk/tree'

interface ObjectNode {
	name: string
	children?: ObjectNode[]
}

@Component({
	selector: 'app-general',
	templateUrl: './general.component.html',
	styleUrls: ['./general.component.scss'],
	animations: fuseAnimations
})
export class GeneralComponent implements OnInit {
	public allPorfolios: any[] = []
	public allProjects: any[] = []
	public projects: Project[] = []
	public projectsFiltered: Project[] = []
	public objectProjects: Project[] = []
	private portfolioId: number
	private projectId: number
	public typeReport: string = '1'
	public titleType: string = 'Portafolio'
	public idModel: number = 1
	public portfolio: Portfolio
	public activeReport: Boolean = false
	public step: number = 0
	public labelType: string = ''
	public canGenerate: boolean = true

	treeControl = new NestedTreeControl<ObjectNode>((node) => node.children)
	dataSource = new MatTreeNestedDataSource<ObjectNode>()
	activeAction = false

	constructor(
		private service: ReportsService,
		private router: ActivatedRoute,
		private snackBar: MatSnackBar,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.router.params.subscribe((params: Params) => {
			this.projectId = parseInt(params.idp, 0)
			this.portfolioId = parseInt(params.id, 0)
			if (!isNaN(this.portfolioId) && isNaN(this.projectId)) {
				this.idModel = this.portfolioId
				this.typeReport = '1'
				this.titleType = 'Portafolio'
			} else if (!isNaN(this.projectId)) {
				this.idModel = this.projectId
				this.typeReport = '2'
				this.titleType = 'Proyecto'
			}
		})

		this.service.getInit().subscribe((response: any) => {
			this.allPorfolios = response.portfolios
			this.allProjects = response.projects
		})
	}

	hasChild = (_: number, node: ObjectNode) =>
		!!node.children && node.children.length > 0

	ngOnInit(): void {
		this.getInitial()
	}

	public check(object: any): void {
		if (!object.type) {
			this.checkAction(object)
		} else {
			if (object.type == 1) {
				this.checkProject(object)
			} else if (object.type == 2) {
				this.checkStage(object)
			} else {
				this.checkAction(object)
			}
		}
	}

	public checkProject(project: Project): void {
		project.children.map((stage: Stage) => {
			stage.check = project.check
			stage.children.map((action: Action) => {
				action.check = project.check
				return action
			})
			return stage
		})
	}

	public checkStage(stage: Stage): void {
		this.projects.map((project: Project) => {
			if (project.id === stage.projectId) {
				const checkAllStage = project.children.filter((stageFilter: Stage) => {
					return stageFilter.check
				}).length

				const totalState = project.children.length

				project.indeterminate =
					totalState !== checkAllStage && checkAllStage > 0
				project.check = totalState === checkAllStage || checkAllStage > 0
			}
		})

		stage.children.map((action: Action) => {
			action.check = stage.check
			return action
		})
	}

	public checkAction(element: Action): void {
		const stageParent = element.stage_id
		this.projects.filter((project: Project) => {
			const index = project.children.findIndex((stage) => {
				return stage.id == stageParent
			})

			if (index >= 0) {
				project.children.filter((stage: Stage) => {
					const checkAllAction = stage.children.filter((action: Action) => {
						return action.check
					}).length
					const totalAction = stage.children.length

					stage.indeterminate =
						totalAction !== checkAllAction && checkAllAction > 0
					stage.check = totalAction === checkAllAction || checkAllAction > 0

					const checkAllStage = project.children.filter((stageC: Stage) => {
						return stageC.check
					}).length

					const totalState = project.children.length
					project.indeterminate =
						totalState !== checkAllStage && checkAllStage > 0
					project.check = totalState === checkAllStage || checkAllStage > 0
				})
			}
		})
	}

	public getInitial(): void {
		if (this.typeReport === '1') {
			this.service.getPortfolio(this.idModel).subscribe(
				(response: any) => {
					if (typeof response.projects !== 'undefined') {
						this.portfolio = new Portfolio(
							response.id,
							response.name,
							response.description,
							response.objective,
							response.promise,
							response.territorial_scale,
							response.benefited_population,
							null,
							response.municipalities,
							response.regions
						)
						let stagesCount = []
						const parseProjects = response.projects.map((project: any) => {
							// console.log(project)
							project = new Project(
								project.id,
								project.project_stages,
								project.dependency,
								1,
								project.name
							)

							const stages = project.children.filter((stage: any) => {
								return stage.subprojects.length > 0
							})
							stagesCount = stagesCount.concat(stages)

							project.children = project.children.map((stage: any) => {
								stage = new Stage(
									stage.id,
									stage.name,
									project.id,
									stage.subprojects,
									2,
									stage.semaphore
								)
								stage.children = stage.children.map((action: any) => {
									action = new Action(
										action.id,
										action.name,
										stage.id,
										action.tasks,
										action.stage_id,
										action.up_id,
										action.up,
										action.subprojects_financing,
										[],
										action.responsable,
										action.semaphore
									)
									action.tasks = action.tasks.map((task: any) => {
										task = new Task(
											task.id,
											task.date_assignment,
											task.date_estimated_finished,
											task.status,
											task.name,
											task.user
										)
										return task
									})
									return action
								})
								return stage
							})
							return project
						})

						if (stagesCount.length === 0) {
							this.canGenerate = false
						}

						this.projects = parseProjects
						const uniqueArray = []
						for (let i = 0; i < this.projects.length; i++) {
							if (
								!uniqueArray.find(
									(x) => x.dependency.id === this.projects[i].dependency.id
								)
							) {
								uniqueArray.push(this.projects[i])
							}
						}
						this.projectsFiltered = uniqueArray

						this.objectProjects = parseProjects
						this.dataSource.data = this.projects

						if (this.projects.length > 10) {
							this.uncheckall()
						}
					}
					this.labelType = this.getLabel()
				},
				(error) => {
					console.log(error)
				}
			)
		} else if (this.typeReport === '2') {
			this.service.getProject(this.idModel).subscribe(
				(response: any) => {
					if (typeof response.projects !== 'undefined') {
						this.portfolio = new Portfolio(
							response.id,
							response.name,
							response.description,
							response.objective,
							response.promise,
							response.territorial_scale,
							response.benefited_population,
							null,
							response.municipalities,
							response.regions
						)
						let stagesCount = []
						const parseProjects = response.projects.map((project: any) => {
							project = new Project(
								project.id,
								project.project_stages,
								project.dependency,
								1,
								project.name
							)
							project.children = project.children.map((stage: any) => {
								const stages = project.children.filter((stage: any) => {
									return stage.subprojects.length > 0
								})
								stagesCount = stagesCount.concat(stages)

								stage = new Stage(
									stage.id,
									stage.name,
									project.id,
									stage.subprojects,
									2,
									stage.semaphore
								)
								stage.children = stage.children.map((action: any) => {
									action = new Action(
										action.id,
										action.name,
										stage.id,
										action.tasks,
										action.stage_id,
										action.up_id,
										action.up,
										action.subprojects_financing,
										[],
										action.responsable,
										action.semaphore
									)
									// console.log(action)
									action.tasks = action.tasks.map((task: any) => {
										task = new Task(
											task.id,
											task.date_assignment,
											task.date_estimated_finished,
											task.status,
											task.name,
											task.user
										)
										return task
									})
									return action
								})
								return stage
							})
							return project
						})

						if (stagesCount.length === 0) {
							this.canGenerate = false
						}

						this.projects = parseProjects
						const uniqueArray = []
						for (let i = 0; i < this.projects.length; i++) {
							if (
								!uniqueArray.find(
									(x) => x.dependency.id === this.projects[i].dependency.id
								)
							) {
								uniqueArray.push(this.projects[i])
							}
						}
						this.projectsFiltered = uniqueArray

						this.objectProjects = parseProjects
						this.dataSource.data = this.projects
					}
					this.labelType = this.getLabel()
				},
				(error) => {
					console.log(error)
				}
			)
		}
	}

	public uncheckall(): void {
		this.projects.forEach((project) => {
			project.check = false
			project.children.forEach((stage) => {
				stage.check = false
				stage.children.forEach((action) => {
					action.check = false
				})
			})
		})
	}

	public applyFilter(filterValue: string): void {
		const data = this.projects.filter((up) => {
			return this.removeAccents(
				up.dependency.name.trim().toLowerCase()
			).includes(this.removeAccents(filterValue.trim().toLowerCase()))
		})
		this.dataSource.data = data
	}

	public getDependencies(): any {
		return this.projects.filter((project) => {
			return project.check
		})
	}

	public getItems(): any[] {
		if (this.typeReport === '1') {
			this.titleType = 'Portafolio'
			return this.allPorfolios
		} else {
			this.titleType = 'Proyectos'
			return this.allProjects
		}
	}

	public getLabel(): string {
		return `${this.portfolio.name}`
	}

	public getNameElemet(element: any): string {
		if (typeof element.name !== 'undefined') {
			return element.name
		}

		return `Projecto ${element.id}`
	}

	public setStep(step: number): void {
		this.step = step
	}

	public generate(): void {
		this.activeReport = false
		let final = JSON.parse(JSON.stringify(this.projects))

		final = final.filter((project: Project) => {
			project.children = project.children.filter((stage: Stage) => {
				stage.children = stage.children.filter((action: Action) => {
					return action.check
				})

				return stage.check
			})

			return project.check && project.children.length > 0
		})

		if (final.length > 0) {
			this.portfolio.projects = final
			this.changeDetectorRef.detectChanges()
			this.activeReport = true

			this.step = 1
		} else {
			this.snackBar.open(
				'No cuenta con datos para generar el reporte',
				'Error',
				{
					duration: 3500
				}
			)
		}
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}

		return strAccentsOut.join('')
	}
}
