import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'profile/password';
    this.httpOptions = global.httpOptions;
  }

  update(data: any): Observable<any> {
    return this.http.put(this.uri + this.module, data, this.httpOptions);
  }
}
