import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListStagesModule } from './list-stages/list-stages.module';
import { CdkTableModule } from '@angular/cdk/table';
import {
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule,
  MatStepperModule
} from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
    FuseConfirmDialogModule,
    ListStagesModule
  ],
  declarations: []
})
export class StagesModule { }
