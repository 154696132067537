import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionDetailComponent implements OnInit {

  public action: any;
  public avatar: string;
  public borderColor: string;
  public depColor: string;

  constructor(
    public dialogRef: MatDialogRef<ActionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: any }
  ) { }

  ngOnInit() {
    this.action = this.data.action;
    this.avatar = `url(${this.action.action.responsable.avatar})`;
    this.borderColor = `2px solid ${this.action.state.project.dependency.color}`;
    this.depColor = this.action.state.project.dependency.color;
  }

  getTasksProgress(action: any): any {
    const total = <number>action.tasks_finished * 100 / <number>action.tasks_all;
    return isNaN(total) ? 0 : total.toFixed(0);
  }

}
