import { Up } from "app/ups/up.model";

export class Comunication {
    id: number = 0;
    name: string = null;
    last_name: string = null;
    m_last_name: string = null;
    email: string = null;
    up_id: number = null;
    up: Up;
    full_name: string = '';

    active: boolean = true;
    created_at: Date;
    updated_at: Date;
}
