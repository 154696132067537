import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { MatSnackBar, MatDialog } from '@angular/material'
import { DashboardService } from '../dashboard.service'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { AdvanceBoardComponent } from '../advance-board/advance-board.component'
import { AuthService } from 'app/services/auth.service'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-index-dashboard',
	templateUrl: './index-dashboard.component.html',
	styleUrls: ['./index-dashboard.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class IndexDashboardComponent implements OnInit {
	id_portfolio: any
	id_project: any
	id_stage: any
	portfolio: any
	project: any
	stage: any
	type1 = 'portfolio'
	type2 = 'project'
	type3 = 'stage'
	type4 = 'subproject'
	mini = true
	sizeMini = '90px'
	sizeMini2 = '80px'
	styleMini = { width: this.sizeMini, height: this.sizeMini, fontSize: '14px' }
	styleMini2 = {
		width: this.sizeMini2,
		height: this.sizeMini2,
		fontSize: '13px'
	}
	typeCurrent: any
	colorStage = '#243984'
	public userAdmin: Boolean = false
	nameStage = 'Etapa '
	coordinacionId = null
	directorateId = null

	styleCenterStage = {
		background: '#eaeaea',
		'border-radius': '50%',
		height: '100%',
		width: '100%',
		display: 'flex',
		'align-items': 'center',
		'justify-content': 'center',
		color: '#656565',
		'margin-left': '-4px',
		padding: '19px'
	}

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private authService: AuthService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userAdmin = this.authService.isAdmin()

		this.dashboardService.coordination.subscribe(
			(coordinationId) => (this.coordinacionId = coordinationId)
		)

		this.dashboardService.directorate.subscribe(
			(directorateId) => (this.directorateId = directorateId)
		)

		// Portfolio
		this.dashboardService.portfolio.subscribe((dataPort) => {
			if (
				dataPort.constructor === Object &&
				Object.entries(dataPort).length !== 0
			) {
				this.portfolio = dataPort

				// Project
				this.dashboardService.current.project.subscribe((projectId) => {
					if (projectId > 0) {
						this.id_project = projectId
						this.getProject()

						// Stage
						this.dashboardService.current.stage.subscribe((stageId) => {
							if (stageId > 0) {
								this.id_stage = stageId
								this.getStage()
							} else {
								this.stage = null
							}
						})
					} else {
						this.project = null
						this.stage = null
					}
				})
			} else {
				this.portfolio = null
				this.project = null
				this.stage = null
			}
		})
	}

	existsProjects(): boolean {
		return this.portfolio && this.portfolio.projects
	}

	isPortfolio(): boolean {
		return this.existsProjects() && this.portfolio.projects.length > 1
	}

	getFirstProject(): any {
		return this.existsProjects() && this.portfolio.projects.length > 0
			? this.portfolio.projects[0].id
			: {}
	}

	getProject(): void {
		if (this.portfolio) {
			this.project = this.portfolio.projects.filter((project) => {
				return project.id === parseInt(this.id_project, 10)
			})
			if (this.project.length > 0) {
				this.project = this.project[0]
			}
		}
	}

	getStage(): void {
		this.nameStage = 'Etapa '
		this.stage = this.project.project_stages.filter((stage, index) => {
			if (stage.id === parseInt(this.id_stage, 10)) {
				this.nameStage = this.nameStage + (stage.position + 1)
				return stage.id === parseInt(this.id_stage, 10)
			}
		})
		if (this.stage.length > 0) {
			this.stage = this.stage[0]
		}
	}

	getData(): any {
		return [
			{ name: '', data: [] },
			{ name: '', data: [] }
		]
	}

	openUserInfoDialog(user: any): void {
		this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: user,
				up: user.up
			}
		})
	}

	openBoardPermissions(): void {
		this.dialog.open(AdvanceBoardComponent, {
			panelClass: 'dialog-board-info',
			disableClose: true
		})
	}

	gotoBack(level: number): any {
		if (level === 1) {
			return [
				'/dashboard',
				'coordinaciones',
				this.coordinacionId,
				'direcciones',
				this.directorateId
			]
		} else if (level === 2) {
			if (this.isPortfolio()) {
				return [
					'/dashboard',
					'coordinaciones',
					this.coordinacionId,
					'direcciones',
					this.directorateId,
					'portafolio',
					this.portfolio.id
				]
			} else {
				return [
					'/dashboard',
					'coordinaciones',
					this.coordinacionId,
					'direcciones',
					this.directorateId,
					'portafolio',
					this.portfolio.id,
					'proyecto',
					this.getFirstProject()
				]
			}
		} else if (level === 3) {
			return [
				'/dashboard',
				'coordinaciones',
				this.coordinacionId,
				'direcciones',
				this.directorateId,
				'portafolio',
				this.portfolio.id,
				'proyecto',
				this.project.id
			]
		}
	}

	getClassCursor(level): boolean {
		if (level === 1) {
			return !!this.project
		} else if (level === 2) {
			return !!this.stage
		}
	}
}
