import { Component, OnInit, ViewEncapsulation, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { DialogAddTasksComponent } from '../dialog-add-tasks/dialog-add-tasks.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { fuseAnimations } from '@fuse/animations';
import { DetailProjectsService } from '../../projects/detail-projects/detail-projects.service';
import { Task } from '../task';
import { AuthService } from '../../services/auth.service';
import { Project } from '../../projects/project';
import { Stage } from '../../stages/stage';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TasksService } from '../tasks.service';
import { CommunicationService } from '../../services/communication.service';
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component';
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component';
import { saveAs } from 'file-saver';
import { RingChartComponent } from 'app/reports/ring-chart/ring-chart.component';
import { Utils } from 'app/constants/utils';
import { SnackBarComponent } from 'app/general/components/snack-bar-component/snack-bar.component';

@Component({
  selector: 'app-details-tasks',
  templateUrl: './details-tasks.component.html',
  styleUrls: ['./details-tasks.component.scss'],
  animations: fuseAnimations,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  encapsulation: ViewEncapsulation.None
})
export class DetailsTasksComponent implements OnInit {
  @ViewChild('projectProgress', {static: true}) projectProgress: RingChartComponent;
  @ViewChild('stageProgress', {static: true}) stageProgress: RingChartComponent;
  @ViewChild('subprojectProgress', {static: true}) subprojectProgress: RingChartComponent;
  dataComment: any;
  task: Task = new Task();
  url: string;
  project: any = new Project();
  stage: any = new Stage();
  action: any = {};
  logs: any;
  typesFiles: any;
  routeImages = 'assets/images/tasks/icons/';
  files: any = [];
  @ViewChildren('file') filesRef: QueryList<ElementRef>;
  progress: any;
  valUpload = 50;
  filesUploaded = [];
  namesFilesUploaded = [];

  imagesProgress = {
    projectProgress: '',
    stageProgress: '',
    subprojectProgress: ''
  };
  public isResponsable: Boolean = false;

  centerStyle = {
    'background': '#EAEAEA',
    'border': '8px solid #FFF',
    'padding-top': '25%'
  };

  centerStyleCustom = {
    'background': '',
    'color': '#FFFFFF',
    'border': '8px solid #FFF',
    'padding-top': '25%'
  };

  colorGreen = '#1AAE60';
  colorYellow = '#FFD938';
  colorRed = '#D6264A';
  public downloadingTask = false;

  constructor(
    public detailProjectsService: DetailProjectsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private taskService: TasksService,
    private communicationService: CommunicationService,
    private utils: Utils
  ) { }

  ngOnInit(): void {
    // Validar Tarea Existente
    this.taskService.checkExists(this.detailProjectsService.current.action.value, this.detailProjectsService.current.task.value).subscribe((data: any) => {
      if (Object.entries(data).length === 0 && data.constructor === Object){
        this.router.navigate(['/portafolios']);
          return;
      }
    });

    this.detailProjectsService.project.subscribe(project => { 
      this.project = project;
      this.projectProgress.porcent = this.utils.getNewPorcent('project', this.project);
      if (this.project && this.project.dependency && this.project.dependency.color) {
        this.projectProgress.color = this.project.dependency.color;
      }
    });
    this.detailProjectsService.stage.subscribe(stage => {
      if (!stage || stage.id === 0) {
        return;
      }
      this.stage = stage;
      this.stageProgress.porcent = this.utils.getNewPorcent('stage', this.stage);

      this.stageProgress.centerStyle = this.centerStyle;

      const semaphore = this.stage.semaphore;
      const completeAdvance = this.stage.tasks_finished === this.stage.tasks_all && this.stage.tasks_all > 0;
      const centerStyleNew = JSON.parse(JSON.stringify(this.centerStyleCustom));

      if (completeAdvance){
        centerStyleNew.background = this.colorGreen;
        this.stageProgress.color = this.colorGreen;
        return;
      }
      
      if (this.stage && this.stage.semaphore === 0 && this.stage.tasks_all > 0) {
        centerStyleNew.background = this.colorRed;
        this.stageProgress.centerStyle = centerStyleNew;
        this.stageProgress.color = this.colorRed;
      }

      if (!completeAdvance) {
        this.stageProgress.color = (semaphore >= 50) ? this.colorGreen : (semaphore >= 20 && semaphore <= 49) ? this.colorYellow : this.colorRed;
      }
    });
    this.detailProjectsService.action.subscribe(action => {
      if (!action || action.id === 0) {
        return;
      }
      this.action = action;
      this.subprojectProgress.porcent = this.utils.getNewPorcent('subproject', this.action);

      this.subprojectProgress.centerStyle = this.centerStyle;
      
      const semaphore = this.action.semaphore;
      const completeAdvance = this.action.tasks_finished === this.action.tasks_all && this.action.tasks_all > 0;
      const centerStyleNew = JSON.parse(JSON.stringify(this.centerStyleCustom));

      if (completeAdvance){
        centerStyleNew.background = this.colorGreen;
        this.subprojectProgress.color = this.colorGreen;
        return;
      } 
      
      if (this.action && this.action.semaphore === 0 && this.action.tasks_all > 0) {
        centerStyleNew.background = this.colorRed;
        this.subprojectProgress.centerStyle = centerStyleNew;
        this.subprojectProgress.color = this.colorRed;
      }

      if (!completeAdvance) {
        this.subprojectProgress.color = (semaphore >= 50) ? this.colorGreen : (semaphore >= 20 && semaphore <= 49) ? this.colorYellow : this.colorRed;
      }
    });
    this.detailProjectsService.task.subscribe(task => {
      if (!task || task.id === 0) {
        return;
      }

      const logs = task.log.reduce((data, log) => {
        const before = data.length > 0 ? data[data.length - 1].task : false;
        const logData: any = {};
        logData.task = {};
        logData.files = !log.status ? [] : log.status.files;
        logData.links = !log.status ? [] : log.status.links;
        logData.status = !log.status ? null : log.status.status;
        logData.user = !log.status ? null : log.status.user;
        logData.comment = !log.status ? null : log.status.comment;
        logData.id = log.task_status_id;
        logData.created_at = !log.status ? null : log.status.created_at;
        logData.created_datetime = !log.status ? null : log.status.created_datetime;
        logData.creator = !log.status ? null : log.status.creator;

        if (log.data) {

          Object.keys(!before ? log.data : before).forEach(key => {
            logData.task[key] = {
              updated: !before ? false : ((log.data.hasOwnProperty(key) ? (log.data[key] !== before[key].value ? true : false) : false)),
              value: !before ? log.data[key] : ((log.data.hasOwnProperty(key) ? log.data[key] : before[key].value))
            };
          });

          logData.task.user = {
            updated: !before ? false : (!log.status ? true : (log.status.user_id !== before.user.value ? true : false)),
            value: !log.status ? 0 : log.status.user_id
          };

          logData.task.status = {
            updated: !before ? false : (!log.status ? true : (log.status.status !== before.status.value ? true : false)),
            value: !log.status ? '--' : log.status.status
          };

        }

        data.push(logData);
        return data;
      }, []);

      this.task = task;

      this.isResponsable = this.task.user.id === this.authService.id;
      this.logs = logs.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    });

    this.typesFiles = [
      { ext: ['pdf'], color: '#E1431A', icon: this.routeImages + 'pdf.png' },
      { ext: ['doc', 'docx'], color: '#19BDF9', icon: this.routeImages + 'word.png' },
      { ext: ['txt'], color: '#E7BD5A', icon: this.routeImages + 'text.png' },
      { ext: ['png', 'jpg', 'jpeg'], color: '#99B4DD', icon: this.routeImages + 'imgn.png' },
      { ext: ['ppt'], color: '#ED8116', icon: this.routeImages + 'ppt.png' },
      { ext: ['xls', 'xlsx', 'gsheet'], color: '#16C155', icon: this.routeImages + 'excel.png' },
      { ext: ['mp3', 'mp4'], color: '#766ED3', icon: this.routeImages + 'multimedia.png' },
      { ext: ['zip', 'rar'], color: '#B59FC6', icon: this.routeImages + 'zip.png' }
    ];
  }

  getName(log: any): string {
    if (log && log.user && log.user.name) {
      return log.user.name + ' ' + log.user.last_name;
    }
    return '';
  }
  
  getStatus(value: string): string {
    let translate = '';

    switch (value) {
      case 'created':
        translate = 'Nueva';
        break;
      case 'processing':
        translate = 'Procesando';
        break;
      case 'finished':
        translate = 'Finalizada';
        break;
      case 'cancelled':
        translate = 'Cancelada';
        break;
      case 'paused':
        translate = 'Pausada';
        break;

      default:
        break;
    }

    return translate;
  }

  openDialog(update: boolean, task: any): void {
    update = typeof update !== 'undefined';
    window.history.pushState({}, '', location.pathname + '#');
    const dialogRef = this.dialog.open(DialogAddTasksComponent, {
      panelClass: 'mat-dialog-system',
      disableClose: true,
      data: {
        subproject: this.action.id,
        update: update,
        task: typeof task === 'undefined' ? null : task.id
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        let msn = update ? 'Actividad Actualizada' : 'Actividad Agregada';

        if (typeof response !== 'undefined') {
          if (response.status !== true) {
            msn = 'Error';
          }

          if (!response) {
            if (response !== undefined){
              this.location.back();
            }
            return false;
          }
          this.location.back();

          this.snackBar.open(msn, 'OK', {
            duration: 2000
          });
          
          this.detailProjectsService.refreshTasks();
          this.detailProjectsService.refreshActions();
          this.detailProjectsService.refreshProject();
          this.detailProjectsService.refreshStages();
        }
      });
  }

  hasPermission(): boolean {
    return this.authService.hasPermission(this.authService.module);
  }

  goToCurrentStage(): void {
    this.detailProjectsService.goToCurrentStage();
  }

  goToCurrentAction(): void {
    this.detailProjectsService.goToCurrentAction();
  }

  hasAvatar(user: any): boolean {

    if (!user) {
      return false;
    }

    if (typeof user.avatar === 'undefined') {
      return false;
    }
    return user.avatar.length <= 0 ? false : true;
  }

  getImgAvatar(user: any): string {
    return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none';
  }

  getUserFullName(user: any): string {
    if (!user) {
      return '';
    }

    return user.name + ' ' + user.last_name;
  }

  getFieldTask(log: any, field: string): string {
    if (!log.task.hasOwnProperty(field)) {
      return '';
    }

    return log.task[field].value;
  }

  hasChangesFieldTask(log: any, field: string): boolean {
    if (!log.task.hasOwnProperty(field)) {
      return false;
    }

    return log.task[field].updated;
  }

  getLabelStatusFieldTask(log, field, updatedLbl, currentLbl): string {
    return this.hasChangesFieldTask(log, field) ? updatedLbl : currentLbl;
  }

  toggle(task: Task): void{
    this.dataComment = {
      'name': 'Actividad: ' + task.name,
      'level_name': 'task',
      'element_id': task.id
    };
    this.communicationService.emitChange(this.dataComment);
  }

  openTaskDeleteDialog(idTask: number, name: string): void {
    const dialog = this.dialog.open(ConfirmComponent, {
      panelClass: 'dialog-confirm',
      data: {
        content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar la Actividad <strong><br>"${name}"</strong>?<label>`
      }
    });

    dialog.afterClosed()
      .subscribe(response => {
        if (response) {
          this.taskService.delete(idTask).subscribe(data => {
            this.snackBar.open('Actividad Eliminada', 'OK', {
              duration: 3500
            });
            this.detailProjectsService.refreshTasks();
            this.detailProjectsService.refreshActions();
            this.detailProjectsService.refreshProject();
            this.detailProjectsService.refreshStages();
            this.goToCurrentAction();
          }, error => {
            this.snackBar.open('Ocurrió un error al eliminar la Actividad', 'ERROR', {
              duration: 3000
            });
          });
        }
      });
  }

  openUserInfoDialog(user: any): void {
    this.dialog.open(UserInfoComponent, {
      panelClass: 'dialog-user-info',
      data: {
        user: user,
        up: user.up
      }
    });
  }

  /* ADD FILES */

  validateActionDepend(action: number, complete: number): boolean {
    if (action > 0) {
      if (complete > 0) {
        return true;
      } 
      return false;
    } else {
      return true;
    }
  }

  addFiles(index: number): void {
    this.filesRef.forEach((ref, i) => {
      if (i === index){
        ref.nativeElement.click();
      }
    });
  }

  onFilesAdded(status_id: any, index:  number): void {
    let elementRef = null;
    this.filesRef.forEach((ref, i) => {
      if (i === index){
        elementRef = ref;
      }
    });

    if (!elementRef) {
      return;
    }

    const files: { [key: string]: File } = elementRef.nativeElement.files;
    if (!this.files[status_id + 'id']){
      this.files[status_id + 'id'] = [];
    }

    if (!this.filesUploaded[status_id + 'id']){
      this.filesUploaded[status_id + 'id'] = [];
      this.namesFilesUploaded[status_id + 'id'] = [];
    }

    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files[status_id + 'id'].unshift(files[key]);
      }
    }
    this.uploadFiles(status_id);
  }

  uploadFiles(status_id: any): void{
    if (this.files[status_id + 'id'].length > 0) {
      const pendingFiles = this.files[status_id + 'id'].filter((file: any) => {
        return this.filesUploaded[status_id + 'id'].indexOf(file.name) >= 0 ? false : true;
      });

      this.progress = this.taskService.save(status_id, pendingFiles);

      for (const key in this.progress) {
        if (this.progress.hasOwnProperty(key)) {
          this.progress[key].progress.subscribe(progressTime => {
            if (progressTime === 100){
              this.filesUploaded[status_id + 'id'].unshift(this.progress[key].file.name);
              this.namesFilesUploaded[status_id + 'id'].unshift(this.progress[key].path_file);
              this.taskService.notifyComunication(status_id).subscribe();
            }
          }, error => {
            console.log(error);
            setTimeout(() => {
              const index1 = this.filesUploaded[status_id + 'id'].findIndex(el => el.name === this.progress[key].file.name);
              if (index1 >= 0){
                this.filesUploaded[status_id + 'id'].splice(index1, 1);
                this.namesFilesUploaded[status_id + 'id'].splice(index1, 1);
              }
              const index = this.files[status_id + 'id'].findIndex(el => el.name === this.progress[key].file.name);
              if (index >= 0){
                this.files[status_id + 'id'].splice(index, 1);
              }
            }, 200);
          });
        }
      }
    }
  }

  verifyUploadedFile(name: string, status_id: any): boolean{
    if (this.filesUploaded && this.filesUploaded[status_id + 'id']) {
      const index = this.filesUploaded[status_id + 'id'].indexOf(name);
      if (index >= 0){
        return false;
      }
    }
    return true;
  }

  getFiles(status_id: any): any[]{
    if (this.files[status_id + 'id']){
      return this.files[status_id + 'id'];
    }

    return [];
  }

  getColorIconFile(file: any): any{
    const objDef = { ext: [''], color: '#AFB5B8', icon: this.routeImages + 'otros.png' };

    if (!file || !file.name){
      return objDef;
    }
    const extension = file.name.split('.').pop().toLowerCase();
    const index = this.typesFiles.findIndex((x: any) => x.ext.indexOf(extension) > -1);

    if (index >= 0) {
      return this.typesFiles[index];
    }
    return objDef;
  }

  async downloadTask(): Promise<void> {
    if (this.downloadingTask){
      return;
    }
    this.downloadingTask = true;
    const snack = this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: 'Descargando ficha'
      }
    });
    await this.createImages();

    this.taskService.getTaskFile(this.task.id, this.imagesProgress).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, 'ficha_actividad.pdf');
      snack.dismiss();
      this.downloadingTask = false;
    });
  }

  // tslint:disable-next-line: typedef
  private async createImages() {
    for (const type in this.imagesProgress) {
      if (type === 'projectProgress') {
        await this.projectProgress.createImage(type);
          this.imagesProgress[type] = this.projectProgress.imgUrl;
      } else if (type === 'stageProgress') {
        await this.stageProgress.createImage(type);
          this.imagesProgress[type] = this.stageProgress.imgUrl;
      } else if (type === 'subprojectProgress') {
        await this.subprojectProgress.createImage(type);
          this.imagesProgress[type] = this.subprojectProgress.imgUrl;
      } 
    }
  }
}
