import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IndexProjectsModule } from "./index-projects/index-projects.module";
import { CdkTableModule } from "@angular/cdk/table";
import {
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
} from "@angular/material";
import { FuseConfirmDialogModule } from "@fuse/components";
import { FormProjectsModule } from "./form-projects/form-projects.module";
import { DetailProjectsModule } from "./detail-projects/detail-projects.module";
import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ".",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ",",
    nullable: false,
};

@NgModule({
    imports: [
        CommonModule,
        CdkTableModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        FuseConfirmDialogModule,
        IndexProjectsModule,
        FormProjectsModule,
        MatStepperModule,
        DetailProjectsModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ],
    declarations: [],
    // providers: [ ExcelService ]
})
export class ProjectsModule {}
