import {
	Component,
	OnInit,
	Inject,
	ViewEncapsulation,
	ViewChild,
	ElementRef
} from '@angular/core'
import {
	FormBuilder,
	FormGroup,
	FormArray,
	Validators,
	FormControl
} from '@angular/forms'
import { Observable } from 'rxjs'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { map, startWith } from 'rxjs/operators'

import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatStepper,
	MatAutocomplete,
	MatTableDataSource,
	MatAutocompleteSelectedEvent,
	MatChipInputEvent,
	MatSnackBar
} from '@angular/material'

import { ProjectsService } from '../../projects/projects.service'
import { PortfoliosService } from '../portfolios.service'
import { Dependency } from '../../dependencies/dependency'
import { CatalogsService } from '../../services/catalogs.service'

export interface ExecutorsAuxiliaries {
	id: number
	name: string
	id_user: number
	name_user: string
	users: any
}

@Component({
	selector: 'app-dialog-dependencies-portfolios',
	templateUrl: './dialog-dependencies-portfolios.component.html',
	styleUrls: ['./dialog-dependencies-portfolios.component.scss']
})
export class DialogDependenciesPortfoliosComponent implements OnInit {
	@ViewChild('executors', { static: false })
	matAutocompleteExecutors: MatAutocomplete
	@ViewChild('executorsUsers', { static: false })
	matAutocompleteExecutorsUsers: MatAutocomplete
	@ViewChild('auxiliaries', { static: false })
	matAutocompleteAuxiliaries: MatAutocomplete
	@ViewChild('auxiliariesUsers', { static: false })
	matAutocompleteAuxiliariesUsers: MatAutocomplete

	form: FormGroup
	action: String = 'create'
	formErrors: any
	addOnBlur = true
	readonly separatorKeysCodes: number[] = [ENTER, COMMA]
	axis = <any>[]
	saving: Boolean = false
	maxLengthDescription = 120
	maxLengthName = 30
	portfolio: any

	show_table_executor: Boolean = true
	show_table_auxiliar: Boolean = true
	errorStepExe: Boolean = false
	errorStepAux: Boolean = false
	icon_exe: string = 'add_circle'
	icon_aux: string = 'add_circle'

	// Responsibles
	responsiblesCtrl = new FormControl()
	filteredResponsibles: Observable<string[]>
	responsiblesObject: any = []
	responsibleActive: string

	// Responsible User
	filteredResponsiblesUsers: Observable<string[]>
	responsiblesUsersCtrl = new FormControl()
	responsiblesUsersObject: any = null
	responsibleUserActive: string
	errorUserResponsible: Boolean = false

	// Executors
	filteredExecutors: Observable<any[]>
	executorsCtrl = new FormControl()
	executorsObject: any = []
	executorActive: any
	addExecutorsObject: any = []
	displayedColumns = ['depExecutor', 'userExecutor', 'removeExecutor']
	executorsTable: MatTableDataSource<ExecutorsAuxiliaries>

	// Executors Users
	filteredExecutorsUsers: Observable<any[]>
	executorsUsersCtrl = new FormControl()
	executorsUsersObject: any = null
	executorUserActive: any
	addExecutorsUsers: any = []

	// Auxiliaries
	filteredAuxiliaries: Observable<string[]>
	auxiliariesCtrl = new FormControl()
	auxiliariesObject: any = []
	auxiliarActive: any
	addAuxiliariesObject: any = []
	displayedAuxiliarColumns = ['depAuxiliar', 'userAuxiliar', 'removeAuxiliar']
	auxiliariesTable: MatTableDataSource<ExecutorsAuxiliaries>

	// Auxiliaries Users
	filteredAuxiliariesUsers: Observable<string[]>
	auxiliariesUsersCtrl = new FormControl()
	auxiliariesUsersObject: any = null
	auxiliarUserActive: any
	addAuxiliariesUsers: any = []

	constructor(
		public snackBar: MatSnackBar,
		private formBuilder: FormBuilder,
		private projectsService: ProjectsService,
		public dialogRef: MatDialogRef<DialogDependenciesPortfoliosComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private catalogsService: CatalogsService,
		private portfoliosService: PortfoliosService
	) {
		this.form = this.formBuilder.group({
			executors: [<any>[]],
			auxiliaries: [<any>[]]
		})
		this.executorsTable = new MatTableDataSource()
		this.auxiliariesTable = new MatTableDataSource()
	}

	ngOnInit(): void {
		this.portfolio = this.data

		this.catalogsService.ups.subscribe((result) => {
			this.responsiblesObject = result.ups
			this.filteredResponsibles = this.responsiblesCtrl.valueChanges.pipe(
				startWith(''),
				map((val) => this.filter(val, this.responsiblesObject))
			)
			this.loadExecutorsAuxiliares()
			this.initDependencies()
		})
	}

	initDependencies(): any {
		this.portfolio.projects.forEach((project) => {
			const dependency = project.dependency
			const user = project.responsible
			const dependencyCat = this.responsiblesObject.find(
				(x) => x.id === dependency.id
			)

			if (!dependencyCat) {
				return
			}

			const obj = {
				id: dependency.id,
				name: dependency.name,
				id_user: user.id,
				name_user: user.name + ' ' + user.last_name,
				users: dependencyCat.users,
				type: 'registered'
			}

			this.executorsTable.data.unshift(obj)
			this.executorsTable.data = this.executorsTable.data
			this.filterExecutors(obj, 1)
		})

		this.portfolio.auxiliaries.forEach((aux) => {
			const dependency = aux.dependency
			const user = aux.user
			const dependencyCat = this.responsiblesObject.find(
				(x) => x.id === dependency.id
			)

			if (!dependencyCat) {
				return
			}

			const obj = {
				id: dependency.id,
				name: dependency.name,
				id_user: !user ? 0 : user.id,
				name_user: !user ? '' : user.name + ' ' + user.last_name,
				users: !dependencyCat.users ? [] : dependencyCat.users,
				type: 'registered'
			}

			let type = 'Estratégico'
			const project = this.portfolio.projects.find((p) =>
				(p.id === aux.project_id) === null ? 0 : aux.project_id
			)
			if (typeof project !== 'undefined') {
				type = project.type
			}

			if (aux.project_id === null || type !== 'Transversal') {
				this.auxiliariesTable.data.unshift(obj)
				this.auxiliariesTable.data = this.auxiliariesTable.data
				this.filterAuxiliaries(obj, 1)
			}
		})

		this.auxiliarActive = null
		this.auxiliarUserActive = null
		this.cleanExecutor()
	}

	filter(val: any, object: any): string[] {
		if (!val) {
			return object
		}

		if (!Number.isInteger(val)) {
			return object.filter((option) =>
				option.name.toLowerCase().includes(val.toLowerCase())
			)
		}
	}

	loadExecutorsAuxiliares(): void {
		const responsible = this.portfolio.up
		// console.log(this.responsiblesObject)
		this.executorsObject = this.auxiliariesObject = this.responsiblesObject
		/*this.responsiblesObject.filter((option) => {
				// const matchDependency = this.portfolio.projects.filter(project => project.dependency.id === option.id);
				// return matchDependency.length === 0 && option.id !== responsible.id;
				return option.id !== responsible.id
			})*/
		// console.log(this.responsiblesObject)
		this.filteredExecutors = this.executorsCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filter(val, this.executorsObject))
		)

		this.filteredAuxiliaries = this.auxiliariesCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filter(val, this.auxiliariesObject))
		)
	}

	validateUserAssigned(auxiliar: any): any {
		const users = auxiliar.users

		let used = this.executorsTable.data.concat(this.auxiliariesTable.data)
		used = used.filter((dep) => {
			return dep.id === auxiliar.id
		})

		let available = users
		if (used.length > 0) {
			/*available = users.filter((user: any) => {
				return used.filter((use) => use.id_user !== user.id).length > 0
			})*/
		}

		return available
	}

	displayExecutorsFn(item): any {
		return this.matAutocompleteExecutors.options
			.filter((x) => x.value === item)
			.map((x) => x.viewValue)[0]
			? this.matAutocompleteExecutors.options
					.filter((x) => x.value === item)
					.map((x) => x.viewValue)[0]
			: item && item.name
			? item.name
			: ''
	}

	displayExecutorsUsersFn(item): any {
		return this.matAutocompleteExecutorsUsers.options
			.filter((x) => x.value === item)
			.map((x) => x.viewValue)[0]
			? this.matAutocompleteExecutorsUsers.options
					.filter((x) => x.value === item)
					.map((x) => x.viewValue)[0]
			: item && item.name
			? item.name
			: ''
	}

	displayAuxiliariesFn(item): any {
		return this.matAutocompleteAuxiliaries.options
			.filter((x) => x.value === item)
			.map((x) => x.viewValue)[0]
			? this.matAutocompleteAuxiliaries.options
					.filter((x) => x.value === item)
					.map((x) => x.viewValue)[0]
			: item && item.name
			? item.name
			: ''
	}

	displayAuxiliariesUsersFn(item): any {
		return this.matAutocompleteAuxiliariesUsers.options
			.filter((x) => x.value === item)
			.map((x) => x.viewValue)[0]
			? this.matAutocompleteAuxiliariesUsers.options
					.filter((x) => x.value === item)
					.map((x) => x.viewValue)[0]
			: item && item.name
			? item.name
			: ''
	}

	optionSelectedExecutorUser(event): any {
		const id = event.option.value
		const selected = this.executorsUsersObject.filter((option) => {
			return option.id === id
		})

		if (selected.length > 0) {
			this.executorUserActive = selected[0]
		}
	}

	addExecutors(): any {
		if (this.executorActive && this.executorUserActive) {
			const obj = {
				id: this.executorActive.id,
				name: this.executorActive.name,
				id_user: this.executorUserActive.id,
				name_user:
					this.executorUserActive.name +
					' ' +
					this.executorUserActive.last_name,
				users: this.executorActive.users,
				type: 'new'
			}

			this.executorsTable.data.unshift(obj)
			this.executorsTable.data = this.executorsTable.data

			this.form.get('executors').setValue(this.executorsTable.data)

			this.executorsCtrl.patchValue('')
			this.executorsUsersCtrl.patchValue('')
			this.filterExecutors(this.executorActive, 1)
			this.executorActive = null
			this.executorUserActive = null
			this.cleanAuxiliar()
		}
	}

	getListExecutors(): any {
		if (this.executorsTable.data && this.executorsTable.data.length > 0) {
			let list = ''
			this.executorsTable.data.forEach((elem, index) => {
				const symbol =
					index === this.executorsTable.data.length - 1 ? '' : ' / '
				list += `${elem.name}, ${elem.name_user}${symbol}`
			})
			return list
		}
		return ''
	}

	showListExecutor(): boolean {
		if (
			this.executorsTable.data &&
			this.executorsTable.data.length > 0 &&
			!this.show_table_executor
		) {
			return true
		}
		return false
	}

	changeTableListExecutor(): void {
		this.show_table_executor = !this.show_table_executor
	}

	filterExecutors(el, action): void {
		if (action === 1) {
			/*console.log(this.executorsObject)
			this.executorsObject = this.executorsObject.filter((option) => {
				return option.id !== el.id
			})
			console.log(this.executorsObject)*/
		} else {
			// this.executorsObject.push(el)
		}
		this.filteredExecutors = this.executorsCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filter(val, this.executorsObject))
		)
	}

	removeExecutor(executor: any): void {
		this.executorsObject.push({
			id: executor.id,
			name: executor.name,
			users: executor.users
		})

		const filter = this.executorsTable.data.filter((option) => {
			return option.id !== executor.id
		})

		this.executorsTable.data = filter
		this.form.get('executors').setValue(this.executorsTable.data)
		this.cleanExecutor()
		this.cleanAuxiliar()
	}

	onBlurExecutor(event): void {
		if (this.executorActive && event.target.value.length === 0) {
			this.cleanExecutor()
			this.loadExecutorsAuxiliares()
			this.errorStepExe = false
		}
	}

	cleanExecutor() {
		this.executorActive = null
		this.executorsCtrl.patchValue('')
		this.executorUserActive = null
		this.executorsUsersCtrl.patchValue('')
		this.executorsUsersObject = null
		this.filteredExecutorsUsers = null
	}

	cleanAuxiliar() {
		this.auxiliarActive = null
		this.auxiliariesCtrl.patchValue('')
		this.auxiliarUserActive = null
		this.auxiliariesUsersCtrl.patchValue('')
		this.auxiliariesUsersObject = null
		this.filteredAuxiliariesUsers = null
	}

	optionSelectedExecutor(event): void {
		const id = event.option.value
		const selected = this.executorsObject.filter((option) => {
			return option.id === id
		})

		if (selected.length > 0) {
			this.loadUsersExecutorsAuxiliaries(selected[0], 1)
			this.executorActive = selected[0]
		}
	}

	loadUsersExecutorsAuxiliaries(auxExec: any, type: Number) {
		const availables = this.validateUserAssigned(auxExec)
		// console.log(availables)

		if (type === 1) {
			this.executorsUsersObject = availables
			this.filteredExecutorsUsers = this.executorsUsersCtrl.valueChanges.pipe(
				startWith(''),
				map((val) => this.filter(val, this.executorsUsersObject))
			)
		} else {
			this.auxiliariesUsersObject = availables
			this.filteredAuxiliariesUsers =
				this.auxiliariesUsersCtrl.valueChanges.pipe(
					startWith(''),
					map((val) => this.filter(val, this.auxiliariesUsersObject))
				)
		}
	}

	getCompleteName(value) {
		return `${value.name} ${value.last_name}`
	}

	showTableExecutor() {
		if (
			this.executorsTable.data &&
			this.executorsTable.data.length > 0 &&
			this.show_table_executor
		) {
			return true
		}
		return false
	}

	updateIconExe(value: any): any {
		this.icon_exe = value
	}

	showTableAuxiliar() {
		if (
			this.auxiliariesTable.data &&
			this.auxiliariesTable.data.length > 0 &&
			this.show_table_auxiliar
		) {
			return true
		}
		return false
	}

	showListAuxiliar() {
		if (
			this.auxiliariesTable.data &&
			this.auxiliariesTable.data.length > 0 &&
			!this.show_table_auxiliar
		) {
			return true
		}
		return false
	}

	onBlurAuxiliar(event) {
		if (this.auxiliarActive && event.target.value.length == 0) {
			this.cleanAuxiliar()
			this.loadExecutorsAuxiliares()
			this.errorStepAux = false
		}
	}

	optionSelectedAuxiliar(event) {
		let id = event.option.value
		let selected = this.auxiliariesObject.filter((option) => {
			return option.id == id
		})

		if (selected.length > 0) {
			this.loadUsersExecutorsAuxiliaries(selected[0], 2)
			this.auxiliarActive = selected[0]
		}
	}

	updateIconAux(value: any) {
		this.icon_aux = value
	}

	optionSelectedAuxiliarUser(event) {
		let id = event.option.value
		let selected = this.auxiliariesUsersObject.filter((option) => {
			return option.id == id
		})

		if (selected.length > 0) {
			this.auxiliarUserActive = selected[0]
		}
	}

	addAuxiliaries(): void {
		if (this.auxiliarActive && this.auxiliarUserActive) {
			const obj = {
				id: this.auxiliarActive.id,
				name: this.auxiliarActive.name,
				id_user: this.auxiliarUserActive.id,
				name_user:
					this.auxiliarUserActive.name +
					' ' +
					this.auxiliarUserActive.last_name,
				users: this.auxiliarActive.users,
				type: 'new'
			}

			this.auxiliariesTable.data.unshift(obj)
			this.auxiliariesTable.data = this.auxiliariesTable.data

			this.form.get('auxiliaries').setValue(this.auxiliariesTable.data)

			this.auxiliariesCtrl.patchValue('')
			this.auxiliariesUsersCtrl.patchValue('')
			this.filterAuxiliaries(this.auxiliarActive, 1)
			this.auxiliarActive = null
			this.auxiliarUserActive = null
			//Clean
			//this.loadUsersExecutorsAuxiliaries(obj, 1);
			this.cleanExecutor()
		}
	}

	filterAuxiliaries(el, action) {
		if (action == 1) {
			this.auxiliariesObject = this.auxiliariesObject.filter((option) => {
				return option.id != el.id
			})
		} else {
			this.auxiliariesObject.push(el)
		}
		this.filteredAuxiliaries = this.auxiliariesCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filter(val, this.auxiliariesObject))
		)
	}

	removeAuxiliar(auxiliar) {
		this.auxiliariesObject.push({
			id: auxiliar.id,
			name: auxiliar.name,
			users: auxiliar.users
		})

		let filter = this.auxiliariesTable.data.filter((option) => {
			return option.id != auxiliar.id
		})

		this.auxiliariesTable.data = filter
		this.form.get('auxiliaries').setValue(this.auxiliariesTable.data)

		this.cleanExecutor()
		this.cleanAuxiliar()
	}

	getExecutors(): Array<any> {
		return (<Array<any>>this.form.get('executors').value).filter(
			(x) => x.type === 'new'
		)
	}

	getAuxiliaries(): Array<any> {
		return (<Array<any>>this.form.get('auxiliaries').value).filter(
			(x) => x.type === 'new'
		)
	}

	hasChanges(): Boolean {
		return this.getExecutors().length > 0 || this.getAuxiliaries().length > 0
			? true
			: false
	}

	save(): any {
		if (!this.hasChanges()) {
			return false
		}

		const dependencies = {
			executors: this.getExecutors(),
			auxiliaries: this.getAuxiliaries()
		}

		this.saving = true

		this.portfoliosService
			.dependencies(this.portfolio.id, dependencies)
			.subscribe((response) => {
				this.dialogRef.close(true)
			})
	}

	changeTableListAuxiliar() {
		this.show_table_auxiliar = !this.show_table_auxiliar
	}

	getListAuxiliaries() {
		if (this.auxiliariesTable.data && this.auxiliariesTable.data.length > 0) {
			let list = ''
			this.auxiliariesTable.data.forEach((elem, index) => {
				let symbol = index == this.auxiliariesTable.data.length - 1 ? '' : ' / '
				list += `${elem.name}, ${elem.name_user}${symbol}`
			})
			return list
		}
		return ''
	}
}
