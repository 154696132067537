import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { DialogCoordinationComponent } from '../dialog-coordination/dialog-coordination.component'
import { UpsService } from '../../ups/ups.service'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from 'app/services/auth.service'
import { Up } from '../../comunication/models/up'
import { DialogUpComponent } from '../../ups/dialog-up/dialog-up.component'
import { Location } from '@angular/common'

@Component({
	selector: 'app-grouper-directorates',
	templateUrl: './grouper-directorates.component.html',
	styleUrls: ['./grouper-directorates.component.scss']
})
export class GrouperDirectoratesComponent implements OnInit {
	groups = []
	path: string
	coordinacionId: string
	public rol: string = ''
	private coordinations: any[]

	addDirectorate = {
		id: null,
		name: 'Agregar Nuevo',
		logo: 'assets/images/plus.png',
		uri_logo: 'assets/images/plus.png',
		color: '#ffff'
	}

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private upService: UpsService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private location: Location
	) {}

	ngOnInit() {
		this.rol = this.authService.rol
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this._getDirectorates()
		this.getUps()
	}

	private _getDirectorates() {
		this.upService
			.perSpecificCoordination(+this.coordinacionId)
			.subscribe((result) => {
				this.groups = [...result]
			})
	}

	getUps(): void {
		this.upService.index().subscribe((response: any) => {
			this.coordinations = response.coordinations
		})
	}

	public handlerDirectorateSelected(direc) {
		this.router.navigate([
			'/portafolios/coordinaciones/' +
				this.coordinacionId +
				'/direcciones/' +
				direc.id
		])
	}

	private _showAddCoordination(): void {
		let modalForm = this.dialog
			.open(DialogCoordinationComponent, {
				panelClass: 'dialog-coordination'
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) this._getDirectorates()
			})
	}

	openDialog(): void {
		const up = new Up()

		window.history.pushState({}, '', location.pathname + '#')
		const dialogRef = this.dialog.open(DialogUpComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '60%',
			data: {
				up: up,
				coordination: this.coordinations
			}
		})

		dialogRef.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}
				return false
			}
			this.location.back()

			this._getDirectorates()
		})
	}

	back() {
		this.router.navigate(['/portafolios/coordinaciones/' + this.coordinacionId])
	}
}
