import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
	MatButtonModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule,
	MatIconModule,
	MatTableModule
} from '@angular/material'

import { CdkTableModule } from '@angular/cdk/table'
import { FuseSharedModule } from '@fuse/shared.module'
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service'
import { IndexDashboardModule } from './index-dashboard/index-dashboard.module'
import { ProjectDashboardModule } from './project-dashboard/project-dashboard.module'
import { PortfolioDashboardModule } from './portfolio-dashboard/portfolio-dashboard.module'
import { DetailProjectDashboardModule } from './detail-project-dashboard/detail-project-dashboard.module'
import { DetailActionDashboardModule } from './detail-action-dashboard/detail-action-dashboard.module'
import { AdvanceBoardModule } from './advance-board/advance-board.module'
import { DashboardGrouperCoordinationsComponent } from './dashboard-grouper-coordinations/dashboard-grouper-coordinations.component'
import { DashboardGrouperDirectoratesComponent } from './dashboard-grouper-directorates/dashboard-grouper-directorates.component'

@NgModule({
	declarations: [
		DashboardGrouperCoordinationsComponent,
		DashboardGrouperDirectoratesComponent
	],
	imports: [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatTableModule,
		CdkTableModule,
		FuseSharedModule,
		IndexDashboardModule,
		PortfolioDashboardModule,
		ProjectDashboardModule,
		DetailProjectDashboardModule,
		DetailActionDashboardModule,
		AdvanceBoardModule
	],
	providers: [AuthGuard]
})
export class DashboardModule {}
