import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComunicationService {
  private url: string;
  private module: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private global: Globals
  ) {
    this.url = global.api;
    this.module = 'comunication';
    this.httpOptions = global.httpOptions;
   }

  getAll(): Observable<any> {
    return this.http.get(this.url + this.module, this.httpOptions);
  }

  getAllUps(): Observable<any> {
    return this.http.get(this.url + this.module + '/ups', this.httpOptions);
  }

  addOrUpdate(comunication){
    return this.http.post(this.url + this.module + '/addOrUpdate', comunication, this.httpOptions);
  }
}
