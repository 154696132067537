import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
  MatSnackBar
} from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';

import { TagsService } from './tags.service';
import { Portfolio } from '../portfolio';
import { Tag } from './tag';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-tags-portfolios',
  templateUrl: './tags-portfolios.component.html',
  styleUrls: ['./tags-portfolios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagsPortfoliosComponent implements OnInit {
  @ViewChild('inputNews', {static: false}) inputNews: ElementRef;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  portfolio: Portfolio = new Portfolio();
  tags: Tag[];
  loadingTags = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hasChanges = false;
  isAdmin = false;
  isCommunication = false;
  isCoordination = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private matBottomSheetRef: MatBottomSheetRef<TagsPortfoliosComponent>,
    private tagsService: TagsService,
    public snackBar: MatSnackBar,
    private authService: AuthService
  ) { 
    if(this.authService.rol === 'admin')
      this.isAdmin = true;

    if(this.authService.module === 'communication')
      this.isCommunication = true;

    if(this.authService.rol === 'coordination')
      this.isCoordination = true;
  }

  ngOnInit(): void {
    this.portfolio = this.data;
    this.getAllTags();
  }

  getAllTags(){
    // this.loadingTags = true;
    this.tagsService.getAll(this.portfolio.id).subscribe(tags => {
      // this.loadingTags = false;
      this.processTags(tags);
    });
  }

  close(): void {
    this.matBottomSheetRef.dismiss();
  }

  addTag(event: MatChipInputEvent, type: string): void {
    const input = event.input;
    const value = event.value;

    if (value.length > 50){
      this.snackBar.open('El tag debe tener un máximo de 50 caracteres, éste cuenta con: ' + value.length, 'CERRAR', {
        duration: 3000
      });
      return;
    }

    if ((value || '').trim()) {
      const exist = this.tags.find(x => x.tag === value && x.active && x.type === type);

      if (!exist) {
        const tag = {
          id: null,
          tag: value,
          type: type,
          active: 1
        };

        this.tags.push(tag);
        this.tagsService.store(this.portfolio.id, tag).subscribe(data => {
          const update = this.tags.find(x => x.tag === value && x.type === type && x.active === 1);
          if (update) {
            update.id = data.id;
          }
          if (type === 'news'){
            const countTagsNews = this.tags.filter(tag_ => tag_.type === 'news' && tag_.active).length;
            if (input) {
              input.disabled = countTagsNews >= 3 ? true : false;
            }
          }
        });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(event: MatChipInputEvent, tag: Tag): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      if (tag.id > 0) {
        this.tags.splice(index, 1);
        this.tagsService.delete(tag.id).subscribe(data => {
          if (tag.type === 'news'){
            const countTagsNews = this.tags.filter(tag_ => tag_.type === 'news' && tag_.active).length;
            this.inputNews.nativeElement.disabled = countTagsNews >= 3 ? true : false;
          }
        });
      }
    }
  }

  tagsType(type: string): any {
    if(this.tags)
      return this.tags.filter(x => x.type === type && x.active > 0);
  }

  // isAdmin(): Boolean {
  //   return this.authService.rol === 'admin';
  // }

  // isCommunication(): Boolean {
  //   return this.authService.module === 'communication';
  // }

  processTags(tags: any): void {
    this.tags = tags.reduce((data, tag) => {
      data.push({
        id: tag.id,
        tag: tag.tag,
        type: tag.tag_type.code,
        active: 1
      });
      return data;
    }, []);
  }
}
