import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatSnackBar,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  MatPaginator
} from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Project } from '../../projects/project';
import { SubprojectsService } from '../subprojects.service';
import { ProjectsService } from '../../projects/projects.service';
import { DialogAddSubprojectComponent } from '../dialog-add-subproject/dialog-add-subproject.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-index-subprojects',
  templateUrl: './index-subprojects.component.html',
  styleUrls: ['./index-subprojects.component.scss'],
  animations: fuseAnimations
})
export class IndexSubprojectsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  form: FormGroup;
  formErrors: any;
  saving: Boolean = false;
  loading: Boolean = true;
  project: Project = new Project(0, '', [], []);
  subprojects: MatTableDataSource<Project[]>;
  displayedColumns = ['name', 'project_manager', 'tasks', 'advance', 'dependency'];
  dataSourceSubprojects: any[] = [];
  dialogSubprojectForm: any;
  confirmSubprojectFormDialog: MatDialogRef<FuseConfirmDialogComponent>;
  subprojectsLoading = <any>[];
  projectId: number;
  debug = 'right';

  statusColors: any = {
    green: '#03e54b',
    yellow: '#fff816',
    red: '#cf3737',
    default: '#d8d8d8'
  };
  rangeProgress = [
    { color: 'red', min: 1, max: 29 },
    { color: 'yellow', min: 30, max: 59 },
    { color: 'green', min: 60, max: 100 }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private fuseSplashScreen: FuseSplashScreenService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private subprojectsService: SubprojectsService,
    public dialog: MatDialog,
    private projectsService: ProjectsService
  ) {

    this.projectId = parseInt(this.route.snapshot.paramMap.get('id'), 0);

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) { }

      if (event instanceof NavigationEnd) {
        this.projectId = parseInt(this.route.snapshot.paramMap.get('id'), 0);
        this.getProject();
      }

      if (event instanceof NavigationError) { }
    });

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.getSubprojects();

  }

  opensubprojectFormDialog(project: Project): void {

    this.dialogSubprojectForm = this.dialog.open(DialogAddSubprojectComponent, {
      panelClass: 'mat-dialog-system',
      data: {
        project: {
          id: this.projectId,
          name: this.project.name
        }
      }
    });

    this.dialogSubprojectForm.afterClosed()
      .subscribe(projectId => {

        if (!!!projectId) {
          return false;
        }

        this.snackBar.open('Proyecto agregado', 'OK', {
          duration: 3500
        });

        // this.subprojectsLoading.push(projectId);      
        this.getSubprojects();

      });

  }

  subprojectsDataSource(projectId): any {

    const projectIndex = this.dataSourceSubprojects.findIndex(x => x.projectId === projectId);

    if (projectIndex < 0) {
      return null;
    }

    return this.dataSourceSubprojects[projectIndex].subprojects;

  }

  projectManagersSubproject(subproject): String {

    if (!!!subproject.project_managers) {
      return '';
    }

    const users = [];

    subproject.project_managers.forEach(user => {
      const pm = user.user;
      users.push(pm.name + ' ' + pm.last_name);
    });

    return users.join('<br/>');

  }

  loadingSubprojects(projectId: any): Boolean {

    const index = this.subprojectsLoading.findIndex(x => x === projectId);

    if (index < 0) {
      return false;
    }

    return true;

  }

  getColorTaskProgress(subproject: any): String {
    let color = 'default';
    const progress = this.progress(subproject);
    const colorIndex = this.rangeProgress.findIndex(range => progress >= range.min && progress <= range.max);
    if (colorIndex >= 0) {
      color = this.rangeProgress[colorIndex].color;
    }
    return this.statusColors[<any>color];
  }

  progress(subproject: any): number {
    let progress = 0;
    if (subproject.tasks_all > 0) {
      progress = Math.round((100 / subproject.tasks_all) * subproject.tasks_finished);
    }
    return progress;
  }

  getProject(): void {
    this.projectsService.get(this.projectId).subscribe((data: any) => {
      this.project = data;
    });
  }

  validateDependency(subproject): string {
    if (subproject.dependency !== null) {
      return subproject.dependency.name;
    }
    return '';
  }

  getSubprojects(): void {

    this.subprojectsService.getAll(this.projectId).subscribe(data => {

      this.subprojects = new MatTableDataSource(data);
      this.subprojects.paginator = this.paginator;
      this.loading = false;
    });

  }

  isAdmin(): Boolean {
    return localStorage.getItem('rol') === 'admin' ? true : false;
  }

  toggleCard(): void {
    this.debug = this.debug === 'right' ? 'left' : 'right';
  }

}
