import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormTasksComponent } from './form-tasks.component';
import {
  MatIconModule, 
  MatCheckboxModule, 
  MatFormFieldModule, 
  MatInputModule,
  MatButtonModule, 
  MatPaginatorModule,
  MatRippleModule, 
  MatSelectModule, 
  MatSortModule,
  MatTableModule, 
  MatTabsModule
} from '@angular/material';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'actividades/nuevo',
    component: FormTasksComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule, 
    MatCheckboxModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatButtonModule, 
    MatPaginatorModule,
    MatRippleModule, 
    MatSelectModule, 
    MatSortModule,
    MatTableModule, 
    MatTabsModule,
    FuseSharedModule
  ],
  declarations: [FormTasksComponent],
  providers: [AuthGuard]
})
export class FormTasksModule { }
