import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { 
  MatButtonModule, 
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule, 
  MatGridListModule,
  MatIconModule, 
  MatInputModule, 
  MatListModule,
  MatSelectModule, 
  MatStepperModule,
  MatTabsModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
} from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { FormProjectsComponent } from './form-projects.component';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';

const routes: Routes = [
  {
    path     : 'jal/proyectos/crear',
    component: FormProjectsComponent,
    canActivate: [AuthGuard]
  },
  {
    path     : 'jal/proyectos/editar',
    component: FormProjectsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,    
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule, 
    MatGridListModule,
    MatIconModule, 
    MatInputModule, 
    MatListModule,
    MatSelectModule, 
    MatStepperModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  declarations: [FormProjectsComponent],
  providers: [AuthGuard]
})
export class FormProjectsModule { }
