import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material';
import { AuthService } from '../../../services/auth.service';
import { Location } from '@angular/common';
import { ComunicationFormComponent } from '../comunication-form/comunication-form.component';
import { ComunicationService } from 'app/comunication/services/comunication.service';
import { Comunication } from 'app/comunication/models/comunication';
import { Utils } from 'app/constants/utils';

@Component({
  selector: 'app-comunication-list',
  templateUrl: './comunication-list.component.html',
  styleUrls: ['./comunication-list.component.scss']
})
export class ComunicationListComponent implements OnInit {
  public dataSource: MatTableDataSource<any>;
  loading: boolean = false;
  comunication: Comunication = null;
  comunications: Comunication[] = [];
  dialogForm: any;
  displayedColumns: string[] = ['full_name', 'email', 'up_name', 'actions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private location: Location,
    private comunicationService: ComunicationService,
    private utils: Utils,
  ) { }

  ngOnInit() {

    const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) { return `0 de ${length}`; }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
    
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.firstPageLabel = 'Primer página';
    this.paginator._intl.getRangeLabel = spanishRangeLabel;
    this.getComunications();
  }

  getComunications(){
    this.loading = true;
    this.comunicationService.getAll().subscribe(
      result => {
        this.comunications = result;
        this.loading = false;
        this.setComunicationList();
      },
      error => {
        this.snackBar.open('Ocurrió un error. Inténtelo de nuevo.', 'OK', {
          duration: 3500
        });
      }
    );
  }

  setComunicationList(){
    this.dataSource = new MatTableDataSource(this.comunications);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.utils.filterTable();
  }

  addComunication(){
    this.comunication = null;
    this.openFormDialog();
  }

  editComunication(comunication){
    this.comunication = comunication;
    this.openFormDialog();
  }

  openFormDialog(): void {
    window.history.pushState({}, '', location.pathname + '#');
    this.dialogForm = this.dialog.open(ComunicationFormComponent, {
      panelClass: 'mat-dialog-system',
      data: {
        comunication: this.comunication
      }
    });

    this.dialogForm.afterClosed()
      .subscribe(response => {
        if(response){
          this.updateList(response[0]);
          this.location.back();
          this.snackBar.open('Registro agregado', 'OK', {
            duration: 3500
          });
        }
      });
  }

  updateList(comunication){
    let comunicationTmp = this.comunications.find(c => c.id == comunication.id);
    if(comunicationTmp !== undefined){
      comunicationTmp.full_name = comunication.full_name;
      comunicationTmp.email = comunication.email;
      comunicationTmp.up_id = comunication.up_id;
      comunicationTmp.up = comunication.up;
    }
    else{
      this.comunications.push(comunication);
      this.comunications = [...this.comunications];
      this.setComunicationList();
    }
  }

  // applyFilter(filterValue: string): void {
  //   this.dataSource.filter = this.removeAccents(filterValue.trim().toLowerCase());
  // }

  // private removeAccents(strAccents: any): string {
  //   strAccents = strAccents.split('');
  //   const strAccentsOut = new Array();
  //   const strAccentsLen = strAccents.length;
  //   const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  //   const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  //   for (let y = 0; y < strAccentsLen; y++) {
  //     if (accents.indexOf(strAccents[y]) !== -1) {
  //       strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
  //     } else {
  //       strAccentsOut[y] = strAccents[y];
  //     }
  //   }

  //   return strAccentsOut.join('');
  // }
}
