export class Axis {
	id: number
	name: string
	topic: string
	coordination_id: number
	sub_axis_platform: []

	constructor(
		id: number = 0,
		name: string = '',
		topic: string = '',
		sub_axis_platform: [] = []
	) {
		this.id = id
		this.name = name
		this.topic = topic
		this.sub_axis_platform = sub_axis_platform
	}
}
