import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { CoordinationService } from 'app/coordinations/services/coordination.service'
import { DialogCoordinationComponent } from '../dialog-coordination/dialog-coordination.component'

@Component({
	selector: 'app-grouper-porfolios',
	templateUrl: './grouper-porfolios.component.html',
	styleUrls: ['./grouper-porfolios.component.scss']
})
export class GrouperPorfoliosComponent implements OnInit {
	groups
	path: string

	addCoordination = {
		id: null,
		name: 'Agregar Nuevo',
		logo: 'assets/images/plus.png',
		uri_logo: 'assets/images/plus.png',
		primary_color: '#ffff'
	}

	constructor(
		private router: Router,
		private coordinationService: CoordinationService,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this._getCoordinations()
	}

	private _getCoordinations() {
		this.coordinationService.getAll().subscribe((result) => {
			this.groups = result
		})
	}

	public handlerCoordinationSelected(coord) {
		if (coord.id == null) this._showAddCoordination()
		else
			this.router.navigate([
				'/portafolios/coordinaciones/' + coord.id + '/direcciones'
			])
	}

	private _showAddCoordination(): void {
		let modalForm = this.dialog
			.open(DialogCoordinationComponent, {
				panelClass: 'dialog-coordination'
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) this._getCoordinations()
			})
	}
}
