import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import {
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTooltipModule,
  MatMenuModule
} from '@angular/material';
import { IndexMembersComponent } from './index-members.component';
import { DialogAddMembersComponent } from '../dialog-add-members/dialog-add-members.component';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';
import { NgxMaskModule } from 'ngx-mask';
import { OrderModule } from 'ngx-order-pipe';
import { AutoCompleteGroupModule } from '../../general/components/auto-complete-group/auto-complete-group.module';
import { ChangePasswordComponent } from 'app/change-password/change-password.component';
import { ChangePasswordModule } from 'app/change-password/change-password.module';

const routes: Routes = [
  {
    path: 'miembros',
    component: IndexMembersComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    FuseSharedModule,
    CdkTableModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    OrderModule,
    NgxMaskModule.forRoot(),
    AutoCompleteGroupModule,
    MatMenuModule,
    ChangePasswordModule
  ],
  declarations: [
    IndexMembersComponent,
    DialogAddMembersComponent
  ],
  entryComponents: [
    DialogAddMembersComponent,
    ChangePasswordComponent
  ],
  providers: [AuthGuard]
})
export class IndexMembersModule { }
