import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import { DialogAddStageComponent } from '../dialog-add-stage/dialog-add-stage.component';
import {
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatSnackBarModule,
  MatMenuModule,
  MatTooltipModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatListModule,
  MatDatepickerModule,
  MatDialogModule
} from '@angular/material';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'stages',
    canActivate: [AuthGuard]
    //component: ListStagesComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    //RouterModule.forChild(routes),
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    FuseSharedModule,
    CdkTableModule,
    MatSnackBarModule,
    MatMenuModule,
    RoundProgressModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatListModule,
    MatDatepickerModule,
    MatDialogModule
  ],
  declarations: [
    //ListStagesComponent,
    DialogAddStageComponent
  ],
  entryComponents: [
    DialogAddStageComponent
  ], 
  providers: [AuthGuard]
})
export class ListStagesModule { }
