import { Component, OnInit } from '@angular/core';
import { TasksService } from '../tasks.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { DialogAddTasksComponent } from '../dialog-add-tasks/dialog-add-tasks.component';
import { SubprojectsService } from '../../subprojects/subprojects.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-index-tasks',
  templateUrl: './index-tasks.component.html',
  styleUrls: ['./index-tasks.component.scss'],
  animations: fuseAnimations
})
export class IndexTasksComponent implements OnInit {

  displayedColumns: string[] = ['name', 'date_assignment', 'date_estimated_finished', 'status', 'manager'];
  dataSource = [];
  subprojectId: number;
  subprojectName: string;
  status = [
    { code: 'created', value: 'Nueva' },
    { code: 'processing', value: 'Proceso' },
    { code: 'finished', value: 'Finalizada' },
    { code: 'cancelled', value: 'Cancelada' },
  ];

  constructor(
    private tasksService: TasksService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private subprojectService: SubprojectsService
  ) { }

  ngOnInit(): void {
    this.subprojectId = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.index();
    this.subporject();
  }

  index(): void {
    this.tasksService.index(this.subprojectId).subscribe((data: any) => {
      this.dataSource = data;
    });
  }

  openDialog(update: boolean, task: any): void {
    update = typeof update !== 'undefined';

    const dialogRef = this.dialog.open(DialogAddTasksComponent, {
      panelClass: 'mat-dialog-system',
      data: {
        subproject: this.subprojectId,
        update: update,
        task: typeof task === 'undefined' ? null : task.id
      },
      autoFocus: false
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        this.index();

        let msn = update ? 'Actividad Actualizada' : 'Actividad Agregada';

        if (typeof response !== 'undefined') {
          if (response.status !== true) {
            msn = 'Error';
          }

          this.snackBar.open(msn, 'Ok', {
            duration: 2000
          });
        }
      });
  }

  subporject(): void {
    this.subprojectService.get(this.subprojectId).subscribe((data: any) => {
      this.subprojectName = data.name.charAt(0).toUpperCase() + data.name.slice(1);
    });
  }

  getStatus(task: any): String {

    const index = this.status.findIndex(x => x.code === task.status);

    if (index < 0) {
      return '';
    }

    return this.status[index].value;

  }

  hasModuleAllowed(module): boolean {
    return this.authService.hasPermission(module);
  }

}
