import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { Globals } from '../globals'

@Injectable({
	providedIn: 'root'
})
export class ProjectsService {
	private uri: string
	private module: string
	private httpOptions: any

	constructor(private http: HttpClient, private global: Globals) {
		this.uri = global.api
		this.module = 'projects'
		this.httpOptions = global.httpOptions
	}

	getAll(): Observable<any> {
		return this.http.get(this.uri + this.module, this.httpOptions)
	}

	getDependenciesAxis(): Observable<any> {
		return this.http.get(
			`${this.uri}${this.module}/dependencies-axis`,
			this.httpOptions
		)
	}

	getDependenciesAxisByCoord(coordinacionId): Observable<any> {
		return this.http.get(
			`${this.uri}${this.module}/dependencies-axis/${coordinacionId}`,
			this.httpOptions
		)
	}

	get(id: number): Observable<any> {
		return this.http.get(this.uri + this.module + '/' + id, this.httpOptions)
	}

	getNew(id: number): Observable<any> {
		let header = this.httpOptions
		return this.http.get(this.uri + this.module + '/' + id, {
			headers: header,
			observe: 'response'
		})
	}

	init(): Observable<any> {
		return this.http.get(this.uri + this.module + '/init', this.httpOptions)
	}

	store(form: Object): Observable<any> {
		return this.http.post(this.uri + 'portfolios', form, this.httpOptions)
	}

	edit(id): Observable<any> {
		return this.http.get(this.uri + this.module + '/' + id, this.httpOptions)
	}

	update(id, form: any): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id,
			form,
			this.httpOptions
		)
	}

	delete(id): Observable<any> {
		return this.http.delete(this.uri + this.module + '/' + id, this.httpOptions)
	}

	menu(): Observable<any> {
		return this.http.get(this.uri + this.module + '/menu', this.httpOptions)
	}

	membersPerDependency(dependency: number): Observable<any> {
		return this.http.get(
			`${this.uri}members/dependency/${dependency}`,
			this.httpOptions
		)
	}

	updateModel(id: number, form: any): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id,
			form,
			this.httpOptions
		)
	}

	addParticipants(id: number, participants: any[]): Observable<any> {
		return this.http.put(
			`${this.uri}${this.module}/${id}/paticipants`,
			{ participants: participants },
			this.httpOptions
		)
	}

	updateLinkCommunication(
		project_id: number,
		link_communication: string
	): Observable<any> {
		return this.http.put(
			`${this.uri}${this.module}/${project_id}/link-communication`,
			{ link_communication },
			this.httpOptions
		)
	}

	updateLinkProject(project_id: number, body: object): Observable<any> {
		return this.http.put(
			`${this.uri}${this.module}/${project_id}/link-project`,
			{ ...body },
			this.httpOptions
		)
	}

	getResponsibles(): Observable<any> {
		return this.http.get(
			this.uri + this.module + '/responsibles',
			this.httpOptions
		)
	}

	getResponsiblesPerCoordination(): Observable<any> {
		return this.http.get(
			this.uri + this.module + '/responsiblesPerCoordination',
			this.httpOptions
		)
	}

	validateProject(form: FormGroup): Observable<any> {
		let params = {
			project_id: form.get('project_id').value,
			responsible_validated: form.get('responsible_validated').value,
			date_validated: form.get('date_validated').value
		}
		return this.http.post(
			this.uri + 'projects/validate-project',
			params,
			this.httpOptions
		)
	}

	getResponsibleList(id: number): Observable<any> {
		return this.http.get(
			`${this.uri}${this.module}/dependencies/${id}/responsible-list`,
			this.httpOptions
		)
	}
}
