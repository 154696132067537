import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuardService as AuthGuard } from '../../app/auth/auth-guard.service';
import { COORDINATION_LIST } from "./coordinations.constant.module";
import { CoordinationListComponent } from "./components/coordination-list/coordination-list.component";

const routes: Routes = [
  {
    path: COORDINATION_LIST,
    component: CoordinationListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoordinationsRoutingModule { }
