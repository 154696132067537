import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Utils } from 'app/constants/utils';
import { DialogCoordinationComponent } from 'app/portfolios/dialog-coordination/dialog-coordination.component';
import { AuthService } from 'app/services/auth.service';
import { CoordinationService } from '../../services/coordination.service';
import { fuseAnimations } from '@fuse/animations';
@Component({
  selector: 'app-coordination-list',
  templateUrl: './coordination-list.component.html',
  styleUrls: ['./coordination-list.component.scss'],
  animations: fuseAnimations
})
export class CoordinationListComponent implements OnInit {
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  coordinations = [];
  displayedColumns: string[] = ['name', 'color', 'updated'];

  constructor(
    private dialog: MatDialog,
    private utils: Utils,
    public authService: AuthService,
    private coordinationService: CoordinationService,
  ) { }

  ngOnInit() {
    this.index();
  }

  index(): void {
    this.coordinationService.getAll().subscribe((data: any) => {
      const coords = data.map((coord: any) => {
        const m_last = coord.name ? coord.name : '';
        return coord;
      });

      this.dataSource = new MatTableDataSource(coords);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = this.utils.filterTable();
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = this.removeAccents(filterValue.trim().toLowerCase());
  }

  private removeAccents(strAccents: any): string {
    strAccents = strAccents.split('');
    const strAccentsOut = new Array();
    const strAccentsLen = strAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) !== -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }

    return strAccentsOut.join('');
  }

  openDialog() {
    this.dialog.open(DialogCoordinationComponent, {
      panelClass: 'dialog-coordination',
    }).afterClosed()
      .subscribe(response => {
        if (response)
          this.index();
      });
  }

  openEditDialog(coord) {
    this.dialog.open(DialogCoordinationComponent, {
      data: { coordination: coord },
      panelClass: 'dialog-coordination',
    }).afterClosed()
      .subscribe(response => {
        if (response)
          this.index();
      });
  }
}
