import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AuthService } from '../../../services/auth.service';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { LoginService } from '../../../login/login.service';

import { navigation } from 'app/navigation/navigation';
import { CommunicationService } from 'app/services/communication.service';
import { ProfileService } from 'app/profile/profileService';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [LoginService]
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  showLoadingBar: boolean;
  userStatusOptions: any[];
  name: string;
  lastname: string;
  date: any = Date.now();

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private _translateService: TranslateService,
    private loginService: LoginService,
    private auth: AuthService,
    private communicationService: CommunicationService,
    private profileService: ProfileService
  ) {
    this.name = localStorage.getItem('name');
    this.lastname = localStorage.getItem('last_name');

    // Set the defaults
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();


    communicationService.changeEmittedGlobal$.subscribe(() => {
      this.date = Date.now();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the router events to show/hide the loading bar
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event) => {
        this.showLoadingBar = true;
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.showLoadingBar = false;
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param langId
   */
  setLanguage(langId): void {
    // Set the selected language for toolbar
    this.selectedLanguage = _.find(this.languages, { 'id': langId });

    // Use the selected language for translations
    this._translateService.use(langId);
  }

  logout(): void {
    this.loginService.logout();
  }

  get dependency(): String {
    return localStorage.getItem('rol') === 'admin' ? 'Administrador' : localStorage.getItem('dependency');
  }

  get initials(): string {
    return this.auth.initials;
  }

  get hasImage(): boolean {
    return this.auth.hasAvatar;
  }

  get avatar(): string {
    return this.auth.avatarUpdate(this.date);
  }

  get userName(): string {
    return this.auth.name;
  }

  toggleProfile(): void{
    this.communicationService.emitChangeGlobal();
  }

  get colorDep(): string {
    const color = (this.auth.dependency_color)? this.auth.dependency_color : '#dadbdd';
    return '2px solid ' + color;
  }
}
