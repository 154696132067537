import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatAutocomplete, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AdvanceBoardService } from 'app/services/advance-board.service';

@Component({
  selector: 'app-advance-board',
  templateUrl: './advance-board.component.html',
  styleUrls: ['./advance-board.component.scss']
})
export class AdvanceBoardComponent implements OnInit {
  public listUsers: any[];
  public form: FormGroup;
  public addUserInput: FormControl = new FormControl();
  public searchUserInput: FormControl = new FormControl();
  public filterAddUser: Observable<any[]>;
  public filterUsers: Observable<any[]>;
  public addUsers: any[];
  public titleUser: String = '';
  public idUser: String = null;
  @ViewChild('addUser', {static: false}) public matAutocompleteAddUser: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<AdvanceBoardComponent>,
    private advanceBService: AdvanceBoardService,
    public snackBar: MatSnackBar
  ) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      add: new FormControl(null)
    });

    Promise.all([this._loadUsers(), this._loadUsersList()]).then(results => {
      this.filterAddUser = this.addUserInput!.valueChanges
        .pipe(
          startWith(''),
          map(val => this._filterGroup(val))
        );
      
        this.filterUsers = this.searchUserInput.valueChanges
          .pipe(
            startWith(''),
            map(val => this._filterInput(val, this.listUsers))
          );
    });
  }

  private _filterGroup(value: string): any{
    if (value) {
      return this.addUsers
        .map(group => ({ name: group.name, users: this._filter(group.users, value)}))
        .filter(group => group.users.length > 0);
    }
    return this.addUsers;
  }

  private _filter = (opt: string[], value: any): string[] => {
    if (Object.entries(value).length > 0 && value.constructor === Object) {
      value = value.fullname;
    }
    const filterValue = this.removeAccents(value.toLowerCase());
    return opt.filter((item: any) => this.removeAccents(item.fullname.toLowerCase()).includes(filterValue));
  }  

  private removeAccents(strAccents: any): string {
    strAccents = strAccents.split('');
    const strAccentsOut = new Array();
    const strAccentsLen = strAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) !== -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }

    return strAccentsOut.join('');

  }

  private _filterInput(val: any, list: any): string[] {
    if (!Boolean(val)) {
      return list;
    }

    if (Object.entries(val).length > 0 && val.constructor === Object) {
      val =  val.users.fullname;
    }

    return list.filter(option => option.users.fullname.toLowerCase().includes(val.toLowerCase()));
  }

  public optionSelectedUser(event: any): void {
    const id = event.option.value.id;
    const name = event.option.value.fullname;
    this.form.get('add').patchValue(id);
    this.addUserInput.patchValue(name);
    this.addPermission();
  }

  public onBlurInput(event: any): void {
    if (event.target.value.length === 0) {
      this.form.get('add').patchValue(null);
      this.addUserInput.patchValue('');
    }
  }

  public addPermission(): void {
    const value = this.form.get('add').value;
    if (value !== null) {
      this.advanceBService.addPermissions({ user_id: value}).subscribe(res => {       
        Promise.all([this._loadUsers(), this._loadUsersList()]).then(result => {
          this.form.get('add').patchValue(null);
          this.addUserInput.patchValue('');
        });
      }, error => {
        this.snackBar.open('Error al agregar el permiso', 'ERROR', {
          duration: 3500
        });
      });
    }
  }

  public deletePermission(id: string, name: string): void {
    this.advanceBService.deletePermissions({ id: id }).subscribe(res => {
      Promise.all([this._loadUsers(), this._loadUsersList()]);
    }, error => {
      this.snackBar.open('Error al eliminar el permiso', 'ERROR', {
        duration: 3500
      });
    });
  }

  private _loadUsersList(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.advanceBService.usersWithPermissions().subscribe(data => {
        this.listUsers = data;
        this.searchUserInput.patchValue('');
        resolve(true);
      });
    });    
  }

  private _loadUsers(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.advanceBService.usersWithoutPermissions().subscribe(data => {
        this.addUsers = data;
        resolve(true);
      });
    });
  }

  public closeDialog(): void {    
   this.idUser = '';
   this.titleUser = '';
  }

  public acceptDialog(): void {

  }
}
