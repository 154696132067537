import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-form-subprojects',
  templateUrl: './form-subprojects.component.html',
  styleUrls: ['./form-subprojects.component.scss'],
  animations: fuseAnimations
})
export class FormSubprojectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
