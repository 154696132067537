import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { AuthService } from './auth.service'
import * as jwtDecode from 'jwt-decode'

@Injectable()
export class AuthRolePermissionService implements CanActivate {
	constructor(public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const auth = new AuthService()
		const userData = jwtDecode(localStorage.getItem('token'))

		if (!auth.isAuthenticated()) {
			this.router.navigate(['login'])
			return false
		}

		if (
			auth.isAuthenticated() &&
			(route.data.roles.indexOf(userData.rol) != -1 ||
				route.data.roles.indexOf(userData.module) != -1)
		) {
			return true
		}

		if (userData.rol === 'coordination') {
			this.router.navigate([
				'portafolios/coordinaciones/' +
					userData.coordination_id +
					'/direcciones'
			])
		} else {
			this.router.navigate([
				'portafolios/coordinaciones/' +
					userData.coordination_id +
					'/direcciones/' +
					userData.up_id
			])
		}

		return true
	}
}
