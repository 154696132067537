import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [

    {
        path        : 'proyectos',
        loadChildren: './projects/projects.module#ProjectsModule'
    },
    {
        path        : 'sub-projectos',
        loadChildren: './subprojects/subprojects.module#SubprojectsModule'
    },    
    /*{ 
        path: 'dashboard', 
        component: DashboardComponent
    },*/
    {
        path      : '**',
        redirectTo: 'portafolios'
    }
];

export const routing = RouterModule.forRoot(appRoutes);
