import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { IndexSubprojectsComponent } from './index-subprojects.component';
import { DialogAddSubprojectComponent } from '../dialog-add-subproject/dialog-add-subproject.component';
import { CdkTableModule } from '@angular/cdk/table';
import { 
  MatButtonModule, 
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule, 
  MatGridListModule,
  MatIconModule, 
  MatInputModule, 
  MatListModule,
  MatSelectModule, 
  MatStepperModule,
  MatTabsModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AuthGuardService as AuthGuard} from '../../auth/auth-guard.service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { ListPipe } from '../../pipe/list.pipe';
import { OrderByPipe } from '../../pipe/order-by.pipe';
import { AutoCompleteGroupModule } from '../../general/components/auto-complete-group/auto-complete-group.module';
import { ActionDetailComponent } from 'app/dialogs/action-detail/action-detail.component';
import { SnackBarModule } from 'app/general/components/snack-bar-component/snack-bar.module';
import { SnackBarComponent } from 'app/general/components/snack-bar-component/snack-bar.component';
import { ActionDetailModule } from 'app/dialogs/action-detail/action-detail.module';

const routes: Routes = [
  {
    path     : 'proyectos/:id',
    component: IndexSubprojectsComponent,
    canActivate: [AuthGuard]
  }
];

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: false
};

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    RouterModule.forChild(routes),
    MatButtonModule, 
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule, 
    MatGridListModule,
    MatIconModule, 
    MatInputModule, 
    MatListModule,
    MatSelectModule, 
    MatStepperModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    MatTableModule,
    MatSnackBarModule,
    RoundProgressModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AutoCompleteGroupModule,
    MatSlideToggleModule,
    ActionDetailModule,
    SnackBarModule
  ],
  declarations: [
    IndexSubprojectsComponent,
    DialogAddSubprojectComponent,
    ListPipe,
    OrderByPipe
  ],
  entryComponents: [
    DialogAddSubprojectComponent,
    ActionDetailComponent,
    SnackBarComponent
  ],
  providers: [AuthGuard]
})
export class IndexSubprojectsModule { }
