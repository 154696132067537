import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { TasksService } from 'app/tasks/tasks.service'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { DialogFilesActionDashboardComponent } from './dialog-files-action-dashboard/dialog-files-action-dashboard.component'
import { DashboardService } from '../dashboard.service'

export interface Task {
	semaphore: string
	name: string
	responsible: string
	status: string
	date_estimated_finished: string
	comment: string
	created_at: Date
	updated_at: Date
}

@Component({
	selector: 'app-detail-action-dashboard',
	templateUrl: './detail-action-dashboard.component.html',
	styleUrls: ['./detail-action-dashboard.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class DetailActionDashboardComponent implements OnInit {
	portfolio: any
	project: any
	stage: any
	action: any

	id_portfolio: any
	id_project: any
	id_stage: any
	id_action: any

	totalTasks = 0
	finishedTasks = 0

	type1 = 'portfolio'
	type2 = 'project'
	type3 = 'stage'
	type4 = 'subproject'
	mini = true
	sizeMini = '100px'
	sizeMini2 = '90px'
	sizeMini3 = '130px'
	styleMini = { width: this.sizeMini, height: this.sizeMini, fontSize: '16px' }
	styleMini2 = {
		width: this.sizeMini2,
		height: this.sizeMini2,
		fontSize: '14px'
	}
	styleMini3 = {
		width: this.sizeMini3,
		height: this.sizeMini3,
		fontSize: '18px'
	}
	styleCenter = {
		background: '#eaeaea',
		'border-radius': '50px',
		height: '92%',
		width: '92%',
		display: 'flex',
		'align-items': 'center',
		'justify-content': 'center',
		color: '#656565'
	}

	colorGreen = '#1AAE60'
	colorYellow = '#FFD938'
	colorRed = '#D6264A'

	displayedColumns = [
		'semaphore',
		'name',
		'responsible',
		'status',
		'date_assignment',
		'date_finished',
		'comment',
		'files'
	]
	dataSource: any

	tasks: Task[] = []
	lastTaskChange: Date

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private route: ActivatedRoute,
		private taskService: TasksService
	) {}

	ngOnInit(): void {
		this.id_portfolio = this.route.snapshot.paramMap.get('portfolioId')
		this.id_project = parseInt(
			this.route.snapshot.paramMap.get('projectId'),
			10
		)
		this.id_stage = parseInt(this.route.snapshot.paramMap.get('stageId'), 10)
		this.id_action = parseInt(this.route.snapshot.paramMap.get('actionId'), 10)

		this.dashboardService
			.getDetailProject(this.id_portfolio, this.id_project)
			.subscribe((response) => {
				this.portfolio = response
				this.project = this.portfolio.projects.filter((project: any) => {
					return project.id === this.id_project
				})
				this.project = this.project.length === 1 ? this.project[0] : {}
				this.getStage()
			})

		this.taskService.index(this.id_action).subscribe((tasks) => {
			this.totalTasks = tasks.length
			this.tasks = tasks.map((task: any) => {
				if (task.status === 'finished') {
					this.finishedTasks += 1
				}
				task = {
					semaphore: this.getColorSemaphore(task.semaphore, task.status),
					name: task.name,
					responsible: this.getNameResponsible(task.user),
					status: this.getStatus(task.status),
					date_assignment: task.date_assignment,
					date_finished: task.date_estimated_finished,
					comment: this.getLastComment(task.log),
					user: task.user,
					files: this.getTaskFiles(task.log),
					created_at: task.created_at,
					updated_at: task.updated_at
				}
				return task
			})

			this.dataSource = this.tasks
			this.getLastTaskChange()
		})
	}

	getLastTaskChange() {
		let tmp = this.dataSource.sort(function (a, b) {
			if (a.updated_at < b.updated_at) {
				return 1
			}
			if (a.updated_at > b.updated_at) {
				return -1
			}
			return 0
		})
		this.lastTaskChange = tmp && tmp.length > 0 ? tmp[0].updated_at : null
	}

	getStage(): void {
		this.stage = this.project.project_stages.filter((stage) => {
			return stage.id === parseInt(this.id_stage, 10)
		})
		if (this.stage.length > 0) {
			this.stage = this.stage[0]
		}
		this.getAction()
	}

	getAction(): void {
		this.action = this.stage.subprojects.filter((action) => {
			return action.id === parseInt(this.id_action, 10)
		})
		if (this.action.length > 0) {
			this.action = this.action[0]
		}
	}

	getNameResponsibleAction(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	getColorSemaphore(percent: number, status: any): string {
		if (status === 'finished') {
			return this.colorGreen
		}

		if (percent >= 50) {
			return this.colorGreen
		} else if (percent >= 20 && percent <= 49) {
			return this.colorYellow
		} else {
			return this.colorRed
		}
	}

	getNameResponsible(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	getStatus(status: string): string {
		if (status === 'created') {
			return 'Nuevo'
		} else if (status === 'processing') {
			return 'Proceso'
		} else if (status === 'finished') {
			return 'Finalizado'
		} else if (status === 'paused') {
			return 'Pausado'
		}
	}

	getData(): any {
		return [
			{ name: '', data: [] },
			{ name: '', data: [] }
		]
	}

	getColorDependency(): string {
		return this.project.dependency.color
	}

	getColorDependencyAction(user: any): string {
		return user.up ? user.up.color : ''
	}

	getColorDependencyTask(user: any): string {
		return user.up ? user.up.color : ''
	}

	getLastComment(log: any): string {
		if (log.length > 0) {
			return log[log.length - 1].status.comment
		}
		return ''
	}

	openUserInfoDialog(user: any): void {
		this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: user,
				up: user.up
			}
		})
	}

	getTaskFiles = (taskLog: any): any =>
		taskLog.reduce((acc: any, log: any) => acc.concat(log.status.files), [])

	showFiles(taskName: string, taskFiles: any): void {
		this.dialog.open(DialogFilesActionDashboardComponent, {
			width: '500px',
			height: '90%',
			panelClass: 'mat-dialog-system',
			data: {
				name: taskName,
				files: taskFiles
			}
		})
	}
}
