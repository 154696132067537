export class Task {
    id: number;
    name: string;
    status: string;   
    user: {
        id: number;
        name: string;
        last_name: string;
        initials: string;
    };  
    log: any[];
    date_estimated_finished: Date;
    date_assignment: Date;
    allowed_tasks: Boolean;
    is_visualizer: Boolean;
    allowed_subprojects: Boolean;
    
    constructor() { 
        this.id = 0;
        this.name = '';
        this.status = '';
        this.user =  {
            id: 0,
            name: '',
            last_name: '',
            initials: ''
        };
        this.log = [];
        this.date_estimated_finished = null;
        this.date_assignment = null;
        this.allowed_tasks = false;
        this.is_visualizer = false;
      }
}
