export function getIconFiles() {
  return {
    'word' : ['doc', 'docx'],
    'excel': ['xls', 'xlsx', 'gsheet'],
    'ppt' : [ 'ppt', 'pptx'],
    'multimedia' : ['mp3' , 'mp4'],
    'zip' : ['zip', 'rar', 'tar'],
    'imgn' : ['jpg', 'jpeg', 'gif', 'png'],
    'text' : ['txt'],
    'pdf' : ['pdf'] };
  };