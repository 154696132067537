import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-financing-chart',
  templateUrl: './financing-chart.component.html',
  styleUrls: ['./financing-chart.component.scss']
})
export class FinancingChartComponent implements OnInit {
  imgUrl : string = '';
  portfolio: any = null;
  project: any = null;
  ups = [];
  splits = [];
  funding_source = [];
  data_graph = [];
  total_total: Number = 0;
  colors = [];
  territorial: any = { 'territorial': [] };

  constructor() { }

  ngOnInit() {
  }

  init(){
    this.ups = [];
    this.splits = [];
    this.funding_source = [];
    this.data_graph = [
      { name: '', data: [{ category: '', value: 0, color: '#FFF' }] },
      { name: '', data: [{ category: '', value: 0, color: '#FFF' }] }
    ];
    this.setColors();
    this.territorial = { 'territorial': [] };
  }

  setColors(){
    if (this.project.dependency.coordination) {
      this.colors = this.project.dependency.coordination.colors;
    } else {
      this.colors = [
        { color: '#4e4e4e', coordination_id: 5 },
        { color: '#4e4e4e', coordination_id: 5 },
        { color: '#4e4e4e', coordination_id: 5 },
        { color: '#4e4e4e', coordination_id: 5 },
        { color: '#4e4e4e', coordination_id: 5 }
      ];
    }
  }

  loadInfo(){
    this.init();
    this.getTerritorial();
    this.project.project_stages.forEach((stage: any) => {
      stage.subprojects.forEach((action: any) => {
        action.subprojects_financing.forEach((financing: any, i: number) => {
          // UPS
          if (financing.name_funding === 'Estatal'){
            const index_up = this.ups.findIndex((x: any) => x.up_id === financing.up_id);
            if (index_up >= 0) {
              this.ups[index_up].total += financing.authorized_amount_total;
            } else {
              if (financing.up_id) {
                this.ups.push({ 
                  'up_id': financing.up_id, 'name': financing.name_up, 
                  'color': this.project.dependency.color, 'total': financing.authorized_amount_total 
                });
              }
            }
          }

          // ORIGEN
          const index_source = this.funding_source.findIndex((x: any) => x.id_type === financing.funding_sources_id);
          if (index_source >= 0) {
            this.funding_source[index_source].total += financing.authorized_amount_total;
          } else {
            this.funding_source.push({ 'id_type': financing.funding_sources_id, 'total': financing.authorized_amount_total });
          }

          // SPLITS
          financing.splits_action.forEach((split: any) => {
            const index_split = this.splits.findIndex((x: any) => x.split_id === split.split_id);
            if (index_split >= 0) {
              this.splits[index_split].total += split.amount;
            } else {
              this.splits.push({ 
                'split_id': split.split_id, 'name': split.split, 
                'color': this.project.dependency.color, 'total': split.amount 
              });
            }
          });
        });
      });
    });
    this.generateDataGraph();
    this.funding_source.sort(this.compare);
  }

  getTerritorial(): void {
    if (this.portfolio.territorial_scale === 'estatal') {
      this.territorial = { 'id': 1, 'name': 'estatal', territorial: [] };
    }
    else if (this.portfolio.territorial_scale === 'municipal') {
      const municipalities = this.portfolio.municipalities.map((mun: any) => {
        return { 'name': mun.municipio.name };
      });
      this.territorial = { 'id': 2, 'name': 'municipal', territorial: municipalities };
    } else if (this.portfolio.territorial_scale === 'regional') {
      const regions = this.portfolio.regions.map((reg: any) => {
        return { 'name': reg.region.name };
      });
      this.territorial = { 'id': 3, 'name': 'regional', territorial: regions };
    }
  }

  compare(a: any, b: any): number {
    if (a.id_type < b.id_type){
      return -1;
    }
    if (a.id_type > b.id_type){
      return 1;
    }
    return 0;
  }

  generateDataGraph(): void {
    // ORIGEN
    const ori = [];
    let total = 0;
    this.funding_source.forEach(element => {
      total += element.total;
    });
    this.funding_source.forEach((element, index) => {
      ori.push(
        {
          category: this.nameSource(element.id_type),
          value: element.total,
          color: this.getColor(index),
          percent: element.total * 100 / total
        }
      );
    });
    this.data_graph.push({ 'name': 'Origen', data: ori });
    this.total_total = total;

    // UPS // RUBRO
    const isPor = this.isPortfolio();
    const ups1 = [];
    let obj = [], name = '';
    total = 0;
    if (isPor) {
      obj = this.ups;
      name = 'Dependencia';
    } else {
      obj = this.splits;
      name = 'Partida';
    }

    obj.forEach(element => {
      total += element.total;
    });
    obj.forEach(element => {
      ups1.push(
        {
          category: element.name,
          value: element.total,
          color: element.color,
          percent: element.total * 100 / total
        }
      );
    });
    this.data_graph.push({ name, data: ups1 });
  }

  nameSource(id: Number): string {
    const source: any = this.typeSource(id);
    return source.name;
  }

  getColor(index): string {
    if (this.colors.length > 0) {
      if (index > this.colors.length - 1) {
        return this.colors[this.colors.length - 1].color;
      }
      return this.colors[index].color;
    } else {
      return '';
    }
  }

  typeSource(id: Number): any {
    const funding_sourcesand = [
      { 'id': 1, 'name': 'Estatal' },
      { 'id': 2, 'name': 'Federal' },
      { 'id': 3, 'name': 'Internacional' },
      { 'id': 4, 'name': 'Otros' },
      { 'id': 5, 'name': 'Municipal' }
    ];
    const index = funding_sourcesand.findIndex((x: any) => x.id === id);
    if (index >= 0) {
      return funding_sourcesand[index];
    }
    return '';
  }  

  isPortfolio(): boolean {
    if (this.portfolio && this.portfolio.projects.length > 1){
      return true;
    }
    return false;
  }

  get upsSplits(): any {
    return this.isPortfolio() ? this.ups : this.splits;
  }

  public async createImage(type) {
    let row = document.getElementById(type).getElementsByClassName('chart-content')[0];
    row.innerHTML = row.innerHTML.replace('style="width: 100%; height: 100%;', 'width="300px" height="300px" style="');
    await html2canvas(row).then(canvas => {
      this.imgUrl = canvas.toDataURL('image/png');
    });
  }
}
