import { Component, ElementRef, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormBuilder, Validators } from '@angular/forms'
import { CoordinationService } from 'app/coordinations/services/coordination.service'

@Component({
	selector: 'app-dialog-coordination',
	templateUrl: './dialog-coordination.component.html',
	styleUrls: ['./dialog-coordination.component.scss']
})
export class DialogCoordinationComponent implements OnInit {
	coord = null
	stringFile = null
	form = new FormBuilder().group({
		name: ['', Validators.required],
		logo: ['', []],
		color: ['', Validators.required]
	})
	saving = false

	constructor(
		private coordinationService: CoordinationService,
		public dialogRef: MatDialogRef<DialogCoordinationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data && data.coordination) {
			this.coord = data.coordination
		}
	}

	ngOnInit() {
		this._setFormValues()
	}

	fileImageChangeEvent(file) {
		if (file.target.files && file.target.files[0]) {
			let filename = file.target.files[0].name
			this.form.get('logo').setValue(filename)

			var reader = new FileReader()
			reader.addEventListener('load', (event) => {
				let result = event.target['result']
				this.stringFile = result.toString()
			})
			reader.readAsDataURL(file.target.files[0])
		} else {
			this.form.get('logo').setValue('')
			this.stringFile = null
		}
	}

	private _getParams() {
		let params = { ...this.form.value }
		params['file'] = this.stringFile
		if (this.coord && this.coord) params['id'] = this.coord.id
		return params
	}

	save() {
		let params = this._getParams()
		this.saving = true
		this.coordinationService.createOrUpdate(params).subscribe((result) => {
			this.saving = false
			this.dialogRef.close(result)
		})
	}

	private _setFormValues() {
		if (this.coord) {
			this.form.get('name').setValue(this.coord.name)
			this.form.get('logo').setValue(this.coord.logo)
			this.form.get('color').setValue(this.coord.primary_color)
		}
	}
}
