import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvanceBoardComponent } from './advance-board.component';
import { Routes, RouterModule } from '@angular/router';
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatIconModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

const route: Routes = [
   { path: 'advance-route/add', component: AdvanceBoardComponent }
];

@NgModule({
  declarations: [AdvanceBoardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(route),
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule
  ]
})
export class AdvanceBoardModule { }
