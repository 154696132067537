import { Injectable } from '@angular/core'

import {
	HttpClient,
	HttpRequest,
	HttpEventType,
	HttpResponse
} from '@angular/common/http'
import { Observable, Subject } from 'rxjs'
import { Globals } from '../globals'
import { Up } from './up.model'

@Injectable({
	providedIn: 'root'
})
export class UpsService {
	private url: string
	private module: string
	private httpOptions: any

	constructor(private http: HttpClient, private global: Globals) {
		this.url = global.api
		this.module = 'up'
		this.httpOptions = global.httpOptions
	}

	public index(): Observable<any> {
		return this.http.get(`${this.url}${this.module}`, this.httpOptions)
	}

	public perCoordination(): Observable<any> {
		return this.http.get(
			`${this.url}${this.module}/coordination`,
			this.httpOptions
		)
	}

	public perSpecificCoordination(id: number): Observable<any> {
		return this.http.get(
			`${this.url}${this.module}/coordination/${id}`,
			this.httpOptions
		)
	}

	public create(up: Up): Observable<any> {
		return this.http.post(`${this.url}${this.module}`, up, this.httpOptions)
	}

	public update(up: Up): Observable<any> {
		return this.http.put(
			`${this.url}${this.module}/${up.id}`,
			up,
			this.httpOptions
		)
	}
}
