import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComunicationListComponent } from './components/comunication-list/comunication-list.component';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';

const routes: Routes = [
      {
        path: 'comunication',
        component: ComunicationListComponent,
        canActivate: [AuthGuard]
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ComunicationRoutingModule { }
