
export class Up {
    id: number = 0;
    name: string = null;
    titular: string = null;
    email: string = null;
    color: string = null;
    government_id: number = null;
    coordination_id: number = null;
    
    active: boolean = true;
    created_at: Date;
    updated_at: Date;
}
