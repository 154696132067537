import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { Globals } from '../globals'

@Injectable({
	providedIn: 'root'
})
export class PortfoliosService {
	private uri: string
	private module: string
	private httpOptions: any

	constructor(private http: HttpClient, private global: Globals) {
		this.uri = global.api
		this.module = 'portfolios'
		this.httpOptions = global.httpOptions
	}

	getAllDashboard(): Observable<any> {
		return this.http.get(this.uri + 'dashboard', this.httpOptions)
	}

	getAllDashboardByCoord(id: number): Observable<any> {
		return this.http.get(
			this.uri + 'dashboard' + '/by-coordination/' + id,
			this.httpOptions
		)
	}

	getAllDashboardByCoordByDirectorate(
		idCoord: number,
		idDirec: number
	): Observable<any> {
		return this.http.get(
			this.uri +
				'dashboard' +
				'/by-coordination/' +
				idCoord +
				'/by-directorate/' +
				idDirec,
			this.httpOptions
		)
	}

	getDetailDashboard(id: number): Observable<any> {
		return this.http.get(this.uri + 'dashboard' + '/' + id, this.httpOptions)
	}

	getAll(): Observable<any> {
		return this.http.get(this.uri + 'portfolios', this.httpOptions)
	}

	get(id: number): Observable<any> {
		return this.http.get(this.uri + this.module + '/' + id, this.httpOptions)
	}

	getNews(id: number): Observable<any> {
		return this.http.get(
			this.uri + this.module + '/' + id + '/news',
			this.httpOptions
		)
	}

	init(): Observable<any> {
		return this.http.get(this.uri + this.module + '/init', this.httpOptions)
	}

	store(form: FormGroup): Observable<any> {
		return this.http.post(this.uri + this.module, form.value, this.httpOptions)
	}

	edit(id): Observable<any> {
		return this.http.get(this.uri + this.module + '/' + id, this.httpOptions)
	}

	update(id: Number, form: FormGroup): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id,
			form,
			this.httpOptions
		)
	}

	delete(id): Observable<any> {
		return this.http.delete(this.uri + this.module + '/' + id, this.httpOptions)
	}

	menu(): Observable<any> {
		return this.http.get(this.uri + this.module + '/menu', this.httpOptions)
	}

	news(q: string): Observable<any> {
		return this.http.get(this.uri + `news/${q}`, this.httpOptions)
	}

	newsRange(q: string, d1: any, d2: any): Observable<any> {
		return this.http.get(this.uri + `news/${q}/${d1}/${d2}`, this.httpOptions)
	}

	dependencies(id, data: any): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id + '/dependencies',
			data,
			this.httpOptions
		)
	}

	removeTag(id: Number): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id + '/removeTag',
			this.httpOptions
		)
	}

	updateTag(id: Number, news_tag: String): Observable<any> {
		return this.http.put(
			this.uri + this.module + '/' + id + '/updateTag',
			{ news_tag },
			this.httpOptions
		)
	}
}
