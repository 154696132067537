export class Up {
    id: number;
    name: string;
    titular: string;
    email: string;
    color: string;
    government_id: number;
    coordination_id: number;

    constructor(
        id?: number,
        name?: string,
        titular?: string,
        email?: string,
        color?: string,
        government_id?: number,
        coordination_id?: number) {

            this.id = id ? id : 0;
            this.name = name ? name : '';
            this.titular = titular ? titular : '';
            this.email = email ? email : '';
            this.color = color ? color : '';
            this.government_id = government_id ? government_id : null;
            this.coordination_id = coordination_id ? coordination_id : null;

        }
}
