import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChangePasswordComponent } from './change-password.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule,
  MatStepperModule,
  MatDividerModule,
  MatMenuModule,
  MatExpansionModule,
  MatTooltipModule,
  MatSidenavModule,
  MatListModule,
  MatToolbarModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
    MatDividerModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSidenavModule,
    FuseSharedModule,
    DragDropModule,
    MatListModule,
    MatToolbarModule
  ],
  declarations: [
    ChangePasswordComponent
  ],
  entryComponents: [
  ],
  providers: [
    
  ],
  exports: [
    ChangePasswordComponent
  ]
})
export class ChangePasswordModule { }
