import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { fuseAnimations } from '@fuse/animations'
import { PortfoliosService } from '../../portfolios/portfolios.service'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import {
	MatSnackBar,
	MatDialogRef,
	MatDialog,
	MatBottomSheet,
	MatSidenav
} from '@angular/material'
import { DialogAddProjectComponent } from '../../projects/dialog-add-project/dialog-add-project.component'
import { DialogEditPortfolioComponent } from '../dialog-edit-portfolio/dialog-edit-portfolio.component'
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component'
import { AuthService } from '../../services/auth.service'
import { TagsPortfoliosComponent } from '../tags-portfolios/tags-portfolios.component'
import { TagsService } from '../tags-portfolios/tags.service'
import { DialogDependenciesPortfoliosComponent } from '../dialog-dependencies-portfolios/dialog-dependencies-portfolios.component'
import { Location } from '@angular/common'
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { VisualizerDialogComponent } from 'app/visualizer/dialog/dialog.component'
import { DialogParticipantsComponent } from '../dialog-participants/dialog-participants.component'
import { Utils } from '../../constants/utils'
import { ProjectsService } from 'app/projects/projects.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ValidateProjectComponent } from '../validate-project/validate-project.component'
import { CoordinationService } from 'app/coordinations/services/coordination.service'
import { AuthenticatedUserService } from 'app/auth/authenticated-user.service'
import { UpsService } from '../../ups/ups.service'

@Component({
	selector: 'app-index-portfolios',
	templateUrl: './index-portfolios.component.html',
	styleUrls: ['./index-portfolios.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class IndexPortfoliosComponent implements OnInit {
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav
	@ViewChild('sidenavprofile', { static: false }) sidenavprofile: MatSidenav
	loading: Boolean = false
	coordinacionId = null
	directorateId = null
	portfolios: any[] = []
	statusColors: any = {
		default: '#d8d8d8'
	}
	rangeProgress = [
		{ color: 'red', min: 1, max: 29 },
		{ color: 'yellow', min: 30, max: 59 },
		{ color: 'green', min: 60, max: 100 }
	]
	dialogProjectForm: any
	dialogPortfolioForm: any
	confirmProjectFormDialog: MatDialogRef<FuseConfirmDialogComponent>
	activeFilter: String = 'all' // all | portfolio | project
	activeLayout: String = 'cards' // cards | table
	filterCtrl = new FormControl('')
	filteredPortfolios: Observable<any[]>
	permission: Boolean = false
	roundProgressConfg = {
		radius: 22,
		stroke: 6
	}
	roundProgressOptionsConfg = {
		cards: {
			radius: 22,
			stroke: 6
		},
		table: {
			radius: 23,
			stroke: 6
		}
	}
	dataComment: any
	directorates = []
	currentDirec = null
	currentUserRole = null
	responsiblesCount = 0
	colaboratorsCount = 0
	projectId = null

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private coordinationService: CoordinationService,
		private upService: UpsService,
		private portfoliosService: PortfoliosService,
		private projectsService: ProjectsService,
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private auth: AuthService,
		private bottomSheet: MatBottomSheet,
		private tagsService: TagsService,
		private location: Location,
		private authService: AuthService,
		private utils: Utils,
		private authenticatedUserService: AuthenticatedUserService
	) {}

	ngOnInit(): void {
		this.currentUserRole = this.authService.rol
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this.directorateId = this.route.snapshot.paramMap.get('directorateId')
		location.hash = ''
		this.permission = this.auth.hasPermission('portfolios')
		this.getPortfolios()
		this.filteredPortfolios = this.filterCtrl.valueChanges.pipe(
			startWith(null),
			map((portfolio: string | '') => this._filteredPortfolios(portfolio))
		)
		this._getDirectorates()
	}

	private _getDirectorates() {
		this.upService
			.perSpecificCoordination(this.coordinacionId)
			.subscribe((result) => {
				this.directorates = result
				this.currentDirec = result.find((i) => i.id == this.directorateId).id
			})
	}

	changeDirectorate(direc) {
		this.currentDirec = direc.value
		this.directorateId = direc.value
		this.responsiblesCount = 0
		this.colaboratorsCount = 0
		this.getPortfolios()
		this.router.navigateByUrl(
			`portafolios/coordinaciones/${this.coordinacionId}/direcciones/${this.directorateId}`
		)
	}

	getStagesProgress(portfolio: any): any {
		return this.utils.getNewPorcent('portfolio', portfolio)
	}

	openProjectFormDialog(): void {
		window.history.pushState({}, '', location.pathname + '#')
		this.dialogProjectForm = this.dialog.open(DialogAddProjectComponent, {
			width: '800px',
			data: {
				coordinacionId: this.coordinacionId,
				directorateId: this.directorateId
			}
		})

		this.dialogProjectForm.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}
				return false
			}
			this.location.back()
			this.snackBar.open('Proyecto agregado', 'OK', {
				duration: 3500
			})
			this.getPortfolios()
		})
	}

	openDependencies(portfolio): void {
		window.history.pushState({}, '', location.pathname + '#')
		const dialogRef = this.dialog.open(DialogDependenciesPortfoliosComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '90%',
			height: '400px',
			disableClose: true,
			data: portfolio
		})

		dialogRef.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}
				return false
			}
			this.location.back()
			this.getPortfolios()
			this.snackBar.open('Proyecto agregado', 'OK', {
				duration: 3500
			})
		})
	}

	public openParticipants(portfolio: any): void {
		const dialog = this.dialog.open(DialogParticipantsComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '90%',
			height: '65%',
			disableClose: true,
			data: portfolio.projects[0]
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.snackBar.open('Participantes agregados', 'OK', {
					duration: 3500
				})
			}
		})
	}

	getPortfolios(): void {
		this.loading = true
		if (this.coordinacionId)
			this.portfoliosService
				.getAllDashboardByCoordByDirectorate(
					this.coordinacionId,
					this.directorateId
				)
				.subscribe((data) => {
					data.forEach((d) => {
						if (d.up.id == this.directorateId) {
							this.responsiblesCount += 1
						} else {
							this.colaboratorsCount += 1
						}
					})
					this.portfolios = data
					this.filterCtrl.setValue('')
					this.loading = false
				})
		else
			this.portfoliosService.getAllDashboard().subscribe((data) => {
				this.portfolios = data
				this.filterCtrl.setValue('')
				this.loading = false
			})
	}

	isFilterActive(type: String): Boolean {
		return this.activeFilter === type ? true : false
	}

	applyFilter(type: String): void {
		this.activeFilter = type
		this.filterCtrl.setValue(this.filterCtrl.value)
	}

	setLayout(type: String): void {
		this.activeLayout = type
		this.roundProgressConfg = this.roundProgressOptionsConfg[<any>type]
	}

	isLayoutActive(type: String): Boolean {
		return this.activeLayout === type ? true : false
	}

	hasMultiProjects(portfolio: any): Boolean {
		return portfolio.projects.length > 1 ? true : false
	}

	qtyProjects(portfolio: any): Number {
		return portfolio.projects.length
	}

	getProjects(portfolio): any {
		if (typeof portfolio.projects === 'undefined') {
			return []
		}

		let projects = portfolio.projects.reduce((data, project) => {
			if (project === null) {
				return false
			}

			data.push(project)
			return data
		}, [])

		projects = projects.sort((a: any, b: any) =>
			a.dependency.name.localeCompare(b.dependency.name)
		)

		return projects
	}

	getFilteredProjects(portfolio): any {
		// console.log(portfolio)
		if (typeof portfolio.projects === 'undefined') {
			return []
		}

		let projects = portfolio.projects.reduce((data, project) => {
			if (project === null) {
				return false
			}

			const exists = data.findIndex(
				(d) => d.dependency_id === project.dependency.id
			)

			if (exists < 0) {
				data.push(project)
			}

			return data
		}, [])

		/*projects = projects.filter((proj, pos, arr) => {
			return arr.map(mapObj =>
						mapObj.id).indexOf(proj.id) == pos;
			});*/

		projects = projects.sort((a: any, b: any) =>
			a.dependency.name.localeCompare(b.dependency.name)
		)

		return projects
	}

	get entryFiltered(): String {
		return this.activeFilter === 'all'
			? 'resultados'
			: this.activeFilter === 'portfolio'
			? 'portafolios'
			: 'proyectos'
	}

	_filteredPortfolios(value: any): any[] {
		return this.portfolios.filter((portfolio) => {
			if (this.activeFilter !== 'all') {
				if (this.activeFilter === 'portfolio' && !portfolio.multi_projects) {
					return false
				} else if (
					this.activeFilter === 'project' &&
					portfolio.multi_projects
				) {
					return false
				}
			}

			if (typeof value === 'object') {
				return true
			}

			value = this.removeAccents(value.toLowerCase())
			let values = value.trim().split(' ')
			values = values.filter((v) => v.length > 2)
			if (values.length === 0) {
				return true
			}
			// Busca dentro de cada uno de los tags, que contengan alguna de las palabras de la búsqueda
			const indexTags = portfolio.tags.findIndex((elem: any) => {
				const tag_str = this.removeAccents(elem.tag.toLowerCase())
				const ind = values.findIndex((val: any) => {
					return tag_str.includes(val) && elem.type !== 4
				})
				return ind > -1
			})

			const portfolioName = this.removeAccents(portfolio.name.toLowerCase())
			const namesPortfolio = portfolioName.split(' ')
			// Busca dentro de cada palabra del nombre, que contengan alguna de las palabras de la búsqueda
			let indexName = 0
			indexName = namesPortfolio.findIndex((name: any) => {
				const ind = values.findIndex((val: any) => {
					return name.includes(val)
				})
				return ind > -1
			})

			return indexName > -1 || indexTags > -1
		})
	}

	showNoMatch(): Boolean {
		return this._filteredPortfolios(this.filterCtrl.value).length === 0
			? true
			: false
	}

	private removeAccents(strAccents: any): String {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}

		return strAccentsOut.join('')
	}

	getLinkProject(portfolio): String {
		return portfolio.multi_projects
			? '/portafolios/' + portfolio.id
			: '/proyectos/' +
					(typeof portfolio.projects[0] !== 'undefined'
						? portfolio.projects[0].id
						: 0)
	}

	getLinkDependencyProject(dependency): String {
		return '/proyectos/' + dependency.id
	}

	getLinkGanttProject(portfolio): String {
		return portfolio.multi_projects
			? ''
			: '/diagrama-gantt/' +
					(typeof portfolio.projects[0] !== 'undefined'
						? portfolio.projects[0].id
						: 0)
	}

	hasAvatar(user: any): boolean {
		if (!user) {
			return false
		}

		if (typeof user.avatar === 'undefined') {
			return false
		}
		return user.avatar.length <= 0 ? false : true
	}

	getImgAvatar(user: any): string {
		return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none'
	}

	getUserFullName(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	showDependencies(portfolio: any): void {
		this.hideAllDependencies()
		portfolio.showDependencies = true
	}

	hideDependencies(portfolio: any): void {
		portfolio.showDependencies = false
	}

	hideAllDependencies(): void {
		this.portfolios.forEach(
			(portfolio: any) => (portfolio.showDependencies = false)
		)
	}

	openTags(portfolio: any): void {
		const bottomSheetRef = this.bottomSheet.open(TagsPortfoliosComponent, {
			panelClass: 'bottomSheet',
			data: portfolio
		})

		bottomSheetRef.afterDismissed().subscribe(() => {
			this.tagsService
				.getAll(portfolio.id)
				.subscribe((tags) => (portfolio.tags = tags))
		})
	}

	hasPermissionTags(portfolio: any): Boolean {
		let isResponsible = false
		if (portfolio && portfolio.up) {
			isResponsible = portfolio.up.id === this.authService.dependencyId
		}

		return (
			this.auth.jwt.rol === 'admin' ||
			this.auth.jwt.module === 'communication' ||
			(this.auth.jwt.rol === 'dependency' && isResponsible)
		)
	}

	hasModuleAllowed(module): boolean {
		return this.auth.hasPermission(module)
	}

	reportRoute(portfolio: any): any[] {
		if (portfolio.multi_projects) {
			return ['/reporte/portafolio', portfolio.id]
		} else {
			return [
				'/reporte/portafolio',
				portfolio.id,
				'proyecto',
				typeof portfolio.projects[0] !== 'undefined'
					? portfolio.projects[0].id
					: 0
			]
		}
	}

	isAdmin(): boolean {
		return this.auth.isAdmin()
	}

	openPortfolioFormDialog(id_portfolio): void {
		let portfolio: any = this.portfolios.filter(
			(portf) => portf.id === id_portfolio
		)
		if (portfolio.length > 0) {
			portfolio = portfolio[0]
		}
		window.history.pushState({}, '', location.pathname + '#')
		this.dialogPortfolioForm = this.dialog.open(DialogEditPortfolioComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '90%',
			data: {
				portfolio: {
					id: portfolio.id,
					name: portfolio.name,
					description: portfolio.description,
					objective: portfolio.objective
				}
			}
		})

		this.dialogPortfolioForm.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}
				return false
			}
			this.location.back()
			this.snackBar.open('Portafolio editado correctamente', 'OK', {
				duration: 3500
			})
			this.getPortfolios()
		})
	}

	openPortfolioDeleteDialog(idPortfolio: number, name: string): void {
		const dialog = this.dialog.open(ConfirmComponent, {
			panelClass: 'dialog-confirm',
			data: {
				content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar el Portafolio <strong><br>"${name}"</strong>?<label>`
			}
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.portfoliosService.delete(idPortfolio).subscribe(
					(data) => {
						this.snackBar.open('Portafolio Eliminado', 'OK', {
							duration: 3500
						})
						this.getPortfolios()
					},
					(error) => {
						this.snackBar.open('Error al eliminar el portafolio', 'ERROR', {
							duration: 3000
						})
					}
				)
			}
		})
	}

	openUserInfoDialog(portfolio: any): void {
		this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: portfolio.user_up,
				up: portfolio.up
			}
		})
	}

	public openVisualizers(portfolio: any): void {
		this.dialog.open(VisualizerDialogComponent, {
			panelClass: 'mat-dialog-system',
			data: {
				id: portfolio.projects[0].id,
				name: portfolio.projects[0].name,
				dependency: portfolio.projects[0].dependency
					? portfolio.projects[0].dependency.name
					: ''
			}
		})
	}

	toggle(portfolio: any): void {
		const type = portfolio.multi_projects ? 1 : 2
		this.dataComment = {
			name:
				type === 1
					? `Portafolio: ${portfolio.name}`
					: `Proyecto: ${portfolio.name}`,
			level_name: type === 1 ? 'portfolio' : 'project',
			element_id: type === 1 ? portfolio.id : portfolio.projects[0].id,
			icon: type === 1 ? 'folder' : 'bookmark_border'
		}
		this.projectId = portfolio.projects[0].id
		this.sidenav.toggle()
	}

	toggleProfile(): void {
		this.sidenavprofile.toggle()
	}

	editProjectDialog(portfolio): void {
		this.projectsService
			.get(portfolio.projects[0].id)
			.subscribe((project: any) => {
				this.dialog
					.open(DialogAddProjectComponent, {
						width: '800px',
						data: {
							project_id: project.id
						}
					})
					.afterClosed()
					.subscribe((response) => {
						if (response) this.getPortfolios()
					})
			})
	}

	openProjectDeleteDialog(portfolio: any): void {
		const dialog = this.dialog.open(ConfirmComponent, {
			panelClass: 'dialog-confirm',
			data: {
				content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar el Proyecto <strong><br>"${portfolio.projects[0].name}"</strong>?<label>`
			}
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.projectsService.delete(portfolio.projects[0].id).subscribe(
					() => {
						this.snackBar.open('Proyecto Eliminado', 'OK', {
							duration: 3500
						})
						this.getPortfolios()
					},
					() => {
						this.snackBar.open('Error al eliminar el proyecto', 'ERROR', {
							duration: 3000
						})
					}
				)
			}
		})
	}

	validateProject(portfolio) {
		this.dialogPortfolioForm = this.dialog.open(ValidateProjectComponent, {
			panelClass: 'mat-dialog-system-md',
			data: {
				portfolio: {
					id: portfolio.id,
					name: portfolio.name,
					multiyear_budget: portfolio.multiyear_budget,
					promise: portfolio.promise,
					description: portfolio.description,
					objective: portfolio.objective,
					project_id: portfolio.projects[0].id
				}
			}
		})

		this.dialogPortfolioForm.afterClosed().subscribe((response) => {
			if (response !== undefined) {
				let portfolioTmp = this.portfolios.find((p) => p.id == portfolio.id)
				portfolioTmp.projects[0].is_validated = response.is_validated
				portfolioTmp.projects[0].responsible_validated_id =
					response.responsible_validated_id
				portfolioTmp.projects[0].responsible_validated =
					response.responsible_validated
				portfolioTmp.projects[0].date_validated = response.date_validated
			}
		})
	}
}
