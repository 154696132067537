import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import {
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatButtonToggleModule,
        MatMenuModule
      } from '@angular/material';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';

import { IndexComponent } from './index/index.component';
import { DialogUpComponent } from './dialog-up/dialog-up.component';

const routes: Routes = [
  {
    path: 'ups',
    component: IndexComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [IndexComponent, DialogUpComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    FuseSharedModule,
    MatButtonToggleModule,
    CdkTableModule,
    MatMenuModule,
    ColorPickerModule,
    NgxMaskModule.forRoot()
  ], 
  entryComponents: [
    DialogUpComponent
  ],
  providers: [AuthGuard]
})
export class UpsModule { }
