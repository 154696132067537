import { Component, OnInit, ViewChild } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import {
	MatDialog,
	MatTableDataSource,
	MatPaginator,
	MatSort
} from '@angular/material'
import { Axis } from '../axis.model'
import { AxisService } from '../axis.service'
import { DialogAxisComponent } from '../dialog-axis/dialog-axis.component'
import { Router } from '@angular/router'
import { Location } from '@angular/common'

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss'],
	animations: fuseAnimations
})
export class IndexComponent implements OnInit {
	public columns: string[] = ['name', 'topic', 'coordination', 'settings']
	public dataSource: MatTableDataSource<Axis>

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: false }) sort: MatSort
	coordinations: any

	constructor(
		private service: AxisService,
		private dialog: MatDialog,
		private router: Router,
		private location: Location
	) {}

	ngOnInit(): void {
		location.hash = ''
		this.getAxis()

		const spanishRangeLabel = (
			page: number,
			pageSize: number,
			length: number
		) => {
			if (length === 0 || pageSize === 0) {
				return `0 de ${length}`
			}

			length = Math.max(length, 0)

			const startIndex = page * pageSize

			const endIndex =
				startIndex < length
					? Math.min(startIndex + pageSize, length)
					: startIndex + pageSize

			return `${startIndex + 1} - ${endIndex} de ${length}`
		}

		this.paginator._intl.itemsPerPageLabel = 'Registros por página'
		this.paginator._intl.nextPageLabel = 'Siguiente'
		this.paginator._intl.previousPageLabel = 'Anterior'
		this.paginator._intl.lastPageLabel = 'Ultima página'
		this.paginator._intl.firstPageLabel = 'Primer página'
		this.paginator._intl.getRangeLabel = spanishRangeLabel
	}

	openDialog(axis: Axis): void {
		if (typeof axis === 'undefined') {
			axis = new Axis()
		}

		window.history.pushState({}, '', location.pathname + '#')
		const dialogRef = this.dialog.open(DialogAxisComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '60%',
			data: {
				axis,
				coordinations: this.coordinations
			}
		})

		dialogRef.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) this.location.back()
				return false
			}
			this.location.back()

			this.getAxis()
		})
	}

	getAxis(): void {
		this.service.index().subscribe((response: any) => {
			this.dataSource = new MatTableDataSource(response.axis)
			this.dataSource.paginator = this.paginator
			this.dataSource.sort = this.sort
			this.coordinations = response.coordinations
		})
	}

	applyFilter(filterValue: string): void {
		this.dataSource.filter = this.removeAccents(
			filterValue.trim().toLowerCase()
		)
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}

		return strAccentsOut.join('')
	}
}
