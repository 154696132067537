import { Component, OnInit, Input, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { StagesService } from '../stages.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  MatDialogRef, MAT_DIALOG_DATA, MatDatepicker,
} from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { isNullOrUndefined, isNull } from 'util';

@Component({
  selector: 'app-dialog-add-stage',
  templateUrl: './dialog-add-stage.component.html',
  styleUrls: ['./dialog-add-stage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class DialogAddStageComponent implements OnInit {
  @ViewChild('dateFinish', {static: false}) dateFinish: MatDatepicker<Date>;
  form: FormGroup;
  @Input() projectId: number;
  @Input() stage: any;
  saving: Boolean = false;

  public minDate = new Date(2018, 11, 6);
  public minDateFinish = new Date();
  public maxDate = new Date(2024, 11, 5);
  public errorDateValid = {isError: false, errorMessage: 'La fecha de asignación no puede ser mayor que la fecha de solución'};
  public dateInitialDate: any;
  public dateFinishDate: any;

  constructor(private formBuilder: FormBuilder,
    private stagesService: StagesService,
    public dialogRef: MatDialogRef<DialogAddStageComponent>,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      dateInitial: ['', Validators.required],
      dateFinished: ['', Validators.required]
    });

    if(this.data != null)
      this.loadStage();

    if (this.stage) {
      let dInitial :any = '';
      if(!isNullOrUndefined(this.stage.date_initial) && this.stage.date_initial != '')
      {
        dInitial = this.stage.date_initial.split("/");
        dInitial = dInitial[2] + "-" + dInitial[1] + "-" + dInitial[0]
      }
      let dFinished : any = '';
      if(!isNullOrUndefined(this.stage.date_finished) && this.stage.date_finished != '')
      {
        dFinished = this.stage.date_finished.split("/");
        dFinished = dFinished[2] + "-" + dFinished[1] + "-" + dFinished[0];
      }

      this.form.patchValue({
        name: this.stage.name,
        description: this.stage.description,
        dateInitial: dInitial,
        dateFinished: dFinished
      });
    }
  }

  loadStage(){
    this.stage = this.data;
    let date : any;
    if (!isNullOrUndefined(this.stage.date_initial) && this.stage.date_initial !== '') {
      date = this.stage.date_initial.split("/");
      this.dateInitialDate = new Date(date[1] + "-" + date[0] + "-" + date[2]);
    }
    
    if (!isNullOrUndefined(this.stage.date_finished) && this.stage.date_finished !== '') {
      date = this.stage.date_finished.split("/");
      this.dateFinishDate = new Date(date[1] + "-" + date[0] + "-" + date[2]);
    }
  }

  addStage(): void {
    
    if(!this.form.valid)
      return;
    this.saving = true;
    const form = Object.assign(this.form.value, { 'projectId': this.projectId });

    if (!this.stage) {
      this.stagesService.store(form).subscribe(response => {
        this.saved();
      }, error => {
        this.saving = false;
        const mnsError = error.error.find(mns => mns.field === 'date');
        if (!!mnsError) {
          this.errorDateValid.isError = true;
          this.errorDateValid.errorMessage = `${mnsError.error} con la etapa: ${mnsError.stage.name},     
          Fecha Inicio: ${mnsError.stage.dateInitial}, 
          Fecha Fin: ${mnsError.stage.dateFinish}`;
        }
      });
    } else {
      this.stagesService.update(form, this.stage.id).subscribe(response => {
        this.saved();
      }, error => {
        this.saving = false;
        const mnsError = error.error.find(mns => mns.field === 'date');
        if (!!mnsError) {
          this.errorDateValid.isError = true;
          this.errorDateValid.errorMessage = `${mnsError.error} con la etapa: ${mnsError.stage.name},     
          Fecha Inicio: ${mnsError.stage.dateInitial}, 
          Fecha Fin: ${mnsError.stage.dateFinish}`;
        }
      });
    }
  }

  saved(): void {
    this.saving = false;
    this.dialogRef.close(true);
  }

  private checkValueFinishDate(){
    if( isNullOrUndefined(this.form.controls['dateFinished'].value) || this.form.controls['dateFinished'].value === '' )
        this.dateFinish.open();
  }

  public setDate(event, name: string, type: Number): void {
    if(!isNullOrUndefined(event.value))
    {
      this.form.get(name).setValue(event.value.format('YYYY-MM-DD'));
      if (type === 1) {
        this.checkValueFinishDate();
        this.minDateFinish = event.value.format('YYYY-MM-DD');
      }
      this.compareTwoDates();
    }
  }

  compareTwoDates(): void {
    const d1 = new Date(this.form.controls['dateInitial'].value);
    const d2 = new Date(this.form.controls['dateFinished'].value);
    if (!d1 && !d2){
      return;
    }
    if (d2 < d1) {
      this.errorDateValid.isError = true;
      this.form.get('dateFinished').setValue(this.minDateFinish);
      this.dateFinishDate = this.minDateFinish;
    } else {
      this.errorDateValid.isError = false;
    }
  }

  public getTodayDate(): any {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;

    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }
  
}
