import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StagesService } from '../stages.service';
import { fuseAnimations } from '@fuse/animations';
import { DialogAddStageComponent } from '../dialog-add-stage/dialog-add-stage.component';
import { AuthService } from '../../services/auth.service';
import { DialogAddSubprojectComponent } from '../../subprojects/dialog-add-subproject/dialog-add-subproject.component';
import { DetailProjectsService } from '../../projects/detail-projects/detail-projects.service';
import {
  MatDialog,
  MatSnackBar
} from '@angular/material';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { moveItemInArray, CdkDragDrop, CdkDrag } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-list-stages',
  templateUrl: './list-stages.component.html',
  styleUrls: ['./list-stages.component.scss'],
  animations: fuseAnimations
})
export class ListStagesComponent implements OnInit, OnDestroy {
  loadingStages = true;
  stages: any[] = [];
  activeAdd: Boolean = false;
  modalStageForm: any;
  projectId: number;
  stageId: any;
  actionId: any;
  activeStage: number;
  project: any;
  stage: any;
  heightPanelHeader = '60px';
  activeSearch: Boolean = false;
  @ViewChild('search', {static: true}) search: ElementRef;

  //Unsuscribe
  private Uproject: Subscription;
  private UCaction: Subscription;
  private UCproject: Subscription;
  private UCstege: Subscription;
  private Ustage: Subscription;
  private Ustages: Subscription;
  //Unsuscribe

  constructor(
    private _stages: StagesService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private auth: AuthService,
    private detailProjectsService: DetailProjectsService,
    private router: Router,
    private location: Location
  ) {

    this.Uproject = this.detailProjectsService.project.subscribe(data => {
      //Validar Etapa Existente
      let index = 0;
      if (Object.entries(data).length !== 0 && data.constructor === Object) {
        if (this.stageId) {
          index = data.project_stages.findIndex(stage => {
            return stage.id == this.stageId;
          });
          if (index < 0) {
            this.router.navigate(['/portafolios']);
            return;
          }

          //Validar Acción Existente
          let indexAct = 0;
          let stageCur = data.project_stages[index];
          if (this.actionId) {
            indexAct = stageCur.subprojects.findIndex(action => {
              return action.id == this.actionId;
            });
            if (indexAct < 0) {
              this.router.navigate(['/portafolios']);
              return;
            }
          }
        }
      }
      this.project = data;
    });
    this.Ustage = this.detailProjectsService.stage.subscribe(data => this.stage = data);
    this.UCaction = this.detailProjectsService.current.action.subscribe(data => this.actionId = data);
    this.UCproject = this.detailProjectsService.current.project.subscribe(id => this.projectId = id);
    this.UCstege = this.detailProjectsService.current.stage.subscribe(id => this.activeStage = this.stageId = id);
    this.Ustages = this.detailProjectsService.stages.subscribe(result => this.stages = result);

    this.detailProjectsService.loading.stages.subscribe(data => {
      this.loadingStages = data;
    });
  }

  ngOnInit(): void {
    location.hash = '';
    const observable = fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(200))
      .subscribe((elemenet: any) => {
        this.getStage();
      });
  }

  getStage() {
    this.stages = this.detailProjectsService.stages.value;

    const filter = this.removeAccents(this.search.nativeElement.value.toLowerCase());

    const result = this.stages.filter((stage: any): any => {
      if (this.removeAccents(stage.description).toLowerCase().indexOf(filter) !== -1 || this.removeAccents(stage.name).toLowerCase().indexOf(filter) !== -1) {
        return true;
      }

      if (stage.subprojects) {
        if ((stage.subprojects.filter((subproject: any): any => {
          if (filter === '') {
            return true;
          } else if (this.removeAccents(subproject.name).toLowerCase().indexOf(filter) !== -1) {
            return true;
          }
        })).length) {
          return (stage.subprojects = stage.subprojects.filter((subproject: any): any => {
            if (filter === '') {
              return true;
            } else if (this.removeAccents(subproject.name).toLowerCase().indexOf(filter) !== -1) {
              return true;
            }
          })).length;
        } else {
          return (stage.subprojects.filter((subproject: any): any => {
            if (filter === '') {
              return true;
            } else if (this.removeAccents(subproject.name).toLowerCase().indexOf(filter) !== -1) {
              return true;
            }
          })).length;
        }
      }
    });

    this.stages = result;

    // if (filter === '') {
    //   this.detailProjectsService.refreshStages();
    // }
  }

  ngOnDestroy(): void {
    this.Uproject.unsubscribe();
    this.UCaction.unsubscribe();
    this.UCproject.unsubscribe();
    this.UCstege.unsubscribe();
    this.Ustage.unsubscribe();
    this.Ustages.unsubscribe();
  }

  showActions(stageId: number): void {
    this.activeStage = stageId;
    // this.detailProjectsService.getActions(stageId);
  }

  openStageModal(stage): void {
    window.history.pushState({}, "", location.pathname + '#');
    this.modalStageForm = this.dialog.open(DialogAddStageComponent, {
      disableClose: true,
      panelClass: 'mat-dialog-system',
    });

    const instance = this.modalStageForm.componentInstance;
    instance.stage = stage;
    instance.projectId = this.projectId;

    this.modalStageForm.afterClosed()
      .subscribe(response => {
        if (!response) {
          if (response !== undefined) {
            this.location.back();
          }
          return false;
        }
        this.location.back();

        const message = stage ? 'Etapa actualizada' : 'Etapa creada';

        this.snackBar.open(message, 'Cerrar', {
          duration: 3500
        });

        this.detailProjectsService.refreshStages(true);
      });
  }


  getStageName(stage: any): string {
    return stage.name.toLowerCase().indexOf('etapa ') === 0 ? stage.name.substring(6, stage.name.length) : stage.name;
  }

  isActivePanel(stage: any): boolean {
    return this.activeStage === stage.id;
  }

  isActiveAction(action: any): boolean {
    return this.actionId === action.id;
  }

  openDialogAction(id: any = null): void {
    window.history.pushState({}, "", location.pathname + '#');
    const dialogModal = this.dialog.open(DialogAddSubprojectComponent, {
      panelClass: 'mat-dialog-system-md',
      width: '80%',
      height: '450px',
      disableClose: true,
      data: {
        project: {
          id: this.project.id,
          name: this.project.dependency.name,
          stageId: this.stage.id
        },
        action: {
          id: id
        }
      }
    });

    dialogModal.afterClosed()
      .subscribe(response => {
        if (!response || (response && response.length > 0 && response[1] == false)) {
          if (response !== undefined)
            this.location.back();
          return false;
        }
        this.location.back();

        this.snackBar.open(response[0], 'OK', {
          duration: 3500
        });

        this.detailProjectsService.refreshActions(true);
      });
  }

  goToAction(action: any): string {
    return `etapa/${this.stageId}/accion/${action.id}`;
  }

  statusSidebar(): string {
    return this.detailProjectsService.statusSidebar();
  }

  activateSearch(): void {
    this.activeSearch = true;
  }

  deactivateSearch(): void {
    this.activeSearch = false;
  }

  onFocusSearch(): void {
    this.search.nativeElement.focus();
  }

  getIconStage(stage: any): string {
    return 'label' + (this.isActivePanel(stage) ? '' : '_outline');
  }

  hideActionsButton(): boolean {
    return this.stages.length > 0 ? false : true;
  }

  drop(event: CdkDragDrop<string[]>) {
    let stage_id = this.stages[event.previousIndex].id;
    let current_position = this.stages[event.previousIndex].position;
    let new_position = event.currentIndex;
    moveItemInArray(this.stages, event.previousIndex, event.currentIndex);
    this.detailProjectsService.setOrderStage(this.projectId, stage_id, current_position, new_position)
      .subscribe(result => {
        this.detailProjectsService.refreshStages();
      }, error => {
        this.snackBar.open('Ocurrió un error', 'OK', {
          duration: 3500
        });
      });
  }

  onDragStart(event: CdkDrag<string[]>, stage: any) {
    /*console.log(event);
    console.log(stage);
    this.activeStage = 0;*/
  }

  dropAction(event: CdkDragDrop<string[]>, stage: any) {
    let stage_id = stage.id;
    let subproject_id = stage.subprojects[event.previousIndex].id;
    let index_stage = stage.position;
    let current_position = stage.subprojects[event.previousIndex].position;
    let new_position = event.currentIndex;
    moveItemInArray(this.stages[index_stage].subprojects, event.previousIndex, event.currentIndex);
    this.detailProjectsService.setOrderSubproject(stage_id, subproject_id, current_position, new_position)
      .subscribe(result => {
        this.detailProjectsService.refreshActions();
      }, error => {
        this.snackBar.open('Ocurrió un error', 'OK', {
          duration: 3500
        });
      });
  }

  private removeAccents(strAccents: any): string {
    strAccents = strAccents.split('');
    const strAccentsOut = new Array();
    const strAccentsLen = strAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) !== -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }

    return strAccentsOut.join('');

  }
}
