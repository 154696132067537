import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Globals } from '../globals';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  private url: string;
  private module: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private global: Globals
  ) { 
    this.url = global.api;
    this.module = 'members';
    this.httpOptions = global.httpOptions;
  }

  index(): Observable<any> {
    return this.http.get(this.url + this.module, this.httpOptions);
  }

  init(): Observable<any> {
    return this.http.get(this.url + 'users/init', this.httpOptions);
  }

  store(params): Observable<any> {
    return this.http.post(this.url + this.module, params, this.httpOptions);
  }

  edit(id: number): Observable<any> {
    return this.http.get(this.url + this.module + '/' + id, this.httpOptions);
  }

  update(params, id: number): Observable<any> {
    return this.http.put(this.url + this.module + '/' + id, params, this.httpOptions);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.url + this.module + '/' + id, this.httpOptions);
  }

  coordinations(): Observable<any> {
    return this.http.get(this.url + 'coordinations', this.httpOptions);
  }
  
  organisms(): Observable<any> {
    return this.http.get(this.url + 'organismo', this.httpOptions);
  }
}
