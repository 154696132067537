import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Globals } from 'app/globals'

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	private uri: string
	private module: string
	private httpOptions: any

	routeParams = new BehaviorSubject<any>({})
	coordination = new BehaviorSubject<any>({})
	directorate = new BehaviorSubject<any>({})
	portfolio = new BehaviorSubject<any>({})
	project = new BehaviorSubject<any>({})
	stage = new BehaviorSubject<any>({})
	action = new BehaviorSubject<any>({})

	current = {
		coordination: new BehaviorSubject<number>(0),
		directorate: new BehaviorSubject<number>(0),
		portfolio: new BehaviorSubject<number>(0),
		project: new BehaviorSubject<number>(0),
		stage: new BehaviorSubject<number>(0),
		action: new BehaviorSubject<number>(0)
	}

	constructor(private http: HttpClient, private global: Globals) {
		this.uri = global.api
		this.module = 'board'
		this.httpOptions = global.httpOptions

		this.routeParams.subscribe((params) => {
			if (params.hasOwnProperty('coordinationId')) {
				this.current.coordination.next(params.coordinationId)
				this.coordination.next(params.coordinationId)
			}

			if (params.hasOwnProperty('directorateId')) {
				this.current.directorate.next(params.directorateId)
				this.directorate.next(params.directorateId)
			}

			if (params.hasOwnProperty('actionId')) {
				this.current.portfolio.next(params.portfolioId)
				this.current.project.next(params.projectId)
				this.current.stage.next(params.stageId)
				this.current.action.next(params.actionId)
			} else if (params.hasOwnProperty('stageId')) {
				this.current.portfolio.next(params.portfolioId)
				this.current.project.next(params.projectId)
				this.current.stage.next(params.stageId)
				this.current.action.next(0)
			} else if (params.hasOwnProperty('projectId')) {
				this.current.portfolio.next(params.portfolioId)
				this.current.project.next(params.projectId)
				this.current.stage.next(0)
				this.current.action.next(0)
			} else if (params.hasOwnProperty('portfolioId')) {
				this.current.portfolio.next(params.portfolioId)
				this.current.project.next(0)
				this.current.stage.next(0)
				this.current.action.next(0)
			} else {
				this.current.portfolio.next(0)
				this.current.project.next(0)
				this.current.stage.next(0)
				this.current.action.next(0)
				this.portfolio.next({})
			}
		})

		this.current.portfolio.subscribe((portfolio) => {
			if (!portfolio) {
				return
			}
			this._getPortfolio(portfolio).subscribe((portfolioData) => {
				this.portfolio.next(portfolioData)
			})
		})
	}

	private _getPortfolio(portfolio: any): Observable<any> {
		return this.getTopBarDashboard(portfolio)
	}

	resolve(routeSnapshot: ActivatedRouteSnapshot): any {
		const snapshot: any = routeSnapshot.paramMap
		this.routeParams.next(snapshot.params)
		return true
	}

	getTopBarDashboard(id: number): Observable<any> {
		return this.http.get(
			this.uri + this.module + '/' + id + '/resume',
			this.httpOptions
		)
	}

	getAllDashboard(): Observable<any> {
		return this.http.get(this.uri + this.module, this.httpOptions)
	}

	getAllDashboardByCoordinationByDirectorate(
		coordinacionId,
		directorateId
	): Observable<any> {
		return this.http.get(
			`${this.uri}${this.module}/by-coordination/${coordinacionId}/by-directorate/${directorateId}`,
			this.httpOptions
		)
	}

	getDetailDashboard(id: number): Observable<any> {
		return this.http.get(this.uri + this.module + '/' + id, this.httpOptions)
	}

	getDetailProject(idPortfolio: number, idProject: number): Observable<any> {
		return this.http.get(
			this.uri + this.module + '/' + idPortfolio + '/' + idProject,
			this.httpOptions
		)
	}
}
