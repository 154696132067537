import {
	Component,
	OnInit,
	Inject,
	ViewEncapsulation,
	ViewChild,
	ElementRef
} from '@angular/core'
import {
	FormBuilder,
	FormGroup,
	Validators,
	FormControl,
	FormArray
} from '@angular/forms'
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatStepper,
	MatTableDataSource,
	MatChipInputEvent,
	MatAutocompleteSelectedEvent,
	MatAutocomplete,
	MatDatepicker
} from '@angular/material'
import { Project } from '../../projects/project'
import { SubprojectsService } from '../subprojects.service'
import { StagesService } from '../../stages/stages.service'
import { forkJoin } from 'rxjs'
import * as leaflet from 'leaflet'
import * as leafletDrawJS from 'leaflet-draw'
import * as translate from 'leaflet-draw-locales'
import { MapOptions } from './mapOptions'
import { DetailProjectsService } from 'app/projects/detail-projects/detail-projects.service'
import { Observable } from 'rxjs'
import { map as mapOperators, startWith, map } from 'rxjs/operators'
import { GeoService } from 'app/services/geo.service'
import { Dependency } from 'app/dependencies/dependency'
import { ENTER, COMMA } from '@angular/cdk/keycodes'
import { Utils } from '../../constants/utils'
import { isNumber } from 'util'
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from '@angular/material/core'
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from '@angular/material-moment-adapter'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
	selector: 'app-dialog-add-subproject',
	templateUrl: './dialog-add-subproject.component.html',
	styleUrls: ['./dialog-add-subproject.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class DialogAddSubprojectComponent implements OnInit {
	public form: FormGroup
	public formStep1: FormGroup
	public formStep2: FormGroup
	public formStep3: FormGroup
	public formStep4: FormGroup
	public project: Project
	public members: any[] = []
	public stages: any[]
	public subAxis: any[]
	public typesIndicator: any[]
	public coverages: any[]
	public dimensionIndicators: any[]
	public measurementFrequencies: any[]

	public measurementUnits: any[] = []
	public filteredUnits: Observable<any[]>
	public unitsCtrl: FormControl = new FormControl()

	public filteredUnits2: Observable<any[]>
	public unitsCtrl2: FormControl = new FormControl()

	public saving: Boolean = false
	public action: any = {
		id: null,
		name: '',
		user: ''
	}
	public ups: any[]
	public uegs: any[]
	public projectActive: any
	public textButton: String = 'Crear Acción'
	public dependenciesUP: any[] = []
	public dependenciesUEG: any[] = []
	public splits: any[] = []
	public fundingSourcesand: any[] = []
	public columns: string[] = ['name']
	public filesFederalSaved: string[] = []
	public filesInternationalSaved: string[] = []
	public filesOthersSaved: string[] = []
	public filesMunicipalSaved: string[] = []
	public filesImpactSaved: string[] = []
	public filesResultSaved: string[] = []
	public progress: any
	public lastCode: Boolean = true
	public widthFlex: Number = 50
	public leafletDraw: any
	private map: any
	private geom: any
	private radius: any
	public splitsAction: MatTableDataSource<any>
	public columnsSplits: string[] = ['split', 'amount', 'year', 'delete']
	public filesFederal: Set<File> = new Set()
	public filesInternational: Set<File> = new Set()
	public filesMunicipal: Set<File> = new Set()
	public filesOthers: Set<File> = new Set()
	public filesIndicatorResult: Set<File> = new Set()
	public filesIndicatorImpact: Set<File> = new Set()
	private mapOptions: MapOptions
	public addSplit: Boolean = true
	public risks: MatTableDataSource<any>
	public columnRisks: string[] = ['type', 'description', 'delete']
	public typeRisk: Number = 1
	public descriptionRisk = new FormControl('', [Validators.required])
	public titleDialog: String = 'Nueva Acción'
	private deleteSplitsAction: number[] = []
	private deleteRisks: number[] = []
	private currentYear: number = new Date().getFullYear()

	// Municipalities
	municipalities: Dependency[] = []
	catalogMunicipalities: Dependency[] = []
	filteredMunicipalities: Observable<any[]>
	municipalitiesCtrl = new FormControl('', [Validators.required])
	municipalitiesAdded = <any>[]
	readonly separatorKeysCodes: number[] = [ENTER, COMMA]

	private allProgressObservable: any[] = []
	public userInactive = false

	@ViewChild('municipalityInput', { static: false })
	municipalityInput: ElementRef<HTMLInputElement>
	@ViewChild('file', { static: false }) file
	@ViewChild('fileFederal', { static: false }) fileFederal
	@ViewChild('fileInternational', { static: false }) fileInternational
	@ViewChild('fileMunicipal', { static: false }) fileMunicipal
	@ViewChild('fileOthers', { static: false }) fileOthers
	@ViewChild('fileIndicatorResult', { static: false }) fileIndicatorResult
	@ViewChild('fileIndicatorImpact', { static: false }) fileIndicatorImpact
	@ViewChild('stepper', { static: true }) stepper
	@ViewChild('firstEFS3', { static: true }) firstEFS3: ElementRef
	@ViewChild('firstEFS4', { static: true }) firstEFS4: ElementRef

	// SELECT GROUP
	public responsableInput: FormControl = new FormControl()
	public responsableActive: any
	public filterResponsable: Observable<any[]>
	public isGroup: Boolean = false
	public responsableGroup: any[] = []
	public userResponsable: Number = 0
	@ViewChild('responsable', { static: false })
	public matAutocompleteResponsable: MatAutocomplete

	// SUB AXIS
	public subAxiInput: FormControl = new FormControl()
	public filterSubAxi: Observable<any[]>
	public subAxi = 0
	@ViewChild('subAxi', { static: false })
	public matAutocompleteSubAxi: MatAutocomplete

	// SELECT STAGE
	public stageInput: FormControl = new FormControl()
	public stageActive: any
	public filterStage: Observable<any[]>
	@ViewChild('stage', { static: false })
	public matAutocompleteStage: MatAutocomplete

	// SELECT ACTION
	public actionInput: FormControl = new FormControl()
	public filterAction: Observable<any[]>
	public actionList: Array<any>
	public actionActive: Boolean = true
	@ViewChild('actionSelect', { static: false })
	public matAutocompleteActions: MatAutocomplete

	// SELECT UP
	public upInput: FormControl = new FormControl()
	public filterUp: Observable<any[]>
	@ViewChild('upSelect', { static: false })
	public matAutocompleteUp: MatAutocomplete

	// SELECT UEG
	public uegInput: FormControl = new FormControl()
	public filterUeg: Observable<any[]>
	@ViewChild('uegSelect', { static: false })
	public matAutocompleteUeg: MatAutocomplete

	// SELECT SPLITS
	public splitInput: FormControl = new FormControl()
	public filterSplit: Observable<any[]>
	@ViewChild('splitSelect', { static: false })
	public matAutocompleteSplit: MatAutocomplete

	public maxLengthName: Number = 255
	public maxLengthDenomination: Number = 255
	public maxLengthCalculationMethod: Number = 255
	public maxLengthDescription: Number = 255
	public maxLengthSourceInformation: Number = 255
	public maxLengthMeansVerification: Number = 255
	public maxLengthSupposed: Number = 255
	public maxLengthDescriptionRisk: Number = 255
	// DATES
	public minDate: Date
	public minDateFinish: Date
	public maxDate: Date
	public errorDateValid = {
		isError: false,
		errorMessage:
			'La fecha de asignación no puede ser mayor que la fecha de solución'
	}
	public dateInitialDate: any
	public dateFinishDate: any

	private currentActionId: number

	public yearForm: FormControl = new FormControl()
	//public year = 2019;
	public years: any
	public progressFederal: false

	constructor(
		public dialogRef: MatDialogRef<DialogAddSubprojectComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private subprojectsService: SubprojectsService,
		private stageService: StagesService,
		private detailProjectsService: DetailProjectsService,
		private geoService: GeoService,
		private utils: Utils,
		private _snackBar: MatSnackBar
	) {
		this.leafletDraw = leafletDrawJS
		this.mapOptions = new MapOptions()
		this.splitsAction = new MatTableDataSource()
		this.risks = new MatTableDataSource()
	}

	ngOnInit(): void {
		if (this.data) {
			this.currentActionId = this.data.action.id
		}
		this.project = this.data.project

		this.detailProjectsService.project.subscribe((data) => {
			this.projectActive = data
		})

		this.detailProjectsService.stage.subscribe((stage) => {
			this.minDate = this.parseDate(stage.date_initial)

			this.minDateFinish = this.minDate
			this.maxDate = this.parseDate(stage.date_finished)
		})

		this.stageService.get(this.project.id).subscribe((stages) => {
			this.stages = stages
		})

		this.form = this.formBuilder.group({
			projectId: [this.project.id],
			name: ['', Validators.required],
			user: ['', Validators.required],
			subAxisId: [''],
			stageId: [this.data.project.stageId],
			newFundingSources: [[]],
			up: [''],
			ueg: [''],
			spendingDestination: [''],
			authorizedAmount: [''],
			document: [''],
			map: [''],
			risks: [[]],
			deleteSplitsAction: [[]],
			deleteRisks: [[]],
			indicatorResult: [''],
			indicatorImpact: [''],
			sourceInformation: [''] /*, Validators.required],*/,
			meansVerification: ['', Validators.required],
			supposed: ['', Validators.required],
			municipalities: this.formBuilder.array([]),
			direct: [''],
			indirect: [''],
			dateInitial: ['', Validators.required],
			dateFinished: ['', Validators.required],
			actionDepend: [null],
			actionId: ['']
		})

		this.formStep1 = this.formBuilder.group({
			projectId: [this.project.id],
			name: ['', Validators.required],
			user: ['', Validators.required],
			subAxisId: [''],
			stageId: ['', Validators.required],
			frequency: ['Mensual', Validators.required],
			municipalities: this.formBuilder.array([]),
			direct: [null],
			indirect: [null],
			actionDepend: [null],
			actionId: [''],
			dateInitial: ['', Validators.required],
			dateFinished: ['', Validators.required]
		})

		if (this.data.project.stageId > 0) {
			this.formStep1.get('stageId').setValue(this.data.project.stageId)
		}

		this.formStep2 = this.formBuilder.group({
			state: false,
			federal: false,
			international: false,
			municipal: false,
			others: false,
			up: [''],
			ueg: [''],
			splits: [''],
			spendingDestination: [''],
			authorizedAmount: [''],
			idState: [0],
			idFederal: [0],
			idInternational: [0],
			idMunicipal: [0],
			idOthers: [0],
			amountFederal: [''],
			amountInternational: [''],
			amountMunicipal: [''],
			amountOthers: [''],
			typeFederal: [''],
			typeInternational: [''],
			typeMunicipal: [''],
			typeOthers: [''],
			document: [''],
			splitsAction: [''],
			currentYear: 0
		})

		this.formStep3 = this.formBuilder.group({
			file: [''],

			/**NEW INPUTS */
			denomination: [''] /*Validators.required],*/,
			calculationMethod: [''] /*Validators.required],*/,
			description: [''] /* Validators.required],*/,
			typeIndicatorId: [1] /*Validators.required],*/,
			coverageId: [1] /*Validators.required],*/,
			dimensionIndicatorId: [1] /*Validators.required],*/,
			measurementFrecuencyId: [1] /*Validators.required],*/,
			yearMeasurement: [this.currentYear] /*Validators.required],*/,
			institutionalGoal: [
				100,
				[/*Validators.required, */ Validators.max(100), Validators.min(1)]
			],
			goalValue: [1, [/*Validators.required, */ Validators.min(1)]],
			measurementUnitId: [''] /*Validators.required],*/,
			senseIndicator: ['ASC'] /*Validators.required],*/,
			baseLineValue: [''],
			baseLineYear: [this.currentYear - 1],
			men: [''],
			women: [''],
			sourceInformation: [''] /*Validators.required],*/,
			meansVerification: [''] /*Validators.required],*/,
			supposed: [''] /*Validators.required]*/
		})

		this.formStep4 = this.formBuilder.group({
			file: [''],
			risks: [''],

			/**NEW INPUTS */
			denomination: [''] /*Validators.required],*/,
			calculationMethod: [''] /*Validators.required],*/,
			description: [''] /* Validators.required],*/,
			typeIndicatorId: [1] /*Validators.required],*/,
			coverageId: [1] /*Validators.required],*/,
			dimensionIndicatorId: [1] /*Validators.required],*/,
			measurementFrecuencyId: [1] /*Validators.required],*/,
			yearMeasurement: [this.currentYear] /*Validators.required],*/,
			institutionalGoal: [
				100,
				[/*Validators.required, */ Validators.max(100), Validators.min(1)]
			],
			goalValue: [1, [/*Validators.required, */ Validators.min(1)]],
			measurementUnitId: [''] /*Validators.required],*/,
			senseIndicator: ['ASC'] /*Validators.required],*/,
			baseLineValue: [''],
			baseLineYear: [this.currentYear - 1],
			men: [''],
			women: [''],
			sourceInformation: [''] /*Validators.required],*/,
			meansVerification: [''] /*Validators.required],*/,
			supposed: [''] /*Validators.required]*/
		})

		this.formStep2.get('spendingDestination').disable()

		this.action.id = this.data.action.id

		this.subprojectsService.initAction(this.project.id).subscribe((data) => {
			this.dependenciesUP = data.dependencies.filter(
				(dependency) => dependency.up !== null
			)
			this.dependenciesUEG = data.dependencies.filter(
				(dependency) => dependency.ueg > 0
			)
			this.fundingSourcesand = data.fundingSourcesand
			this.splits = data.splits
			this.subAxis = data.subAxis.sub_axis.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})

			this.typesIndicator = data.typesIndicator
			this.coverages = data.coverages
			this.dimensionIndicators = data.dimensionIndicators
			this.measurementFrequencies = data.measurementFrequencies
			this.measurementUnits = data.measurementUnits
			this.filteredUnits = this.unitsCtrl.valueChanges.pipe(
				startWith(''),
				mapOperators((val) => this.filterMeasurementUnits(val))
			)

			this.filteredUnits2 = this.unitsCtrl2.valueChanges.pipe(
				startWith(''),
				mapOperators((val) => this.filterMeasurementUnits(val))
			)
			/*this.lastCode = true;*/
			this.formStep2.controls['spendingDestination'].setValue(data.lastCode)
			this.setAmount()

			this.filterSubAxi = this.subAxiInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterSubAxis(val))
			)

			this.filterAction = this.actionInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterActions(val, this.data.action.id))
			)

			this.filterUp = this.upInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterInputsSelect(val, this.ups))
			)

			this.filterUeg = this.uegInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterInputsSelect(val, this.getUeg()))
			)

			this.filterSplit = this.splitInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterInputsSelect(val, this.getSpits()))
			)
		})

		if (this.data.action.id === null) {
			this.subprojectsService
				.membersId(this.projectActive.dependency_id)
				.subscribe((members) => {
					if (
						members === null ||
						members === undefined ||
						this.project === null ||
						this.project === undefined
					)
						return

					const mem = members.filter((member) => {
						return (
							(member.modules &&
								member.modules.module.name !== 'tasks' &&
								member.modules.module.name !== 'communication') ||
							!member.modules
						)
					})
					this.members = mem

					this.subprojectsService
						.usersTransversal(this.project.id)
						.subscribe((transversals) => {
							this.isGroup = transversals.length > 0

							const transFilter = transversals.filter((transversal) => {
								return (
									((transversal.modules &&
										transversal.modules.module.name !== 'tasks' &&
										transversal.modules.module.name !== 'communication') ||
										!transversal.modules) &&
									!this.members.find((mems) => mems.id === transversal.id)
								)
							})

							transFilter.map((elemnt) => {
								this.members.push(elemnt)
							})

							this.members = this.utils.orderUserList(this.members)

							if (this.isGroup) {
								this.addGroupResponsable()
							}

							this.filterResponsable = this.responsableInput.valueChanges.pipe(
								startWith(''),
								map((val) => this.utils.filterUsers(val, this.members))
							)
							if (this.stages) {
								this.stageInput.patchValue(
									this.stages.find(
										(stage) => stage.id === this.formStep1.get('stageId').value
									)
								)
								this.filterStage = this.stages.find(
									(stage) => stage.id === this.formStep1.get('stageId').value
								)
							}
							this.filterStage = this.stageInput.valueChanges.pipe(
								startWith(''),
								map((val) => this.filterStages(val))
							)
							if (
								typeof this.stageInput.value !== 'undefined' &&
								this.stageInput.value !== undefined &&
								this.stageInput.value !== null
							) {
								this.loadActionsByStage(this.stageInput.value.id)
							}
						})
				})

			// load Municipalities
			this.geoService.municipalities.subscribe((result) => {
				this.municipalities = result
				this.loadMunicipalities()
			})
		} else {
			this.titleDialog = 'Editar Acción'
			this.subprojectsService.get(this.action.id).subscribe((action) => {
				this.formStep1.controls['name'].setValue(action.name)
				if (action.project_managers[0].user.active === 1) {
					this.formStep1.controls['user'].setValue(
						action.project_managers[0].user.id
					)
					this.userResponsable = action.project_managers[0].user.id
				} else {
					this.userInactive = true
					this.responsableInput.markAsTouched()
				}
				this.formStep1.controls['subAxisId'].setValue(action.sub_axis_id)

				this.subAxiInput.patchValue(action.sub_axis)
				this.subAxiInput.updateValueAndValidity()

				this.dateFinishDate = this.parseDate(action.date_finished)
				this.dateInitialDate = this.parseDate(action.date_initial)
				this.minDateFinish = this.dateInitialDate

				this.formStep1.controls['dateInitial'].setValue(this.dateInitialDate)
				this.formStep1.controls['dateFinished'].setValue(this.dateFinishDate)

				// load Municipalities
				this.geoService.municipalities.subscribe((result) => {
					this.municipalities = result
					this.loadMunicipalities()
					this.initMunicipalities(action.municipalities)
				})

				this.formStep1.controls['direct'].setValue(action.benefited_direct)
				this.formStep1.controls['indirect'].setValue(action.benefited_indirect)

				this.formStep2.controls['splits'].setValue(action.split_id)
				this.formStep2.controls['spendingDestination'].setValue(
					action.spending_destination
				)
				this.formStep2.controls['authorizedAmount'].setValue(
					action.authorized_amount
				)
				this.formStep1.controls['actionDepend'].setValue(action.action_depend)
				this.loadActionsByStage(
					this.formStep1.get('stageId').value,
					action.subproject_id
				)

				if (action.indicator_result !== null) {
					this.formStep3.controls['denomination'].setValue(
						action.indicator_result.denomination
					)
					this.formStep3.controls['calculationMethod'].setValue(
						action.indicator_result.calculation_method
					)
					this.formStep3.controls['description'].setValue(
						action.indicator_result.description
					)
					this.formStep3.controls['typeIndicatorId'].setValue(
						action.indicator_result.type_indicator_id
					)
					this.formStep3.controls['coverageId'].setValue(
						action.indicator_result.coverage_id
					)
					this.formStep3.controls['dimensionIndicatorId'].setValue(
						action.indicator_result.dimension_indicator_id
					)
					this.formStep3.controls['measurementFrecuencyId'].setValue(
						action.indicator_result.measurement_frecuency_id
					)
					this.formStep3.controls['yearMeasurement'].setValue(
						action.indicator_result.year_measurement
					)
					this.formStep3.controls['institutionalGoal'].setValue(
						action.indicator_result.institutional_goal
					)
					this.formStep3.controls['goalValue'].setValue(
						action.indicator_result.goal_value
					)
					this.formStep3.controls['measurementUnitId'].setValue(
						action.indicator_result.measurement_unit_id
					)

					this.formStep3.controls['senseIndicator'].setValue(
						action.indicator_result.sense_indicator.toUpperCase()
					)
					this.formStep3.controls['baseLineValue'].setValue(
						action.indicator_result.base_line_value
					)
					this.formStep3.controls['baseLineYear'].setValue(
						action.indicator_result.base_line_year
					)
					this.formStep3.controls['men'].setValue(action.indicator_result.men)
					this.formStep3.controls['women'].setValue(
						action.indicator_result.women
					)
					this.formStep3.controls['sourceInformation'].setValue(
						action.indicator_result.source_information
					)
					this.formStep3.controls['meansVerification'].setValue(
						action.indicator_result.means_verification
					)
					this.formStep3.controls['supposed'].setValue(
						action.indicator_result.supposed
					)
				}

				if (action.indicator_impact !== null) {
					this.formStep4.controls['denomination'].setValue(
						action.indicator_impact.denomination
					)
					this.formStep4.controls['calculationMethod'].setValue(
						action.indicator_impact.calculation_method
					)
					this.formStep4.controls['description'].setValue(
						action.indicator_impact.description
					)
					this.formStep4.controls['typeIndicatorId'].setValue(
						action.indicator_impact.type_indicator_id
					)
					this.formStep4.controls['coverageId'].setValue(
						action.indicator_impact.coverage_id
					)
					this.formStep4.controls['dimensionIndicatorId'].setValue(
						action.indicator_impact.dimension_indicator_id
					)
					this.formStep4.controls['measurementFrecuencyId'].setValue(
						action.indicator_impact.measurement_frecuency_id
					)
					this.formStep4.controls['yearMeasurement'].setValue(
						action.indicator_impact.year_measurement
					)
					this.formStep4.controls['institutionalGoal'].setValue(
						action.indicator_impact.institutional_goal
					)
					this.formStep4.controls['goalValue'].setValue(
						action.indicator_impact.goal_value
					)
					this.formStep4.controls['measurementUnitId'].setValue(
						action.indicator_impact.measurement_unit_id
					)

					this.formStep4.controls['senseIndicator'].setValue(
						action.indicator_impact.sense_indicator.toUpperCase()
					)
					this.formStep4.controls['baseLineValue'].setValue(
						action.indicator_impact.base_line_value
					)
					this.formStep4.controls['baseLineYear'].setValue(
						action.indicator_impact.base_line_year
					)
					this.formStep4.controls['men'].setValue(action.indicator_impact.men)
					this.formStep4.controls['women'].setValue(
						action.indicator_impact.women
					)
					this.formStep4.controls['sourceInformation'].setValue(
						action.indicator_impact.source_information
					)
					this.formStep4.controls['meansVerification'].setValue(
						action.indicator_impact.means_verification
					)
					this.formStep4.controls['supposed'].setValue(
						action.indicator_impact.supposed
					)
				}

				const indexState = this.findActiveFunding(
					action.subprojects_financing,
					1
				)
				const indexFederal = this.findActiveFunding(
					action.subprojects_financing,
					2
				)
				const indexInternational = this.findActiveFunding(
					action.subprojects_financing,
					3
				)
				const indexOthers = this.findActiveFunding(
					action.subprojects_financing,
					4
				)
				const indexMunicipal = this.findActiveFunding(
					action.subprojects_financing,
					5
				)

				this.formStep2.patchValue({
					state: indexState >= 0 ? true : false,
					federal: indexFederal >= 0 ? true : false,
					international: indexInternational >= 0 ? true : false,
					others: indexOthers >= 0 ? true : false,
					municipal: indexMunicipal >= 0 ? true : false,
					idState:
						indexState >= 0 ? action.subprojects_financing[indexState].id : 0,
					idFederal:
						indexFederal >= 0
							? action.subprojects_financing[indexFederal].id
							: 0,
					idInternational:
						indexInternational >= 0
							? action.subprojects_financing[indexInternational].id
							: 0,
					idOthers:
						indexOthers >= 0 ? action.subprojects_financing[indexOthers].id : 0,
					idMunicipal:
						indexMunicipal >= 0
							? action.subprojects_financing[indexMunicipal].id
							: 0,
					amountFederal:
						indexFederal >= 0
							? action.subprojects_financing[indexFederal].amount
								? action.subprojects_financing[indexFederal].amount
								: ''
							: '',
					amountInternational:
						indexInternational >= 0
							? action.subprojects_financing[indexInternational].amount
								? action.subprojects_financing[indexInternational].amount
								: ''
							: '',
					amountOthers:
						indexOthers >= 0
							? action.subprojects_financing[indexOthers].amount
								? action.subprojects_financing[indexOthers].amount
								: ''
							: '',
					amountMunicipal:
						indexMunicipal >= 0
							? action.subprojects_financing[indexMunicipal].amount
								? action.subprojects_financing[indexMunicipal].amount
								: ''
							: '',
					typeFederal:
						indexFederal >= 0
							? action.subprojects_financing[indexFederal].type
							: '',
					typeInternational:
						indexInternational >= 0
							? action.subprojects_financing[indexInternational].type
							: '',
					typeOthers:
						indexOthers >= 0
							? action.subprojects_financing[indexOthers].type
							: '',
					typeMunicipal:
						indexMunicipal >= 0
							? action.subprojects_financing[indexMunicipal].type
							: ''
				})

				if (indexState >= 0) {
					this.formStep2.controls['up'].setValue(
						action.subprojects_financing[indexState].up_id
					)
					this.formStep2.controls['ueg'].setValue(
						action.subprojects_financing[indexState].ueg_id
					)
					this.splitsAction.data =
						action.subprojects_financing[indexState].splits_action
					// this.upInput.disable();
					// this.uegInput.disable();
				}

				this.risks.data = action.risks

				this.subprojectsService
					.membersId(this.projectActive.dependency_id)
					.subscribe((members) => {
						if (
							members === null ||
							members === undefined ||
							this.project === null ||
							this.project === undefined
						)
							return

						const mem = members.filter((member) => {
							return (
								(member.modules && member.modules.module_id !== 5) ||
								!member.modules
							)
						})
						this.members = mem

						this.subprojectsService
							.usersTransversal(this.project.id)
							.subscribe((transversals) => {
								this.isGroup = transversals.length > 0

								const transFilter = transversals.filter((transversal) => {
									return (
										((transversal.modules &&
											transversal.modules.module.name !== 'tasks' &&
											transversal.modules.module.name !== 'communication') ||
											!transversal.modules) &&
										!this.members.find((mems) => mems.id === transversal.id)
									)
								})

								transFilter.map((elemnt) => {
									this.members.push(elemnt)
								})

								this.members = this.utils.orderUserList(this.members)

								if (this.isGroup) {
									this.addGroupResponsable()
								} else {
									if (this.members)
										this.responsableInput.setValue(
											this.members.find(
												(user) => user.id === this.userResponsable
											)
										)
								}

								this.filterResponsable =
									this.responsableInput.valueChanges.pipe(
										startWith(''),
										map((val) => this.utils.filterUsers(val, this.members))
									)

								if (this.stages) {
									this.stageInput.patchValue(
										this.stages.find(
											(stage) =>
												stage.id === this.formStep1.get('stageId').value
										)
									)
									this.filterStage = this.stages.find(
										(stage) => stage.id === this.formStep1.get('stageId').value
									)
								}

								this.filterStage = this.stageInput.valueChanges.pipe(
									startWith(''),
									map((val) => this.filterStages(val))
								)
							})
					})

				this.textButton = 'Editar Acción'
				this.geom = action.geom
				this.radius =
					action.radius !== null
						? action.radius
						: JSON.parse(action.array_radius)
			})

			this.subprojectsService.getFilesAll(this.action.id).subscribe((files) => {
				this.filesFederalSaved = files.federal
				this.filesInternationalSaved = files.international
				this.filesOthersSaved = files.others
				this.filesMunicipalSaved = files.municipal
				this.filesImpactSaved = files.impact
				this.filesResultSaved = files.result
			})
		}

		this.getPartidasYears()
		//this.getUpsWithUegWithPartidas();

		/**
		 * MAP
		 */
		/*this.stepper.selectionChange.subscribe((data) => {
      if (data.selectedIndex === 2) {
        if (typeof this.map === 'undefined') {
          this.map = leaflet.map('map').setView(this.mapOptions.pointInitial, this.mapOptions.zoomInitial);

          const lang = translate('es');
          this.map.drawLocal = lang;

          leaflet.tileLayer(this.mapOptions.templateLayer, {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }).addTo(this.map);

          const drawnItems = new leaflet.FeatureGroup();
          this.map.addLayer(drawnItems);

          this.mapOptions.drawnItems = drawnItems;

          const drawnControl = new leaflet.Control.Draw(this.mapOptions.optionDraw);
          const drawnControlsOff = new leaflet.Control.Draw(this.mapOptions.optionDrawsOff);

          this.map.addControl(drawnControl);
          
          if (typeof this.geom !== 'undefined' && this.geom !== null) {
            let isMarker = false;
            let circleElem = 0;
            this.geom.geometries.forEach(geoms => {
              let radiusValue = null;
              if (geoms.type === 'Point' && this.radius === null) {
                isMarker = true;
              }
              if (geoms.type === 'Point' && this.radius !== null) {
                radiusValue = this.radius;
                if (this.radius.length > 0) {
                  radiusValue = this.radius[circleElem];
                  circleElem++;
                }
              }

              this.mapOptions.draw(geoms.type, geoms.coordinates, 'Titulo', this.map, drawnControlsOff, drawnControl, radiusValue);
            });
          }

          this.map.on(leaflet.Draw.Event.CREATED, (e) => {
            const draggable = true;
            const type = e.layerType;
            const layer = e.layer;
            layer.type = type;
            if (type === 'polygon' || type === 'polyline') {
              layer.options.color = this.mapOptions.getRandomColor();
            }
            layer.bindPopup(`<input type="button" value="${draggable ? 'Click para eliminar' : ''}" id="delete-polygone" class="marker-delete-button"/>`)
                .on('click', (e) => {
                  document.getElementById('delete-polygone').addEventListener('click', () => {
                      if (draggable) {
                          this.map.removeLayer(e.target);
                      }
                      this.mapOptions.validateOptionsDraw(this.map);
                  });
          });
            drawnItems.addLayer(layer);
          });

          this.mapOptions.validateOptionsDraw(this.map);

          this.map.addControl(this.mapOptions.searchControl);

          //event click to address//
          this.map.on('geosearch/showlocation', (e) => {

            leaflet.marker([e.location.y, e.location.x], {
              icon: leaflet.icon({
                iconUrl: 'assets/images/pin-jalisco.png',
                iconSize: [43, 56]
              }),
              title: e.location.label,
              alt: 'marker',
              draggable: true,
            }).bindPopup('<input type="button" value="Eliminar Marcador" id="delete-marker" class="marker-delete-button"/>')
              .on('popupopen', (ep) => {
                document.getElementById('delete-marker').addEventListener('click', () => {
                  this.map.removeLayer(ep.target);
                });
              }).addTo(this.map);

          });

          this.map.on('draw:drawstop', (e) => {
            const res = this.mapOptions.validateOptionsDraw(this.map, true);
            if (res) {
              this.openSnackBar('Máximo 50 elementos.', 'OK');
            }
          });

          this.map.on('draw:deletestop', (e) => {
            let countLayers = 0;
            for (const key in e.target._targets) {
              if (typeof e.target._targets[key]._containerId === 'undefined') {
                countLayers++;
                if (e.target._targets[key].type === 'marker') {
                  countLayers = 0;
                }
              }
            }
            this.mapOptions.validateOptionsDraw(this.map);
          });
        }

        if (this.data.action.id !== null) {
          let selected;
          selected = this.measurementUnits.find(unit => unit.id === this.formStep3.get('measurementUnitId').value);

          if (typeof selected !== 'undefined') {
            this.unitsCtrl.setValue(selected.name);
          }

          let selected2;
          selected2 = this.measurementUnits.find(unit => unit.id === this.formStep4.get('measurementUnitId').value);

          if (typeof selected2 !== 'undefined') {
            this.unitsCtrl2.setValue(selected2.name);
          }
        }

      }

      if (data.selectedIndex === 1) {
        this.validationForm2();
      }

      if (data.selectedIndex === 4) {
        this.formStep4.get('risks').clearValidators();
        if (this.risks.data.length <= 0) {
          this.formStep4.get('risks').setValidators(Validators.required);
        }
        this.formStep4.get('risks').updateValueAndValidity();
      } else {
        this.formStep4.get('risks').clearValidators();
        this.formStep4.get('risks').updateValueAndValidity();
      }

      switch (data.selectedIndex) {
        case 1:
          this.setAmount();
          break;
        case 2:
          this.firstEFS3.nativeElement.focus();
          break;
        case 3:
          this.firstEFS4.nativeElement.focus();
          break;
        default:
          break;
      }

    });*/

		/**
		 * / END MAP
		 */
		this.formStep2.get('authorizedAmount').disable()
	}

	getPartidasYears() {
		this.subprojectsService.getPartidasYears().subscribe((result) => {
			this.years = result
			this.formStep2.get('currentYear').setValue(new Date().getFullYear())
			this.splitInput.disable()
			this.getUpsWithUegWithPartidas()
		})
	}

	yearSelected(year) {
		this.splitInput.disable()
		this.getUpsWithUegWithPartidas()
	}

	getUpsWithUegWithPartidas() {
		this.subprojectsService
			.ups(this.formStep2.get('currentYear').value)
			.subscribe((ups: any[]) => {
				this.splitInput.enable()
				this.ups = ups.filter((up) => {
					return up.government_id
				})
				if (this.formStep2.get('up').value > 0) {
					if (this.ups)
						this.upInput.patchValue(
							this.ups.find((up) => up.id === this.formStep2.get('up').value)
						)
				}

				if (this.formStep2.get('ueg').value > 0) {
					this.uegInput.patchValue(
						this.getUeg().find(
							(ueg) => ueg.id === this.formStep2.get('ueg').value
						)
					)
				}

				this.filterSplit = this.splitInput.valueChanges.pipe(
					startWith(''),
					map((val) => this.filterInputsSelect(val, this.getSpits()))
				)
			})
	}

	findActiveFunding(obj: any, type: number): number {
		const index = obj.findIndex((row) => row.funding_sources_id === type)
		return index
	}

	initMunicipalities(municipalitiesInit): void {
		const munInit = municipalitiesInit
		munInit.map((municipality) => {
			const index = this.municipalities.findIndex(
				(x: any) => x.id === municipality.municipality_id
			)
			if (index >= 0) {
				const formMunicipalities = (<FormArray>(
					this.form.get('municipalities')
				)) as FormArray
				formMunicipalities.push(new FormControl(this.municipalities[index]))
				this.municipalitiesAdded.push(this.municipalities[index])
			}
			return municipality
		})

		this.municipalities = this.municipalities.filter((option) => {
			const index = munInit.findIndex(
				(x: any) => x.municipality_id === option.id
			)
			return index === -1
		})

		this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
			startWith(null),
			map((municipality: string | null) =>
				municipality
					? this._filterMunicipalities(municipality)
					: this.municipalities.slice()
			)
		)
	}

	loadMunicipalities(): void {
		this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
			startWith(null),
			map((municipality: string | null) =>
				municipality
					? this._filterMunicipalities(municipality)
					: this.municipalities.slice()
			)
		)
	}

	private _filterMunicipalities(value: any): any[] {
		return this.municipalities.filter((municipality) => {
			const municipalityName = this.removeAccents(
				municipality.name.toLowerCase()
			)

			if (typeof value === 'object') {
				return false
			}

			value = this.removeAccents(value)
			return municipalityName.indexOf(value) > -1
		})
	}

	addMunicipality(event: MatChipInputEvent): void {}

	removeMunicipality(municipality: any): void {
		const formMunicipalities = (<FormArray>(
			this.form.get('municipalities')
		)) as FormArray
		const i = formMunicipalities.controls.findIndex(
			(x) => x.value.id === municipality.id
		)
		if (i >= 0) {
			formMunicipalities.removeAt(i)
		}
		const index = this.municipalitiesAdded.indexOf(municipality)
		this.municipalitiesAdded.splice(index, 1)
		this.filterMunicipalities(municipality, 2)
	}

	selectedMunicipality(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.value
		const repeated = this.municipalitiesAdded.find(
			(data) => data.id === value.id
		)

		if (!!!repeated) {
			const formMunicipalities = (<FormArray>(
				this.form.get('municipalities')
			)) as FormArray
			formMunicipalities.push(new FormControl(value))
			this.municipalitiesAdded.push(value)
			this.municipalityInput.nativeElement.value = ''
			this.municipalitiesCtrl.setValue(null)
		}
		this.filterMunicipalities(event.option.value, 1)
	}

	filterMunicipalities(el, action): void {
		if (action === 1) {
			this.municipalities = this.municipalities.filter((option) => {
				return option.id !== el.id
			})
		} else {
			this.municipalities.push(el)
		}
		this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
			startWith(null),
			map((municipality: string | null) =>
				municipality
					? this._filterMunicipalities(municipality)
					: this.municipalities.slice()
			)
		)
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}
		return strAccentsOut.join('')
	}

	save(): any {
		this.detailProjectsService.current.stage.next(
			this.formStep1.get('stageId').value
		)
		this.detailProjectsService.refreshStages()

		if (!this.formStep1.valid) {
			return false
		}

		this.form.controls['projectId'].setValue(
			this.formStep1.get('projectId').value
		)
		this.form.controls['name'].setValue(this.formStep1.get('name').value)
		this.form.controls['user'].setValue(this.formStep1.get('user').value)
		this.form.controls['subAxisId'].setValue(
			this.formStep1.get('subAxisId').value
		)
		this.form.controls['stageId'].setValue(this.formStep1.get('stageId').value)
		this.form.controls['dateInitial'].setValue(
			this.formStep1.get('dateInitial').value
		)
		this.form.controls['dateFinished'].setValue(
			this.formStep1.get('dateFinished').value
		)
		this.form.controls['actionId'].setValue(
			this.formStep1.get('actionId').value
		)
		this.form.controls['indicatorResult'].setValue(this.formStep3.value)
		this.form.controls['indicatorImpact'].setValue(this.formStep4.value)

		if (this.data.action.id === null) {
			this.subprojectsService.store(this.form).subscribe(
				(data) => {
					this.uploadFile(data.id, 'Accion Creada')
				},
				(error) => {
					this.saving = false
					const mnsError = error.error.find((mns) => mns.field === 'date')
					if (!!mnsError) {
						this.errorDateValid.isError = true
						this.errorDateValid.errorMessage = `${mnsError.error} con la acción: ${mnsError.subproject.name}, 
          Fecha Inicio: ${mnsError.subproject.dateInitial}, 
          Fecha Fin: ${mnsError.subproject.dateFinish}`
					}
				}
			)
		} else {
			this.subprojectsService.update(this.action.id, this.form).subscribe(
				(data) => {
					this.uploadFile(this.action.id, 'Accion Actualizada')
				},
				(error) => {
					this.saving = false
					const mnsError = error.error.find((mns) => mns.field === 'date')
					if (!!mnsError) {
						this.errorDateValid.isError = true
						this.errorDateValid.errorMessage = `${mnsError.error} con la acción: ${mnsError.subproject.name}, 
          Fecha Inicio: ${mnsError.subproject.dateInitial}, 
          Fecha Fin: ${mnsError.subproject.dateFinish}`
					}
				}
			)
		}
	}

	/* save(): any {

    this.detailProjectsService.current.stage.next(this.formStep1.get('stageId').value);
    this.detailProjectsService.refreshStages();

    if (!this.formStep1.valid || !this.formStep2.valid) {
      return false;
    }

    const layers: any[] = [];
    const pointMult: any[] = [];

    for (const key in this.map._targets) {

      if (typeof this.map._targets[key]._containerId === 'undefined') {

        let typeCheck = '';

        if (typeof this.map._targets[key].type === 'undefined') {
          typeCheck = this.map._targets[key].options.alt;
        } else {
          typeCheck = this.map._targets[key].type;
        }

        if (typeof typeCheck === 'undefined') {
          typeCheck = '';
        }

        if (typeCheck.toLowerCase() === 'marker' || typeCheck.toLowerCase() === 'point') {
          pointMult.push(
            this.map._targets[key]._latlng
          );
        } else if (typeCheck !== '') {
          if (typeCheck === 'circle') {
            layers.push({
              type: typeCheck,
              xy: this.map._targets[key]._latlng,
              radius: this.map._targets[key]._mRadius
            });
          } else {
            layers.push({
              type: typeCheck,
              xy: this.map._targets[key]._latlngs
            });
          }
        }
      }
    }

    this.saving = true;
    if (pointMult.length > 0) {
      layers.push({
        type: 'marker',
        xy: pointMult
      });
    }

    this.form.controls['map'].setValue(layers);
    this.form.controls['projectId'].setValue(this.formStep1.get('projectId').value);
    this.form.controls['name'].setValue(this.formStep1.get('name').value);
    this.form.controls['user'].setValue(this.formStep1.get('user').value);
    this.form.controls['subAxisId'].setValue(this.formStep1.get('subAxisId').value);
    this.form.controls['stageId'].setValue(this.formStep1.get('stageId').value);
    this.form.controls['direct'].setValue(this.formStep1.get('direct').value);
    this.form.controls['indirect'].setValue(this.formStep1.get('indirect').value);
    this.form.controls['deleteSplitsAction'].setValue(this.deleteSplitsAction);
    this.form.controls['spendingDestination'].setValue(this.formStep2.get('spendingDestination').value);
    this.form.controls['risks'].setValue(this.risks.data);
    this.form.controls['deleteRisks'].setValue(this.deleteRisks);
    this.form.controls['indicatorResult'].setValue(this.formStep3.value);
    this.form.controls['indicatorImpact'].setValue(this.formStep4.value);
    this.form.controls['up'].setValue(this.formStep2.get('up').value);
    this.form.controls['ueg'].setValue(this.formStep2.get('ueg').value);
    this.form.controls['dateInitial'].setValue(this.formStep1.get('dateInitial').value);
    this.form.controls['dateFinished'].setValue(this.formStep1.get('dateFinished').value);
    this.form.controls['actionDepend'].setValue(this.formStep1.get('actionDepend').value);
    this.form.controls['actionId'].setValue(this.formStep1.get('actionId').value);

    const newFundingSources = [];
    const typesSources = [{ 'id': 1, 'name': 'State', }, { 'id': 2, 'name': 'Federal' },
    { 'id': 3, 'name': 'International' }, { 'id': 4, 'name': 'Others' },
    { 'id': 5, 'name': 'Municipal' }];

    typesSources.forEach(element => {
      const active = this.formStep2.get(element.name.toLowerCase()).value;

      if (active) {
        if (element.name === 'State') {
          newFundingSources.push(
            {
              'id': this.formStep2.get(`id${element.name}`).value,
              'funding_sources_id': element.id,
              'splits': this.formStep2.get('splits').value,
              'splitsAction': this.splitsAction.data,
              'amount': null,
              'type': null
            }
          );
        } else {
          newFundingSources.push(
            {
              'id': this.formStep2.get(`id${element.name}`).value,
              'funding_sources_id': element.id,
              'splits': null,
              'splitsAction': [],
              'amount': this.formStep2.get(`amount${element.name}`).value,
              'type': this.formStep2.get(`type${element.name}`).value,
            }
          );
        }
      }
    });

    this.form.patchValue({
      'newFundingSources': newFundingSources
    });

    const authorizedAmount = this.formStep2.get('authorizedAmount').value;

    if (authorizedAmount.length === 12) {
      if (authorizedAmount.split('.').length === 1) {
        this.form.controls['authorizedAmount'].setValue(authorizedAmount.substr(0, 10) + '.' + authorizedAmount.substr(-2));
      }
    } else {
      this.form.controls['authorizedAmount'].setValue(this.formStep2.get('authorizedAmount').value);
    }

    if (this.data.action.id === null) {
      this.subprojectsService.store(this.form).subscribe(data => {

        this.uploadFile(data.id, 'Accion Creada');

      }, error => {
        this.saving = false;
        const mnsError = error.error.find(mns => mns.field === 'date');
        if (!!mnsError) {
          this.errorDateValid.isError = true;
          this.errorDateValid.errorMessage = `${mnsError.error} con la acción: ${mnsError.subproject.name}, 
          Fecha Inicio: ${mnsError.subproject.dateInitial}, 
          Fecha Fin: ${mnsError.subproject.dateFinish}`;
        }
      });

    } else {
      this.subprojectsService.update(this.action.id, this.form).subscribe(data => {

        this.uploadFile(this.action.id, 'Accion Actualizada');

      }, error => {
        this.saving = false;
        const mnsError = error.error.find(mns => mns.field === 'date');
        if (!!mnsError) {
          this.errorDateValid.isError = true;
          this.errorDateValid.errorMessage = `${mnsError.error} con la acción: ${mnsError.subproject.name}, 
          Fecha Inicio: ${mnsError.subproject.dateInitial}, 
          Fecha Fin: ${mnsError.subproject.dateFinish}`;
        }
      });
    }
  } */

	private uploadFile(id: number, mns: string): void {
		this.allProgressObservable = []

		if (this.filesFederal.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesFederal,
				'federal'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.filesInternational.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesInternational,
				'international'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.filesMunicipal.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesMunicipal,
				'municipal'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.filesOthers.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesOthers,
				'others'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.filesIndicatorResult.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesIndicatorResult,
				'result'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.filesIndicatorImpact.size > 0) {
			this.progress = this.subprojectsService.save(
				id,
				this.filesIndicatorImpact,
				'impact'
			)
			for (const key in this.progress) {
				if (this.progress.hasOwnProperty(key)) {
					if (typeof this.progress[key].progress !== 'undefined') {
						this.allProgressObservable.push(this.progress[key].progress)
					}
				}
			}
		}

		if (this.checkFilesExists()) {
			setTimeout(() => {
				this.dialogRef.close([mns, true])
			}, 500)
		} else {
			forkJoin(this.allProgressObservable).subscribe((end) => {
				setTimeout(() => {
					this.dialogRef.close([mns, true])
				}, 500)
			})
		}
	}

	checkFilesExists(): boolean {
		return (
			this.filesIndicatorImpact.size <= 0 &&
			this.filesIndicatorResult.size <= 0 &&
			this.filesFederal.size <= 0 &&
			this.filesInternational.size <= 0 &&
			this.filesMunicipal.size <= 0 &&
			this.filesOthers.size <= 0
		)
	}

	checkFilesSaved(): boolean {
		return (
			this.filesImpactSaved.length <= 0 &&
			this.filesResultSaved.length <= 0 &&
			this.filesFederalSaved.length <= 0 &&
			this.filesInternationalSaved.length <= 0 &&
			this.filesMunicipalSaved.length <= 0 &&
			this.filesOthersSaved.length <= 0
		)
	}

	next(step: MatStepper): void {
		step.next()
	}

	back(step: MatStepper): void {
		step.previous()
	}

	onFilesFederalAdded(): void {
		const files: { [key: string]: File } = this.fileFederal.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesFederal.add(files[key])
			}
		}
	}

	onFilesInternationalAdded(): void {
		const files: { [key: string]: File } =
			this.fileInternational.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesInternational.add(files[key])
			}
		}
	}

	onFilesMunicipalAdded(): void {
		const files: { [key: string]: File } =
			this.fileMunicipal.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesMunicipal.add(files[key])
			}
		}
	}

	onFilesOthersAdded(): void {
		const files: { [key: string]: File } = this.fileOthers.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesOthers.add(files[key])
			}
		}
	}

	onFilesIndicatorResultAdded(): void {
		const files: { [key: string]: File } =
			this.fileIndicatorResult.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesIndicatorResult.add(files[key])
			}
		}
	}

	onFilesIndicatorImpactAdded(): void {
		const files: { [key: string]: File } =
			this.fileIndicatorImpact.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.filesIndicatorImpact.add(files[key])
			}
		}
	}

	addFilesFederal(): void {
		this.fileFederal.nativeElement.click()
	}

	addFilesInternational(): void {
		this.fileInternational.nativeElement.click()
	}

	addFilesMunicipal(): void {
		this.fileMunicipal.nativeElement.click()
	}

	addFilesOthers(): void {
		this.fileOthers.nativeElement.click()
	}

	addFilesResult(): void {
		this.fileIndicatorResult.nativeElement.click()
	}

	addFilesImpact(): void {
		this.fileIndicatorImpact.nativeElement.click()
	}

	fundingIsEstatal(): boolean {
		const state = this.formStep2.get('state').value
		return state
	}

	fundingIsFederal(): boolean {
		const state = this.formStep2.get('federal').value
		return state
	}

	fundingIsInternational(): boolean {
		const state = this.formStep2.get('international').value
		return state
	}

	fundingIsMunicipal(): boolean {
		const state = this.formStep2.get('municipal').value
		return state
	}

	fundingIsOthers(): boolean {
		const state = this.formStep2.get('others').value
		return state
	}

	validationForm2(): void {
		return
	}

	resetSplitAction(): void {
		this.splitsAction.data.forEach((element) => {
			this.subprojectsService
				.getSplit(element.partida_presupuestal_id)
				.subscribe((response: any) => {
					if (typeof element.id !== 'undefined') {
						this.deleteSplitsAction.push(element.id)
					}

					if (
						typeof this.getSpits().find(
							(split) => split.split.id === element.split_id
						).remaining_amount !== 'undefined'
					) {
						this.getSpits().find(
							(split) => split.split.id === element.split_id
						).remaining_amount = response.remaining_amount
					}
				})
		})

		this.splitsAction.data = []
		this.setAmount()
	}

	getNameFile(file: string): string {
		const split = file.split('/')
		return split[split.length - 1]
	}

	getTotalStep(step: MatStepper): number {
		if (typeof step._steps !== 'undefined') {
			return step._steps.length - 1
		}

		return 4
	}

	getUeg(): any[] {
		if (typeof this.ups !== 'undefined') {
			const ups = this.ups.find(
				(up) => up.id === this.formStep2.get('up').value
			)
			return typeof ups === 'undefined' ? [] : ups.ueg
		} else {
			return []
		}
	}

	getSpits(): any[] {
		if (typeof this.ups !== 'undefined') {
			const uegs = this.getUeg().find(
				(ueg) => ueg.id === this.formStep2.get('ueg').value
			)
			if (typeof uegs !== 'undefined') {
				for (let x = 0; x <= uegs.partidas_presupuestales.length; x++) {
					if (typeof uegs.partidas_presupuestales[x] !== 'undefined') {
						uegs.partidas_presupuestales[x]['name'] =
							uegs.partidas_presupuestales[x].split.concept
					}
				}
			}

			return typeof uegs === 'undefined' ? [] : uegs.partidas_presupuestales
		} else {
			return []
		}
	}

	setAmount(): number {
		this.formStep2.controls['authorizedAmount'].setValue('0')

		let splits = { remaining_amount: 0 }
		const verifySplit = this.getSpits().find(
			(split) => split.split.id === this.formStep2.get('splits').value
		)
		if (typeof verifySplit !== 'undefined') {
			splits = this.getSpits().find(
				(split) => split.split.id === this.formStep2.get('splits').value
			)
		}

		if (splits.remaining_amount > 0) {
			this.formStep2.get('authorizedAmount').enable()
			this.addSplit = false
		} else {
			this.formStep2.get('authorizedAmount').disable()
			this.addSplit = true
		}

		this.formStep2.controls['authorizedAmount'].clearValidators()

		this.formStep2.controls['authorizedAmount'].setValue(
			splits.remaining_amount
		)

		this.formStep2.controls['authorizedAmount'].setValidators(
			Validators.max(splits.remaining_amount)
		)
		this.formStep2.controls['authorizedAmount'].updateValueAndValidity()
		return splits.remaining_amount
	}

	insertSplit(): void {
		if (this.formStep2.controls.authorizedAmount.hasError('max')) {
			return
		}

		if (this.formStep2.get('authorizedAmount').value <= 0) {
			return
		}

		const nameSplit = this.getSpits().find(
			(split) => split.split.id === this.formStep2.get('splits').value
		)

		this.splitsAction.data.push({
			id: 0,
			partida_presupuestal_id: nameSplit.id,
			split_id: this.formStep2.get('splits').value,
			split: nameSplit.split.concept,
			amount: this.formStep2.get('authorizedAmount').value,
			budget_split: { year: this.formStep2.get('currentYear').value }
		})

		nameSplit.remaining_amount =
			nameSplit.remaining_amount -
			parseFloat(this.formStep2.get('authorizedAmount').value)

		this.splitsAction.data = this.splitsAction.data
		// this.upInput.disable();
		// this.uegInput.disable();

		this.setAmount()
	}

	insertRisk(): void {
		if (!this.validRiskFields()) {
			return
		}

		let type = 'Ambiental'
		switch (this.typeRisk) {
			case 1:
				type = 'Ambiental'
				break
			case 2:
				type = 'Tiempo'
				break
			case 3:
				type = 'Económico'
				break
			case 4:
				type = 'Político'
				break
			case 5:
				type = 'Tecnológico'
				break
			case 6:
				type = 'Operacional'
				break
			case 7:
				type = 'Legal'
				break
			case 8:
				type = 'Social'
				break
			default:
				type = 'Ambiental'
				break
		}

		const verifyRisk = this.risks.data.find((risk) => risk.type === type)
		if (typeof verifyRisk === 'undefined') {
			this.risks.data.push({
				type: type,
				description: this.descriptionRisk.value
			})
		}

		this.formStep4.get('risks').clearValidators()
		this.formStep4.get('risks').updateValueAndValidity()
		this.risks.data = this.risks.data
		this.descriptionRisk.reset()
	}

	validRiskFields(): boolean {
		if (
			this.descriptionRisk.value == null ||
			this.descriptionRisk.value.trim().length === 0
		) {
			this.descriptionRisk.markAsTouched()
			return false
		}
		return true
	}

	removeSplit(index): void {
		if (typeof this.splitsAction.data[index] !== 'undefined') {
			if (typeof this.splitsAction.data[index].id !== 'undefined') {
				this.deleteSplitsAction.push(this.splitsAction.data[index].id)
			}

			this.subprojectsService
				.getSplit(this.splitsAction.data[index].partida_presupuestal_id)
				.subscribe((response: any) => {
					if (
						typeof this.getSpits().find(
							(split) => split.split.id === response.partida
						).remaining_amount !== 'undefined'
					) {
						this.getSpits().find(
							(split) => split.split.id === response.partida
						).remaining_amount = response.remaining_amount
					}
				})

			this.setAmount()
		}

		this.splitsAction.data.splice(index, 1)
		this.splitsAction.data = this.splitsAction.data
		if (this.splitsAction.data.length <= 0) {
			this.formStep2.get('up').enable()
			this.formStep2.get('ueg').enable()
		}
	}

	removeRisk(index): void {
		if (typeof this.risks.data[index] !== 'undefined') {
			if (typeof this.risks.data[index].id !== 'undefined') {
				this.deleteRisks.push(this.risks.data[index].id)
			}
		}

		this.risks.data.splice(index, 1)
		this.risks.data = this.risks.data

		if (this.risks.data.length <= 0) {
			this.formStep4.get('risks').clearValidators()
			this.formStep4.get('risks').setValidators(Validators.required)
			this.formStep4.get('risks').updateValueAndValidity()
		}
	}

	onBlurUnit(event): void {
		let selected
		selected = this.measurementUnits.find(
			(unit) => unit.name.toLowerCase() === event.target.value.toLowerCase()
		)

		if (typeof selected !== 'undefined') {
			this.formStep3.get('measurementUnitId').setValue(selected.id)
		} else {
			if (event.target.value.trim() !== '') {
				this.subprojectsService
					.measurementUnit(event.target.value)
					.subscribe((response: any) => {
						this.formStep3.get('measurementUnitId').setValue(response.id)
					})
			} else {
				this.formStep3.get('measurementUnitId').reset(null)
				this.formStep3.get('measurementUnitId').updateValueAndValidity()
				this.unitsCtrl.setValue('')
			}
		}
	}

	onBlurUnit2(event): void {
		let selected
		selected = this.measurementUnits.find(
			(unit) => unit.name.toLowerCase() === event.target.value.toLowerCase()
		)

		if (typeof selected !== 'undefined') {
			this.formStep4.get('measurementUnitId').setValue(selected.id)
		} else {
			if (event.target.value.trim() !== '') {
				this.subprojectsService
					.measurementUnit(event.target.value)
					.subscribe((response: any) => {
						this.formStep4.get('measurementUnitId').setValue(response.id)
					})
			} else {
				this.formStep4.get('measurementUnitId').reset(null)
				this.formStep4.get('measurementUnitId').updateValueAndValidity()
				this.unitsCtrl2.setValue('')
			}
		}
	}

	optionSelectedUnit(event): void {
		let selected
		selected = this.measurementUnits.find(
			(unit) => unit.name.toLowerCase() === event.option.value.toLowerCase()
		)

		if (typeof selected !== 'undefined') {
			this.formStep3.get('measurementUnitId').setValue(selected.id)
		}
	}

	optionSelectedUnit2(event): void {
		let selected
		selected = this.measurementUnits.find(
			(unit) => unit.name.toLowerCase() === event.option.value.toLowerCase()
		)

		if (typeof selected !== 'undefined') {
			this.formStep4.get('measurementUnitId').setValue(selected.id)
		}
	}

	filterMeasurementUnits(val: any): any[] {
		if (!val) {
			return this.measurementUnits
		}

		return this.measurementUnits.filter((unit) => {
			return unit.name.toLowerCase().includes(val.toLowerCase())
		})
	}

	message(type: any): string {
		const tooltips = [
			{
				type: 'denominacion',
				text: 'Es una expresión verbal que identifica al indicador y que expresa lo que se desea medir con él (no repite el objetivo ni el método de cálculo, tampoco debe reflejar una acción).'
			},
			{
				type: 'metodo_de_calculo',
				text: 'Es la expresión matemática o algebraica del indicador, simboliza de manera sencilla la forma en que se relacionan las variables para reflejarel grado de avance en las metas.'
			},
			{
				type: 'descripcion_del_indicador',
				text: 'Concepto relevante a medir, debe ser preciso para que pueda ser medidos de manera objetiva.'
			},
			{
				type: 'tipo_de_indicador',
				text: `INDICADOR DE DESEMPEÑO. Son una herramienta que entrega información cuantitativa respecto del nivel del logro alcanzado por un programa, pudiendo cubrir aspectos cuatitativos o cualitativos de este logro. Es una expresión que establece entre dos o mas variables, la que comparada con periodos anteriores, productos (bienes o servicios) similares o una meta o compromiso, permite evaluar el desempeño.
      INDICADOR ESTRATÉGICO. Indicador de desempeño que mide el grado de cumplimiento de los objetivos de las políticas públicas, y de los programas presupuestarios que impactan de manera directa en la población objetivo o área de enfoque. 
      INDICADOR DE GESTIÓN. Indicador de desempeño que mide el avance y logro en procesos y actividades, es decir, sobre la forma en que los bienes y servicios públicos son generados.`
			},
			{
				type: 'dimension_del_indicador',
				text: `EFICACIA. Mide el grado de cumplimiento de los objetivos, al comparar los resultados logrados (outputs) respecto a los resultados programados (outputs).
      EFICIENCIA. Mide la utilización de los recursos en la producción de resultados, al comparar los productos o servicios prestados (outputs) respecto a los insumos o recursos empleados para ello (input).`
			},
			{
				type: 'anio_medicion_resultado',
				text: 'Ejercicio fiscal correpondiente (2019)'
			},
			{
				type: 'cobertura',
				text: 'Es un componente del indicador permite agrupar la extensión geográfica en la que se realizará la medición. Se clasifica en Estatal, Regional y Municipal'
			},
			{
				type: 'frecuencia_de_medicion',
				text: 'Expresa la periodicidad con la que va a calcularse el indicador (mensual, trimestral, semestral y anual)'
			},
			{
				type: 'meta_institucional',
				text: 'Refiere al cociente de la variable realizado entre lo programado multiplicado por cien. El resulatdo será 100 (porcentaje) para todos los indicadores de desempeño considerados en la MIR. La meta de los iondicadores deberán estar directamente relacionadas con el objetivo y debe estar orientada a verificar significativamente el desempeño del programa, pero debe ser factible de alcanzar.'
			},
			{
				type: 'meta_valor',
				text: 'Es la referencia del desempeño debe de representarse en cifras absolutas (sólo el número) el cual busca alcanzar '
			},
			{
				type: 'unidad_de_medida',
				text: 'Es una cantidad estandarizada de una determina magnitud física, definida y adoptada por convención o por Ley para cada meta definida en el indicador. A)Unidad de medida de la meta institucional b)Unidad de medidad de la meta de valor'
			},
			{
				type: 'sentido_del_indicador',
				text: 'Se refiere a a dirección que debe tener el compartamiento del indicador ya sea ascendente o descendente (cuando su orientación es ascendente significa que la meta siempre buscará ser mayor que la línea base, cuando es descendente la meta siempre buscará ser menor que la línea base'
			},
			{
				type: 'linea_base_meta_valor',
				text: 'Es el valor del indicador que se establece como punto de partida para evaluarlo y darle seguimiento.'
			},
			{
				type: 'anio_linea_base',
				text: 'Es el año del indicador que se establece como punto de partida (línea base) para evaluarlo y darle seguimiento.'
			},
			{
				type: 'fuente_informacion',
				text: 'Son todos los recursos que contienen datos formales, informales, escritos, orales o multimedia. Se dividen en tres tipos primarias, secundarias y terciarias'
			},
			{
				type: 'medios_verificacion',
				text: 'Son las fuentes de información oficiales o registros administrativos que se utilizan para dar fe de la información vertida en los indicadores y autentificar los avances de actividad reportados'
			},
			{
				type: 'supuestos',
				text: 'Son los factores externos que están fuera de control de la institución responsable de desarrollar el programa presupuestario, pero que inciden en el éxito o fracaso del mismo. Se redactan en positivo.'
			}
		]

		const index = tooltips.findIndex((x: any) => x.type == type)
		if (index >= 0) {
			return tooltips[index].text
		}

		return ''
	}

	private addGroupResponsable(): void {
		this.members.map((responsable) => {
			const group = !!responsable.up ? responsable.up.name : 'ADMINISTRADORES'

			this.responsableGroup[group] = this.responsableGroup[group] || {
				type: group,
				data: []
			}
			this.responsableGroup[group].data.push(responsable)
		})

		const list = []

		for (const key in this.responsableGroup) {
			if (typeof this.responsableGroup[key] !== 'undefined') {
				list.push(this.responsableGroup[key])
			}
		}

		this.responsableGroup = list
	}

	public optionSelectedResponsable(event: any): void {
		if (Number.isInteger(event)) {
			if (event === 0) {
				this.formStep1.get('user').patchValue(null)
			} else {
				this.formStep1.get('user').patchValue(event)
			}
		} else {
			const id = event.option.value
			this.formStep1.get('user').patchValue(id)
		}
	}

	public optionSelectedSubAxi(event: any): void {
		if (Number.isInteger(event)) {
			if (event === 0) {
				this.formStep1.get('subAxisId').patchValue(null)
			} else {
				this.formStep1.get('subAxisId').patchValue(event)
			}
		} else {
			const id = event.option.value.id
			this.formStep1.get('subAxisId').patchValue(id)
		}
	}

	public optionSelectedActions(event: any): void {
		const id: number = event.option.value.id
		this.formStep1.get('actionId').patchValue(id)
		this.minDate = this.parseDate(event.option.value.date_finished)
	}

	public onBlurParticipant(event: any): void {
		if (event.target.value.length === 0) {
			this.responsableInput.patchValue('')
			this.responsableActive = null
		}
	}

	public onBlurSubAxi(event: any): void {
		if (this.formStep1.get('subAxisId').value === '') {
			this.subAxiInput.patchValue('')
		}

		if (event.target.value.length === 0) {
			this.subAxiInput.patchValue('')
			this.formStep1.get('subAxisId').patchValue(null)
		}
	}

	public changeUp(ueg?): void {
		if (!ueg) {
			this.formStep2.get('ueg').patchValue(null)
			this.uegInput.patchValue('')
		}
		this.formStep2.get('splits').patchValue(null)
		this.splitInput.patchValue('')
		this.setAmount()
	}

	public changeUeg(): void {
		this.changeUp(true)
	}

	public onBlurSelect(
		event: any,
		field: string,
		input: string,
		formNum: string
	): void {
		const form = `formStep${formNum}`
		if (this[form].get(field).value === '') {
			this[input].patchValue('')
		}

		if (event.target.value.length === 0) {
			this[input].patchValue('')
			this[form].get(field).patchValue(null)
		}
	}

	public optionSelectedSelect(
		event: any,
		field: string,
		formNum: string,
		func?
	): void {
		const form = `formStep${formNum}`
		const id = event.option.value.id
		this[form].get(field).patchValue(id)
		if (func) {
			this[func]()
		}
	}

	public optionSelectedSplits(event: any): void {
		const id = event.option.value.split.id
		this.formStep2.get('splits').patchValue(id)
		this.setAmount()
	}

	public displaySelect(item: any): any {
		if (!Boolean(item)) {
			return ''
		}

		if (typeof item === 'string') {
			return item
		} else if (typeof item.name !== 'undefined') {
			return item.name
		}

		return ''
	}

	public filterInputsSelect(val: any, list: any): string[] {
		if (!Boolean(val)) {
			return list
		}

		if (!isNumber(val)) {
			if (Object.entries(val).length > 0 && val.constructor === Object) {
				val = val.name
			}

			return list.filter((option) => {
				return option.name.toLowerCase().includes(val.toLowerCase())
			})
		}
	}

	public onBlurActions(event: any): void {
		if (this.formStep1.get('actionId').value === '') {
			this.actionInput.patchValue('')
		}

		if (event.target.value.length === 0) {
			this.actionInput.patchValue('')
			this.formStep1.get('actionId').patchValue(null)
		}
	}

	public displayStage(item: any): any {
		return this.matAutocompleteStage.options
			.filter((o) => o.value === item)
			.map((o) => o.viewValue)[0]
			? this.matAutocompleteStage.options
					.filter((o) => o.value === item)
					.map((o) => o.viewValue)[0]
			: item && item.name
			? item.name
			: ''
	}

	public displayResponsable(item: any): any {
		return this.matAutocompleteResponsable.options
			.filter((o) => o.value === item)
			.map((o) => o.viewValue)[0]
			? this.matAutocompleteResponsable.options
					.filter((o) => o.value === item)
					.map((o) => o.viewValue)[0]
			: this.nameUser(item)
	}

	public displaySubAxi(item: any): any {
		if (!Boolean(item)) {
			return ''
		}

		if (typeof item === 'string') {
			return item
		} else if (typeof item.name !== 'undefined') {
			return item.name
		}

		return ''
	}

	public displayAction(item: any): any {
		if (!Boolean(item)) {
			return ''
		}

		if (typeof item === 'string') {
			return item
		} else if (typeof item.name !== 'undefined') {
			return item.name
		}

		return ''
	}

	public filterActions(val: any, id: any, filterId?): string[] {
		if (filterId) {
			const actions = this.actionList.filter((option) => {
				if (id) {
					if (option.id === id) {
						return false
					}
				}
				return true
			})
			const list = actions.filter((option) =>
				option.name.toLowerCase().includes(val.toLowerCase())
			)
			return list
		}

		if (!Boolean(val)) {
			return this.actionList
		}

		if (!isNumber(val)) {
			if (Object.entries(val).length > 0 && val.constructor === Object) {
				val = val.name
			}
			const actions = this.actionList.filter((option) => {
				if (id) {
					if (option.id === id) {
						return false
					}
				}
				return true
			})
			const list = actions.filter((option) =>
				option.name.toLowerCase().includes(val.toLowerCase())
			)
			return list
		}
	}

	private nameUser(item: any): string {
		let name = ''
		if (!!!item) {
			return name
		}
		if (!!item.name) {
			name += item.name
		}
		if (!!item.last_name) {
			name += ' ' + item.last_name
		}

		if (!!item.m_last_name) {
			name += ' ' + item.m_last_name
		}

		return name
	}

	filterStages(val: any): string[] {
		if (!val) {
			return this.stages
		}
		if (!isNumber(val)) {
			if (Object.entries(val).length > 0 && val.constructor === Object) {
				val = val.name
			}
			return this.stages.filter((option) => {
				return option.name.toLowerCase().includes(val.toLowerCase())
			})
		}
	}

	public filterSubAxis(val: any): string[] {
		if (!Boolean(val)) {
			return this.subAxis
		}

		if (!isNumber(val)) {
			if (Object.entries(val).length > 0 && val.constructor === Object) {
				val = val.name
			}

			return this.subAxis.filter((option) => {
				return option.name.toLowerCase().includes(val.toLowerCase())
			})
		}
	}

	onBlurStage(event: any): void {
		if (event.target.value.length === 0) {
			this.formStep1.get('stageId').patchValue('')
		} else {
			if (this.formStep1.get('stageId').value === '') {
				this.stageInput.patchValue('')
			}
		}
	}

	optionSelectedStage(event: any): void {
		if (event.option.value) {
			this.formStep1.get('stageId').patchValue(event.option.value)

			this.detailProjectsService.stages.value.map((stage) => {
				if (event.option.value == stage.id) {
					this.minDate = this.parseDate(stage.date_initial)

					this.minDateFinish = this.minDate
					this.maxDate = this.parseDate(stage.date_finished)
					this.loadActionsByStage(stage.id)
				}
			})
		}
	}

	changeActionDepend(): void {
		this.actionActive = this.formStep1.get('actionDepend').value

		if (this.formStep1.get('actionDepend').value) {
			this.actionInput.patchValue('')
			this.formStep1.get('actionId').patchValue(null)
			this.formStep1.get('actionId').clearValidators()
			this.minDate = this.parseDate(
				this.detailProjectsService.stage.value.date_initial
			)
		} else {
			this.formStep1.get('actionId').setValidators([Validators.required])
		}
		this.formStep1.get('actionId').updateValueAndValidity()
	}

	private loadActionsByStage(id: number, actionId?): any {
		this.detailProjectsService.getActionsByStage(id).subscribe((data) => {
			this.actionList = data.subprojects
			this.filterAction = this.actionInput.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterActions(val, this.currentActionId))
			)
			if (data.subprojects.length === 0) {
				this.formStep1.get('actionDepend').setValue(null)
				this.formStep1.get('actionId').setValue(null)
			}

			if (actionId) {
				this.actionActive = false
				this.formStep1.get('actionId').setValue(actionId)
				this.actionInput.patchValue(
					this.actionList.find(
						(action) => action.id === this.formStep1.get('actionId').value
					)
				)
			}

			if (this.data.action.id !== null) {
				const actions = data.subprojects.filter((action) => {
					if (action.id === this.data.action.id) {
						return false
					} else {
						return true
					}
				})
				this.actionList = actions
			}
		})
	}
	// FUNCTION FOR DATES
	private parseDate(date: any): any {
		if (!!date) {
			date = date.split('/')
			date = date[2] + '-' + date[1] + '-' + date[0]
		}

		return date
	}

	public setDate(event, name: string, type: Number, dateNext?): void {
		this.formStep1.get(name).setValue(event.value.format('YYYY-MM-DD'))
		if (type === 1) {
			this.minDateFinish = event.value.format('YYYY-MM-DD')
		}
		this.compareTwoDates()
		if (dateNext !== undefined) {
			dateNext.open()
		}
	}

	private compareTwoDates(): void {
		const d1 = new Date(this.formStep1.controls['dateInitial'].value)
		const d2 = new Date(this.formStep1.controls['dateFinished'].value)
		if (!d1 && !d2) {
			return
		}

		if (d2 < d1) {
			this.errorDateValid.isError = true
			this.formStep1.get('dateFinished').setValue(this.minDateFinish)
			this.dateFinishDate = this.minDateFinish
		} else {
			this.errorDateValid.isError = false
		}
	}

	private openSnackBar(message: string, action: string): any {
		this._snackBar.open(message, action, {
			duration: 2000
		})
	}
}
