import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-tasks',
  templateUrl: './form-tasks.component.html',
  styleUrls: ['./form-tasks.component.scss']
})
export class FormTasksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
