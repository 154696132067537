import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MAT_DATE_LOCALE,
	DateAdapter,
	MAT_DATE_FORMATS,
	MatAutocomplete
} from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import {
	MomentDateAdapter,
	MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter'
import { ProjectsService } from 'app/projects/projects.service'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { isNumber } from 'util'

@Component({
	selector: 'app-validate-project',
	templateUrl: './validate-project.component.html',
	styleUrls: ['./validate-project.component.scss'],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class ValidateProjectComponent implements OnInit {
	saving: Boolean = false
	portfolio: any = null
	validateForm: FormGroup
	responsibles: any[] = []
	responsibleCtrl = new FormControl()
	//responsible
	public subAxiInput: FormControl = new FormControl()
	public filterSubAxi: Observable<any[]>
	public subAxi = 0
	public subAxis: any[]
	public dateFinishDate = null
	@ViewChild('subAxi', { static: false })
	public matAutocompleteSubAxi: MatAutocomplete

	constructor(
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ValidateProjectComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private projectsService: ProjectsService
	) {
		this.portfolio = data.portfolio
	}

	ngOnInit() {
		this.saving = true
		this.projectsService.getResponsiblesPerCoordination().subscribe(
			(result) => {
				this.responsibles = result
				this.subAxis = result
				this.saving = false

				this.filterSubAxi = this.subAxiInput.valueChanges.pipe(
					startWith(''),
					map((val) => this.filterSubAxis(val))
				)
			},
			(error) => {
				console.error(error)
			}
		)
		this.validateForm = this.formBuilder.group({
			project_id: this.portfolio.project_id,
			responsible_validated: ['', Validators.required],
			date_validated: [null, Validators.required]
		})
	}

	save(): any {
		if (!this.validateForm.valid) {
			return false
		}
		this.projectsService
			.validateProject(this.validateForm)
			.subscribe((data) => {
				this.dialogRef.close(data)
			})
	}

	public onBlurSubAxi(event: any): void {
		if (this.validateForm.get('responsible_validated').value === '') {
			this.subAxiInput.patchValue('')
		}

		if (event.target.value.length === 0) {
			this.subAxiInput.patchValue('')
			this.validateForm.get('responsible_validated').patchValue(null)
		}
	}

	public optionSelectedSubAxi(event: any): void {
		if (Number.isInteger(event)) {
			if (event === 0) {
				this.validateForm.get('responsible_validated').patchValue(null)
			} else {
				this.validateForm.get('responsible_validated').patchValue(event)
			}
		} else {
			const id = event.option.value.id
			this.validateForm.get('responsible_validated').patchValue(id)
		}
	}

	public displaySubAxi(item: any): any {
		if (!Boolean(item)) {
			return ''
		}

		if (typeof item === 'string') {
			return item
		} else if (typeof item.fullname !== 'undefined') {
			return item.fullname
		}

		return ''
	}

	public filterSubAxis(val: any): string[] {
		if (!Boolean(val)) {
			return this.subAxis
		}

		if (!isNumber(val)) {
			if (Object.entries(val).length > 0 && val.constructor === Object) {
				val = val.fullname
			}

			return this.subAxis.filter((option) => {
				return option.fullname.toLowerCase().includes(val.toLowerCase())
			})
		}
	}
}
