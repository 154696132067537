import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { DetailProjectsComponent } from './detail-projects.component';
import { DetailProjectsRoutingModule } from './detail-projects-routing.module';
import { DetailProjectsService } from './detail-projects.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
  MatButtonModule,
  MatChipsModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule,
  MatStepperModule,
  MatDividerModule,
  MatMenuModule,
  MatExpansionModule,
  MatTooltipModule,
  MatSidenavModule,
  MatListModule,
  MatDatepickerModule,
  MatProgressBarModule
} from '@angular/material';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ListStagesComponent } from '../../stages/list-stages/list-stages.component';
import { DetailStagesComponent } from '../../stages/detail-stages/detail-stages.component';
import { DialogAddSubprojectComponent } from '../../subprojects/dialog-add-subproject/dialog-add-subproject.component';
import { DetailSubprojectsComponent } from '../../subprojects/detail-subprojects/detail-subprojects.component';
import { DetailsTasksComponent } from '../../tasks/details-tasks/details-tasks.component';
import { RouterModule } from '@angular/router';
import { SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { CommentsModule } from 'app/comments/comments.module';
import { ReportsModule } from 'app/reports/reports.module';
import { ActionDetailModule } from 'app/dialogs/action-detail/action-detail.module';
import { DialogLinkCommunicationComponent } from '../dialog-link-communication/dialog-link-communication.component';
import { DialogLinkProjectComponent } from '../dialog-link-project/dialog-link-project.component';

@NgModule({
  imports: [
    CommonModule,
    DetailProjectsRoutingModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatStepperModule,
    MatDividerModule,
    MatMenuModule,
    MatExpansionModule,
    RoundProgressModule,
    MatTooltipModule,
    MatSidenavModule,
    FuseSharedModule,
    DragDropModule,
    RouterModule,
    SatDatepickerModule, 
    SatNativeDateModule,
    NgxMaskModule.forRoot(),
    MatListModule,
    CommentsModule,
    MatDatepickerModule,
    MatProgressBarModule,
    ReportsModule,
    ActionDetailModule
  ],
  declarations: [
    ListStagesComponent,
    DetailProjectsComponent,
    DetailStagesComponent,    
    DetailSubprojectsComponent,
    DetailsTasksComponent,
    DialogLinkCommunicationComponent,
    DialogLinkProjectComponent
  ],
  entryComponents: [
    ListStagesComponent,
    DetailStagesComponent,
    DialogAddSubprojectComponent,    
    DetailSubprojectsComponent,
    DetailsTasksComponent,
    DialogLinkCommunicationComponent,
    DialogLinkProjectComponent
  ],
  providers: [
    DetailProjectsService
  ]
})
export class DetailProjectsModule { }
