import { FuseNavigation } from '@fuse/types'
import { Rol } from '../constants/rol'
import * as jwtDecode from 'jwt-decode'
import { COORDINATION_LIST } from 'app/coordinations/coordinations.constant.module'
import { FuseNavigationItem } from '../../@fuse/types/fuse-navigation'

const navigationRol: FuseNavigation[] = getNavigation()

export const navigation: FuseNavigation[] = navigationRol

export function getNavigation(): FuseNavigation[] {
	const rol: string = localStorage.getItem('rol')
	let moduleToken: String = ''
	let advanceBoard: String = '0'

	if (!!localStorage.getItem('token')) {
		moduleToken = jwtDecode(localStorage.getItem('token')).module
	}

	if (!!localStorage.getItem('advance_board')) {
		advanceBoard = localStorage.getItem('advance_board')
	}

	let navigationRol: FuseNavigation[] = null
	if (rol === Rol.Task) {
		navigationRol = [
			{
				id: 'applications',
				title: '',
				type: 'group',
				icon: 'apps',
				children: [
					{
						id: 'projects',
						title: 'Portafolio',
						type: 'item',
						icon: 'folder',
						url: 'portafolios'
					}
				]
			}
		]

		if (advanceBoard === '1') {
			navigationRol[0]['children'].push({
				id: 'dashboard',
				title: 'Tablero de Avances',
				type: 'item',
				icon: 'dashboard',
				url: '/dashboard/coordinaciones'
			})
		}
	} else {
		navigationRol = [
			{
				id: 'applications',
				title: '',
				type: 'group',
				icon: 'apps',
				children: [
					{
						id: 'projects',
						title: 'Portafolio',
						type: 'item',
						icon: 'folder',
						url: 'portafolios'
					}
					// {
					// 	id: 'members',
					// 	title: 'Miembros',
					// 	type: 'item',
					// 	icon: 'supervisor_account',
					// 	url: '/miembros'
					// }
				]
			}
		]

		if (rol === 'admin' || rol === 'coordination' || rol === 'directorate') {
			// if (moduleToken === null || advanceBoard === '1') {
			navigationRol[0]['children'].push({
				id: 'dashboard',
				title: 'Tablero de Avances',
				type: 'item',
				icon: 'dashboard',
				url: '/dashboard/coordinaciones'
			})
			// }

			const options: any = [
				{
					id: 'members',
					title: 'Miembros',
					type: 'item',
					icon: 'supervisor_account',
					url: '/miembros'
				},
				...(rol === 'admin'
					? [
							{
								id: 'coordinations',
								title: 'Coordinaciones',
								type: 'item',
								icon: 'developer_board',
								url: '/' + COORDINATION_LIST
							}
					  ]
					: []),
				...(rol === 'admin' || rol === 'coordination'
					? [
							{
								id: 'ups',
								title: 'Direcciones',
								type: 'item',
								icon: 'folder',
								url: '/ups'
							}
					  ]
					: []),
				...(rol === 'admin' || rol === 'coordination'
					? [
							{
								id: 'axis',
								title: 'Ejes',
								type: 'item',
								icon: 'graphic_eq',
								url: '/ejes'
							}
					  ]
					: [])
			]

			navigationRol[0]['children'].push({
				id: 'config',
				title: 'Configuraciones',
				type: 'collapsable',
				icon: 'settings',
				children: options
			})
		} else {
			if (advanceBoard === '1') {
				navigationRol[0]['children'].push({
					id: 'dashboard',
					title: 'Tablero de Avances',
					type: 'item',
					icon: 'dashboard',
					url: '/dashboard/coordinaciones'
				})
			}
		}
	}
	return navigationRol
}
