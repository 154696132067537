import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommunicationService } from 'app/services/communication.service';
import { ProfileService } from './profileService';
import { AuthService } from 'app/services/auth.service';
import { LoginService } from 'app/login/login.service';

@Component({
  selector: 'global-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnChanges {
  @Input() dataChange: any = null;
  @ViewChild('file', {static: false}) file: ElementRef;
  data: any = null;
  resetPasswordForm: FormGroup;
  resetPasswordFormErrors: any;
  _unsubscribeAll: Subject<any>;
  showPass: Boolean = false;
  showPass1: Boolean = false;
  previewImg = null;
  loadedImg = false;
  showErrorPhoto: Boolean = false;
  isComplete = true;
  loading = false;
  date: any = Date.now();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private profileService: ProfileService,
    private authService: AuthService,
    private loginService: LoginService
  ) { 
    this.resetPasswordFormErrors = {
      oldPassword    : {},
      password       : {},
      passwordConfirm: {}
    };
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      file: [''],
      oldPassword    : ['', [Validators.required, Validators.minLength(8)]],
      password       : ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, this.confirmPassword, Validators.minLength(8)]]
    });

    this.resetPasswordForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
          this.onResetPasswordFormValuesChanged();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'dataChange') {
        this.getProfile();
      }
    }
  }

  getProfile(): void{
    this.profileService.get().subscribe((data) => {
      this.data = data;
      localStorage.setItem('token', data['token']);
    });
  }

  hasAvatar(user: any): boolean {
    if (!user) {
      return false;
    }
    if (typeof user.avatar === 'undefined') {
      return false;
    }
    return user.avatar.length <= 0 ? false : true;
  }

  getImgAvatar(user: any): string {
    if (this.previewImg === null) {
      return this.hasAvatar(user) ? `url("${user.avatar}?${this.date}")` : 'none';
    } else {
      return this.previewImg === null ? 'none' : 'url("' + this.previewImg + '")';
    }
  }

  getUserFullName(user: any): string {
    return user.name + ' ' + user.last_name + (user.m_last_name ? ' ' + user.m_last_name : '');
  }

  onResetPasswordFormValuesChanged(): void {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.resetPasswordFormErrors[field] = {};
      const control = this.resetPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  confirmPassword(control: AbstractControl): any {
    if (!control.parent || !control) {
      return;
    }
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
      return;
    }
    if (passwordConfirm.value === '') {
      return;
    }
    if (password.value !== passwordConfirm.value) {
      return {
        passwordsNotMatch: true
      };
    }
  }

  addFile(): void {
    this.file.nativeElement.click();
  }

  onFileChange(event: any): void {
    const reader = new FileReader();
    this.previewImg = null;
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        this.resetPasswordForm.get('file').setValue({
          filename: file.name,
          filetype: file.type,
          value: Object(reader.result).split(',')[1]
        });
        this.previewImg = e.target.result;
        this.loadedImg = true;
        this.showErrorPhoto = false;
      };
    } else {
      this.loadedImg = false;
      this.previewImg = null;
      this.resetPasswordForm.get('file').setValue('');
      this.showErrorPhoto = true;
    }
  }

  validate(type: Number): void{
    if (type === 1){
      this.resetPasswordForm.get('oldPassword').clearValidators();
      this.resetPasswordForm.get('password').clearValidators();
      this.resetPasswordForm.get('passwordConfirm').clearValidators();
    } else {
      this.resetPasswordForm.get('oldPassword').setValidators([Validators.required, Validators.minLength(8)]);
      this.resetPasswordForm.get('password').setValidators([Validators.required, Validators.minLength(8)]);
      this.resetPasswordForm.get('passwordConfirm').setValidators([Validators.required, this.confirmPassword, Validators.minLength(8)]);
    }

    this.resetPasswordForm.get('oldPassword').updateValueAndValidity();
    this.resetPasswordForm.get('password').updateValueAndValidity();
    this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
  }

  upPasswordConfirm(value: string): void{
    const formValue = this.resetPasswordForm.value;
    if (formValue.oldPassword === '' && formValue.password === '' && formValue.passwordConfirm === ''){
      this.validate(1);
    } else {
      this.validate(2);
    }
  }

  save(): void {
    const formValue = this.resetPasswordForm.value;
    let passChange: Boolean = false;

    if (formValue.oldPassword === '' && formValue.password === '' && formValue.passwordConfirm === ''){
      this.validate(1);
      passChange = false;
    } else {
      this.validate(2);
      passChange = true;
    }

    if (!passChange && formValue.file === ''){
      this.isComplete = true;
      this.snackBar.open('No exixten cambios por guardar', 'OK', {
        duration: 3500
      });
      return;
    }

    if (this.resetPasswordForm.status === 'VALID'){
      this.isComplete = true;
      this.loading = true;
      this.profileService.update(formValue).subscribe(data => {
        if (data && data.type){
          if (data.type === 1){
            this.loginService.storeLocal(data.token);
            this.clearFile();
            this.getProfile();
          } else if (data.type === 2){
            this.validate(1);
            this.resetPasswordForm.patchValue({
              oldPassword: '',
              password: '',
              passwordConfirm: ''
            });
          } else if (data.type === 3) {
            this.loginService.storeLocal(data.token);
            this.clearFile();
            this.getProfile();
            this.validate(1);
            this.resetPasswordForm.patchValue({
              oldPassword: '',
              password: '',
              passwordConfirm: ''
            });
          }
          this.date = Date.now();
        }
        this.loading = false;
        this.snackBar.open('Actualizado correctamente', 'OK', {
          duration: 3500
        });
      }, error => {
        this.loading = false;
        this.snackBar.open(error.error.data, 'OK', {
          duration: 3500
        });
      });
    } else {
      this.isComplete = false;
    }
  }

  clearFile(): void{
    this.loadedImg = false;
    this.previewImg = null;
    this.resetPasswordForm.get('file').setValue('');
    this.file.nativeElement.value = '';

    this.validate(1);
    this.resetPasswordForm.patchValue({
      oldPassword: '',
      password: '',
      passwordConfirm: ''
    });
  }

  onClose(): void {
    this.clearFile();
    this.communicationService.emitChangeGlobal();
  }

  existsDependency(): boolean{
    return this.data && this.data.dependency && this.data.dependency.color;
  }

  getDependencyColor(): string {
    return this.existsDependency() ? this.data.dependency.color : '';
  }

  getDependencyName(): string {
    return this.existsDependency() ? this.data.dependency.name : '';
  }
}
