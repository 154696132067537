import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service'
import { ProjectDashboardComponent } from '../project-dashboard/project-dashboard.component'
import { PortfolioDashboardComponent } from '../portfolio-dashboard/portfolio-dashboard.component'
import { IndexDashboardComponent } from './index-dashboard.component'
import { DetailProjectDashboardComponent } from '../detail-project-dashboard/detail-project-dashboard.component'
import { DetailActionDashboardComponent } from '../detail-action-dashboard/detail-action-dashboard.component'
import { AdvanceBoardGuard } from 'app/guards/advance-board.guard'
import { DashboardGrouperCoordinationsComponent } from '../dashboard-grouper-coordinations/dashboard-grouper-coordinations.component'
import { DashboardGrouperDirectoratesComponent } from '../dashboard-grouper-directorates/dashboard-grouper-directorates.component'
import { DashboardService } from '../dashboard.service'

const projectsRoutes: Routes = [
	{
		path: 'dashboard',
		component: IndexDashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'coordinaciones',
				component: DashboardGrouperCoordinationsComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin']
				},
				resolve: {
					data: DashboardService
				}
			},
			{
				path: 'coordinaciones/:coordinationId/direcciones',
				component: DashboardGrouperDirectoratesComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin', 'coordination']
				},
				resolve: {
					data: DashboardService
				}
			},
			{
				path: 'coordinaciones/:coordinationId/direcciones/:directorateId',
				component: PortfolioDashboardComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin', 'coordination', 'directorate', 'stages']
				},
				resolve: {
					data: DashboardService
				}
			},
			{
				path: 'coordinaciones/:coordinationId/direcciones/:directorateId/portafolio/:portfolioId',
				component: ProjectDashboardComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin', 'coordination', 'directorate', 'stages']
				},
				resolve: {
					data: DashboardService
				}
			},
			{
				path: 'coordinaciones/:coordinationId/direcciones/:directorateId/portafolio/:portfolioId/proyecto/:projectId',
				component: DetailProjectDashboardComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin', 'coordination', 'directorate', 'stages']
				},
				resolve: {
					data: DashboardService
				}
			},
			{
				path: 'coordinaciones/:coordinationId/direcciones/:directorateId/portafolio/:portfolioId/proyecto/:projectId/etapa/:stageId/accion/:actionId',
				component: DetailActionDashboardComponent,
				canActivate: [AuthGuard, AdvanceBoardGuard],
				data: {
					roles: ['admin', 'coordination', 'directorate', 'stages']
				},
				resolve: {
					data: DashboardService
				}
			}
		]
	}
]

@NgModule({
	imports: [RouterModule.forChild(projectsRoutes)],
	exports: [RouterModule],
	providers: [AdvanceBoardGuard]
})
export class DashboardRoutingModule {}
