import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserInfoComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<UserInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: any, up: any }
  ) {}

  ngOnInit(): void {
    // console.log(this.data);
  }

  hasAvatar(user: any): boolean {
    if (!user) {
      return false;
    }
    if (typeof user.avatar === 'undefined') {
      return false;
    }
    return user.avatar.length <= 0 ? false : true;
  }

  getImgAvatar(user: any): string {
    return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none';
  }

  getUserFullName(user: any): string {
    return user.name + ' ' + user.last_name + (user.m_last_name ? ' ' + user.m_last_name : '');
  }
  
  close(respose: Boolean): void {
    this.dialogRef.close(respose);
  }

  existsUp(): boolean{
    return !!this.data.up;
  }

  getNameUp(): string{
    return this.existsUp() ? this.data.up.name : '';
  }

  getColorUp(): string{
    return this.existsUp() ? this.data.up.color : '';
  }
}
