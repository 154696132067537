import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ProjectsService } from '../projects.service';

@Component({
  selector: 'app-link-communication',
  templateUrl: './dialog-link-communication.component.html',
  styleUrls: ['./dialog-link-communication.component.scss']
})
export class DialogLinkCommunicationComponent implements OnInit {
  project: any;
  link: FormControl;
  saving = false;

  constructor(
    public dialogRef: MatDialogRef<DialogLinkCommunicationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectsService: ProjectsService
  ) {
    this.project = data;
  }

  ngOnInit(): void {
    this.link = new FormControl();
    this.link.patchValue(this.project.link_communication);
  }

  clearInput(): void {
    this.link.patchValue('');
  }

  public save(): void {
    if (this.link.invalid) {
      return;
    }

    this.saving = true;
    const link = this.link.value.trim();
    this.projectsService.updateLinkCommunication(this.project.id, link).subscribe(response => {
      this.saving = false;
      this.dialogRef.close(true);
    }, error => {
      console.log(error);
    });
  }
}
