import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logoFile from './images.js';
import { isDate } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  firstDate: string = null;
  lastDate: string = null;

  constructor() {
  }

  calculateDates(data) {
    data.forEach(element => {
      if (/*element.pParent === 0 && */element.pStart !== '') {
        this.firstDate = (!this.firstDate) ? element.pStart : this.minorStartDate(this.firstDate, element.pStart);
      }

      if (/*element.pParent === 0 && */element.pEnd !== '') {
        this.lastDate = (!this.lastDate) ? element.pEnd : this.majorFinishDate(this.lastDate, element.pEnd);
      }
    });

    if(!this.firstDate || !this.lastDate){
      return [];
    }

    let first: any = this.firstDate.replace(/\-/g, '/');
    first = this.extendLeftDays(first);
    let second: any = this.lastDate.replace(/\-/g, '/');
    second = this.extendRightDays(second);

    let daylist: any = this.getDaysArray(new Date(first), new Date(second));
    daylist = daylist.map((v) => v.toISOString().slice(0, 10)).join(",").split(",");
    daylist = daylist.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    return daylist;
  }

  calculateWeekList(data): any{
    data.forEach(element => {
      if (/*element.pParent === 0 && */element.pStart !== '') {
        this.firstDate = (!this.firstDate) ? element.pStart : this.minorStartDate(this.firstDate, element.pStart);
      }

      if (/*element.pParent === 0 && */element.pEnd !== '') {
        this.lastDate = (!this.lastDate) ? element.pEnd : this.majorFinishDate(this.lastDate, element.pEnd);
      }
    });

    if (!this.firstDate || !this.lastDate){
      return [];
    }

    let first: any = this.firstDate.replace(/\-/g, '/');
    first = this.extendLeftDays(first);
    let second: any = this.lastDate.replace(/\-/g, '/');
    second = this.extendRightDays(second);

    let weekList: any = this.getWeeksArray(new Date(first), new Date(second));
    weekList = weekList.map((v) => v.toISOString().slice(0, 10)).join(",").split(",");
    weekList = weekList.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    return weekList;
  }

  calculateMonthList(data): any{
    data.forEach(element => {
      if (/*element.pParent === 0 && */element.pStart !== '') {
        this.firstDate = (!this.firstDate) ? element.pStart : this.minorStartDate(this.firstDate, element.pStart);
      }

      if (/*element.pParent === 0 && */element.pEnd !== '') {
        this.lastDate = (!this.lastDate) ? element.pEnd : this.majorFinishDate(this.lastDate, element.pEnd);
      }
    });

    if (!this.firstDate || !this.lastDate){
      return [];
    }

    let first: any = this.firstDate.replace(/\-/g, '/');
    first = this.extendLeftDays(first);
    let second: any = this.lastDate.replace(/\-/g, '/');
    second = this.extendRightDays(second);

    // todo: isai
    let weekList: any = this.getMonthsArray(new Date(first), new Date(second));
    weekList = weekList.map((v) => v.toISOString().slice(0, 10)).join(",").split(",");
    weekList = weekList.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    return weekList;
  }

  extendLeftDays(date) {
    var result = new Date(date);
    let numberDay = result.getDay();
    if(numberDay === 1){
      result.setDate(result.getDate() - 7);
      return this.formatDate(result);
    } else {
      result.setDate(result.getDate() - numberDay + 1);
      return this.formatDate(result);
    }
  }

  extendRightDays(date) {
    var result = new Date(date);
    let numberDay = result.getDay();
    if(numberDay === 7){
      return this.formatDate(result);
    } else {
      const days = 7 - numberDay;
      result.setDate(result.getDate() + days + 1);
      return this.formatDate(result);
    }
  }

  formatDate(date): string {
    let dd: any = date.getDate();
    let mm: any = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    return yyyy + '-' + mm + '-' + dd;
  }

  minorStartDate(first: string, second: string): string {
    return new Date(first) < new Date(second) ? first : second;
  }

  majorFinishDate(first: string, second: string): string {
    return new Date(first) > new Date(second) ? first : second;
  }

  getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    let yearStart: any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return weekNo + 1;//[d.getUTCFullYear(), weekNo];
  }

  /*nextChar(c) {
    let i: any = (parseInt(c, 36) + 1) % 36;
    return (!i * 10 + i).toString(36);
  }*/

  convertToNumberingScheme(number) {
    let baseChar = ("A").charCodeAt(0), letters  = "";
    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0;
    } while(number > 0);
  
    return letters;
  }

  datediff(first, second) {
    if ( !isDate(first) ){
      first = new Date(first);
    }
    
    if ( ! isDate(second) ){
      second = new Date(second);
    }
    
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  getColorClass(dataRow, dateHeader) {
    const totalDays = this.datediff(dataRow.pStart, dataRow.pEnd);
    const completedDays =  (dataRow.pComp * totalDays / 100);
    let light_color = true;

    if ( Math.trunc(this.datediff(dataRow.pStart, dateHeader)) <= completedDays && completedDays > 0) {
      light_color = false;
    }

    if (dataRow.pClass === 'ggroupblack') {
      if (!light_color) {
        return 'color_blue';
      }
      return 'color_light_blue';
    } else if (dataRow.pClass === 'gtaskgreen') {
      if (!light_color) {
        return 'color_green';
      }
      return 'color_light_green';
    } else if (dataRow.pClass === 'gtaskyellow') {
      if (!light_color) {
        return 'color_yellow';
      }
      return 'color_light_yellow';
    } else if (dataRow.pClass === 'gtaskred') {
      if (!light_color) {
        return 'color_red';
      }
      return 'color_light_red';
    } else {
      return 'color_gray_ligth';
    }
  }

  getDaysArray(start, end) {
    for (var arr = [], dt = start; dt < end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  getWeeksArray(start: Date, end: Date): Date [] {
    const weeks : Date[] = new Array();
    start.setHours(24);
    end.setHours(24);
    for (var arr = [], dt = start; dt < end; dt.setDate(dt.getDate() + 7)) {
      weeks.push(new Date(dt));
    }
    return weeks;
  }

  /**
   * get dates of the start to end
   */
  getMonthsArray(start: Date, end: Date): Date [] {
    let iterate: boolean = true;
    const months: Date[] = new Array();
    const index: Date = new Date();
    index.setFullYear(start.getFullYear());
    index.setMonth(start.getMonth());
    index.setDate(1);
    
    while (iterate) {
      months.push(new Date(index));
      // continue with the next month
      index.setMonth(index.getMonth() + 1 );
    
      if ( index > end ){
        iterate = false;
      }
    }
    return months;
  }

  today(type): string {
    const today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (type === 1) {
      return yyyy + '-' + mm + '-' + dd;
    } else if (type === 3) {
      return yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ":" + today.getMinutes();
    } else {
      return dd + '-' + mm + '-' + yyyy;
    }
  }

  ////////////////////////////////////// GENERATE EXCEL ////////////////////////////////////////////////////

  generateExcel(dataObject: any, namePortfolio: string, dependency: any, responsible: string) {
    const dayslist = this.calculateDates(dataObject);
    const data = [];
    const dataDraw = [];
    const title = namePortfolio;
    let header = ["Etapa / Acción", "Inicio", "Fin"];

    //Create workbook and worksheet
    let workbook: any = new Workbook();
    let worksheet = workbook.addWorksheet('Diagama de Gantt');

    worksheet.columns = [
      { style: { font: { name: 'Arial' } } },
    ];

    //Add Row Image
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:A2');
    worksheet.addRow([]);

    //Add Row TITLE
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', size: 12, bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Diagrama de Gantt']);
    worksheet.addRow(['Fecha : ' + this.today(3)]);
    let wsDependency = worksheet.addRow([dependency.name]);

    const colorDependendy = dependency.color ? dependency.color.replace('#', '') : '60656e';
    wsDependency.getCell(1).font = {color: {argb: 'FFFFFF'}, fgColor: { argb: 'FF0000' }};
    wsDependency.getCell(1).fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF'+ colorDependendy }};

    worksheet.addRow([`Responsable : ${responsible}`]);
    worksheet.mergeCells('A4:A5');
    //Blank Row 
    worksheet.addRow([]);

    const colors = [ { 
      color_green: '1AAE60', 
      color_light_green: '67C995', 
      color_yellow: 'FFD938', 
      color_light_yellow: 'FFEC9A',
      color_red: 'D6264A', 
      color_light_red: 'DD7579', 
      color_blue: '001970', 
      color_light_blue: '91A1C5', 
      color_stage_gray: 'F3F4F8',
      color_gray: '60656E', 
      color_gray_ligth: 'EAEAEA'
    }];

    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    dataObject.forEach((dataRow, index) => {
      data.push([dataRow.pName, dataRow.pStart, dataRow.pEnd]);
      dataDraw.push([{}, {}, {}]);

      dayslist.forEach(dateHeader => {
        let t = dateHeader.split('-')[2];
        if (index === 0) {
          header.push(t);
        }

        data[index].push('');
        if (new Date(dateHeader) >= new Date(dataRow.pStart) && new Date(dateHeader) <= new Date(dataRow.pEnd)) {
          dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateHeader) });
        } else {
          dataDraw[index].push({ 'draw': false });
        }
      });
    });

    //Add Header Week
    let weeksRow = worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(header);
    //let numberHeadeRow = 4;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      /*if(number + numberHeadeRow % 6 === 0 || number + numberHeadeRow % 7 === 0){
        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFCCCCCC' }};
      }*/
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      cell.font = {name: 'Arial', color: {argb: colors[0].color_gray}, bold: true};
    });

    /*MERGE WEEKS*/
    let lastLetter = 'D';
    let lastNumber = 4;
    let merge = '';

    dayslist.forEach((element, j) => {
      if (j % 7 === 0){
        //Draw title Week
        let year = element.split('-')[0];
        let month = element.split('-')[1];
        month = monthNames[parseInt(month) - 1];
        let titleWeek = `${month} ${year} - Semana ${this.getWeekNumber(new Date(element))}`;
        weeksRow.getCell(lastNumber).value = titleWeek;

        //Merge colums for weeks
        let next = lastLetter;
        [1,2,3,4,5,6,7].forEach(pos => {
          next = this.convertToNumberingScheme(lastNumber);
          lastNumber++;
        });
        merge = lastLetter + '11' + ':' + next + '11';
        worksheet.mergeCells(merge);
        lastLetter = this.convertToNumberingScheme(lastNumber);
      }
    });

    weeksRow.eachCell((cell) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = {name: 'Arial', color: {argb: colors[0].color_blue}, bold: true};
    });

    // Add Data and Conditional Formatting
    data.forEach((d, i) => {  //ROW
      const row = worksheet.addRow(d);
      [1,2,3].forEach((elem) => {
        const color = dataObject[i].pParent === 0 ? colors[0].color_blue : colors[0].color_gray;
        row.getCell(elem).font = {name: 'Arial', color: {argb: color}, bold: true};
      });

      let dataPartial = d.slice(3, d.length);
      let dataPartialDraw = dataDraw[i].slice(3, dataDraw[i].length);
      let index = 4;
      let drawPercent = false;
      dataPartial.forEach((element, j) => {
        let el = row.getCell(index);
        if (dataPartialDraw[j].draw) {
          el.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: colors[0][dataPartialDraw[j].color] }
          }
          drawPercent = true;
        } else {
          if (drawPercent){
            el.value = (dataObject[i].pComp != ''? `${dataObject[i].pComp}%` : '');
            drawPercent = false;
          }
        }
        index++;
      });
    });

    worksheet.getColumn(1).width = 27;
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 12;
    let i = 4;

    dayslist.forEach(element => {
      worksheet.getColumn(i).width = 3;
      i++;
    }); //return;

    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'diagrama-gantt-por-dia' + '.xlsx');
    });
  }

  generateExcelByWeek(dataObject: any, namePortfolio: string, dependency: any, responsible: string){

    const weekList = this.calculateWeekList(dataObject);
    const data = [];
    const dataDraw = [];
    const title = namePortfolio;
    let header = ["Etapa / Acción", "Inicio", "Fin"];
    const columnsToMerge: string[] = new Array();
    const columnsForBlock: number = 3;
    const daysForBlock: number = 7;
    const daysForColumn = Math.abs(Math.round(daysForBlock / columnsForBlock));

    //Create workbook and worksheet
    let workbook: any = new Workbook();
    let worksheet = workbook.addWorksheet('Diagama de Gantt');

    worksheet.columns = [
      { style: { font: { name: 'Arial' } } },
    ];

    //Add Row Image
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:A2');
    worksheet.addRow([]);

    //Add Row TITLE
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', size: 12, bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Diagrama de Gantt']);
    worksheet.addRow(['Fecha : ' + this.today(3)]);
    let wsDependency = worksheet.addRow([dependency.name]);

    const colorDependendy = dependency.color ? dependency.color.replace('#', '') : '60656e';
    wsDependency.getCell(1).font = {color: {argb: 'FFFFFF'}, fgColor: { argb: 'FF0000' }};
    wsDependency.getCell(1).fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF'+ colorDependendy }};

    worksheet.addRow([`Responsable : ${responsible}`]);
    worksheet.mergeCells('A4:A5');
    //Blank Row 
    worksheet.addRow([]);

    const colors = [ { 
      color_green: '1AAE60', 
      color_light_green: '67C995', 
      color_yellow: 'FFD938', 
      color_light_yellow: 'FFEC9A',
      color_red: 'D6264A', 
      color_light_red: 'DD7579', 
      color_blue: '001970', 
      color_light_blue: '91A1C5', 
      color_stage_gray: 'F3F4F8',
      color_gray: '60656E', 
      color_gray_ligth: 'EAEAEA'
    }];

    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    dataObject.forEach((dataRow, index) => {
      data.push([dataRow.pName, dataRow.pStart, dataRow.pEnd]);
      dataDraw.push([{}, {}, {}]);

      let columnStart = 'D';
      const rowHeader = '12';
      let headerColumn = 4;
      let columnFinal = '';
      let previusWeek:Date = null;
      
      // each weeks has 4 columns in to excel file
      weekList.forEach( (dateHeader,contador) => {
        let t = dateHeader.split('-')[2];
        let month = dateHeader.split('-')[1];
        month = monthNames[parseInt(month) - 1];
        if (index === 0) {
          header.push(` ${t} ${month} `);
          // add 3 additional columns for complete 4 columns per week
          for(let element = 0; element < columnsForBlock - 1; element++){
            header.push('');
          }

          // add columns to merge into header in row 12
          columnStart = this.convertToNumberingScheme(headerColumn);
          headerColumn += (columnsForBlock - 1);
          columnFinal = this.convertToNumberingScheme(headerColumn);
          columnsToMerge.push( columnStart + rowHeader + ':' + columnFinal + rowHeader );
          headerColumn ++;

        }

        for (let count = 0; count < columnsForBlock; count++){
          data[index].push('');
        }

        const weekDateIndex: Date = new Date(dateHeader);
        const taskStart: Date = new Date(dataRow.pStart);
        const taskEnd: Date = new Date(dataRow.pEnd);

        if (weekDateIndex >= taskStart && weekDateIndex <= taskEnd) {

          const diferenceInDays = this.dateDifferenceInDay(taskEnd,weekDateIndex);

          if ( diferenceInDays <= (daysForBlock - 1) ) {
            // draw incomplete last block  isai
            const columnsToDraw = this.getDrawColumnsPerBlock(diferenceInDays, daysForBlock, columnsForBlock);
            for(let count = 0; count < columnsForBlock; count ++){
              if ( count <= columnsToDraw ){
                const dateInCourse = this.appendDayToDate(dateHeader, (count * daysForColumn) );
                dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': false, 'columnsToDraw' : columnsToDraw, 'inverse' : true });
              }
            }

          } else {
            // draw block complete
            for (let count = 0; count < columnsForBlock; count++){
              const dateInCourse = this.appendDayToDate(dateHeader, (count * daysForColumn));
              dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': true, 'columnsToDraw' : columnsForBlock });
            }
          }

          
        } else {
          // draw incomplete first block
          const diferenceInDays = this.dateDifferenceInDay(weekDateIndex, taskStart);
          if ( weekDateIndex <  taskStart && diferenceInDays <= (daysForBlock - 1)  ){
            const columnsToDraw = this.getDrawColumnsPerBlock(daysForBlock - diferenceInDays, daysForBlock, columnsForBlock);
            
            for (let count = 1; count <= columnsForBlock; count++){
              if ( count >= ( columnsForBlock - columnsToDraw ) ) {
                const dateInCourse = this.appendDayToDate(dateHeader,  ( ( count - 1) * daysForColumn) );
                dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': false, 'columnsToDraw' : columnsToDraw, 'inverse' : false });
              } else {
                dataDraw[index].push({ 'draw': false });
              }
            }
            
          } else {
            // not draw the block
            for (let count = 0; count < columnsForBlock; count++){
              dataDraw[index].push({ 'draw': false });
            }
            
          }
        }
      });

    });

    //Add Header Week
    let weeksRow = worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(header);

    // merges header columns row 12
    columnsToMerge.forEach( element => {
      worksheet.mergeCells(element);
    } );

    //let numberHeadeRow = 4;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      cell.font = {name: 'Arial', color: {argb: colors[0].color_gray}, bold: true};
    });

    /*MERGE WEEKS*/
    let lastLetter = 'D';
    let lastNumber = 4;
    let merge = '';
    
    weekList.forEach((element, j) => {
      
        //Draw title Week
        let year = element.split('-')[0];
        let month = element.split('-')[1];
        month = monthNames[parseInt(month) - 1];
        let titleWeek = `${year}`;
        weeksRow.getCell(lastNumber).value = titleWeek;

        //Merge colums for weeks
        let next = lastLetter;
        for (let pos = 0; pos < columnsForBlock; pos++){
          next = this.convertToNumberingScheme(lastNumber);
          lastNumber++;
        }
        merge = lastLetter + '11' + ':' + next + '11';
        worksheet.mergeCells(merge);
        lastLetter = this.convertToNumberingScheme(lastNumber);
      
    });

    weeksRow.eachCell((cell) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = {name: 'Arial', color: {argb: colors[0].color_blue}, bold: true};
    });

    // Add Data and Conditional Formatting
    data.forEach((d, i) => {  //ROW
      const row = worksheet.addRow(d);
      [1,2,3].forEach((elem) => {
        const color = dataObject[i].pParent === 0 ? colors[0].color_blue : colors[0].color_gray;
        row.getCell(elem).font = {name: 'Arial', color: {argb: color}, bold: true};
      });

      let dataPartial = d.slice(3, d.length);
      let dataPartialDraw = dataDraw[i].slice(3, dataDraw[i].length);
      let index = 4;
      let drawPercent = false;
      dataPartial.forEach((element, j) => {
        // draw the columns with the data row
        if ( dataPartialDraw[j] !== undefined && dataPartialDraw[j].draw  ) {
          let el = row.getCell(index);
          el.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: colors[0][dataPartialDraw[j].color] }
          };
          drawPercent = true;
        } else {
          // draw the porcent text in the last column of the row
          if (drawPercent ){
            const el = row.getCell(index);
            el.value = (dataObject[i].pComp != ''? `${dataObject[i].pComp}%` : '');
            drawPercent = false;
          }
        }
        index++;
      });
    });

    worksheet.getColumn(1).width = 27;
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 12;
    let i = 4;

    weekList.forEach(element => {
      for (let pos = 0; pos < columnsForBlock; pos++){
        worksheet.getColumn(i).width = 2;
      i++;
      }
    }); //return;

    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'diagrama-gantt-por-semana' + '.xlsx');
    });

  }

  generateExcelByMounth(dataObject: any, namePortfolio: string, dependency: any, responsible: string){

    const monthList = this.calculateMonthList(dataObject);
    const data = [];
    const dataDraw = [];
    const title = namePortfolio;
    let header = ["Etapa / Acción", "Inicio", "Fin"];
    const columnsToMerge: string[] = new Array();
    const columnsForBlock: number = 5;
    const daysForBlock: number = 30;
    const daysForColumn = Math.abs(Math.round(daysForBlock / columnsForBlock));

    //Create workbook and worksheet
    let workbook: any = new Workbook();
    let worksheet = workbook.addWorksheet('Diagama de Gantt');

    worksheet.columns = [
      { style: { font: { name: 'Arial' } } },
    ];

    //Add Row Image
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:A2');
    worksheet.addRow([]);

    //Add Row TITLE
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', size: 12, bold: true }
    worksheet.addRow([]);
    worksheet.addRow(['Diagrama de Gantt']);
    worksheet.addRow(['Fecha : ' + this.today(3)]);
    let wsDependency = worksheet.addRow([dependency.name]);

    const colorDependendy = dependency.color ? dependency.color.replace('#', '') : '60656e';
    wsDependency.getCell(1).font = {color: {argb: 'FFFFFF'}, fgColor: { argb: 'FF0000' }};
    wsDependency.getCell(1).fill = {type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF'+ colorDependendy }};

    worksheet.addRow([`Responsable : ${responsible}`]);
    worksheet.mergeCells('A4:A5');
    //Blank Row 
    worksheet.addRow([]);

    const colors = [ { 
      color_green: '1AAE60', 
      color_light_green: '67C995', 
      color_yellow: 'FFD938', 
      color_light_yellow: 'FFEC9A',
      color_red: 'D6264A', 
      color_light_red: 'DD7579', 
      color_blue: '001970', 
      color_light_blue: '91A1C5', 
      color_stage_gray: 'F3F4F8',
      color_gray: '60656E', 
      color_gray_ligth: 'EAEAEA'
    }];

    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    dataObject.forEach((dataRow, index) => {
      data.push([dataRow.pName, dataRow.pStart, dataRow.pEnd]);
      dataDraw.push([{}, {}, {}]);

      let columnStart = 'D';
      const rowHeader = '12';
      let headerColumn = 4;
      let columnFinal = '';
      let previusWeek:Date = null;
      
      // each weeks has 4 columns in to excel file
      monthList.forEach( (dateHeader,contador) => {
        let t = dateHeader.split('-')[2];
        let month = dateHeader.split('-')[1];
        month = monthNames[parseInt(month) - 1];
        if (index === 0) {
          header.push(` ${month} `);
          // add 3 additional columns for complete 4 columns per week
          for(let element = 0; element < columnsForBlock - 1; element++){
            header.push('');
          }

          // add columns to merge into header in row 12
          columnStart = this.convertToNumberingScheme(headerColumn);
          headerColumn += (columnsForBlock - 1);
          columnFinal = this.convertToNumberingScheme(headerColumn);
          columnsToMerge.push( columnStart + rowHeader + ':' + columnFinal + rowHeader );
          headerColumn ++;
        }

        // add the total columns for block for the data array
        for (let count = 0; count < columnsForBlock; count++){
          data[index].push('');
        }

        const weekDateIndex: Date = new Date(dateHeader);
        const taskStart: Date = new Date(dataRow.pStart);
        const taskEnd: Date = new Date(dataRow.pEnd);

        if (weekDateIndex >= taskStart && weekDateIndex <= taskEnd) {

          const diferenceInDays = this.dateDifferenceInDay(taskEnd,weekDateIndex);

          if ( diferenceInDays <= (daysForBlock - 1) ) {
            // draw incomplete last block  isai
            const columnsToDraw = this.getDrawColumnsPerBlock(diferenceInDays, daysForBlock, columnsForBlock);
            for(let count = 0; count < columnsForBlock; count ++){
              if ( count <= columnsToDraw ){
                const dateInCourse = this.appendDayToDate(dateHeader, (count * daysForColumn ));
                dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': false, 'columnsToDraw' : columnsToDraw, 'inverse' : true });
              }
            }

          } else {
            // draw block complete
            for (let count = 0; count < columnsForBlock; count++){
              const dateInCourse = this.appendDayToDate(dateHeader, (count * daysForColumn ));
              dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': true, 'columnsToDraw' : columnsForBlock });
            }
          }

          
        } else {
          // draw incomplete first block
          const diferenceInDays = this.dateDifferenceInDay(weekDateIndex, taskStart);
          if ( weekDateIndex <  taskStart && diferenceInDays <= (daysForBlock - 1) ){
            const columnsToDraw = this.getDrawColumnsPerBlock(daysForBlock - diferenceInDays, daysForBlock, columnsForBlock);
            
            for (let count = 1; count <= columnsForBlock; count++){
              if ( count >= ( columnsForBlock - columnsToDraw ) ) {
                const dateInCourse = this.appendDayToDate(dateHeader, ( (count - 1 ) * daysForColumn) );
                dataDraw[index].push({ 'draw': true, 'color': this.getColorClass(dataRow, dateInCourse), 'complete': false, 'columnsToDraw' : columnsToDraw, 'inverse' : false });
              } else {
                dataDraw[index].push({ 'draw': false });
              }
            }
            
          } else {
            // not draw the block
            for (let count = 0; count < columnsForBlock; count++){
              dataDraw[index].push({ 'draw': false });
            }
            
          }
          
        }
         
      }); // end period of loop ( month )
    }); // end rows data loop

    //Add Header Week
    let weeksRow = worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(header);

    // merges header columns row 12
    columnsToMerge.forEach( element => {
      worksheet.mergeCells(element);
    } );

    //let numberHeadeRow = 4;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      cell.font = {name: 'Arial', color: {argb: colors[0].color_gray}, bold: true};
    });

    /*MERGE WEEKS*/
    let lastLetter = 'D';
    let lastNumber = 4;
    let merge = '';
    
    monthList.forEach((element, j) => {
      
        //Draw title Week
        let year = element.split('-')[0];
        let month = element.split('-')[1];
        month = monthNames[parseInt(month) - 1];
        let titleWeek = `${year}`;
        weeksRow.getCell(lastNumber).value = titleWeek;

        //Merge colums for weeks
        let next = lastLetter;
        for (let pos = 0; pos < columnsForBlock; pos++){
          next = this.convertToNumberingScheme(lastNumber);
          lastNumber++;
        }
        merge = lastLetter + '11' + ':' + next + '11';
        worksheet.mergeCells(merge);
        lastLetter = this.convertToNumberingScheme(lastNumber);
      
    });

    weeksRow.eachCell((cell) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = {name: 'Arial', color: {argb: colors[0].color_blue}, bold: true};
    });


    // Add Data and Conditional Formatting
    data.forEach((d, i) => {  //ROW
      const row = worksheet.addRow(d);
      [1,2,3].forEach((elem) => {
        const color = dataObject[i].pParent === 0 ? colors[0].color_blue : colors[0].color_gray;
        row.getCell(elem).font = {name: 'Arial', color: {argb: color}, bold: true};
      });

      let dataPartial = d.slice(3, d.length);
      let dataPartialDraw = dataDraw[i].slice(3, dataDraw[i].length);
      let index = 4;
      let drawPercent = false;
      dataPartial.forEach((element, j) => {
        // draw the columns with the data row
        if ( dataPartialDraw[j] !== undefined && dataPartialDraw[j].draw  ) {
          let el = row.getCell(index);
          el.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: colors[0][dataPartialDraw[j].color] }
          };
          drawPercent = true;
        } else {
          // draw the porcent text in the last column of the row
          if (drawPercent ){
            const el = row.getCell(index);
            el.value = (dataObject[i].pComp != ''? `${dataObject[i].pComp}%` : '');
            drawPercent = false;
          }
        }
        index++;
      });
    });

    worksheet.getColumn(1).width = 27;
    worksheet.getColumn(2).width = 12;
    worksheet.getColumn(3).width = 12;
    let i = 4;

    monthList.forEach(element => {
      for (let pos = 0; pos < columnsForBlock; pos++){
        worksheet.getColumn(i).width = 2;
      i++;
      }
    }); //return;

    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'diagrama-gantt-por-mes' + '.xlsx');
    });

  }

  /**
   * get difference in days bettween two dates
   * @param start 
   * @param end 
   */
  dateDifferenceInDay(start: Date, end: Date ): number {
    let diference:number = 0;
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    diference = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        
    return diference;
  }

  /**
   * get the columns to should draw for the incomplete blocks
   * @param diferenceInDays 
   * @param totalDays 
   * @param blockOfColumns 
   */
  getDrawColumnsPerBlock(diferenceInDays: number, totalDays: number, blockOfColumns: number): number{

    const porcentaje =  Math.floor(( diferenceInDays /  totalDays ) * 100);
    const result = Math.floor(porcentaje / Math.floor(100 / blockOfColumns) );
    return result;
  }

  /**
   * return the next month
   */
  getNextMonth(date: Date){

    let next = null;
    if (date.getMonth() === 11) {
         next = new Date(date.getFullYear() + 1, 0, 1);
    } else {
         next = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }

    return next;
  }

  appendDayToDate(dateString: string ,days: number){
    const d = new Date(dateString);
    d.setDate(d.getDate() + days );
    return d;
  }

}