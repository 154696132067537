import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-ring-chart',
  templateUrl: './ring-chart.component.html',
  styleUrls: ['./ring-chart.component.scss']
})
export class RingChartComponent implements OnInit {
  porcent: Number = 0;
  color = '#001970';
  imgUrl = '';
  centerStyle = {};

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  public async createImage(type) {
    const row = document.getElementById(type).getElementsByClassName('donutGraf')[0];
    document.querySelector('body').style.cssText = '--progress-color: ' + this.color;

    await html2canvas(row).then(canvas => {
      this.imgUrl = canvas.toDataURL('image/png');
    });
  }
}
