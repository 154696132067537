import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';

@Injectable()
export class AuthenticatedUserService {
  currentUser = null;
  constructor() {
    this.currentUser = jwtDecode(localStorage.getItem('token'));
  }
  // ...
  public getUser(): any {
    return this.currentUser;
  }

  public getRole(): any {
    return this.currentUser.rol;
  }
}