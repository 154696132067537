import { Injectable } from '@angular/core'
import * as jwtDecode from 'jwt-decode'

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private moduleScope = [
		{ module: 'stages', scope: ['stages', 'subprojects', 'tasks'] },
		{ module: 'subprojects', scope: ['subprojects', 'tasks'] },
		{ module: 'tasks', scope: ['tasks'] },
		{ module: 'communication', scope: [] }
	]

	constructor() {}

	get token(): string {
		return !localStorage.getItem('token') ? null : localStorage.getItem('token')
	}

	get jwt(): any {
		const token = {
			name: '',
			lastname: '',
			avatar: '',
			module: '',
			rol: '',
			id: 0
		}
		return !this.token ? token : jwtDecode(this.token)
	}

	get name(): string {
		return this.jwt.name
	}

	get fullName(): string {
		return this.jwt.name + ' ' + this.jwt.lastname
	}

	get initials(): string {
		return this.jwt.name[0] + this.jwt.lastname[0]
	}

	get hasAvatar(): boolean {
		const avatar = this.jwt.avatar
		return avatar.length > 0 ? true : false
	}

	get avatar(): string {
		return this.hasAvatar ? 'url("' + this.jwt.avatar + '")' : 'none'
	}

	avatarUpdate(date): string {
		return this.hasAvatar
			? 'url("' + this.jwt.avatar + '?' + date + '")'
			: 'none'
	}

	get module(): any {
		if (this.jwt.module !== null) {
			return this.jwt.module
		}
		return ''
	}

	get dependencyId(): number {
		return this.jwt.dependency_id !== null ? this.jwt.dependency_id : 0
	}

	get modules(): any {
		let module = 'tasks'

		if (this.jwt.module !== null) {
			module = this.jwt.module
		}

		const filterModule = this.moduleScope.filter((x) => x.module === module)

		if (filterModule.length === 0) {
			return []
		}

		return filterModule[0].scope
	}

	get allowedModules(): any[] {
		const modules = this.modules
		return modules.reduce((allow: any, module: any) => {
			allow.push(module)
			return allow
		}, [])
	}

	get rol(): string {
		return this.jwt.rol
	}

	get coordination_id(): string {
		return this.jwt.coordination_id
	}

	hasPermission(module: any): boolean {
		// if ( this.rol === 'admin' || this.rol === 'dependency' || (module === 'portfolios' && this.rol === 'dependency')) {
		if (
			this.rol === 'admin' ||
			this.rol === 'coordination' ||
			this.rol === 'directorate'
		) {
			return true
		}

		if (module === 'permissions_gantt') {
			if (
				this.rol === 'government' ||
				this.rol === 'admin' ||
				this.rol === 'coordination'
			) {
				return true
			}
		}

		const allowed = this.allowedModules.findIndex((permission) => {
			return permission.toLowerCase() === module.toLowerCase()
		})

		return allowed >= 0 ? true : false
	}

	isAdmin(): boolean {
		return this.rol === 'admin' ? true : false
	}

	isGovernment(): boolean {
		return this.rol === 'government' ? true : false
	}

	isCommunication(): boolean {
		return this.rol === 'task' && this.jwt.module === 'communication'
			? true
			: false
	}

	isCoordination(): boolean {
		return this.rol === 'coordination' && this.jwt.module === 'coordination'
	}

	get id(): number {
		return this.jwt.id
	}

	public isVisualizer(visualizer: any[]): boolean {
		return !!visualizer.find((v) => v.user_id === this.id)
	}

	get id_user(): string {
		return this.jwt.id
	}

	get coordinationName(): string {
		return !!this.jwt.coordination_name ? this.jwt.coordination_name : ''
	}

	get dependency_color(): string {
		return this.jwt.dependency_color
	}
}
