import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormSubprojectsComponent } from './form-subprojects.component';
import { 
  MatIconModule, 
  MatCheckboxModule, 
  MatFormFieldModule, 
  MatInputModule,
  MatButtonModule, 
  MatPaginatorModule,
  MatRippleModule, 
  MatSelectModule, 
  MatSortModule,
  MatTableModule, 
  MatTabsModule
} from '@angular/material';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';

const routes: Routes = [
  {
    path     : 'sub-projectos',
    component: FormSubprojectsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule, 
    MatCheckboxModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatButtonModule, 
    MatPaginatorModule,
    MatRippleModule, 
    MatSelectModule, 
    MatSortModule,
    MatTableModule, 
    MatTabsModule,
    FuseSharedModule
  ],
  declarations: [FormSubprojectsComponent],
  providers: [AuthGuard]
})
export class FormSubprojectsModule { }
