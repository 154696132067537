import { Injectable } from '@angular/core'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { Globals } from '../globals'
import { MatTreeNestedDataSource } from '@angular/material'

interface ObjectNode {
	name: string
	children?: ObjectNode[]
	type?: number
}

@Injectable({
	providedIn: 'root'
})
export class ReportsService {
	private url: string
	private module: string
	private httpOptions: any
	public dataSourceTree = new MatTreeNestedDataSource<ObjectNode>()

	constructor(private http: HttpClient, private global: Globals) {
		this.url = global.api
		this.module = 'reports'
		this.httpOptions = global.httpOptions
	}

	getInit(): Observable<any> {
		return this.http.get(`${this.url}${this.module}/init`, this.httpOptions)
	}

	getPortfolio(id: Number): Observable<any> {
		return this.http.get(
			`${this.url}${this.module}/portfolio/${id}`,
			this.httpOptions
		)
	}

	getProject(id: Number): Observable<any> {
		return this.http.get(
			`${this.url}${this.module}/project/${id}`,
			this.httpOptions
		)
	}

	getAllPortfolios(listMunicipalities?): Observable<any> {
		if (typeof listMunicipalities !== 'undefined') {
			return this.http.post(
				`${this.url}${this.module}/portfolios`,
				{
					municipalities: listMunicipalities
				},
				this.httpOptions
			)
		} else {
			return this.http.post(
				`${this.url}${this.module}/portfolios`,
				this.httpOptions
			)
		}
	}

	downlaodMunicipalities(
		municipalities: any[],
		fundingSourceData: any[],
		imgGraph: any,
		totalAmount: any,
		imgMap: any,
		populationBenefiatedData: any,
		activeAction: Boolean
	): Observable<any> {
		return this.http.post(
			`${this.url}${this.module}/municipalities`,
			{
				municipalities: municipalities,
				fundingSourceData: fundingSourceData,
				imgGraph: imgGraph,
				totalAmount: totalAmount,
				imgMap: imgMap,
				populationBenefiatedData: populationBenefiatedData,
				activeAction: activeAction
			},
			{ responseType: 'blob' }
		)
	}

	generarPdf(
		id: Number,
		isPortfolio: Boolean,
		selected: any,
		activeAction: any
	): Observable<any> {
		return this.http.post(
			`${this.url}reportsGeneral`,
			{
				id: id,
				isPortfolio: isPortfolio,
				selected: selected,
				activeAction: activeAction
			},
			{ responseType: 'blob' }
		)
	}
}
