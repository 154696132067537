import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { MatSnackBar, MatDialog } from '@angular/material'
import { DashboardService } from '../dashboard.service'
import { FormControl } from '@angular/forms'
import { AuthService } from 'app/services/auth.service'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-portfolio-dashboard',
	templateUrl: './portfolio-dashboard.component.html',
	styleUrls: ['./portfolio-dashboard.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class PortfolioDashboardComponent implements OnInit {
	portfolios: any = []
	portfoliosAll: any = []
	type = 'portfolio'
	mini = true
	loading = true
	type1 = 'project'
	sizeMini = '100px'
	styleMini = { width: this.sizeMini, height: this.sizeMini, fontSize: '20px' }
	filterCtrl = new FormControl('')
	coordinacionId = null
	directorateId = null
	currentUserRole = null

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private authService: AuthService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.currentUserRole = this.authService.rol
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this.directorateId = this.route.snapshot.paramMap.get('directorateId')
		this.dashboardService
			.getAllDashboardByCoordinationByDirectorate(
				this.coordinacionId,
				this.directorateId
			)
			.subscribe((data) => {
				this.loading = false
				this.portfolios = data
				this.portfoliosAll = data

				this.filterCtrl.valueChanges.subscribe((data) =>
					this._filteredPortfolios(data)
				)
			})
	}

	_filteredPortfolios(value: any) {
		this.portfolios = this.portfoliosAll.filter((portfolio) => {
			if (typeof value === 'object') {
				return true
			}

			value = this.removeAccents(value.toLowerCase())
			let values = value.trim().split(' ')
			values = values.filter((v) => v.length > 2)
			if (values.length === 0) {
				return true
			}

			// Busca dentro de cada uno de los tags, que contengan alguna de las palabras de la búsqueda
			const indexTags = portfolio.tags.findIndex((elem: any) => {
				const tag_str = this.removeAccents(elem.tag.toLowerCase())
				const ind = values.findIndex((val: any) => {
					return tag_str.includes(val) && elem.type !== 4
				})
				return ind > -1
			})

			const portfolioName = this.removeAccents(portfolio.name.toLowerCase())
			const namesPortfolio = portfolioName.split(' ')
			// Busca dentro de cada palabra del nombre, que contengan alguna de las palabras de la búsqueda
			let indexName = 0
			indexName = namesPortfolio.findIndex((name: any) => {
				const ind = values.findIndex((val: any) => {
					return name.includes(val)
				})
				return ind > -1
			})

			return indexName > -1 || indexTags > -1
			//return indexName > -1;
		})
	}

	private removeAccents(strAccents: any): String {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}
		return strAccentsOut.join('')
	}

	existsProjects(portfolio: any): boolean {
		return portfolio && portfolio.projects
	}

	isPortfolio(portfolio: any): boolean {
		return portfolio.projects.length > 1
	}

	getFirstProject(portfolio: any): any {
		return this.existsProjects(portfolio) && portfolio.projects.length > 0
			? portfolio.projects[0].id
			: {}
	}

	getRouterLink(portfolio: any): any {
		if (this.isPortfolio(portfolio)) {
			return [
				'/dashboard',
				'coordinaciones',
				this.coordinacionId,
				'direcciones',
				this.directorateId,
				'portafolio',
				portfolio.id
			]
		} else {
			return [
				'/dashboard',
				'coordinaciones',
				this.coordinacionId,
				'direcciones',
				this.directorateId,
				'portafolio',
				portfolio.id,
				'proyecto',
				this.getFirstProject(portfolio)
			]
		}
	}
}
