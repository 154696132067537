import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-files-action-dashboard',
  templateUrl: './dialog-files-action-dashboard.component.html',
  styleUrls: ['./dialog-files-action-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogFilesActionDashboardComponent implements OnInit {

  taskName: string;
  dataSource: any;
  displayedColumns = ['files'];
  iconsPath = 'assets/images/files/';
  iconsExtension = 'png';

  colours = { pdf: '#E1431A', doc: '#19BDF9', docx: '#19BDF9', txt: '#E7BD5A',
    jpg: '#99B4DD', jpeg: '#99B4DD', png: '#99B4DD', bmp: '#99B4DD', gif: '#99B4DD',
    ppt: '#ED8116', pptx: '#ED8116', xls: '#16C155', xlsx: '#16C155', gsheet: '#766ED3',
    mp3: '#766ED3', mp4: '#766ED3', wav: '#766ED3', flac: '#766ED3', zip: '#B59FC6',
    rar: '#B59FC6'
  };

  defaultCoulor = '#AFB5B8';

  icons = { pdf: 'pdf', doc: 'doc', docx: 'doc', txt: 'txt', jpg: 'img', jpeg: 'img',
    png: 'img', bmp: 'img', gif: 'img', ppt: 'ppt', pptx: 'ppt', xls: 'xls', xlsx: 'xls',
    gsheet: 'mp3', mp3: 'mp3', mp4: 'mp3', wav: 'mp3', flac: 'mp3', zip: 'zip', rar: 'zip'
  };

  defaultIcon = 'oth';

  constructor(
    public dialogRef: MatDialogRef<DialogFilesActionDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string, files: any }
  ) { }

  ngOnInit() {
    this.taskName = this.data.name;
    this.dataSource = this.data.files;
    this.sortDataSource();
  }

  getColour(filename: string): string {
    const ext = this.getExtension(filename);

    if (!(ext in this.colours))
      return this.defaultCoulor;

    return this.colours[ext];
  }

  getIcon(filename: string): string {
    const ext = this.getExtension(filename);

    if (!(ext in this.icons))
      return this.iconsPath + this.defaultIcon + '.' + this.iconsExtension;

    return this.iconsPath + this.icons[ext] + '.' + this.iconsExtension;
  }

  getExtension = (filename: string): string => (filename.split('.')).pop().toLowerCase();

  sortDataSource = () => this.dataSource.sort(this.sortFilenames);

  sortFilenames = (fileA, fileB) => {
    const filenameA = fileA.original_name.toUpperCase();
    const filenameB = fileB.original_name.toUpperCase();

    return filenameA <= filenameB ? -1 : 1;
  };
}
