import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { MembersService } from '../members.service'
import { MatSnackBar } from '@angular/material'
import { CatalogsService } from '../../services/catalogs.service'
import { AuthService } from '../../services/auth.service'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { ValidateEmpty } from 'app/validations/empty.validator'

@Component({
	selector: 'app-dialog-add-members',
	templateUrl: './dialog-add-members.component.html',
	styleUrls: ['./dialog-add-members.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DialogAddMembersComponent implements OnInit {
	form: FormGroup
	saving: Boolean = false
	modulesLabels = [
		{ module: 'coordination', label: 'Coordinación' },
		{ module: 'directorate', label: 'Dirección' },
		{ module: 'stages', label: 'Colaborador' },
		{ module: 'subprojects', label: 'Acciones' },
		{ module: 'tasks', label: 'Actividades' },
		{ module: 'communication', label: 'Comunicación' },
		{ module: 'advisors', label: 'Asesores' }
	]
	modulesACL: any[] = []

	ups: any[] = []
	upsGroups: any[] = []
	filteredDependencies: Observable<any[]>
	dependencyCtrl = new FormControl()

	public coordinations: { id: number; name: string }[] = []
	public showCoordinations: Boolean = false
	public organisms: { type: string; data: any[] }[] = []
	public organismsClone: { type: string; data: any[] }[] = []
	public hiddenOrganism: Boolean = true
	defaultValue = 0
	member = null
	loadingMember = false
	permissionSelectedAdmin = false

	constructor(
		private formBuilder: FormBuilder,
		private membersService: MembersService,
		private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<DialogAddMembersComponent>,
		private catalogService: CatalogsService,
		public authService: AuthService,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		dialogRef.disableClose = true
		if (data !== undefined && data !== null) {
			this.loadingMember = true
			this.member = data.member
		}
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: ['', [Validators.required, ValidateEmpty]],
			lastName: ['', [Validators.required, ValidateEmpty]],
			mLastName: '',
			email: ['', [Validators.required, Validators.email]],
			username: ['', [Validators.required, ValidateEmpty]],
			module: ['', Validators.required],
			coordination: [''],
			phone: ['', Validators.required],
			up: [''],
			organism: [0]
		})

		this.upsGroups = [
			{
				type: 'Direcciones',
				ups: []
			} /*,
			{
				type: 'Municipios',
				ups: []
			}*/
		]

		this.membersService.init().subscribe((modules) => {
			const moduleTasks = modules.find((module) => module.name === 'tasks')
			this.form
				.get('module')
				.setValue(typeof moduleTasks.id === 'undefined' ? 1 : moduleTasks.id)

			console.log(modules)

			modules.forEach((element) => {
				const traduce = this.modulesLabels.find(
					(module) => module.module === element.name
				)
				element.name = traduce.label
			})

			this.modulesACL = modules
			if (this.authService.isAdmin()) {
				this.modulesACL.unshift({
					id: 1,
					name: 'Administrador',
					name_traduce: 'Administrador'
				})
			}

			// if (this.modulesACL) {
			// 	this.modulesACL.push({ id: 2, name: 'Dependencia' })
			// }

			// this.modulesACL.sort((a, b) => {
			// 	if (a.name > b.name) {
			// 		return 1
			// 	}

			// 	if (a.name < b.name) {
			// 		return -1
			// 	}
			// 	return 0
			// })
		})

		this.membersService.coordinations().subscribe((coordinations) => {
			this.coordinations = <[{ id: number; name: string }]>coordinations
			if (this.authService.isAdmin()) {
				this.coordinations = this.coordinations.filter(
					(coordination) => coordination.name !== 'coordination'
				)
			}
		})

		this.catalogService.ups.subscribe((result) => {
			this.ups = result.ups
			this.filterPerCordinations(0)
			if (this.member !== null) this.setMemberValue()
		})

		this.membersService.organisms().subscribe((response) => {
			this.organisms = <[{ type: string; data: any[] }]>response
			this.organismsClone = <[{ type: string; data: any[] }]>response
		})
	}

	setMemberValue() {
		let name =
			this.member.name[0] === ' '
				? this.member.name.substr(1)
				: this.member.name
		this.form.get('name').setValue(name)
		this.form.get('lastName').setValue(this.member.last_name)
		this.form.get('mLastName').setValue(this.member.m_last_name)
		this.form.get('email').setValue(this.member.email)
		this.form.get('username').setValue(this.member.username)
		this.form.get('phone').setValue(this.member.phone)
		this.form.get('up').setValue(this.member.up_id)

		if (this.member.permission === 'Coordinación') {
			if (this.member.coordination_id) {
				this.showCoordinations = true
				this.form.get('coordination').setValue(this.member.coordination_id)
			}
		} else {
			if (this.member.dependency)
				this.dependencyCtrl.patchValue(this.member.dependency.name)
		}

		// if(this.member.dependency){
		//   this.dependencyCtrl.patchValue(this.member.dependency.name);
		//   this.form.get("coordination").setValue(this.member.dependency.coordination_id);
		// }

		if (this.member.modules)
			this.form.get('module').setValue(this.member.modules.module.id)
		else if (this.member.permission) {
			let moduleTmp = this.modulesACL.find(
				(m) => m.name == this.member.permission
			)
			if (moduleTmp !== null && moduleTmp !== undefined)
				this.form.get('module').setValue(moduleTmp.id)
		}

		this.loadingMember = false
	}

	optionSelectedDependency(event: any): void {
		const index = event.option.group.label === 'Direcciones' ? 0 : 1

		let selected = []
		selected = this.upsGroups[index].ups.filter((option) => {
			return option.name
				.toLowerCase()
				.includes(event.option.value.toLowerCase())
		})

		if (selected.length > 0) {
			this.form.get('up').patchValue(selected[0].id)

			this.organisms = JSON.parse(JSON.stringify(this.organismsClone))

			this.organisms = this.organisms.filter((type) => {
				type.data = type.data.filter((data) => data.up_id === selected[0].id)
				return !!type.data.length
			})

			this.hiddenOrganism = !!!this.organisms.length
		}
	}

	filterGroup(val: any): any {
		if (!val) {
			return this.upsGroups
		}
		return this.upsGroups
			.map((group) => ({ type: group.type, ups: this._filter(group.ups, val) }))
			.filter((group) => group.ups.length > 0)
	}

	onBlurDependency(event): void {
		if (event.target.value.length === 0) {
			this.form.get('up').patchValue('')
		}
	}

	private _filter(opt: string[], val: string): any {
		const filterValue = val.toLowerCase()
		return opt.filter((item: any) =>
			item.name.toLowerCase().includes(filterValue)
		)
	}

	private _getRole() {
		let role = this.modulesACL.find((m) => m.id == this.form.value['module'])
		return role['name']
	}

	save(): any {
		if (!this.form.valid) {
			return false
		}

		this.saving = true
		let params = this.form.value
		params['role'] = this._getRole()
		if (this.member)
			this.membersService.update(params, this.member.id).subscribe(
				(data: any) => {
					this.saving = false
					this.dialogRef.close(true)
				},
				(error: any) => {
					this.saving = false
					const messages = error.error.map((err) => err.error)
					this.snackBar.open(messages.toString(), 'OK', {
						duration: 3500
					})
				}
			)
		else
			this.membersService.store(params).subscribe(
				(data: any) => {
					this.saving = false
					this.dialogRef.close(true)
				},
				(error: any) => {
					this.saving = false
					const messages = error.error.map((err) => err.error)
					this.snackBar.open(messages.toString(), 'OK', {
						duration: 3500
					})
				}
			)
	}

	public changePermission(event: any): void {
		const modulSelected = this.modulesACL.find((mod) => mod.id === event.value)
		this.permissionSelectedAdmin = false
		if (modulSelected.name == 'Administrador') {
			this.permissionSelectedAdmin = true
			this.form.get('up').clearValidators()
			this.form.get('coordination').clearValidators()
		} else if (modulSelected.name === 'Coordinación') {
			this.form.get('coordination').setValidators(Validators.required)
			this.form.get('coordination').updateValueAndValidity()
			this.form.get('up').clearValidators()
			this.form.get('up').updateValueAndValidity()
			this.showCoordinations = true
		} else if (modulSelected.name === 'Dirección') {
			this.form.get('coordination').clearValidators()
			this.form.get('coordination').updateValueAndValidity()
			this.form.get('up').setValidators([Validators.required])
			this.form.get('up').updateValueAndValidity()
			this.showCoordinations = false
		} else {
			this.form.get('coordination').clearValidators()
			this.form.get('coordination').updateValueAndValidity()
			this.form.get('up').setValidators(Validators.required)
			this.form.get('up').updateValueAndValidity()
			this.showCoordinations = false
		}

		this.clearFields()

		if (modulSelected.name === 'Coordinación') {
			if (
				this.authService.rol === 'coordination' &&
				this.authService.module === 'coordination'
			) {
				this.form.patchValue({
					coordination: this.authService.coordination_id
				})
			}
		} else if (modulSelected.name === 'Dirección') {
			if (
				this.authService.rol === 'coordination' &&
				this.authService.module === 'coordination'
			) {
				this.filterPerCordinations(this.authService.coordination_id)
			}
		} else {
			this.form.patchValue({
				coordination: ''
			})
			this.filterPerCordinations(0)
		}

		this._clearFields()
	}

	// Coordinacion y direccion asignado por usuario loggeado
	private _clearFields() {
		switch (this.authService.rol) {
			// case 'coordination':
			case 'directorate':
				this.form.get('up').clearValidators()
				this.form.get('up').updateValueAndValidity()
				break
		}
	}

	public componentEmiter(event: any): any {
		this.form.get('organism').patchValue(event)
	}

	clearFields(): void {
		this.dependencyCtrl.patchValue('')
		this.form.patchValue({
			up: '',
			organism: 0
		})
		this.hiddenOrganism = true
	}

	changeCoordination(event): void {
		this.clearFields()
		this.filterPerCordinations(this.form.get('coordination').value)
	}

	filterPerCordinations(coordination): void {
		/*if (this.upsGroups.length === 1) {
			this.upsGroups.push({
				type: 'Municipios',
				ups: []
			})
		}*/

		this.upsGroups[0].ups = this.ups.filter((up) => {
			/*if (coordination === 0) {
				return up.government_id
			} else {
				return up.government_id && up.coordination_id === coordination
			}*/
			if (coordination === 0) {
				return true
			} else {
				return up.coordination_id === coordination
			}
		})

		/*this.upsGroups[1].ups = this.ups.filter((up) => {
			if (coordination === 0) {
				return !up.government_id
			} else {
				return !up.government_id && up.coordination_id === coordination
			}
		})*/

		/*if (this.upsGroups[1].ups.length <= 0) {
			this.upsGroups.splice(1, 1)
		}*/

		this.filteredDependencies = this.dependencyCtrl.valueChanges.pipe(
			startWith(''),
			map((val) => this.filterGroup(val))
		)
	}
}
