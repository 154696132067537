import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DetailProjectsService } from '../../projects/detail-projects/detail-projects.service';
import { Stage } from '../stage';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { StagesService } from '../stages.service';
import { AuthService } from '../../services/auth.service';
import { CommunicationService } from '../../services/communication.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DialogAddStageComponent } from '../dialog-add-stage/dialog-add-stage.component';

@Component({
  selector: 'app-detail-stages',
  templateUrl: './detail-stages.component.html',
  styleUrls: ['./detail-stages.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class DetailStagesComponent implements OnInit {
  stage: Stage;
  isEditName: Boolean = false;
  isEditDescription: Boolean = false;
  isEditDateInit: Boolean = false;
  isEditDateFinish: Boolean = false;
  @ViewChild('name', {static: false}) public nameField: ElementRef;
  @ViewChild('description', {static: false}) public descriptionField: ElementRef;
  dataComment: any;

  public minDate = new Date(2018, 11, 6);
  public minDateFinish = new Date();
  public maxDate = new Date(2024, 11, 5);
  public errorDateValid = {isError: false, errorMessage: 'La fecha de asignación no puede ser mayor que la fecha de solución'};
  public dateInitialDate: any;
  public dateFinishDate: any;

  modalStageForm: any;
  DialogAddStageComponentTest : DialogAddStageComponent;

  constructor(
    private detailsService: DetailProjectsService,
    private stagesService: StagesService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private auth: AuthService,
    private communicationService: CommunicationService
  ) {
    this.stage = new Stage();
  }

  ngOnInit(): void {
    this.detailsService.stage.subscribe(data => {
      this.stage = data;
      this.dateFinishDate = this.parseDate(data.date_finished);
      this.dateInitialDate = this.parseDate(data.date_initial);
      this.minDateFinish = this.dateInitialDate;

      this.errorDateValid.isError = false;
      this.errorDateValid.errorMessage = '';
    });
  }

  getStageName(stage: any): string {
    return stage.name.toLowerCase().indexOf('etapa ') === 0 ? stage.name.substring(6, stage.name.length) : stage.name;
  }

  goToProject(): void {
    this.detailsService.goToProject();
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      panelClass: 'dialog-confirm',
      data: {
        content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar la Etapa <strong><br>"${this.stage.name}"</strong>?<label>`
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (response) {
          this.stagesService.delete(this.detailsService.stage.value.id).subscribe(() => {
            this.snack.open('Etapa Eliminada', 'Cerrar', {
              duration: 3500
            });
            this.detailsService.refreshStages(true);
          });
        }
      });
  }

  edit(): void {
    this.modalStageForm = this.dialog.open(DialogAddStageComponent, {
      data: this.stage,
      disableClose: true,
      panelClass: 'mat-dialog-system',
    }).afterClosed()
      .subscribe(response => {
        if(response)
          this.detailsService.refreshActions();
      });
  }

  hasModuleAllowed(module): boolean {
    return this.auth.hasPermission(module);
  }

  onBlur(input: string): void {
    if (input === 'name') {
      this.stagesService.update(
        { name: this.nameField.nativeElement.value },
        this.detailsService.stage.value.id)
        .subscribe((response: any) => {
          this.detailsService.stage.value.name = this.nameField.nativeElement.value;
          this.isEditName = false;
        });

    } else if (input === 'description') {
      this.stagesService.update(
        { description: this.descriptionField.nativeElement.value },
        this.detailsService.stage.value.id)
        .subscribe((response: any) => {
          this.detailsService.stage.value.description = this.descriptionField.nativeElement.value;
          this.isEditDescription = false;
        });
    } 
  }

  toggle(stage: Stage): void{
    this.dataComment = {
      'name': 'Etapa: ' + stage.name,
      'level_name': 'stage',
      'element_id': stage.id,
      'icon': 'label_outline'
    };
    this.communicationService.emitChange(this.dataComment);
  }

  private parseDate(date: any): any {
    if (!!date){
      date = date.split('/');
      date = date[2] + '-' + date[1] + '-' + date[0];
    }
    
    return date;
  }

  private formatDate(date: any): any {
    if (!!date) {
      date = date.split('-');
      date = date[2] + '/' + date[1] + '/' + date[0];
    }

    return  date;
  }

  public changeDateInitial(event: any): void {
    this.dateInitialDate = event.value.format('YYYY-MM-DD');
    this.minDateFinish = this.dateInitialDate;

    if (!this.compareTwoDates()) {
      this.stagesService.update(
        { dateInitial: this.dateInitialDate },
        this.detailsService.stage.value.id)
        .subscribe((response: any) => {
          this.detailsService.stage.value.date_initial = this.formatDate(this.dateInitialDate);
          this.isEditDateInit = false;
          if (!!response.dateConflictMessage.error) {
            this.errorDateValid.isError = true;
            this.errorDateValid.errorMessage = `${response.dateConflictMessage.error} con la etapa: ${response.dateConflictMessage.stage.name},     
            Fecha Inicio: ${response.dateConflictMessage.stage.dateInitial}, 
            Fecha Fin: ${response.dateConflictMessage.stage.dateFinish}`;
          }
        }, error => {
          const mnsError = error.error.find(mns => mns.field === 'date');
          if (!!mnsError) {            
            this.errorDateValid.isError = true;
            this.errorDateValid.errorMessage = `${mnsError.error} con la etapa: ${mnsError.stage.name},     
            Fecha Inicio: ${mnsError.stage.dateInitial}, 
            Fecha Fin: ${mnsError.stage.dateFinish}`;
          }
        });
    }
    
  }

  public changeDateFinish(event: any): void {

    this.dateFinishDate = event.value.format('YYYY-MM-DD');

    if (!this.compareTwoDates()) {
      this.stagesService.update(
        { dateFinished: this.dateFinishDate },
        this.detailsService.stage.value.id)
        .subscribe((response: any) => {
          this.detailsService.stage.value.date_finished = this.formatDate(this.dateFinishDate);
          this.isEditDateFinish = false;
          if (!!response.dateConflictMessage.error) {
            this.errorDateValid.isError = true;
            this.errorDateValid.errorMessage = `${response.dateConflictMessage.error} con la etapa: ${response.dateConflictMessage.stage.name},     
            Fecha Inicio: ${response.dateConflictMessage.stage.dateInitial}, 
            Fecha Fin: ${response.dateConflictMessage.stage.dateFinish}`;
          }
        },  error => {
          const mnsError = error.error.find(mns => mns.field === 'date');
          if (!!mnsError) {
            this.errorDateValid.isError = true;
            this.errorDateValid.errorMessage = `${mnsError.error} con la etapa: ${mnsError.stage.name},     
            Fecha Inicio: ${mnsError.stage.dateInitial}, 
            Fecha Fin: ${mnsError.stage.dateFinish}`;
          }
        });
    }
    
  }

  public onBlurDate(name: string): void  {     
    if (name === 'initial') {
      this.isEditDateInit = false;
    } else if (name === 'finish') { 
      this.isEditDateFinish = false;
    }
  }

  private compareTwoDates(): boolean {
    const d1 = new Date(this.dateInitialDate);
    const d2 = new Date(this.dateFinishDate);
    if (!d1 && !d2){
      return false;
    }

    if (d2 < d1) {
      this.errorDateValid.errorMessage = 'La fecha de asignación no puede ser mayor que la fecha de solución';
    }

    return this.errorDateValid.isError = d2 < d1;
  }
}
