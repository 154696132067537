import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'geo';
    this.httpOptions = global.httpOptions;
   }

  get municipalities(): Observable<any>{
    return this.http.get(this.uri + 'municipios', this.httpOptions);
  }
  get regions(): Observable<any>{
    return this.http.get(this.uri + 'regions', this.httpOptions );
  }
}
