import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import {
	MatDialog,
	MatTableDataSource,
	MatPaginator,
	MatSort
} from '@angular/material'
import { Up } from '../up.model'
import { UpsService } from '../ups.service'
import { DialogUpComponent } from '../dialog-up/dialog-up.component'
import { Location } from '@angular/common'
import { Router } from '@angular/router'

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss'],
	animations: fuseAnimations
})
export class IndexComponent implements OnInit {
	public columns: string[] = [
		'name',
		'titular',
		'email',
		'color',
		'coordination_id',
		'settings'
	]
	public dataSource: MatTableDataSource<Up>
	private coordinations: any[]

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: false }) sort: MatSort
	@ViewChild('search', { static: false }) search: ElementRef

	constructor(
		private service: UpsService,
		private dialog: MatDialog,
		private router: Router,
		private location: Location
	) {}

	ngOnInit(): void {
		location.hash = ''
		this.getUps()

		const spanishRangeLabel = (
			page: number,
			pageSize: number,
			length: number
		) => {
			if (length === 0 || pageSize === 0) {
				return `0 de ${length}`
			}

			length = Math.max(length, 0)

			const startIndex = page * pageSize

			const endIndex =
				startIndex < length
					? Math.min(startIndex + pageSize, length)
					: startIndex + pageSize

			return `${startIndex + 1} - ${endIndex} de ${length}`
		}

		this.paginator._intl.itemsPerPageLabel = 'Registros por página'
		this.paginator._intl.nextPageLabel = 'Siguiente'
		this.paginator._intl.previousPageLabel = 'Anterior'
		this.paginator._intl.lastPageLabel = 'Ultima página'
		this.paginator._intl.firstPageLabel = 'Primer página'
		this.paginator._intl.getRangeLabel = spanishRangeLabel
	}

	openDialog(up: Up): void {
		if (typeof up === 'undefined') {
			up = new Up()
		}

		window.history.pushState({}, '', location.pathname + '#')
		const dialogRef = this.dialog.open(DialogUpComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '60%',
			data: {
				up: up,
				coordination: this.coordinations
			}
		})

		dialogRef.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}
				return false
			}
			this.location.back()

			this.getUps()
		})
	}

	getCoordination(up: any): string {
		if (up.coordination !== null) {
			return up.coordination.name
		}

		return ''
	}

	getUps(): void {
		this.service.index().subscribe((response: any) => {
			const ups = response.ups.reduce((data, up) => {
				up.nameFilter = this.removeAccents(up.name)
				data.push(up)
				return data
			}, [])
			this.dataSource = new MatTableDataSource(response.ups)
			this.dataSource.paginator = this.paginator
			this.dataSource.sort = this.sort
			this.coordinations = response.coordinations
			this.applyFilter(this.search.nativeElement.value)
		})
	}

	applyFilter(filterValue: string): void {
		this.dataSource.filter = this.removeAccents(
			filterValue.trim().toLowerCase()
		)
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}

		return strAccentsOut.join('')
	}
}
