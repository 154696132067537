import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatChipInputEvent, MatAutocompleteSelectedEvent, MatTableDataSource, MatDialog } from '@angular/material'; 
import { ENTER, COMMA} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Utils } from '../../constants/utils';
import { VisualizerService } from '../visualizer.service';
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VisualizerDialogComponent implements OnInit {

  public users: any[] = [];
  public filteredUsers: Observable<string[]>;
  public filteredUsersGroup: Observable<string[]>;
  public usersCtrl: FormControl = new FormControl('');
  public form: FormGroup;
  public saving: Boolean = true;
  public project: {id: number, name: string, dependency: string };
  public columnUsers: string[] = ['user', 'action'];
  public usersTable: MatTableDataSource<any> = new MatTableDataSource([]);
  public changed: Boolean = false;
  public successChanged: Boolean = true;
  public usersChanged: Number = 0;
  private groupUserClear: any[] = [];

  @ViewChild('usersInput', {static: false}) usersInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<VisualizerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: Utils,
    private formBuild: FormBuilder,
    private service: VisualizerService,
    private dialog: MatDialog,
  ) { 

    dialogRef.disableClose = true;
    this.project = <{id: number, name: string, dependency: string }> data;
    
  }

  ngOnInit(): void {

    this.form = this.formBuild.group({
      user: ['']
    });

    this.service.users().subscribe(users => {
      this.users = users;

      this.service.usersByProject(this.project.id).subscribe(usersResponse => {
        this.initUsers(usersResponse);

        this.saving = false;
      });
    }, error => {
      console.log('Error en la peticion');
    });
  }

  private filterGorup(val: any, object: any[]): any{

    if (!val) {
      return object;
    }

    return object.map(
      group => ({upGroup: group.upGroup, users: this.filter(val, group.users) })
    ).filter(group => {
      return group.users && group.users.length > 0;
    });
  }

  private filter(val, users: any []): any[] {
    if (!val){
      return users;
    }

    return users.filter(user => {
      const userName = this.utils.removeAccents(
              user.name.toLowerCase() + ' ' + 
              (user.last_name === null ? '' : user.last_name).toLowerCase() + ' ' + 
              (user.m_last_name === null ? '' : user.m_last_name ).toLowerCase());

      if (typeof val === 'object'){
        return false;
      }

      val = this.utils.removeAccents(val);

      return userName.indexOf(val) > -1;
    });

  }

  private initUsers(users: any[]): void {
    users.map(user => {
      const index = this.users.find((x: any) => x.id === user.id);
      if (index) {
        this.usersTable.data.push(index);
        this.usersTable.data = this.orderUser(this.usersTable.data);
      }
      return user;
    });

    this.users = this.users.filter(user => {
      const index = users.findIndex((x: any) => x.id === user.id);
      return index === -1;
    });

    const usersClone = [...this.users];

    this.groupUserClear = this.groupUsers(usersClone);

    this.filteredUsersGroup = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map(val => this.filterGorup(val, this.groupUserClear))
    );
  }

  public filterUsers(user, action): void {
    if (action === 1) {
      this.users = this.users.filter(element => {
        return element.id !== user.id;
      });
    } else {
      this.users.push(user);
    }

    const usersClone = this.users.slice();

    this.groupUserClear = this.groupUsers(usersClone);

    this.filteredUsersGroup = this.usersCtrl.valueChanges.pipe(
      startWith(''),
      map(val => this.filterGorup(val, this.groupUserClear))
    );
  }

  public removeUser(user: any): void {    
    this.form.get('user').patchValue(user.id);
    this.filterUsers(user, 2);
    this.usersTable.data = this.orderUser(this.usersTable.data.filter(userFilter => userFilter.id !== user.id));
    this.usersChanged = user.id;
    this.delete();
  }

  public addUsers(event: MatChipInputEvent): void {}

  public selectedUser(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    const repeated = this.usersTable.data.find(user => user.ud === value.id);

    if (!!!repeated) {      
      this.form.get('user').patchValue(value.id);
      this.usersInput.nativeElement.value = '';
      this.usersCtrl.setValue(null);
      this.usersTable.data.push(value);
      this.usersTable.data = this.orderUser(this.usersTable.data);
      this.usersChanged = value.id;

      this.save();
    }

    this.filterUsers(event.option.value, 1);
  }

  public save(): void {
    this.changed = true;
    this.service.save(this.project.id, this.form.value).subscribe(response => {
      this.changed = false;
      this.successChanged = true;
      this.usersChanged = 0;
      document.getElementById('visualizers-users-ok-btn').focus();
    }, error => {
      this.dialogRef.close({status: true, error: true});
      this.successChanged = false;
      this.usersChanged = 0;
    });
  }

  public delete(): void {
    this.changed = true;
    this.service.delete(this.project.id, this.form.get('user').value).subscribe(response => {
      this.changed = false;
      this.successChanged = true;
      this.usersChanged = 0;
    }, error => {
      this.dialogRef.close({status: true, error: true});
      this.successChanged = false;
      this.usersChanged = 0;
    });
  }

  public hasAvatar(user: any): boolean {
    if (!user) {
      return false;
    }

    if (typeof user.avatar === 'undefined') {
      return false;
    }
    return user.avatar.length <= 0 ? false : true;
  }

  public getImgAvatar(user: any): string {
    return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none';
  }

  public getUserFullName(user: any): string {
    return user.name + ' ' + user.last_name + (user.m_last_name ? ' ' + user.m_last_name : '');
  }

  private orderUser(users): any[] {
    return users.sort((a, b) => {
      const organization_a = a.coordination !== null ? a.coordination : a.up  ;
      const organization_b = b.coordination !== null ? b.coordination : b.up ;
      return ( organization_a.name < organization_b.name) ? -1 : 
              (organization_a.name > organization_b.name) ? 1 :  
              ( ( this.getUserFullName(a) < this.getUserFullName(b)) ? -1 : 
              (this.getUserFullName(a) > this.getUserFullName(b)) ? 1 : 0 );
    });
  }

  private groupUsers(users: any[]): any[] {
    const filter = [];
    const response = [];
    users.map(userGroup => {
      const val = !!userGroup.up ? userGroup.up.name : 'COORDINADORES';
      filter[val] = filter[val] || { upGroup: val, users: [] };
      filter[val].users.push(userGroup);
    });

    for (const prop in filter) {
      if (typeof filter[prop] !== 'undefined'){
        response.push(filter[prop]);
      }
    }

    return response;
  }

  openUserInfoDialog(user: any): void {
    const dialog = this.dialog.open(UserInfoComponent, {
      panelClass: 'dialog-user-info',
      data: {
        user: user,
        up: user.up
      }
    });
  }
}
