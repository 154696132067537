import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PortfoliosService } from "../portfolios.service";

import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatStepper,
    MatSnackBar,
} from "@angular/material";

@Component({
    selector: "app-dialog-edit-portfolio",
    templateUrl: "./dialog-edit-portfolio.component.html",
    styleUrls: ["./dialog-edit-portfolio.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DialogEditPortfolioComponent implements OnInit {
    form: FormGroup;
    formErrors: any;
    portfolio: any;
    saving: Boolean = false;
    maxLengthDescription = 250;
    maxLengthObjective = 300;
    maxLengthName = 30;
    portfolioForm: FormGroup;

    constructor(
        public snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private portfoliosService: PortfoliosService,
        public dialogRef: MatDialogRef<DialogEditPortfolioComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        dialogRef.disableClose = true;
        this.portfolio = this.data.portfolio;

        this.formErrors = {
            name: {},
            description: {},
            objective: {},
        };
    }

    ngOnInit(): void {
        this.portfolioForm = this.formBuilder.group({
            name: [this.portfolio.name, Validators.required],
            description: [
                this.portfolio.description,
                [
                    Validators.required,
                    Validators.maxLength(this.maxLengthDescription),
                ],
            ],
            objective: [
                this.portfolio.objective,
                [
                    Validators.required,
                    Validators.maxLength(this.maxLengthObjective),
                ],
            ],
        });
    }

    formValid(): boolean {
        if (!this.portfolioForm.valid) {
            return false;
        }
        return true;
    }

    save(): any {
        if (!this.formValid()) {
            return false;
        }
        this.saving = true;

        const form = this.portfolioForm.value;

        this.portfoliosService.update(this.portfolio.id, form).subscribe(
            (data) => {
                this.saving = false;
                this.dialogRef.close(true);
            },
            (error) => {
                this.saving = false;
                const keys = Object.keys(error.error);
                this.snackBar.open(error.error[keys[0]], "OK", {
                    duration: 3500,
                });
            }
        );
    }
}
