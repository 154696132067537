import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { forkJoin, Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ProjectsService } from '../../projects/projects.service'
import * as moment from 'moment'
import { Utils } from 'app/constants/utils'
import { AuthService } from 'app/services/auth.service'

@Component({
	selector: 'app-dialog-add-project',
	templateUrl: './dialog-add-project.component.html',
	styleUrls: ['./dialog-add-project.component.scss']
})
export class DialogAddProjectComponent implements OnInit {
	title: string = 'Nuevo proyecto'
	dependencyId = null

	loading: boolean = true

	project: any

	saving: Boolean = false
	maxLengthDescription = 250
	maxLengthObjective = 300
	maxLengthName = 30

	//Axis
	axis: any = []
	filteredAxis: Observable<any[]>

	//Responsibles
	filteredDependencies: Observable<any[]>
	dependencies: any = []

	//Responsible User
	filteredResponsiblesUsers: Observable<any>
	filteredColaboratorsUsers: Observable<any>
	responsiblesObject: any = []

	projectForm: FormGroup

	minDate = new Date(2018, 11, 6)
	maxDate = new Date(2024, 11, 5)

	coordinacionId: number
	directorateId: number = 0

	// SHOW / HIDE PASSWORD INPUT
	togglePasswordInput = false

	constructor(
		public snackBar: MatSnackBar,
		private formBuilder: FormBuilder,
		private projectsService: ProjectsService,
		public dialogRef: MatDialogRef<DialogAddProjectComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private _utils: Utils,
		private authService: AuthService
	) {
		dialogRef.disableClose = true
		this.coordinacionId = +data.coordinacionId
		this.directorateId = data.directorateId ? +data.directorateId : 0
	}

	ngOnInit(): void {
		this.projectForm = this.formBuilder.group({
			name: ['', Validators.required],
			termn: [
				{
					begin: moment().toDate(),
					end: moment().add(1, 'd').toDate()
				},
				Validators.required
			],
			promise: [false],
			description: ['', [Validators.required]],
			objective: ['', [Validators.required]],
			dependency: ['', Validators.required],
			responsible: ['', Validators.required],
			colaborator: [''],
			axis: ['']
		})

		if (!this.data || !this.data.project_id) {
			this.projectsService
				.getDependenciesAxisByCoord(this.coordinacionId)
				.subscribe((data: any) => {
					this.axis = data.axis_list.filter(
						(ax) => ax.coordination_id === this.coordinacionId
					)
					this.dependencies = data.dependency_list

					console.log(this.authService.dependencyId)

					if (this.authService.dependencyId) {
						this.projectForm.controls.dependency.patchValue(
							this.dependencies.filter(
								(dep) => dep.id == this.authService.dependencyId
							)[0]
						)
					} else {
						this.projectForm.controls.dependency.patchValue(
							this.dependencies.filter((dep) => dep.id == this.directorateId)[0]
						)
					}

					this.projectForm.controls.dependency.disable()

					this.projectsService
						.getResponsibleList(
							this.authService.dependencyId
								? this.authService.dependencyId
								: this.directorateId
						)
						.subscribe((data: any) => {
							this.responsiblesObject = data.responsible_list.filter(
								(res) =>
									res.modules.module_id === 8 || res.modules.module_id === 3
							)

							this.filteredResponsiblesUsers =
								this.projectForm.controls.responsible.valueChanges.pipe(
									startWith(''),
									map((val) => this.filterResponsibles(val))
								)

							this.filteredColaboratorsUsers =
								this.projectForm.controls.colaborator.valueChanges.pipe(
									startWith(''),
									map((val) => this.filterResponsibles(val))
								)
						})

					this.initPrepare()
				})
		} else {
			this.title = 'Editar proyecto'

			forkJoin({
				resources: this.projectsService.getDependenciesAxisByCoord(
					this.coordinacionId
				),
				project: this.projectsService.get(this.data.project_id)
			}).subscribe(({ resources, project }) => {
				this.project = project
				this.axis = resources.axis_list
				this.dependencies = resources.dependency_list

				this.projectForm.controls.name.setValue(
					project.name ? project.name : project.portfolio.name
				)
				this.projectForm.controls.termn.patchValue({
					begin: new Date(this._utils.parseDate(project.termn_begin)),
					end: new Date(this._utils.parseDate(project.termn_end))
				})

				this.projectForm.controls.promise.setValue(project.promise)
				this.projectForm.controls.description.setValue(project.description)
				this.projectForm.controls.objective.setValue(project.objective)
				this.projectForm.controls.dependency.patchValue(project.dependency)
				this.projectForm.controls.responsible.patchValue(project.responsible)
				this.projectForm.controls.colaborator.patchValue(project.colaborator)
				this.projectForm.controls.axis.patchValue(project.axis)

				this.projectForm.controls.dependency.disable()
				this.projectForm.controls.axis.disable()

				this.initPrepare()

				this.projectsService
					.getResponsibleList(project.dependency.id)
					.subscribe((data: any) => {
						this.responsiblesObject = data.responsible_list.filter(
							(res) =>
								res.modules.module_id === 8 || res.modules.module_id === 3
						)
						this.filteredResponsiblesUsers =
							this.projectForm.controls.responsible.valueChanges.pipe(
								startWith(''),
								map((val) => this.filterResponsibles(val))
							)
						this.filteredColaboratorsUsers =
							this.projectForm.controls.colaborator.valueChanges.pipe(
								startWith(''),
								map((val) => this.filterResponsibles(val))
							)
					})
			})
		}
	}

	initPrepare(): void {
		this.filteredAxis = this.projectForm.controls.axis.valueChanges.pipe(
			startWith(''),
			map((val) => this.filterAxis(val))
		)

		this.filteredDependencies =
			this.projectForm.controls.dependency.valueChanges.pipe(
				startWith(''),
				map((val) => this.filterDependency(val))
			)

		this.projectForm.controls.dependency.valueChanges.subscribe((val) => {
			if (!val || typeof val === 'string') {
				return
			}
			this.projectForm.controls.responsible.reset()
			this.projectForm.controls.colaborator.reset()
			this.projectsService.getResponsibleList(val.id).subscribe((data: any) => {
				this.responsiblesObject = data.responsible_list.filter(
					(res) => res.modules.module_id === 8 || res.modules.module_id === 3
				)

				this.filteredResponsiblesUsers =
					this.projectForm.controls.responsible.valueChanges.pipe(
						startWith(''),
						map((val) => this.filterResponsibles(val))
					)

				this.filteredColaboratorsUsers =
					this.projectForm.controls.colaborator.valueChanges.pipe(
						startWith(''),
						map((val) => this.filterResponsibles(val))
					)
			})
		})

		/*this.projectForm.controls.responsible.valueChanges.subscribe((val) => {
			if (!val || typeof val === 'string') {
				return
			}
			if (
				this.projectForm.controls.responsible.value ===
				this.projectForm.controls.colaborator.value
			) {
				this.projectForm.controls.colaborator.patchValue('')
			}
		})*/

		this.loading = false
	}

	filterAxis(val: any): string[] {
		if (!(typeof val === 'string')) {
			return
		}
		if (!val) {
			return this.axis
		}
		return this.axis.filter((option) => {
			return this._utils
				.removeAccents(option.name.toLowerCase())
				.includes(this._utils.removeAccents(val.toLowerCase()))
		})
	}

	filterDependency(val: any) {
		if (!(typeof val === 'string')) {
			return
		}
		if (!val) {
			return this.dependencies
		}
		return this.dependencies.filter((option) => {
			return this._utils
				.removeAccents(option.name.toLowerCase())
				.includes(this._utils.removeAccents(val.toLowerCase()))
		})
	}

	filterResponsibles(val: any) {
		if (!(typeof val === 'string')) {
			return
		}
		if (!val) {
			return this.responsiblesObject
		}
		return this.responsiblesObject.filter((option) => {
			return this._utils
				.removeAccents(option.fullname.toLowerCase())
				.includes(this._utils.removeAccents(val.toLowerCase()))
		})
	}

	save(): any {
		if (this.projectForm.invalid) {
			return false
		}

		this.saving = true

		const body = {
			name: this.projectForm.controls.name.value,
			description: this.projectForm.controls.description.value,
			objective: this.projectForm.controls.objective.value,
			termn_begin: moment(
				this.projectForm.controls.termn.value['begin']
			).format('YYYY-MM-DD'),
			termn_end: moment(this.projectForm.controls.termn.value['end']).format(
				'YYYY-MM-DD'
			),
			responsible_id: this.projectForm.controls.responsible.value.id,
			colaborator_id: this.projectForm.controls.colaborator.value
				? this.projectForm.controls.colaborator.value.id
				: null,
			dependency_id: this.projectForm.controls.dependency.value.id,
			axis_id: this.projectForm.controls.axis.value
				? this.projectForm.controls.axis.value.id
				: null,
			promise: this.projectForm.controls.promise.value
		}

		if (!this.data || !this.data.project_id) {
			this.projectsService.store(body).subscribe(
				(data) => {
					this.saving = false
					this.dialogRef.close(true)
				},
				(error) => {
					this.saving = false
					const keys = Object.keys(error.error)
					this.snackBar.open(error.error[keys[0]], 'OK', {
						duration: 3500
					})
				}
			)
		} else {
			let changes: boolean = false

			if (this.projectForm.controls.name.value != this.project.name) {
				changes = true
			}
			if (
				this.projectForm.controls.description.value != this.project.description
			) {
				changes = true
			}
			if (this.projectForm.controls.objective.value != this.project.objective) {
				changes = true
			}
			if (
				moment(this.projectForm.controls.termn.value['begin']).format(
					'YYYY-MM-DD'
				) != this.project.termn_begin
			) {
				changes = true
			}
			if (
				moment(this.projectForm.controls.termn.value['end']).format(
					'YYYY-MM-DD'
				) != this.project.termn_end
			) {
				changes = true
			}
			if (
				this.projectForm.controls.responsible.value.id !=
				this.project.responsible.id
			) {
				changes = true
			}
			if (this.projectForm.controls.promise.value != this.project.promise) {
				changes = true
			}

			if (!changes) {
				this.snackBar.open('No hay cambios que guardar', 'OK', {
					duration: 3500
				})
				this.saving = false
				return
			}
			this.projectsService.update(this.data.project_id, body).subscribe(
				(data) => {
					this.saving = false
					this.dialogRef.close(true)
				},
				(error) => {
					this.saving = false
					const keys = Object.keys(error.error)
					this.snackBar.open(error.error[keys[0]], 'OK', {
						duration: 3500
					})
				}
			)
		}
	}

	displayDependencyNameFn(item: any) {
		return item ? item.name : ''
	}

	displayResponsibleFullNameFn(item: any) {
		return item ? item.fullname : ''
	}

	displayColaboratorFullNameFn(item: any) {
		return item ? item.fullname : ''
	}

	displayAxisNameFn(item: any) {
		return item ? item.name : ''
	}
}
