import { Injectable } from '@angular/core'
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse
} from '@angular/common/http'
import { AuthService } from '../services/auth.service'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { LoginService } from '../login/login.service'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService, private loginService: LoginService) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (
			request.url.indexOf('auth/login') < 0 &&
			request.url.indexOf('users/new') < 0 &&
			request.url.indexOf('users/password') < 0
		) {
			request = request.clone({
				setHeaders: {
					token: this.auth.token
				}
			})
		}

		return next.handle(request).pipe(
			tap(
				(event) => {
					if (event instanceof HttpResponse) {
						// console.clear();
						// console.log(event);
						// // http response status code
						// console.log(event.status);
					}
				},
				(error) => {
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// console.error(error.status);
					// console.error(error.message);
					// console.log('--- end of response---');
				}
			)
		)
	}
}
