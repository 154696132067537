import {
	Component,
	OnInit,
	Input,
	SimpleChanges,
	OnChanges
} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { CommentsService } from './commentsService'
import { AuthService } from '../services/auth.service'
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component'
import { MatDialog, MatSnackBar } from '@angular/material'

@Component({
	selector: 'global-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
	@Input() dataComment: any = null
	@Input() projectId: number = null

	text: FormControl
	data: any
	comments: any
	loading: Boolean = false
	deleting: Boolean = false
	messageEmpty: String = ''
	id_comment: Number = 0

	constructor(
		private commentsService: CommentsService,
		private authService: AuthService,
		public dialog: MatDialog,
		public snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.text = new FormControl('', Validators.required)
		this.comments = []
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['dataComment']) {
			this.data = this.dataComment
			if (this.data) {
				this.text.patchValue('')
				this.getComments()
			}
		}
	}

	getComments(): void {
		this.commentsService
			.get(this.data.level_name, this.data.element_id)
			.subscribe((response) => {
				this.comments = response
				if (this.comments.length === 0) {
					this.messageEmpty = 'No existen comentarios'
				}
			})
	}

	getCharacters(): string {
		return this.text.value ? this.text.value.length : 0
	}

	getDate(comment: any): string {
		if (comment) {
			const date = comment.created_at.split(' ')[0]
			return date.split('-').reverse().join('/')
		}
		return ''
	}

	isCreator(user: any): boolean {
		return this.authService.id_user === user.id
	}

	hasAvatar(user: any): boolean {
		if (!user) {
			return false
		}
		if (typeof user.avatar === 'undefined') {
			return false
		}
		return user.avatar.length <= 0 ? false : true
	}

	getImgAvatar(user: any): string {
		return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none'
	}

	getUserFullName(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	openCommentDeleteDialog(idComment: number): void {
		const dialog = this.dialog.open(ConfirmComponent, {
			data: {
				title: ``,
				content: `¿Confirmar que deseas eliminar el comentario?`
			}
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.deleting = true
				this.id_comment = idComment
				this.commentsService.delete(idComment).subscribe(
					(data) => {
						if (data.status) {
							this.showMessage('Comentario Eliminado', 'OK')
							this.getComments()
						} else {
							this.showMessage('Error al eliminar el comentario', 'ERROR')
						}
					},
					(error) => {
						this.showMessage('Error al eliminar el comentario', 'ERROR')
					}
				)
			}
		})
	}

	showMessage(message: string, close: string): void {
		this.deleting = false
		this.id_comment = 0
		this.snackBar.open(message, close, {
			duration: 3000
		})
	}

	save(el: HTMLElement): void {
		if (!this.text.value) {
			return
		}
		const data = {
			text: this.text.value,
			level_name: this.data.level_name,
			element_id: this.data.element_id,
			projectId: this.projectId
		}

		this.loading = true
		this.commentsService.store(data).subscribe(
			(response) => {
				this.showMessage('Comentario Agregado', 'OK')
				this.getComments()
				this.text.patchValue('')
				this.loading = false
				el.scrollIntoView()
			},
			(error) => {
				this.loading = false
			}
		)
	}
}
