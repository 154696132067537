import {
	Component,
	OnInit,
	ViewChild,
	Input,
	SimpleChanges,
	OnChanges
} from '@angular/core'
import { fuseAnimations } from '@fuse/animations'

import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import canvg from 'canvg'

import { ActivatedRoute } from '@angular/router'
import { CatalogsService } from '../../services/catalogs.service'
import { ReportsService } from 'app/reports/reports.service'
import { saveAs } from 'file-saver'
import { throwError } from 'rxjs'

@Component({
	selector: 'app-reports-portfolios',
	templateUrl: './reports-portfolios.component.html',
	styleUrls: ['./reports-portfolios.component.scss'],
	animations: fuseAnimations
})
export class ReportsPortfoliosComponent implements OnInit, OnChanges {
	@ViewChild('chart', { static: false }) chart: any = null
	@Input() portfolio: any = null
	@Input() activeAction: any = null
	numbers: any
	faSquare: any
	ups: any = []
	funding_source: any = []
	territorial: any = { territorial: [] }
	tasks: any = []
	tasks_finished: Number = 0
	dependencies: any = []
	primary_color: String = ''
	dependency_color: String = ''
	logo: String = ''
	logo_jal: String = ''
	data_graph: any = []
	total_total: Number = 0
	pagina = 1
	projects_actions_stages: any = []
	coordinations: any = []
	splits: any = []
	colors: any = []
	downloading: Boolean = false
	benefited_population: Number = 0
	promise: Boolean = false
	noCoord: Boolean = false
	date: any = new Date()
	private sendData: {
		id: number
		projects: {
			id: number
			stages: {
				id: number
				action: {
					id: number
				}[]
			}[]
		}[]
	}
	public pricipalDependency = ''
	objectMun = []

	constructor(
		private catalogService: CatalogsService,
		private reportService: ReportsService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		const month =
			this.date.getMonth() + 1 <= 9
				? '0' + (this.date.getMonth() + 1)
				: this.date.getMonth() + 1
		const day =
			this.date.getDate() <= 9 ? '0' + this.date.getDate() : this.date.getDate()
		this.date = day + '/' + month + '/' + this.date.getFullYear()
	}

	init(): void {
		this.data_graph = [
			{ name: '', data: [{ category: '', value: 0, color: '#FFF' }] },
			{ name: '', data: [{ category: '', value: 0, color: '#FFF' }] },
			{ name: '', data: [{ category: '', value: 0, color: '#FFF' }] },
			{ name: '', data: [{ category: '', value: 0, color: '#FFF' }] }
		]
		this.ups = []
		this.funding_source = []
		this.territorial = { territorial: [] }
		this.tasks = []
		this.tasks_finished = 0
		this.dependencies = []
		this.primary_color = ''
		this.dependency_color = ''
		this.logo = ''
		this.logo_jal = ''
		this.total_total = 0
		this.pagina = 1
		this.projects_actions_stages = []
		this.coordinations = []
		this.splits = []
		this.colors = []

		this.getTerritorial()
		// PROJECTS
		this.sendData = { id: this.portfolio.id, projects: [] }
		const projectParse = []

		this.portfolio.projects.forEach((project: any, index: Number) => {
			this.sendData.projects.push({ id: project.id, stages: [] })

			this.projects_actions_stages.push(project)
			this.benefited_population = this.portfolio.benefited_population
			this.promise = this.portfolio.promise

			if (index === 0) {
				this.dependency_color = project.dependency.color
				this.pricipalDependency = project.dependency.name
				// this.logo_jal = 'assets/images/Jalisco-Gob.png'
				this.logo_jal = 'assets/images/logo-vertical.png'
				if (project.dependency.coordination) {
					this.primary_color = project.dependency.coordination.primary_color
					// debugger
					this.logo = project.dependency.coordination.uri_logo
					this.colors = project.dependency.coordination.colors
				} else {
					this.primary_color = '#4e4e4e'
					this.logo = 'assets/images/logo-gdl.svg'
					this.colors = [
						{ color: '#4e4e4e', coordination_id: 5 },
						{ color: '#4e4e4e', coordination_id: 5 },
						{ color: '#4e4e4e', coordination_id: 5 },
						{ color: '#4e4e4e', coordination_id: 5 },
						{ color: '#4e4e4e', coordination_id: 5 }
					]
					this.noCoord = true
				}
			}
			this.dependencies.push(project.dependency)

			if (project.dependency.coordination) {
				// COORDINATIONS
				const index_coordination = this.coordinations.findIndex(
					(x: any) => x.coordination_id === project.dependency.coordination_id
				)
				if (index_coordination >= 0) {
					this.coordinations[index_coordination].dependencies.push({
						name: project.dependency.name
					})
				} else {
					this.coordinations.push({
						coordination_id: project.dependency.coordination_id,
						name: project.dependency.coordination.name,
						dependencies: [{ name: project.dependency.name }]
					})
				}
			} else {
				this.coordinations.push({
					coordination_id: 5,
					name: '',
					dependencies: [{ name: project.dependency.name }]
				})
			}

			project.children.forEach((stage: any) => {
				this.sendData.projects
					.find((pro) => pro.id === project.id)
					.stages.push({ id: stage.id, action: [] })

				stage.children.forEach((action: any) => {
					this.sendData.projects
						.find((pro) => pro.id === project.id)
						.stages.find((stag) => stag.id === stage.id)
						.action.push({ id: action.id })

					/*action.subprojects_financing.forEach((financing: any, i: number) => {
						// UPS
						if (financing.name_funding === 'Estatal') {
							const index_up = this.ups.findIndex(
								(x: any) => x.up_id === financing.up_id
							)
							if (index_up >= 0) {
								this.ups[index_up].total += financing.authorized_amount_total
							} else {
								if (financing.up_id) {
									this.ups.push({
										up_id: financing.up_id,
										name: financing.name_up,
										color: project.dependency.color,
										total: financing.authorized_amount_total
									})
								}
							}
						}

						// ORIGEN
						const index_source = this.funding_source.findIndex(
							(x: any) => x.id_type === financing.funding_sources_id
						)
						if (index_source >= 0) {
							this.funding_source[index_source].total +=
								financing.authorized_amount_total
						} else {
							this.funding_source.push({
								id_type: financing.funding_sources_id,
								total: financing.authorized_amount_total
							})
						}

						// SPLITS
						financing.splits_action.forEach((split: any) => {
							const index_split = this.splits.findIndex(
								(x: any) => x.split_id === split.split_id
							)
							if (index_split >= 0) {
								this.splits[index_split].total += split.amount
							} else {
								this.splits.push({
									split_id: split.split_id,
									name: split.split,
									color: project.dependency.color,
									total: split.amount
								})
							}
						})
					})*/

					action.tasks.forEach((task: any) => {
						this.tasks.push(task)
					})
				})
			})
		})

		this.generateDataGraph()
		/*this.toDataUrl(this.logo, function(myBase64) {
      this.logo = myBase64;
    });*/
		this.funding_source.sort(this.compare)
		// });
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const propName in changes) {
			if (propName === 'portfolio') {
				this.portfolio = changes[propName].currentValue
				this.init()
			}
		}
	}

	compare(a: any, b: any): number {
		if (a.id_type < b.id_type) {
			return -1
		}
		if (a.id_type > b.id_type) {
			return 1
		}
		return 0
	}

	getTerritorial(): void {
		if (this.portfolio.territorial_scale === 'estatal') {
			this.territorial = { id: 1, name: 'estatal', territorial: [] }
		} else if (this.portfolio.territorial_scale === 'municipal') {
			const municipalities = this.portfolio.municipalities.map(
				(mun: any, index: Number) => {
					return { name: mun.municipio.name }
				}
			)
			this.configObjectTerritorial(municipalities)
			this.territorial = {
				id: 2,
				name: 'municipal',
				territorial: municipalities
			}
		} else if (this.portfolio.territorial_scale === 'regional') {
			const regions = this.portfolio.regions.map((reg: any, index: Number) => {
				return { name: reg.region.name }
			})
			this.configObjectTerritorial(regions)
			this.territorial = { id: 3, name: 'regional', territorial: regions }
		}
	}

	configObjectTerritorial(municipalities): void {
		const missing = Math.abs((municipalities.length % 3) - 3)
		if (missing === 1) {
			municipalities.push({ name: '' })
		} else if (missing === 2) {
			municipalities.push({ name: '' })
			municipalities.push({ name: '' })
		}

		let index = 0
		municipalities.forEach((mun: any, i: Number) => {
			if (!this.objectMun[index]) {
				this.objectMun[index] = []
			}
			this.objectMun[index].push(mun)

			if (this.objectMun[index].length === 3) {
				index++
			}
		})
	}

	get territorialTitle(): string {
		return this.territorial.id === 1
			? 'TODO EL ESTADO DE JALISCO'
			: this.territorial.id === 2
			? 'MUNICIPIOS INTERVENIDOS'
			: 'REGIONES INTERVENIDAS'
	}

	generateDataGraph(): void {
		// ORIGEN
		const ori = []
		let total = 0
		this.funding_source.forEach((element) => {
			total += element.total
		})
		this.funding_source.forEach((element, index) => {
			ori.push({
				category: this.nameSource(element.id_type),
				value: element.total,
				color: this.getColor(index),
				percent: (element.total * 100) / total
			})
		})
		this.data_graph.push({ name: 'Origen', data: ori })
		this.total_total = total

		// UPS // RUBRO
		const isPor = this.isPortfolio()
		const ups1 = []
		let obj = [],
			name = ''
		total = 0
		if (isPor) {
			obj = this.ups
			name = 'Dependencia'
		} else {
			obj = this.splits
			name = 'Partida'
		}

		obj.forEach((element) => {
			total += element.total
		})
		obj.forEach((element) => {
			ups1.push({
				category: element.name,
				value: element.total,
				color: element.color,
				percent: (element.total * 100) / total
			})
		})
		this.data_graph.push({ name, data: ups1 })
	}

	typeSource(id: Number): any {
		const funding_sourcesand = [
			{ id: 1, name: 'Estatal' },
			{ id: 2, name: 'Federal' },
			{ id: 3, name: 'Internacional' },
			{ id: 4, name: 'Otros' },
			{ id: 5, name: 'Municipal' }
		]
		const index = funding_sourcesand.findIndex((x: any) => x.id === id)
		if (index >= 0) {
			return funding_sourcesand[index]
		}
		return ''
	}

	getColor(index): string {
		if (this.colors.length > 0) {
			if (index > this.colors.length - 1) {
				return this.colors[this.colors.length - 1].color
			}
			return this.colors[index].color
		} else {
			return ''
		}
	}

	get upsSplits(): any {
		return this.isPortfolio() ? this.ups : this.splits
	}

	nameSource(id: Number): string {
		const source: any = this.typeSource(id)
		return source.name
	}

	colorSource(id: Number): string {
		const source: any = this.typeSource(id)
		return source.color
	}

	tasksActive(): any {
		const tasks = this.tasks.filter((task: any) => {
			const d = task.date_estimated_finished.split('/')
			let date: any = d[1] + '/' + d[0] + '/' + d[2]
			date = new Date(date)
			return date <= new Date(this.today(2))
		})
		return tasks
	}

	totalTasks(): number {
		const tasks = this.tasksActive()
		return tasks.length
	}

	filterTaskFinished(): any {
		const tasks = this.tasksActive()
		return tasks.filter((task: any) => {
			return task.status === 'finished'
		})
	}

	finishedTasks(): number {
		let finished = 0
		const tasks = this.filterTaskFinished()
		tasks.forEach((task: any) => {
			if (task.status === 'finished') {
				finished += 1
			}
		})
		return finished
	}

	isPortfolio(): boolean {
		if (this.portfolio && this.portfolio.projects.length > 1) {
			return true
		}
		return false
	}

	percent1(): string {
		if (this.tasks.length === 0) {
			return '0%'
		}
		return `${(this.totalTasks() * 100) / this.tasks.length}%`
	}

	percent2(): string {
		if (this.tasks.length === 0) {
			return '0%'
		}
		return `${(this.finishedTasks() * 100) / this.tasks.length}%`
	}

	percentN1(): number {
		if (this.tasks.length === 0) {
			return 0
		}
		return Math.trunc((this.totalTasks() * 100) / this.tasks.length)
	}

	percentN2(): number {
		if (this.tasks.length === 0) {
			return 0
		}
		return Math.trunc((this.finishedTasks() * 100) / this.tasks.length)
	}

	toDataUrl(url, callback): void {
		const xhr = new XMLHttpRequest()
		xhr.onload = function () {
			const reader = new FileReader()
			reader.onloadend = function () {
				callback(reader.result)
			}
			reader.readAsDataURL(xhr.response)
		}
		xhr.open('GET', url)
		xhr.responseType = 'blob'
		xhr.send()
	}

	// htmltoPDF(): void {
	//   const row: any = document.getElementById('reportgraph');
	//   // row.style.border = '1px solid red';
	//   let img = '';
	//   this.downloading = true;

	//   html2canvas(row).then(canvas => {
	//     let svg = document.getElementsByClassName('k-chart-surface')[0].innerHTML;
	//     if (svg) {
	//       svg = svg.replace(/\r?\n|\r/g, '').trim();
	//     }
	//     const canvasSvg = document.createElement('canvas');
	//     const context = canvasSvg.getContext('2d');
	//     context.clearRect(0, 0, canvasSvg.width, canvasSvg.height);
	//     canvg(canvasSvg, svg);
	//     img = canvasSvg.toDataURL('image/png');

	//     const id = this.isPortfolio() ? this.portfolio.id : this.portfolio.projects[0].id;

	//     this.reportService.generarPdf(id, img, this.isPortfolio(), this.sendData, this.activeAction).subscribe(
	//       (response) => {
	//         const blob = new Blob([response], { type: 'application/pdf' });
	//         saveAs(blob, 'Reporte General.pdf');
	//         this.downloading = false;
	//       },
	//       e => { throwError(e); }
	//     );
	//   });
	// }

	htmltoPDF(): void {
		const id = this.isPortfolio()
			? this.portfolio.id
			: this.portfolio.projects[0].id

		this.reportService
			.generarPdf(id, this.isPortfolio(), this.sendData, this.activeAction)
			.subscribe(
				(response) => {
					const blob = new Blob([response], { type: 'application/pdf' })
					saveAs(blob, 'Reporte General.pdf')
					this.downloading = false
				},
				(e) => {
					throwError(e)
				}
			)
	}

	today(type: any): string {
		const today: any = new Date()
		let dd: any = today.getDate()
		let mm: any = today.getMonth() + 1
		const yyyy = today.getFullYear()
		if (dd < 10) {
			dd = '0' + dd
		}
		if (mm < 10) {
			mm = '0' + mm
		}
		if (type === 1) {
			return dd + '/' + mm + '/' + yyyy
		} else {
			return mm + '/' + dd + '/' + yyyy
		}
	}

	public replaceStatus(status): string {
		switch (status) {
			case 'created':
				return 'Nueva'
			case 'processing':
				return 'Procesando'
			case 'finished':
				return 'Finalizada'
			case 'cancelled':
				return 'Cancelado'
			case 'paused':
				return 'Pausado'

			default:
				return ''
		}
	}

	public statusColor(semaphore: number, status: string): string {
		if (status === 'Finalizada') {
			return '#3DAD54'
		}

		let response = ''
		const result = semaphore

		if (result >= 50) {
			response = '#3DAD54'
		} else if (result >= 20 && result <= 49) {
			response = '#F4C904'
		} else {
			response = '#E94E31'
		}

		return response
	}

	getColorPorcent(porcent: number) {
		if (porcent <= 20) {
			return 'gtaskred'
		}
		if (porcent > 20 && porcent < 50) {
			return 'gtaskyellow'
		}
		if (porcent >= 50) {
			return 'gtaskgreen'
		}
	}
}
