import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailProjectDashboardComponent } from './detail-project-dashboard.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import { OrderModule } from 'ngx-order-pipe';
import {
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatSnackBarModule,
  MatMenuModule,
  MatTooltipModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatStepperModule,
  MatDatepickerModule,
  MatBottomSheetModule,
  MatToolbarModule,
  MatSidenavModule
} from '@angular/material';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseSidebarModule } from '@fuse/components';
import { DonutGraphModule } from '../donut-graph/donut-graph.module';
import { DonutGraphComponent } from '../donut-graph/donut-graph.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    FuseSharedModule,
    CdkTableModule,
    MatSnackBarModule,
    MatMenuModule,
    RoundProgressModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDatepickerModule,
    OrderModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
    MatToolbarModule,
    MatSidenavModule,
    FuseSidebarModule,
    DonutGraphModule,
    RouterModule
  ],
  declarations: [
    DetailProjectDashboardComponent
  ],
  entryComponents: [
    DonutGraphComponent
  ],
  exports: [
    DetailProjectDashboardComponent
  ],
  providers: [],
})
export class DetailProjectDashboardModule { }
