import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], arg: any): any {

    if (value) {
      return value.sort((a: any, b: any): number => {

        if (arg.split('.').length > 0){
          let propertyA = '';
          let propertyB = '';
  
          arg.split('.').forEach(element => {          
            if (propertyA === '') {
              propertyA = a[element];
            } else {
              propertyA = propertyA[element];
            }
  
            if (propertyB === '') {
              propertyB = b[element];
            } else {
              propertyB = propertyB[element];
            }
          });
  
          if (propertyA > propertyB) {
            return 1;
          }
    
          if (propertyA < propertyB) {
            return -1;
          }
  
        } else {
          if (a[arg] > b[arg]) {
            return 1;
          }
    
          if (a[arg] < b[arg]) {
            return -1;
          }
        }
  
        return 0;
      });
    }

    return 0;
  }

}
