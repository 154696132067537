import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service'
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive'
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service'
import { AuthService } from 'app/services/auth.service'

@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
	// Layout
	@Input()
	layout

	fusePerfectScrollbarUpdateTimeout: any
	navigation: any

	// Private
	private _fusePerfectScrollbar: FusePerfectScrollbarDirective
	private _unsubscribeAll: Subject<any>

	/**
	 * Constructor
	 *
	 * @param {FuseNavigationService} _fuseNavigationService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {Router} _router
	 */
	constructor(
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _router: Router,
		private authService: AuthService
	) {
		// Set the defaults
		this.layout = 'vertical'

		// Set the private defaults
		this._unsubscribeAll = new Subject()
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	// Directive
	@ViewChild(FusePerfectScrollbarDirective, { static: false })
	set directive(theDirective: FusePerfectScrollbarDirective) {
		if (!theDirective) {
			return
		}

		this._fusePerfectScrollbar = theDirective

		this._fuseNavigationService.onItemCollapseToggled
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
					this._fusePerfectScrollbar.update()
				}, 310)
			})
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				if (this._fuseSidebarService.getSidebar('navbar')) {
					this._fuseSidebarService.getSidebar('navbar').close()
				}
			})

		// Get current navigation
		this._fuseNavigationService.onNavigationChanged
			.pipe(filter((value) => value !== null))
			.subscribe(() => {
				this.navigation = this._fuseNavigationService.getCurrentNavigation()
			})
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		if (this.fusePerfectScrollbarUpdateTimeout) {
			clearTimeout(this.fusePerfectScrollbarUpdateTimeout)
		}

		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next()
		this._unsubscribeAll.complete()
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void {
		this._fuseSidebarService.getSidebar('navbar').toggleOpen()
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void {
		console.log(this._fuseSidebarService.getSidebar('navbar').unfolded)
		this._fuseSidebarService.getSidebar('navbar').toggleFold()
	}

	isFolded(): Boolean {
		return this._fuseSidebarService.getSidebar('navbar').folded
	}

	get dependency(): String {
		return this.authService.isCoordination()
			? this.authService.coordinationName
			: this.authService.isAdmin()
			? 'Administrador'
			: localStorage.getItem('dependency')
	}
}
