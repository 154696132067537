import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { fuseAnimations } from '@fuse/animations'
import { PortfoliosService } from '../../portfolios/portfolios.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { UserInfoComponent } from '../../dialogs/user-info/user-info.component'
import {
	MatSnackBar,
	MatDialogRef,
	MatDialog,
	MatSidenav
} from '@angular/material'
import { DialogAddProjectComponent } from '../../projects/dialog-add-project/dialog-add-project.component'
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component'
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component'
import { ProjectsService } from '../../projects/projects.service'
import { VisualizerDialogComponent } from 'app/visualizer/dialog/dialog.component'
import { DialogParticipantsComponent } from '../dialog-participants/dialog-participants.component'
import { Project } from 'app/projects/project'
import { Utils } from '../../constants/utils'
import { DialogEditProjectComponent } from 'app/projects/dialog-edit-project/dialog-edit-project.component'
import { ValidateProjectComponent } from '../validate-project/validate-project.component'

@Component({
	selector: 'app-detail-portfolio',
	templateUrl: './detail-portfolio.component.html',
	styleUrls: [
		'../index-portfolios/index-portfolios.component.scss',
		'./detail-portfolio.component.scss'
	],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class DetailPortfolioComponent implements OnInit {
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav
	portfolio: any
	statusColors: any = {
		default: '#d8d8d8'
	}
	dialogProjectForm: any
	confirmProjectFormDialog: MatDialogRef<FuseConfirmDialogComponent>
	activeFilter: String = 'all'
	activeLayout: String = 'cards'
	filterCtrl = new FormControl()
	id_portfolio: any
	dialogPortfolioForm: any
	dataComment: any
	projectId = null

	constructor(
		private portfoliosService: PortfoliosService,
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private auth: AuthService,
		private router: Router,
		private projectsService: ProjectsService,
		private utils: Utils
	) {}

	ngOnInit(): void {
		this.id_portfolio = this.route.snapshot.paramMap.get('id')
		this.getProyects()
	}

	getTasksProgress(project: any): any {
		return this.utils.getNewPorcent('project', project)
	}

	openProjectFormDialog(): void {
		this.dialogProjectForm = this.dialog.open(DialogAddProjectComponent, {
			panelClass: 'mat-dialog-system'
		})

		this.dialogProjectForm.afterClosed().subscribe((response) => {
			if (!response) {
				return false
			}
			this.snackBar.open('Proyecto agregado', 'OK', {
				duration: 3500
			})

			this.getProyects()
		})
	}

	getProyects(): void {
		this.portfoliosService
			.getDetailDashboard(this.id_portfolio)
			.subscribe((data) => {
				if (Object.entries(data).length === 0 && data.constructor === Object) {
					this.router.navigate(['/portafolios'])
				}
				this.portfolio = data
			})
	}

	setLayout(type: String): void {
		this.activeLayout = type
	}

	isLayoutActive(type: String): Boolean {
		return this.activeLayout === type ? true : false
	}

	hasMultiProjects(portfolio: any): Boolean {
		return portfolio.projects.length > 1 ? true : false
	}

	qtyProjects(portfolio: any): Number {
		return portfolio.projects.length
	}

	getDependencies(portfolio): any {
		if (typeof portfolio.projects === 'undefined') {
			return []
		}

		const dependencies = portfolio.projects.reduce((data, dependency) => {
			data.push(dependency)
			return data
		}, [])

		return dependencies
	}

	get entryFiltered(): String {
		return this.activeFilter === 'all'
			? 'resultados'
			: this.activeFilter === 'portfolio'
			? 'portfolios'
			: 'proyectos'
	}

	showNoMatch(): Boolean {
		return false
	}

	getLinkDependencyProject(project): String {
		return '/proyectos/' + project.id
	}

	hasAvatar(user: any): boolean {
		if (!user) {
			return false
		}

		if (typeof user.avatar === 'undefined') {
			return false
		}
		return user.avatar.length <= 0 ? false : true
	}

	getImgAvatar(user: any): string {
		return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none'
	}

	getUserFullName(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	hasPermissionTags(): Boolean {
		return (
			this.auth.jwt.rol === 'admin' || this.auth.jwt.module === 'communication'
		)
	}

	hasModuleAllowed(module): boolean {
		return this.auth.hasPermission(module)
	}

	reportRoute(project: any): any {
		return ['/reporte/portafolio', project.portfolio_id, 'proyecto', project.id]
	}

	hasCoordination(project: any): any {
		if (!project.dependency) {
			return
		}

		if (project.dependency.coordination_id) {
			return true
		}
		return false
	}

	getDependencyId(): number {
		return this.auth.dependencyId
	}

	isAdmin(): boolean {
		return this.auth.isAdmin()
	}

	openProjectDeleteDialog(project: any): void {
		const dialog = this.dialog.open(ConfirmComponent, {
			panelClass: 'dialog-confirm',
			data: {
				content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar el Proyecto <strong><br>"${this.portfolio.name}"</strong>?<label>`
			}
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.projectsService.delete(project.id).subscribe(
					() => {
						this.snackBar.open('Proyecto Eliminado', 'OK', {
							duration: 3500
						})
						this.getProyects()
					},
					() => {
						this.snackBar.open('Error al eliminar el proyecto', 'ERROR', {
							duration: 3000
						})
					}
				)
			}
		})
	}

	public openVisualizers(project: any): void {
		this.dialog.open(VisualizerDialogComponent, {
			panelClass: 'mat-dialog-system',
			data: {
				id: project.id,
				name: project.name,
				dependency: project.dependency ? project.dependency.name : ''
			}
		})
	}

	public openParticipants(project: any): void {
		const dialog = this.dialog.open(DialogParticipantsComponent, {
			panelClass: 'mat-dialog-system-md',
			width: '90%',
			height: '65%',
			disableClose: true,
			data: project
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.snackBar.open('Participantes agregados', 'OK', {
					duration: 3500
				})
			}
		})
	}

	toggle(project: Project): void {
		this.dataComment = {
			name: `Proyecto: ${this.portfolio.name}`,
			level_name: 'project',
			element_id: project.id,
			icon: 'bookmark_border',
			dependency_name: project.dependency.name
		}
		this.projectId = project.id
		this.sidenav.toggle()
	}

	openUserInfoDialog(project: any): void {
		this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: project.user,
				up: project.dependency
			}
		})
	}

	getLinkGanttProject(project): String {
		return '/diagrama-gantt/' + project.id
	}

	editProjectDialog(project): void {
		project.portfolio = { ...this.portfolio }
		project.portfolio.projects = null
		this.projectsService
			.membersPerDependency(project.dependency_id)
			.subscribe((members: any) => {
				this.dialog
					.open(DialogAddProjectComponent, {
						width: '800px',
						data: {
							project_id: project.id
							// members: members.filter(member => member.up_id === project.dependency.id)
						}
					})
					.afterClosed()
					.subscribe((response) => {
						if (response) this.getProyects()
					})
			})
	}

	validateProject(portfolio, project) {
		this.dialogPortfolioForm = this.dialog.open(ValidateProjectComponent, {
			panelClass: 'mat-dialog-system-md',
			data: {
				portfolio: {
					id: portfolio.id,
					name: portfolio.name,
					multiyear_budget: portfolio.multiyear_budget,
					promise: portfolio.promise,
					description: portfolio.description,
					objective: portfolio.objective,
					project_id: project.id
				}
			}
		})

		this.dialogPortfolioForm.afterClosed().subscribe((response) => {
			if (response !== undefined) {
				let projectTmp = this.portfolio.projects.find((p) => p.id == project.id)
				projectTmp.is_validated = response.is_validated
				projectTmp.responsible_validated_id = response.responsible_validated_id
				projectTmp.responsible_validated = response.responsible_validated
				projectTmp.date_validated = response.date_validated
			}
		})
	}
}
