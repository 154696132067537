import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class GanttService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'gantt';
    this.httpOptions = global.httpOptions;
  }

  get(type_name: String, level_id: Number): Observable<any> {
    return this.http.get(this.uri + this.module + '/' + type_name + '/' + level_id, this.httpOptions);
  }

  getProject(idProject: number): Observable<any> {
    return this.http.get(this.uri + 'projects/' + idProject, this.global.httpOptions);
  }
}
