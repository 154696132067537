import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComunicationRoutingModule } from './comunication.routing.module';
import { ComunicationListComponent } from './components/comunication-list/comunication-list.component';
import { ComunicationFormComponent } from './components/comunication-form/comunication-form.component';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { 
  MatIconModule, 
  MatCheckboxModule, 
  MatFormFieldModule, 
  MatInputModule,
  MatButtonModule, 
  MatPaginatorModule,
  MatRippleModule, 
  MatSelectModule, 
  MatSortModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatMenuModule
 } from '@angular/material';
import { ComunicationService } from './services/comunication.service';

@NgModule({
  declarations: [
    ComunicationListComponent,
    ComunicationFormComponent
  ],
  imports: [
    CommonModule,
    MatIconModule, 
    MatCheckboxModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatButtonModule, 
    MatPaginatorModule,
    MatRippleModule, 
    MatSelectModule, 
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    ComunicationRoutingModule,
    MatProgressSpinnerModule,
    FuseSharedModule
  ],
  entryComponents: [
    ComunicationFormComponent
  ],
  providers: [
    AuthGuard,
    ComunicationService
  ]
})
export class ComunicationModule { }
