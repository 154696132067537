import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	OnDestroy,
	ViewEncapsulation
} from '@angular/core'
import { DetailProjectsService } from './detail-projects.service'
import { AuthService } from '../../services/auth.service'
import { Project } from '../../projects/project'
import { Location } from '@angular/common'
import { ProjectsService } from '../../projects/projects.service'
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from 'saturn-datepicker'
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from '@angular/material-moment-adapter'
import { FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { MatDialog, MatSnackBar, MatSidenav } from '@angular/material'
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component'
import { VisualizerDialogComponent } from 'app/visualizer/dialog/dialog.component'
import { CommunicationService } from 'app/services/communication.service'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { DialogParticipantsComponent } from 'app/portfolios/dialog-participants/dialog-participants.component'
import { Utils } from '../../constants/utils'
import { DialogLinkCommunicationComponent } from '../dialog-link-communication/dialog-link-communication.component'
import { DialogAddProjectComponent } from '../dialog-add-project/dialog-add-project.component'
import { DialogLinkProjectComponent } from '../dialog-link-project/dialog-link-project.component'

@Component({
	selector: 'app-detail-projects',
	templateUrl: './detail-projects.component.html',
	styleUrls: ['./detail-projects.component.scss'],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
	// encapsulation: ViewEncapsulation.None
})
export class DetailProjectsComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav
	dataComment: any
	debug = {
		project: null,
		stage: null,
		action: null,
		task: null
	}

	debugData = {
		project: null,
		stage: null,
		action: null,
		task: null
	}

	project: Project = new Project()
	stage: any
	isPortfolio: Boolean = false

	roundProgressConfig = {
		radius: 30,
		stroke: 8
	}
	canEdit = false
	showDescription: Boolean = false
	showObjective: Boolean = false
	showWebsite: Boolean = false
	showTerritorialScale: Boolean = false
	members: any[] = []
	editResponsible: Boolean = false
	editDescription: Boolean = false
	editObjective: Boolean = false
	editWebsite: Boolean = false
	editDates: Boolean = false
	minDate = new Date(2018, 11, 6)
	maxDate = new Date(2024, 11, 5)
	date = { begin: new Date(), end: new Date() }
	@ViewChild('user', { static: false }) User: ElementRef
	@ViewChild('description', { static: false }) DescriptionField: ElementRef
	@ViewChild('objective', { static: false }) ObjectiveField: ElementRef
	@ViewChild('dates', { static: false }) DatesField: ElementRef
	maxLengthDescription = 250
	maxLengthObjective = 300
	descriptionCtrl: FormControl
	objectiveCtrl: FormControl
	membersSubscribe: Subscription
	projectSubscribe: Subscription
	membersProject = []
	isCommunication = false
	isLoading: boolean = true

	constructor(
		private detailProjectsService: DetailProjectsService,
		private location: Location,
		private projectsService: ProjectsService,
		private formatDate: DateAdapter<any>,
		private auth: AuthService,
		private router: Router,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private communicationService: CommunicationService,
		private utils: Utils
	) {
		this.formatDate.setLocale('es')
		communicationService.changeEmitted$.subscribe((data) => {
			this.dataComment = {
				name: data.name,
				level_name: data.level_name,
				element_id: data.element_id,
				icon: data.icon || ''
			}
			this.sidenav.toggle()
		})
	}

	ngOnInit(): void {
		/*Validar Proyecto Existente*/
		this.isLoading = true
		const id = this.detailProjectsService.current.project.value
		this.projectSubscribe = this.projectsService
			.getNew(id)
			.subscribe((response) => {
				if (!response.body) {
					return
				}

				if (response.body) {
					if (
						Object.entries(response.body).length === 0 &&
						response.body.constructor === Object
					) {
						this.router.navigate(['/portafolios'])
					}
				}
			})
		this.projectSubscribe = this.detailProjectsService.project.subscribe(
			(data) => {
				if (typeof data.termn_begin === 'string') {
					const dateB = data.termn_begin.split('/')
					const dateE = data.termn_end.split('/')

					this.date = {
						begin: new Date(dateB[2], parseInt(dateB[1], 0) - 1, dateB[0]),
						end: new Date(dateE[2], parseInt(dateE[1], 0) - 1, dateE[0])
					}
				} else {
					data.termn_begin = ''
					data.termn_end = ''
				}

				this.project = data

				if (
					this.project &&
					this.project.portfolio &&
					this.project.portfolio.projects
				) {
					this.isPortfolio = this.project.portfolio.projects.length > 1
				}

				if (typeof data.dependency_id !== 'undefined') {
					this.membersSubscribe = this.projectsService
						.membersPerDependency(data.dependency_id)
						.subscribe((response: any) => {
							this.members = response
							this.membersProject = this.members.filter(
								(member) => member.up_id === data.dependency.id
							)
						})
				}

				this.descriptionCtrl = new FormControl(
					(this.project as any).description || ''
				)
				this.objectiveCtrl = new FormControl(
					(this.project as any).objective || ''
				)
			}
		)
		this.detailProjectsService.stage.subscribe((data) => (this.stage = data))

		this.detailProjectsService.current.project.subscribe(
			(data: any) => (this.debug.project = data)
		)
		this.detailProjectsService.current.stage.subscribe(
			(data: any) => (this.debug.stage = data)
		)
		this.detailProjectsService.current.action.subscribe(
			(data: any) => (this.debug.action = data)
		)
		this.detailProjectsService.current.task.subscribe(
			(data: any) => (this.debug.task = data)
		)

		this.canEdit = this.auth.hasPermission('portfolio')
		this.isCommunication = this.auth.isCommunication()

		this.detailProjectsService.loading.project.subscribe((data) => {
			if (!data) this.isLoading = data
		})
	}

	getProject(id: number): void {
		this.projectsService.getNew(id).subscribe((response) => {
			this.project = response.body
		})
	}

	ngOnDestroy(): void {
		if (this.membersSubscribe) {
			this.membersSubscribe.unsubscribe()
		}
		this.projectSubscribe.unsubscribe()
	}

	getStageName(stage: any): string {
		if (!stage.name) {
			return ''
		}

		return stage.name.toLowerCase().indexOf('etapa ') === 0
			? stage.name.substring(6, stage.name.length)
			: stage.name
	}

	statusSidebar(): string {
		return this.detailProjectsService.statusSidebar()
	}

	getStagesProgress(): any {
		return this.utils.getNewPorcent('project', this.project)
	}

	getRadiusProgress(): Number {
		return this.roundProgressConfig.radius
	}

	getStrokeProgress(): Number {
		return this.roundProgressConfig.stroke
	}

	toggleDescription(): void {
		this.showDescription = !this.showDescription
	}

	toggleObjective(): void {
		this.showObjective = !this.showObjective
	}

	toggleWebsite(): void {
		this.showWebsite = !this.showWebsite
	}

	toggleTerritorialScale(): void {
		this.showTerritorialScale = !this.showTerritorialScale
	}

	getInitialsName(): string {
		if (!this.project.user) {
			return ''
		}

		return this.project.user.initials
	}

	getPartialName(): string {
		if (typeof this.project.user !== 'undefined') {
			const name = this.project.user.name + ' ' + this.project.user.last_name
			return name.replace(/(([^\s]+\s\s*){2})(.*)/, '$1…')
		}
		return ''
	}

	getResponsible(): string {
		if (typeof this.project.user === 'undefined') {
			return ''
		}
		return this.project.user.name
			? `${this.project.user.name} ${this.project.user.last_name}`
			: ''
	}

	hasAvatar(user: any): boolean {
		if (!user) {
			return false
		}

		if (typeof user.avatar === 'undefined') {
			return false
		}
		return user.avatar.length <= 0 ? false : true
	}

	getImgAvatar(user: any): string {
		return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none'
	}

	getUserFullName(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	back(): void {
		this.location.back()
	}

	changeUser(event: any): void {
		this.projectsService
			.updateModel(this.project.id, { responsible_user: event.value })
			.subscribe((response: any) => {
				this.detailProjectsService.refreshProject()
				this.editResponsible = false
			})
	}

	editProjectDialog(): void {
		this.dialog
			.open(DialogAddProjectComponent, {
				width: '800px',
				data: {
					project_id: this.project.id
					// members: this.membersProject,
				}
			})
			.afterClosed()
			.subscribe((response) => {
				this.getProject(this.detailProjectsService.current.project.value)
			})
	}

	editingObjective(): void {
		if (this.canEdit) {
			if (!this.showObjective) {
				this.toggleObjective()
			}
			this.editObjective = true
			setTimeout(() => {
				this.ObjectiveField.nativeElement.focus()
				this.ObjectiveField.nativeElement.select()
			}, 100)
		}
	}

	goPortfolios(): string {
		return this.isPortfolio
			? '/portafolios/' + this.project.portfolio_id
			: '/portfolios'
	}

	reportRoute(): any {
		if (this.project) {
			return [
				'/reporte/portafolio',
				this.project.portfolio_id,
				'proyecto',
				this.project.id
			]
		}
		return ''
	}

	getDependencyId(): number {
		return this.auth.dependencyId
	}

	getLinkGanttProject(project): String {
		if (!project) {
			return ''
		}

		return '/diagrama-gantt/' + project.id
	}

	openProjectDeleteDialog(): void {
		const portfolioId = this.project.portfolio_id
		const dialog = this.dialog.open(ConfirmComponent, {
			panelClass: 'dialog-confirm',
			data: {
				content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar el Proyecto <strong><br>"${this.project.name}"</strong>?<label>`
			}
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.projectsService.delete(this.project.id).subscribe(
					() => {
						this.snackBar.open('Proyecto Eliminado', 'OK', {
							duration: 3500
						})
						this.router.navigate([`/portafolios/${portfolioId}`])
					},
					() => {
						this.snackBar.open('Error al eliminar el proyecto', 'ERROR', {
							duration: 3000
						})
					}
				)
			}
		})
	}

	public openVisualizers(project: any): void {
		this.dialog.open(VisualizerDialogComponent, {
			panelClass: 'mat-dialog-system',
			data: {
				id: project.id,
				name: project.name,
				dependency: project.dependency ? project.dependency.name : ''
			}
		})
	}

	toggle(project: Project): void {
		this.dataComment = {
			name: `Proyecto: ${project.name}`,
			level_name: 'project',
			element_id: project.id,
			icon: 'bookmark_border'
		}
		this.sidenav.toggle()
	}

	public validateMultiyearBudget(multiyearBudget: any): boolean {
		return parseInt(multiyearBudget, 0) > 0
	}

	openUserInfoDialog(project: any): void {
		const dialog = this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: project.responsible,
				up: project.dependency
			}
		})
	}

	public openParticipants(project: any): void {
		const dialog = this.dialog.open(DialogParticipantsComponent, {
			panelClass: 'dialog-add-participant',
			width: '50%',
			height: '65%',
			disableClose: true,
			data: project
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.snackBar.open('Participantes agregados', 'OK', {
					duration: 3500
				})
			}
		})
	}

	public openLinkCommunication(project): void {
		const dialog = this.dialog.open(DialogLinkCommunicationComponent, {
			panelClass: 'dialog-link-communication',
			width: '550px',
			height: '220px',
			disableClose: true,
			data: project
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.getProject(this.detailProjectsService.current.project.value)

				this.snackBar.open('Se guardó correctamente', 'Aceptar', {
					duration: 3500
				})
			}
		})
	}

	public openLinkProject(project): void {
		const dialog = this.dialog.open(DialogLinkProjectComponent, {
			panelClass: 'dialog-link-project',
			width: '500px',
			height: '300px',
			disableClose: true,
			data: project
		})

		dialog.afterClosed().subscribe((response) => {
			if (response) {
				this.getProject(this.detailProjectsService.current.project.value)

				this.snackBar.open('Se guardó correctamente', 'Aceptar', {
					duration: 3500
				})
			}
		})
	}
}
