import { Action } from './action.model';

export class Stage {
    id: number;
    name: string;
    check: Boolean = true;
    indeterminate: Boolean = false;
    projectId: number;
    children: Action[];
    type: number;
    semaphore?: number;
  
    constructor(id: number, name: string, projectId: number, actions: Action[], type: number, semaphore?: number){
      this.id = id;
      this.name = name;
      this.projectId = projectId;
      this.children = actions;
      this.type = type;
      this.semaphore = semaphore;
    }
  }
