import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { IndexTasksComponent } from './index-tasks.component';
import { CdkTableModule } from '@angular/cdk/table';
import {
  MatButtonModule, 
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule, 
  MatGridListModule,
  MatIconModule, 
  MatInputModule, 
  MatListModule,
  MatSelectModule, 
  MatStepperModule,
  MatTabsModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatTooltipModule,
  MatProgressBarModule,
} from '@angular/material';
import { DialogAddTasksComponent } from '../dialog-add-tasks/dialog-add-tasks.component';
import { AuthGuardService as AuthGuard } from '../../auth/auth-guard.service';
import { FocusDirective } from '../dialog-add-tasks/focus.directive';
import { AutoCompleteGroupModule } from '../../general/components/auto-complete-group/auto-complete-group.module';

const routes: Routes = [
  {
    path: 'proyectos/:id/actividades',
    component: IndexTasksComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    RouterModule.forChild(routes),
    MatButtonModule, 
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule, 
    MatGridListModule,
    MatIconModule, 
    MatInputModule, 
    MatListModule,
    MatSelectModule, 
    MatStepperModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    FuseSharedModule,
    MatTableModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTooltipModule,
    AutoCompleteGroupModule
  ],
  declarations: [
    IndexTasksComponent,
    DialogAddTasksComponent,
    FocusDirective
  ],
  entryComponents: [
    DialogAddTasksComponent
  ],
  providers: [AuthGuard]
})
export class IndexTasksModule { }
