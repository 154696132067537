import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { 
  MatIconModule, 
  MatProgressSpinnerModule, 
  MatExpansionModule 
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { AuthGuardService as AuthGuard } from '../../../app/auth/auth-guard.service';

//import { ReportsPortfoliosComponent } from './reports-portfolios.component';

const routes: Routes = [
  {
    //path: 'portafolios/:id/reportes',
    //component: ReportsPortfoliosComponent,
    //canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [/*ReportsPortfoliosComponent*/],
  imports: [
    CommonModule,
    //RouterModule.forChild(routes),
    MatIconModule,
    FuseSharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule
  ]
})
export class ReportsPortfoliosModule { }
