import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatButtonToggleModule,
  MatMenuModule,
  MatTreeModule,
  MatListModule,
  MatExpansionModule,
  MatChipsModule,
  MatAutocompleteModule
} from '@angular/material';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';

import { GeneralComponent } from './general/general.component';
import { ReportsPortfoliosComponent} from '../portfolios/reports-portfolios/reports-portfolios.component';

import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { MunicipalitiesComponent } from './municipalities/municipalities.component';
import { FilterMatTreePipe } from './pipes/filter-mat-tree.pipe';
import { RingChartComponent } from './ring-chart/ring-chart.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { FinancingChartComponent } from './financing-chart/financing-chart.component';

const routes: Routes = [
  {
    path: 'reporte',
    component: GeneralComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte/portafolio',
    component: GeneralComponent,
    /*children: [
      { path: '', component: GeneralComponent },
      { path: ':id', component: GeneralComponent },
      { path: ':id/proyecto', component: GeneralComponent },
      { path: ':id/proyecto/:idp', component: GeneralComponent }
    ],*/
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte/portafolio/:id',
    component: GeneralComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte/portafolio/:id/proyecto/:idp',
    component: GeneralComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte/municipal',
    component: MunicipalitiesComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [GeneralComponent, ReportsPortfoliosComponent, MunicipalitiesComponent, FilterMatTreePipe, RingChartComponent, FinancingChartComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatListModule,
    MatTreeModule,
    FuseSharedModule,
    MatExpansionModule,
    ChartsModule,
    MatChipsModule,
    MatAutocompleteModule,
    RoundProgressModule
  ],
  providers: [AuthGuard],
  exports: [
    RingChartComponent,
    FinancingChartComponent
  ],
})
export class ReportsModule { }
