import { Project } from './project.model';

export class Portfolio {
    id: number;
    name: string;
    description: string;
    objective: string;
    promise: number;
    territorial_scale: string;
    benefited_population: number;
    projects: Project[];
    municipalities: any[];
    regions: any[];

    check: Boolean = true;
    indeterminate: Boolean = false;
    children: Project[];
    type: number;

    constructor(
        id?: number, 
        name?: string, 
        description?: string,
        objective?: string,
        promise?: number,
        territorial_scale?: string,
        benefited_population?: number,
        projects?: Project[],
        municipalities?: any[],
        regions?: any[],
        type?: number 
        ) {
            this.id = id ? id : 0;
            this.name = name ? name : '';
            this.description = description ? description : '';
            this.objective = objective ? objective : '';
            this.promise = promise ? promise : 0;
            this.territorial_scale = territorial_scale ? territorial_scale : '';
            this.benefited_population = benefited_population ? benefited_population : 0;
            this.projects = projects ? projects : [];
            this.municipalities = municipalities ? municipalities : [];
            this.regions = regions ? regions : [];
            this.children = this.projects;
            this.type = type ? type : 0;
        }
}
