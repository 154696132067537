import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectsService } from '../projects.service';

@Component({
  selector: 'app-dialog-link-project',
  templateUrl: './dialog-link-project.component.html',
  styleUrls: ['./dialog-link-project.component.scss']
})
export class DialogLinkProjectComponent implements OnInit {
  project: any;
  projectForm: FormGroup;
  link: FormControl;
  saving = false;

  // SHOW / HIDE PASSWORD INPUT
	togglePasswordInput = false;
  
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogLinkProjectComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectsService: ProjectsService
  ) {
    this.project = data;
  }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
			website: [this.project.website, Validators.required],
			website_user: [this.project.website_user],
			website_password: [this.project.website_password]
		})
  }

  public save(): void {
    
    if (this.projectForm.invalid) {
      return;
    }

    this.projectsService.updateLinkProject(this.project.id, this.projectForm.value).subscribe(response => {
      this.saving = false;
      this.dialogRef.close(true);
    }, error => {
      console.log(error);
    });
  }
}
