import {
	Component,
	OnInit,
	Inject,
	ViewEncapsulation,
	ViewChild
} from '@angular/core'
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatAutocomplete,
	MatSnackBar
} from '@angular/material'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from '@angular/material/core'
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from '@angular/material-moment-adapter'
import { TasksService } from '../tasks.service'
import { SubprojectsService } from '../../subprojects/subprojects.service'
import { forkJoin, Observable } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { DetailProjectsService } from 'app/projects/detail-projects/detail-projects.service'
import { startWith, map } from 'rxjs/operators'
import { Utils } from '../../constants/utils'
import { Task } from 'app/reports/models/task.model'
import { getIconFiles } from '../../constants/iconFiles'

@Component({
	selector: 'app-dialog-add-tasks',
	templateUrl: './dialog-add-tasks.component.html',
	styleUrls: ['./dialog-add-tasks.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class DialogAddTasksComponent implements OnInit {
	public form: FormGroup
	subproject: number
	responsables: any[]
	saving: Boolean = false
	public task: Task = new Task()
	date_assignment: any
	date_estimated_finished: any
	statuses: any
	statusActive: number
	names: any = []
	filesUpload: any = []
	progress: any
	files: Set<File> = new Set()
	project: any
	selectedTab: Number = 0
	links: any[] = []
	linkControl: FormControl = new FormControl()

	minDate: Date
	minDateFinish: Date
	maxDate: Date
	errorDateValid = {
		isError: false,
		errorMessage:
			'La fecha de asignación no puede ser mayor que la fecha de solución'
	}

	@ViewChild('file', { static: false }) file

	public action: any
	public responsableInput: FormControl = new FormControl()
	public responsableActive: any
	public filterResponsable: Observable<any[]>
	public isGroup: Boolean = false
	public responsableGroup: any[] = []
	public userResponsable: Number = 0
	public isDateAssignmentChanged = false
	public isDateEstimatedFinishedChanged = false
	@ViewChild('responsable', { static: false })
	public matAutocompleteResponsable: MatAutocomplete
	public stepConfirm = false
	public userInactive = false
	currentUserRole: any = null

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private adapter: DateAdapter<any>,
		private service: TasksService,
		private subprojectService: SubprojectsService,
		private auth: AuthService,
		private detailProjectsService: DetailProjectsService,
		private utils: Utils,
		private snackBar: MatSnackBar,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.currentUserRole = this.authService.module
		this.subproject = this.data.subproject
		this.statusActive = 0
		this.linkControl.patchValue('')

		this.statuses = [
			{ id: 1, name: 'Nuevo', value: 'created', status: 0 },
			{ id: 1, name: 'Nuevo', value: 'created', status: 1 },
			{ id: 2, name: 'Procesando', value: 'processing', status: 1 },
			{ id: 2, name: 'Procesando', value: 'processing', status: 2 },
			{ id: 3, name: 'Pausado', value: 'paused', status: 2 },
			{ id: 4, name: 'Finalizado', value: 'finished', status: 2 },
			{ id: 5, name: 'Cancelado', value: 'cancelled', status: -1 }
		]

		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			date_assignment: ['', Validators.required],
			date_estimated_finished: ['', Validators.required],
			status: ['created', Validators.required],
			user_id: [null, Validators.required],
			subproject_id: [this.subproject],
			comment: [''],
			filesUpload: [[]],
			links: [[]]
		})

		this.detailProjectsService.action.subscribe((action) => {
			this.action = action
			this.minDate = this.parseDate(action.date_initial)
			this.minDateFinish = this.minDate
			this.maxDate = this.parseDate(action.date_finished)
			this.form.controls['date_assignment'].patchValue(this.minDate)
			this.date_assignment = this.minDate
			if (!!!action.date_finished) {
				this.detailProjectsService.stage.subscribe((stage) => {
					this.minDate = this.parseDate(stage.date_initial)
					this.minDateFinish = this.minDate
					this.maxDate = this.parseDate(stage.date_finished)
					this.form.controls['date_assignment'].patchValue(this.minDate)
					this.date_assignment = this.minDate

					if (!!!stage.date_initial) {
						this.minDate = new Date(2018, 11, 6)
						this.minDateFinish = this.minDate
						this.maxDate = new Date(2024, 11, 5)
						this.date_assignment = this.getTodayDate()
						this.form.controls['date_assignment'].patchValue(
							this.getTodayDate()
						)
					}
				})
			}
		})

		this.detailProjectsService.project.subscribe((data) => {
			this.project = data
			const dependency_id = this.project.dependency_id

			this.subprojectService.membersId(dependency_id).subscribe((data: any) => {
				this.responsables = data

				this.subprojectService
					.usersTransversal(this.project.id)
					.subscribe((transversals) => {
						this.isGroup = transversals.length > 0

						const transFilter = transversals.filter((transversal) => {
							return (
								(!!!transversal.modules ||
									(!!transversal.modules &&
										transversal.modules.module.name !== 'communication')) &&
								!this.responsables.find((resp) => resp.id === transversal.id)
							)
						})

						transFilter.map((elemnt) => {
							this.responsables.push(elemnt)
						})

						this.orderList()

						if (this.isGroup) {
							this.addGroupResponsable()
						}

						if (this.data.update) {
							this.getTask(this.data.task)
						}
					})

				this.filterResponsable = this.responsableInput.valueChanges.pipe(
					startWith(''),
					map((val) => this.utils.filterUsers(val, this.responsables))
				)
			})
		})
	}

	compareTwoDates(): void {
		const d1 = new Date(this.form.controls['date_assignment'].value)
		const d2 = new Date(this.form.controls['date_estimated_finished'].value)
		if (!d1 && !d2) {
			return
		}
		if (d2 < d1) {
			this.errorDateValid.isError = true
		} else {
			this.errorDateValid.isError = false
		}
	}

	save(): void {
		this.compareTwoDates()
		if (this.errorDateValid.isError) {
			return
		}
		this.saving = true
		if (this.data.update) {
			this.service.update(this.form, this.data.task).subscribe((data: any) => {
				if (this.files.size > 0) {
					this.progress = this.service.save(data.id, this.files)
					const allProgressObservable = []

					for (const key in this.progress) {
						if (this.progress.hasOwnProperty(key)) {
							allProgressObservable.push(this.progress[key].progress)
						}
					}

					forkJoin(allProgressObservable).subscribe((end) => {
						this.service.notifyComunication(data.id).subscribe((done) => {
							setTimeout(() => {
								this.saving = false
								this.dialogRef.close(data)
							}, 500)
						})
					})
				} else {
					setTimeout(() => {
						this.saving = false
						this.dialogRef.close(data)
					}, 500)
				}
			})
		} else {
			this.service.store(this.subproject, this.form).subscribe(
				(data: any) => {
					if (this.files.size > 0) {
						this.progress = this.service.save(data.id, this.files)
						const allProgressObservable = []

						for (const key in this.progress) {
							if (this.progress.hasOwnProperty(key)) {
								allProgressObservable.push(this.progress[key].progress)
							}
						}

						forkJoin(allProgressObservable).subscribe((end) => {
							this.service.notifyComunication(data.id).subscribe((done) => {
								setTimeout(() => {
									this.saving = false
									this.dialogRef.close(data)
								}, 500)
							})
						})
					} else {
						setTimeout(() => {
							this.saving = false
							this.dialogRef.close(data)
						}, 500)
					}
					this.detailProjectsService.validateActionDepend(data.task_id)
				},
				(error) => {
					this.saving = false
					console.log(error)
				}
			)
		}
	}

	setDate(event, name: string, type: Number): void {
		this.form.controls[name].setValue(event.value.format('YYYY-MM-DD'))
		if (type === 1) {
			this.minDateFinish = event.value.format('YYYY-MM-DD')
			this.isDateAssignmentChanged = true
		} else {
			this.isDateEstimatedFinishedChanged = true
		}
		this.compareTwoDates()
	}

	openDatepicker(datepicker, field) {
		datepicker.open()
		field.markAsTouched()
	}

	getTask(id: number): void {
		this.service.edit(this.subproject, id).subscribe((data: any) => {
			this.task = data
			this.form.controls['name'].setValue(data.name)
			const date_assignmentSplit = data.date_assignment.split('/')
			const date_estimated_finishedSplit =
				data.date_estimated_finished.split('/')
			this.date_estimated_finished = new Date(
				date_estimated_finishedSplit[2],
				parseInt(date_estimated_finishedSplit[1], 0) - 1,
				date_estimated_finishedSplit[0]
			)

			this.form.controls['date_assignment'].setValue(
				date_assignmentSplit[2] +
					'-' +
					date_assignmentSplit[1] +
					'-' +
					date_assignmentSplit[0]
			)
			this.form.controls['date_estimated_finished'].setValue(
				date_estimated_finishedSplit[2] +
					'-' +
					date_estimated_finishedSplit[1] +
					'-' +
					date_estimated_finishedSplit[0]
			)
			this.date_assignment =
				date_assignmentSplit[2] +
				'-' +
				date_assignmentSplit[1] +
				'-' +
				date_assignmentSplit[0]
			this.date_estimated_finished =
				date_estimated_finishedSplit[2] +
				'-' +
				date_estimated_finishedSplit[1] +
				'-' +
				date_estimated_finishedSplit[0]

			this.form.controls['status'].setValue(data.status)

			if (data.user && data.user.active === 1) {
				this.form.controls['user_id'].setValue(data.user_id)
				this.userResponsable = data.user_id
				this.responsableInput.setValue(
					this.responsables.find((user) => user.id === this.userResponsable)
				)
				// if (this.currentUserRole === 'tasks') {
				// 	this.form.controls.name.disable()
				// 	this.responsableInput.disable()
				// }
			} else {
				this.userInactive = true
				this.responsableInput.markAsTouched()
			}

			if (data.status === 'created') {
				this.statusActive = 1
				this.form.patchValue({
					status: 'created'
				})
			} else if (data.status === 'processing') {
				this.statusActive = 2
				this.form.patchValue({
					status: 'processing'
				})
			} else if (data.status === 'paused') {
				this.statusActive = 2
				this.form.patchValue({
					status: 'paused'
				})
			} else if (data.status === 'finished' && this.project.is_admin) {
				this.statusActive = 2
				this.form.patchValue({
					status: 'finished'
				})
			}
		})
	}

	getTodayDate(): string {
		let today: any = new Date()
		let dd: any = today.getDate()
		let mm: any = today.getMonth() + 1

		const yyyy = today.getFullYear()
		if (dd < 10) {
			dd = '0' + dd
		}
		if (mm < 10) {
			mm = '0' + mm
		}
		today = yyyy + '-' + mm + '-' + dd
		return today
	}

	getTitleTask(): string {
		if (!this.data.update) {
			return 'Guardar Actividad'
		} else {
			return 'Editar Actividad'
		}
	}

	onSelectFile(event: any): void {
		const files: { [key: string]: File } = event.target.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 10))) {
				this.files.add(files[key])
			}
		}
	}

	onFilesAdded(): void {
		const files: { [key: string]: File } = this.file.nativeElement.files
		for (const key in files) {
			if (!isNaN(parseInt(key, 10))) {
				this.files.add(files[key])
			}
		}
	}

	addFiles(): void {
		this.file.nativeElement.click()
	}

	/*autocomplete*/
	public onBlurParticipant(event: any): void {
		if (event.target.value.length === 0) {
			this.responsableInput.patchValue('')
			this.responsableActive = null
		}
	}

	public optionSelectedResponsable(event: any): any {
		if (Number.isInteger(event)) {
			if (event === 0) {
				this.form.get('user_id').patchValue(null)
			} else {
				this.form.get('user_id').patchValue(event)
			}
		} else {
			const id = event.option.value
			this.form.get('user_id').patchValue(id)
		}
	}

	public displayResponsable(item: any): any {
		if (
			!!this.matAutocompleteResponsable &&
			typeof this.matAutocompleteResponsable.options !== 'undefined'
		) {
			return this.matAutocompleteResponsable.options
				.filter((o) => o.value === item)
				.map((o) => o.viewValue)[0]
				? this.matAutocompleteResponsable.options
						.filter((o) => o.value === item)
						.map((o) => o.viewValue)[0]
				: this.nameUser(item)
		}
	}

	private addGroupResponsable(): void {
		this.responsables.map((responsable) => {
			const group = !!responsable.up ? responsable.up.name : 'ADMINISTRADORES'
			this.responsableGroup[group] = this.responsableGroup[group] || {
				type: group,
				data: []
			}
			this.responsableGroup[group].data.push(responsable)
		})

		const list = []

		for (const key in this.responsableGroup) {
			if (typeof this.responsableGroup[key] !== 'undefined') {
				list.push(this.responsableGroup[key])
			}
		}

		this.responsableGroup = list
	}

	private orderList(): void {
		this.responsables.sort((a, b) => {
			if (a.name > b.name) {
				return 1
			} else if (a.last_name < b.last_name) {
				return -1
			} else if (
				!!a.m_last_name &&
				!!b.m_last_name &&
				a.m_last_name < b.m_last_name
			) {
				return -1
			} else {
				return 0
			}
		})
	}

	private nameUser(item: any): string {
		let name = ''
		if (!!!item) {
			return name
		}
		if (!!item.name) {
			name += item.name
		}
		if (!!item.last_name) {
			name += ' ' + item.last_name
		}

		if (!!item.m_last_name) {
			name += ' ' + item.m_last_name
		}

		return name
	}

	// FUNCTION FOR DATES
	private parseDate(date: any): any {
		if (!!date) {
			date = date.split('/')
			date = date[2] + '-' + date[1] + '-' + date[0]
		}

		return date
	}

	/**
	 * get a file icon name
	 * @param file file to get de icon
	 * @returns the icon name
	 */
	getFileIconName(file: File): string {
		let iconFile = 'otros'

		const parts: string[] = file.name.split('.')
		const extension: string = parts[parts.length - 1]
		const iconFiles = getIconFiles()

		Object.keys(iconFiles).forEach((key) => {
			iconFiles[key].forEach((element) => {
				if (extension === element) {
					iconFile = key
				}
			})
		})

		return iconFile
	}

	nextConfirm(): void {
		// if (this.form.get('status').value !== 'finished' || this.files.size > 0) {
		// debugger;
		if (this.form.valid) {
			this.stepConfirm = true
		} else {
			for (const item in this.form.controls) {
				if (item) {
					this.form.controls[item].markAsTouched()
				}
			}
			this.responsableInput.markAsTouched()
		}
		// } else {
		// 	const duration = 2500
		// 	const errorMessageFiles =
		// 		'Es necesario que subas al menos un archivo para poder finalizar tu Actividad'
		// 	this.selectedTab = 1
		// 	this.snackBar.open(errorMessageFiles, 'Ok', {
		// 		duration: duration
		// 	})
		// }
	}

	backForm(): void {
		this.stepConfirm = false
	}

	/**
	 * get a fullname of the user
	 * @param userId
	 */
	getUserById(userId: number): string {
		const userFinded = this.responsables.find((x) => x.id === userId)
		let fullname = ''
		if (userFinded !== undefined && userFinded !== null) {
			fullname = `${userFinded.name} ${userFinded.last_name}  `
			if (userFinded.m_last_name !== null) {
				fullname += userFinded.m_last_name
			}
		}
		return fullname
	}

	/**
	 * remove of the list a file
	 */
	removeTempFile(file: File): void {
		this.files.delete(file)
	}

	getStatusNameByValue(statusValue: string): string {
		let statusName = ''

		const status = this.statuses.find((x) => x.value === statusValue)
		if (status !== undefined && status !== null) {
			statusName = status.name
		}
		//debugger;
		return statusName
	}

	refreshSelectedTab(tabChanged: Number): void {
		this.selectedTab = tabChanged
	}

	addLink(): void {
		let linkText = this.linkControl.value.trim()
		if (this.linkControl.valid && linkText.length > 0) {
			if (linkText.indexOf('http') == -1) linkText = 'https://' + linkText
			this.links.push(linkText)
			this.linkControl.patchValue('')
			this.form.patchValue({
				links: this.links
			})
		}
	}

	removeLink(index): void {
		this.links.splice(index, 1)
		this.form.patchValue({
			links: this.links
		})
	}
}
