import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvanceBoardService {
  private uri: string;
  private module: string;
  private httOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'users/advance-board';
    this.httOptions = global.httpOptions;
   }

  public usersWithoutPermissions(): Observable<any> {
    return this.http.get(this.uri + this.module , this.httOptions);
  }

  public usersWithPermissions(): Observable<any> {
    return this.http.get(this.uri + this.module + '/permissions', this.httOptions);
  }

  public addPermissions(user: any): Observable<any> {
    return this.http.post(this.uri + this.module + '/permissions', user, this.httOptions);
  }

  public deletePermissions(user: any): Observable<any> {
    return this.http.put(this.uri + this.module + '/permissions', user, this.httOptions);
  }
  
}
