import {
	GeoSearchControl,
	GoogleProvider,
	OpenStreetMapProvider,
	EsriProvider
} from 'leaflet-geosearch';

import * as leaflet from 'leaflet';

export class MapOptions {
    private drawnItemsC: any;

    set drawnItems(drawnItems) { this.drawnItemsC = drawnItems; }
    get pointInitial(): number[] { return [20.6737777, -103.4054542]; }
    get zoomInitial(): number { return 13; }
    get templateLayer(): string { return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'; }
    get iconUrl(): string { return 'assets/images/pin-jalisco.png'; }
    get iconAnchor(): number[] { return [22, 54]; }
    get iconSize(): number[] { return [43, 56]; }
    get optionDraw(): any { 
        return {
            position: 'topright',
            draw: {
              polyline: {
                shapeOptions: {
                  color: '#ff0000',
                  weight: 10
                }
              },
              polygon: {
                allowIntersection: false,
                drawError: {
                  color: '#e1e100',
                  message: '<strong>Error!</strong>'
                },
                shapeOptions: {
                  color: '#ff0000'
                }
              },
              circle: {
                shapeOptions: {
                    color: '#ff0000',
                    clickable: false
                }
              },
              circlemarker: false,
              rectangle: false,
              marker: {
                icon: new this.markerIconC()
              }
            },
            /*edit: {
              featureGroup: this.drawnItemsC,
              remove: true
            }*/
          }; 
    }

    get optionDrawsOff(): any { return {
        position: 'topright',
        draw: false,
        edit: {
          featureGroup: this.drawnItemsC,
          remove: true
        }
      }; 
    }

    get markerIconC(): any { 
        return leaflet.Icon.extend({
        options: {
          shadownUrl: null,
          iconAnchor: this.iconAnchor,
          iconSize: this.iconSize,
          iconUrl: this.iconUrl
        }
      });
    }


    /**
     * Search
     */
    /*const provider = new GoogleProvider({
        params: {
        key: ''
        }
    });*/
    /*const provider = new OpenStreetMapProvider({
        params: {country: 'Mexico'}
    });*/
    private provider = new EsriProvider();

    get searchControl(): GeoSearchControl { 
        return new GeoSearchControl({
            provider: this.provider,
            showMarker: true,                                   // optional: true|false  - default true
            showPopup: false,                                   // optional: true|false  - default false
            marker: {                                           // optional: L.Marker    - default L.Icon.Default
            icon: leaflet.icon({
                iconUrl: this.iconUrl,
                iconSize: this.iconSize
            }),
            draggable: false,
            },
            popupFormat: ({ query, result }) => result.label,   // optional: function    - default returns result label
            maxMarkers: 1,                                      // optional: number      - default 1
            retainZoomLevel: false,                             // optional: true|false  - default false
            animateZoom: true,                                  // optional: true|false  - default true
            autoClose: true,                                   // optional: true|false  - default false
            searchLabel: 'Ingrese Dirección',                       // optional: string      - default 'Enter address'
            keepResult: false 
        });
    }

    public draw(type, point, title, map, drawnControlsOff, drawnControl, radius, reset?: boolean, draggableP?: boolean, view?: boolean): any {
        const resetAll = reset ? reset : false;
        const draggable = typeof draggableP !== 'undefined' ? draggableP : true;
        const onlyView: boolean = typeof view !== 'undefined' ? view : false;

        if (resetAll) {
            for (const key in map._targets) {
                if (typeof map._targets[key]._containerId === 'undefined'){
                    map.removeLayer(map._targets[key]);
                }
            }
        }

        if (!radius && type === 'Point') {
            type = 'multipoint';
            point = [point];
        }

        switch (type.toLowerCase()) {
            case 'point':
                const pointElem = leaflet.circle(point.reverse(), radius, {
                    color: 'red',
                    alt: 'circle',
                    interactive: draggable
                }).bindPopup(`<input type="button" value="${draggable ? 'Click para eliminar' : ''}" id="delete-circle" class="marker-delete-button"/>`)
                    .on('popupopen', (e) => {
                        document.getElementById('delete-circle').addEventListener('click', () => {
                            if (draggable) {
                                map.removeLayer(e.target);
                            }
                            this.validateOptionsDraw(map);                        
                            this.drawControl(map, drawnControl, drawnControlsOff);
                        });
                    });
                pointElem['type'] = 'circle';
                pointElem.addTo(map);
                break;
            case 'multipoint':
                point.forEach((po) => {
                    const pointElem: any = leaflet.marker(po.reverse(), {
                        icon: leaflet.icon({
                            iconUrl: this.iconUrl,
                            iconSize: this.iconSize,
                            iconAnchor: this.iconAnchor
                        }),
                        title: title,
                        alt: type,
                        draggable: draggable
                    });
                    pointElem['type'] = 'marker';
                    if (onlyView === false) {
                        pointElem.bindPopup(`<input type="button" value="${draggable ? 'Click para eliminar' : ''}" id="delete-marker" class="marker-delete-button"/>`)
                                .on('popupopen', (e) => {
                                    document.getElementById('delete-marker').addEventListener('click', () => {
                                        if (draggable) {
                                            map.removeLayer(e.target);
                                        }
                                        this.validateOptionsDraw(map);                         
                                        this.drawControl(map, drawnControl, drawnControlsOff);
                                    });
                                });
                    }                    
                    pointElem.addTo(map);
                });

                break;
            case 'polygon':
                const poligon: any = leaflet.polygon(point[0], 
                    {
                        color: this.getRandomColor(),
                        alt: 'polygon',
                        interactive: draggable
                    });
                if (onlyView === false) {
                    poligon.bindPopup(`<input type="button" value="${draggable ? 'Click para eliminar' : ''}" id="delete-polygone" class="marker-delete-button"/>`)
                            .on('click', (e) => {
                                document.getElementById('delete-polygone').addEventListener('click', () => {
                                    if (draggable) {
                                        map.removeLayer(e.target);
                                    }
                                    this.validateOptionsDraw(map);                                
                                    this.drawControl(map, drawnControl, drawnControlsOff);
                                });
                            });
                }
                poligon['type'] = 'polygon';
                poligon.addTo(map);
                map.fitBounds(poligon.getBounds());
                if (draggable) {
                    poligon.editing.enable();
                }
                break;
            case 'linestring':
                const latlngs = [];
                point.forEach(element => {
                    latlngs.push(element.reverse());
                });

                const polyline: any = leaflet.polyline(latlngs,
                    {
                        color: this.getRandomColor(),
                        alt: 'polyline',
                        interactive: true
                    });
                if (onlyView === false) {
                    polyline.bindPopup(`<input type"button" value="${draggable ? 'Click para eliminar' : ''}" id="delete-polyline" class="market-delete-button"/>`)
                    .on('click', (e) => {
                        document.getElementById('delete-polyline').addEventListener('click', () => {
                            if (draggable) {
                                map.removeLayer(e.target);
                            }
                            this.validateOptionsDraw(map);                                
                            this.drawControl(map, drawnControl, drawnControlsOff);
                        });
                    });
                }
                polyline['type'] = 'polyline';
                polyline.addTo(map);
                map.fitBounds(polyline.getBounds());
                if (draggable) {
                    polyline.editing.enable();
                }
                break;            
            default:
                break;
        }
    }

    public drawControl(map, drawnControl, drawnControlsOff): void{

        let countLayers = 0;
        for (const key in map._targets) {
            if (typeof map._targets[key]._containerId === 'undefined'){
                countLayers++;
            }
        }

        const controlDraw: any = document.getElementsByClassName('leaflet-draw-toolbar-top');

        if (countLayers === 0) {
            controlDraw[0].style.display = 'block';
        }
    }

    public drawNoEdit(type, point, title, map, drawnControlsOff, drawnControl, radius, reset?: boolean, draggableP?: boolean): any {

		const resetAll = reset ? reset : false;
		const draggable = false;

		if (resetAll) {
			for (const key in map._targets) {
				if (typeof map._targets[key]._containerId === 'undefined') {
					map.removeLayer(map._targets[key]);
				}
			}
		}

		if (radius !== null) {
			leaflet.circle(point.reverse(), radius, {
				color: 'red',
				alt: 'circle',
				interactive: draggable,
				draggable: draggable
			}).addTo(map);
		} else {
			switch (type.toLowerCase()) {
				case 'point':
					leaflet.marker(point.reverse(), {
						icon: leaflet.icon({
							iconUrl: this.iconUrl,
							iconSize: this.iconSize,
							iconAnchor: this.iconAnchor
						}),
						title: title,
						alt: type,
						draggable: draggable,
					}).addTo(map);
					break;
				case 'polygon':
					const poligon = leaflet.polygon(point[0],
						{
							color: 'red',
							alt: 'polygon',
							interactive: draggable,
							draggable: draggable
						}).addTo(map);
					map.fitBounds(poligon.getBounds());
					if (draggable) {
						poligon.editing.enable();
					} else {
						poligon.editing.disable();
						poligon.setStyle({
							color: '#b34d3d',
							weight: 7,
							fillOpacity: .7
						});
					}
					break;
				case 'linestring':
					const latlngs = [];
					point.forEach(element => {
						latlngs.push(element.reverse());
					});

					const polyline = leaflet.polyline(latlngs,
						{
							color: 'red',
							alt: 'polyline',
							interactive: draggable,
							draggable: draggable
						}).addTo(map);
					map.fitBounds(polyline.getBounds());
					if (draggable) {
						polyline.editing.enable();
					} else {
						polyline.editing.disable();
						polyline.setStyle({
							color: '#b34d3d',
							weight: 7,
							fillOpacity: .7
						});
					}
					break;

				default:
					break;
			}
		}


		let countLayers = 0;
		for (const key in map._targets) {
			if (typeof map._targets[key]._containerId === 'undefined') {
				countLayers++;
			}
		}

		if (countLayers === 0) {
			const controlDraw: any = document.getElementsByClassName('leaflet-draw-toolbar-top');
			if (controlDraw.length > 0) {
				controlDraw[0].style.display = 'block';
			}
		}
    }
    
    public validateOptionsDraw(map, message?): any {
        const targets: any = { polyline: 0, polygon: 0, circle: 0, marker: 0 };
        const controlDraw: any = document.getElementsByClassName('leaflet-draw-toolbar-top');
        let totalLayers = 0;

        for (const key in map._targets) {              
            if (typeof map._targets[key]._containerId === 'undefined' && map._targets[key].type !== undefined) {
                if (map._targets[key] !== 'undefined') {
                    targets[map._targets[key].type]++;
                    totalLayers++;
                }
            }
        }

        if (totalLayers >= 50) {
            controlDraw[0].style.display = 'none';
            if (message) {
                return 'message';
            }
        } else {
            controlDraw[0].style.display = 'block';
            Object.keys(targets).forEach((name, index) => {
                if (targets[name] >= 30) {
                    controlDraw[0].children[index].style.display = 'none';                
                } else {
                    controlDraw[0].children[index].style.display = 'block';
                }
            });
            if (message) {
                return null;
            }
        }   
    }

    public getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
}
