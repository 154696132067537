import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-project-dashboard',
	templateUrl: './project-dashboard.component.html',
	styleUrls: ['./project-dashboard.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class ProjectDashboardComponent implements OnInit {
	portfolio: any
	id_portfolio: any
	type = 'project'
	mini2 = true
	size = '100px'
	style = { width: this.size, height: this.size, fontSize: '22px' }
	data: any = [
		{ name: '', data: [] },
		{ name: '', data: [] }
	]
	coordinacionId = null
	directorateId = null

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this.directorateId = this.route.snapshot.paramMap.get('directorateId')
		this.id_portfolio = this.route.snapshot.paramMap.get('portfolioId')
		this.dashboardService
			.getDetailDashboard(this.id_portfolio)
			.subscribe((data) => {
				this.portfolio = data
			})
	}

	getRouterLink(project: any): any {
		return [
			'/dashboard',
			'coordinaciones',
			this.coordinacionId,
			'direcciones',
			this.directorateId,
			'portafolio',
			this.id_portfolio,
			'proyecto',
			project.id
		]
	}
}
