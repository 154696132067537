import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {Globals} from '../globals';

@Injectable({
  providedIn: 'root'
})
export class SubprojectsService {
  private uri: string;
  private module: string;
  private httpOptions: any;
  private httpOptionsAsyc: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'subprojects';
    this.httpOptions = global.httpOptions;
    this.httpOptionsAsyc = global.httpOptionsAsync;
   }

  getDirectories(id: Number): Observable<any> {
    return this.http.get(this.uri + 'files?path=documents/' + id, this.httpOptions);
  }

  getFileImpact(id: Number): Observable<any> {
    return this.http.get(this.uri + 'files?path=documents/indicator/impact/' + id, this.httpOptions);
  }

  getFileResult(id: Number): Observable<any> {
    return this.http.get(this.uri + 'files?path=documents/indicator/result/' + id, this.httpOptions);
  }

  getFileMix1(id: Number): Observable<any> {
    return this.http.get(this.uri + 'files?path=documents/indicator/mix1/' + id, this.httpOptions);
  }

  getFileMix2(id: Number): Observable<any> {
    return this.http.get(this.uri + 'files?path=documents/indicator/mix2/' + id, this.httpOptions);
  }

  getFilesAll(id: Number): Observable<any> {
    return this.http.get(this.uri + this.module + '/files-all/' + id, this.httpOptions);
  }

  getAll(id: Number): Observable<any> {        
    return this.http.get(this.uri + 'stages/' + id + '/' + this.module, this.httpOptions);
  }

  get(id: number): Observable<any> {
    return this.http.get(this.uri + this.module + '/' + id, this.httpOptions);
  }

  init(): Observable<any> {        
    return this.http.get(this.uri + this.module + '/init', this.httpOptions);
  }

  ups(year): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', year.toString());
    return this.http.get(this.uri + 'up/ueg/splits', { params: params});
  }

  initAction(projectId: any = null): Observable<any> 
  {
    if (projectId !== null){
      return this.http.get(this.uri + this.module + '/initAction/' + projectId, this.httpOptions);
    } else {
      return this.http.get(this.uri + this.module + '/initAction', this.httpOptions);
    }
  }

  members(): Observable<any> {        
    return this.http.get(this.uri + this.module + '/members', this.httpOptions);
  }

  membersId(dependency_id: Number): Observable<any> {        
    return this.http.get(this.uri + this.module + '/members/' + dependency_id, this.httpOptions);
  }

  public usersTransversal(projectId: number): Observable<any> {
    return this.http.get(this.uri + this.module + '/usersTransversal/' + projectId, this.httpOptions);
  }

  membersPerRol(rolId): Observable<any> {
    return this.http.get(this.uri + 'members/rol/' + rolId, this.httpOptions);
  }

  store(form: FormGroup): Observable<any> {
    return this.http.post(this.uri + this.module, form.value, this.httpOptions);    
  }

  edit(id): Observable<any> {    
    return this.http.get(this.uri + this.module + '/' + id, this.httpOptions);
  }
  
  update(id, form: FormGroup): Observable<any> {
    return this.http.put(this.uri + this.module + '/' + id, form.value, this.httpOptions);    
  }

  delete(id): Observable<any> {
    return this.http.delete(this.uri + this.module + '/' + id, this.httpOptions);    
  }

  dependencies(): Observable<any> 
  {
    return this.http.get(this.uri + this.module + '/dependencies', this.httpOptions);
  }

  fundingSourcesand(): Observable<any>
  {
    return this.http.get(this.uri + this.module + '/fundingSourcesand', this.httpOptions);
  }

  save(id: any, files: Set<File>, typeIndicator: any): { [key: string]: Observable<number> } 
  {
    const status = {};
    let typeIndicatorSend = '';

    if (typeIndicator !== null){
      typeIndicatorSend = '/' + typeIndicator;
    }
    
    files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('actionId', id);
      formData.append('file', file);

      const req = new HttpRequest('POST', this.uri + this.module + '/document' + typeIndicatorSend, formData, this.httpOptionsAsyc);

      const progress = new Subject<number>();

      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          const percentDone = Math.round((100 * event.loaded) / event.total);

          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      }, (error: any) => {
        progress.complete();
      });

      status[file.name] = {
        progress: progress.asObservable()
      };

    });

    return status;
  }

  getSplit(id: number): Observable<any> {
    return this.http.get(this.uri + `up/ueg/split/${id}`, this.httpOptions);
  }

  measurementUnit(name: string): Observable<any> {
    return this.http.post(this.uri + 'measurementUnit', {name: name}, this.httpOptions);
  }

  dataShow(): Observable<any> {
    return this.http.get(this.uri + this.module, this.httpOptions);
  }

  getActionFile(id: Number, imagesProgress: any): Observable<any> {
    return this.http.post(this.uri + this.module + '/' + id + '/downloadaction', {
      'id': id,
      'imagesProgress': imagesProgress,
    }, { responseType: 'blob' });
  }

  getPartidasYears(): Observable<any> {
    return this.http.get(this.uri + 'partidas_presupuestales/years', this.httpOptions);
  }

}
