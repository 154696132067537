import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { Project } from '../models/project.model';
import { Portfolio } from '../models/portfolio.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatAutocompleteSelectedEvent } from '@angular/material';
import { ReportsService } from '../reports.service';
import { Stage } from '../models/stage.model';
import { Action } from '../models/action.model';
import { Task } from '../models/task.model';
import { Utils } from 'app/constants/utils';
import { MapOptions } from 'app/subprojects/dialog-add-subproject/mapOptions';
import * as leaflet from 'leaflet';
import * as translate from 'leaflet-draw-locales';
import * as leafletImage from 'leaflet-image';
import { FormControl, Validators, FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { Dependency } from 'app/dependencies/dependency';
import { GeoService } from 'app/services/geo.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import html2canvas from 'html2canvas';
import canvg from 'canvg';
import { saveAs } from 'file-saver';

interface ObjectNode {
  name: string;
  children?: ObjectNode[];
  type?: number;
}

@Component({
  selector: 'app-municipalities',
  templateUrl: './municipalities.component.html',
  styleUrls: ['./municipalities.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class MunicipalitiesComponent implements OnInit {
  public groupMunicipalities: any[] = [];
  public municiplities: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public populationBenefiated: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public fundingSources: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public fundingSourceData = [
    {
      source: 'Estatal', total: 0, color: '#00953e'
    },
    {
      source: 'Federal', total: 0, color: '#006f42'
    },
    {
      source: 'Internacional', total: 0, color: '#96c11e'
    },
    {
      source: 'Municipal', total: 0, color: '#a7bac9'
    },
    {
      source: 'Otros', total: 0, color: '#a7bac9'
    }
  ];

  public fundingSourceDataEmpty = [
  ];

  public totalFunding: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public step: Number = 0;
  public treeControl = new NestedTreeControl<ObjectNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<ObjectNode>();
  public dataSourceClone = new MatTreeNestedDataSource<ObjectNode>();
  private map: any;
  private mapOption: MapOptions;
  private drawnControlsOff: any;

  public filteredMunicipalities: Observable<any[]>;
  public municipalitiesCtrl = new FormControl('', [Validators.required]);
  private municipalities: Dependency[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public municipalitiesAdded = <any>[];
  public form: FormGroup;
  public proccess: Boolean = true;
  public activeAction: Boolean = false;
  public downloading: Boolean = false;
  private proccessImage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private imgGraph: any;
  private imgMap: any;
  public selectedAllProject: { check: boolean, indeterminate: boolean } = { check: false, indeterminate: false };

  public valueSearch = '';
  public date = new Date();
  public diableStep2 = true;
  public selectedData = false;
  public populationObject: any = [];

  @ViewChild('search', { static: false }) public search: ElementRef;
  @ViewChild('municipalityInput', { static: false }) municipalityInput: ElementRef<HTMLInputElement>;

  constructor(
    private service: ReportsService,
    private utils: Utils,
    private formBuilder: FormBuilder,
    private geoService: GeoService
  ) {
    this.mapOption = new MapOptions();
  }

  ngOnInit(): void {

    this.map = leaflet.map('map', {
      preferCanvas: true,
      renderer: leaflet.canvas()
    }).setView(this.mapOption.pointInitial, 10);

    const lang = translate('es');
    this.map.drawLocal = lang;

    leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png').addTo(this.map);

    const drawnItems = new leaflet.FeatureGroup();
    this.map.addLayer(drawnItems);

    this.mapOption.drawnItems = drawnItems;

    this.drawnControlsOff = new leaflet.Control.Draw(this.mapOption.optionDrawsOff);

    this.geoService.municipalities.subscribe(result => {
      this.municipalities = result;
      this.loadMunicipalities();
    });

    this.form = this.formBuilder.group({
      municipalities: this.formBuilder.array([])
    });

    this.service.getAllPortfolios().subscribe(response => {
      this.processPorfolio(response, true);
      this.municiplities = new BehaviorSubject<any[]>([]);
    }, error => {
      console.log(error);
      this.proccess = false;
    });

  }

  public hasChild = (_: number, node: ObjectNode) => !!node.children && node.children.length > 0;

  private processPorfolio(portfolios, init?: boolean): void {

    const parsePortfolio = portfolios.map((portfolio: any) => {

      const children = portfolio.children || [];

      portfolio = new Portfolio(
        portfolio.id,
        portfolio.name,
        portfolio.description,
        portfolio.objective,
        portfolio.promise,
        portfolio.territorial_scale,
        portfolio.benefited_population,
        portfolio.projects,
        [],
        [],
        1
      );

      portfolio.check = false;
      if (typeof init === 'undefined') {
        portfolio.children = children;
      }

      portfolio.children = portfolio.children.map((project: any) => {
        const nameResponsable = (typeof init === 'undefined') ? project.responsable : project.responsible.name + ' ' + project.responsible.last_name;
        project = new Project(project.id, (typeof init === 'undefined') ? project.children : project.project_stages, project.dependency, 2, portfolio.name, portfolio.id);
        project.responsable = nameResponsable;
        project.promise = portfolio.promise === 1;
        project.check = false;
        project.nameProject = portfolio.name;
        project.populationAction = { total: 0, direct: 0, indirect: 0 };
        project.children = project.children.map((stage: any) => {

          stage = new Stage(stage.id, stage.name, project.id, (typeof init === 'undefined') ? stage.children : stage.subprojects, 3);
          stage.check = false;

          stage.children = stage.children.map((action: any) => {

            const benefitedDirect = (typeof init === 'undefined') ? action.benefitedDirect : !!action.benefited_direct ? action.benefited_direct : 0;
            const benefitedIndirect = (typeof init === 'undefined') ? action.benefitedIndirect : !!action.benefited_indirect ? action.benefited_indirect : 0;
            const geom = action.geom;
            const radius = action.radius;
            const semaphore = !!action.semaphore ? action.semaphore : 0;

            action = new Action(
              action.id,
              action.name,
              stage.id,
              action.tasks,
              action.stage_id,
              project.dependency.id,
              project.dependency.name,
              action.subprojects_financing,
              action.municipalities,
              action.responsable
            );

            action.benefitedDirect = benefitedDirect;
            action.benefitedIndirect = benefitedIndirect;

            action.geom = geom;
            action.radius = radius;
            action.semaphore = semaphore;
            action.check = false;

            project.populationAction.total += this.utils.parseFloat(action.benefitedDirect) + this.utils.parseFloat(action.benefitedIndirect);
            project.populationAction.direct += this.utils.parseFloat(action.benefitedDirect);
            project.populationAction.indirect += this.utils.parseFloat(action.benefitedIndirect);

            let totalFinancing = 0;
            action.subprojects_financing.map(financing => {
              let totalF = 0;
              this.fundingSourceData.map(source => {
                if (source.source === financing.name_funding) {

                  if (financing.name_funding === 'Estatal') {
                    financing.splits_action.map(financingSplit => {
                      source.total += this.utils.parseFloat(financingSplit.amount);
                    });
                  } else {
                    source.total += this.utils.parseFloat(financing.amount);
                  }

                }
                totalF += source.total;
              });
              totalFinancing = totalF;
              this.totalFunding.next(totalF);
            });

            action.totalFinancing = totalFinancing;

            this.fundingSources.next(this.fundingSourceData);

            action.tasks = action.tasks.map((task: any) => {
              const responsable = (typeof init === 'undefined') ? task.responsable : task.user.name + ' ' + task.user.last_name;
              const semaphore = task.semaphore;
              task = new Task(task.id, task.date_assignment, task.date_estimated_finished, task.status, task.name);
              task.responsable = responsable;
              task.semaphore = semaphore;
              return task;
            });

            action.municipalities.map(municality => {
              this.groupMunicipalities[municality.municipio.name] = this.groupMunicipalities[municality.municipio.name] || { 'group': municality.municipio.name, projects: [] };
              if (!!!this.groupMunicipalities[municality.municipio.name].projects.find(projectFind => projectFind.id === project.id)) {
                this.groupMunicipalities[municality.municipio.name].projects.push(project);
              }

              this.municiplities.next(this.municiplities.getValue()
                .filter(group => group.group !== municality.municipio.name)
                .concat(this.groupMunicipalities[municality.municipio.name]));
            });

            return action;
          });

          return stage;
        });

        return project;
      });

      return portfolio;
    });

    this.dataSource.data = parsePortfolio;

    if (typeof init !== 'undefined') {
      this.dataSourceClone.data = JSON.parse(JSON.stringify(parsePortfolio));
    }

    this.proccess = false;
  }

  private processReport(portfolios): void {

    this.groupMunicipalities = [];
    this.municiplities = new BehaviorSubject<any[]>([]);
    this.fundingSourceData = [
      {
        source: 'Estatal', total: 0, color: '#00953e'
      },
      {
        source: 'Federal', total: 0, color: '#006f42'
      },
      {
        source: 'Internacional', total: 0, color: '#96c11e'
      },
      {
        source: 'Municipal', total: 0, color: '#a7bac9'
      },
      {
        source: 'Otros', total: 0, color: '#a7bac9'
      }
    ];
    this.fundingSources = new BehaviorSubject<any[]>([]);

    const parsePortfolio = portfolios.filter((portfolio: Portfolio) => {

      portfolio.children = portfolio.children.filter((project: Project) => {

        project.children = project.children.filter((stage: Stage) => {

          stage.children = stage.children.filter((action: Action) => {
            return action.check;
          });

          return stage.check;
        });

        return project.check;
      });

      return portfolio.check;
    });

    parsePortfolio.map((portfolio: Portfolio) => {

      if (portfolio.check) {

        portfolio.children = portfolio.children.map((project: Project) => {

          if (project.check) {
            project.populationAction = { total: 0, direct: 0, indirect: 0 };
            project.children = project.children.map((stage: Stage) => {

              if (project.check) {

                stage.children = stage.children.map((action: Action) => {

                  if (project.check) {

                    action.totalFinancing = 0;

                    action.subprojects_financing.forEach(financing => {
                      if (financing.name_funding === 'Estatal') {
                        financing.splits_action.map(financingSplit => {
                          action.totalFinancing += this.utils.parseFloat(financingSplit.amount);
                        });
                      } else {
                        action.totalFinancing += this.utils.parseFloat(financing.amount);
                      }
                    });

                    if (!!action.geom) {
                      action.geom.geometries.forEach(geoms => {
                        this.mapOption.drawNoEdit(geoms.type, geoms.coordinates, 'Titulo', this.map, this.drawnControlsOff, this.drawnControlsOff, action.radius, false, false);
                      });
                    }

                    project.populationAction.total += this.utils.parseFloat(action.benefitedDirect) + this.utils.parseFloat(action.benefitedIndirect);
                    project.populationAction.direct += this.utils.parseFloat(action.benefitedDirect);
                    project.populationAction.indirect += this.utils.parseFloat(action.benefitedIndirect);

                    action.subprojects_financing.map(financing => {
                      let totalF = 0;
                      this.fundingSourceData.map(source => {
                        if (source.source === financing.name_funding) {

                          if (financing.name_funding === 'Estatal') {
                            financing.splits_action.map(financingSplit => {
                              source.total += this.utils.parseFloat(financingSplit.amount);
                            });
                          } else {
                            source.total += this.utils.parseFloat(financing.amount);
                          }

                        }
                        totalF += source.total;
                      });
                      this.totalFunding.next(totalF);
                    });

                    this.fundingSources.next(this.fundingSourceData);

                    action.tasks = action.tasks.map((task: Task) => {
                      task.colorStatus = this.statusColor(task.semaphore, this.replaceStatus(task.status));
                      return task;
                    });

                    action.municipalities.map(municality => {
                      this.groupMunicipalities[municality.municipio.name] =
                        this.groupMunicipalities[municality.municipio.name] || { 'group': municality.municipio.name, projects: [] };

                      if (!!!this.groupMunicipalities[municality.municipio.name].projects.find(projectFind => projectFind.id === project.id)) {
                        this.groupMunicipalities[municality.municipio.name].projects.push(project);
                      }
                      this.municiplities.next(this.municiplities.getValue()
                        .filter(group => group.group !== municality.municipio.name)
                        .concat(this.groupMunicipalities[municality.municipio.name]));
                    });

                  }

                  return action;
                });

              }

              return stage;
            });
          }

          this.populationObject.push({ population: project.populationAction, project_id: project.id});

          return project;
        });
      }

      return portfolio;
    });

    this.step = 1;
  }

  public GenerarReport(): void {

    for (const key in this.map._targets) {
      if (typeof this.map._targets[key]._containerId === 'undefined') {
        this.map.removeLayer(this.map._targets[key]);
      }
    }
    const data = JSON.parse(JSON.stringify(this.dataSource.data));
    this.processReport(data);
    this.diableStep2 = false;
  }

  public setStep(step: number): void {
    this.step = step;
    this.diableStep2 = step === 0;
  }

  public check(object: any, data?: any): void {
    if (!object.type) {
      this.checkAction(object);
    } else if (object.type === 1) {
      this.checkPortfolio(object);
    } else if (object.type === 2) {
      this.checkProject(object);
    } else if (object.type === 3) {
      this.checkStage(object);
    } else {
      this.checkAction(object);
    }
    this.contentData();
  }

  public checkAllPortfolio(event): void {
    this.dataSource.data.map((portfolio: Portfolio) => {
      portfolio.indeterminate = false;
      portfolio.check = this.selectedAllProject.check;

      portfolio.children.map((project: Project) => {
        project.indeterminate = false;
        project.check = this.selectedAllProject.check;

        project.children.map((stage: Stage) => {
          stage.indeterminate = false;
          stage.check = this.selectedAllProject.check;

          stage.children.map((action: Action) => {
            action.check = this.selectedAllProject.check;

            return action;
          });

          return stage;
        });

        return project;
      });
    });

    this.selectedData = event.checked;
  }

  private checkPortfolio(portfolio: Portfolio): void {
    const idPortfolio = portfolio.id;
    const checkPorfolio = portfolio.check;

    portfolio.children.forEach((project: Project) => {
      project.check = portfolio.check;
      project.children.forEach((stage: Stage) => {
        stage.check = project.check;
        stage.children.forEach((action: Action) => {
          action.check = stage.check;
        });
      });
    });

    this.dataSource.data.forEach((portfolio: Portfolio) => {
      if (portfolio.id === idPortfolio) {
        portfolio.check = checkPorfolio;

        portfolio.children.forEach((project: Project) => {
          project.check = portfolio.check;
          project.children.forEach((stage: Stage) => {
            stage.check = project.check;
            stage.children.forEach((action: Action) => {
              action.check = stage.check;
            });
          });
        });
      }
    });

    this.selectedAllProject.check = this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length > 0;
    this.selectedAllProject.indeterminate = !this.selectedAllProject.check ? false :
      this.dataSource.data.length !== this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length;
  }

  private checkProject(project: Project): void {
    const checkProject = project.check;
    const indexProject = project.id;
    this.dataSource.data.map((portfolio: Portfolio) => {
      if (portfolio.id === project.portfolioId) {
        const checkAllProjects = portfolio.children.filter((projectFilter: Project) => {
          if (projectFilter.id === indexProject) {
            projectFilter.check = checkProject;

            projectFilter.children = projectFilter.children.map((stage: Stage) => {
              stage.check = checkProject;

              stage.children = stage.children.map((action: Action) => {
                action.check = checkProject;

                return action;
              });
              return stage;
            });
          }
          return projectFilter.check;
        }).length;

        const totalProjects = portfolio.children.length;

        portfolio.indeterminate = (totalProjects !== checkAllProjects) && checkAllProjects > 0;
        portfolio.check = (totalProjects === checkAllProjects) || checkAllProjects > 0;
      }
    });

    project.children.map((stage: Stage) => {
      stage.check = project.check;
      stage.children.map((action: Action) => {
        action.check = project.check;
        return action;
      });

      return stage;
    });

    this.selectedAllProject.check = this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length > 0;
    this.selectedAllProject.indeterminate = !this.selectedAllProject.check ? false :
      this.dataSource.data.length !== this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length;
  }

  private checkStage(stage: Stage): void {
    const checkStage = stage.check;
    const idStage = stage.id;
    this.dataSource.data.filter((portfolio: Portfolio) => {
      const index = portfolio.children.findIndex((project: Project) => {
        return project.id === stage.projectId;
      });

      if (index >= 0) {
        portfolio.children.filter((project: Project) => {
          const checkAllStage = (project.children.filter((stageFilter: Stage) => {
            if (stageFilter.id === idStage) {
              stageFilter.check = checkStage;

              stageFilter.children = stageFilter.children.map((action: Action) => {
                action.check = checkStage;
                return action;
              });
            }
            return stageFilter.check;
          })).length;

          const totalStage = project.children.length;

          project.indeterminate = (totalStage !== checkAllStage) && checkAllStage > 0;
          project.check = (totalStage === checkAllStage) || checkAllStage > 0;
        });

        const checkAllProject = (portfolio.children.filter((projectC: Project) => {
          return projectC.check;
        })).length;


        const totalProject = portfolio.children.length;

        portfolio.indeterminate = (totalProject !== checkAllProject) && checkAllProject > 0;
        portfolio.check = (totalProject === checkAllProject) || checkAllProject > 0;
      }
    });

    this.selectedAllProject.check = this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length > 0;
    this.selectedAllProject.indeterminate = !this.selectedAllProject.check ? false :
      this.dataSource.data.length !== this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length;

    stage.children = stage.children.filter((action: Action) => {
      action.check = checkStage;
      return true;
    });
  }

  private checkAction(action: Action): void {
    const stageParent = action.stage_id;
    const checkAction = action.check;
    const idAction = action.id;

    this.dataSource.data.filter((portfolio: Portfolio) => {
      const index = portfolio.children.findIndex((project: Project) => {
        const indexProject = project.children.findIndex((stage: Stage) => {
          return stage.id === stageParent;
        });

        return indexProject >= 0;
      });

      if (index >= 0) {
        portfolio.children.filter((project: Project) => {
          project.children.filter((stage: Stage) => {
            const checkAllAction = (stage.children.filter((actionFilter: Action) => {
              if (actionFilter.id === idAction) {
                actionFilter.check = checkAction;
              }
              return actionFilter.check;
            })).length;

            const totalAction = stage.children.length;

            stage.indeterminate = (totalAction !== checkAllAction) && checkAllAction > 0;
            stage.check = (totalAction === checkAllAction) || checkAllAction > 0;
          });

          const checkAllStage = (project.children.filter((stageFilter: Stage) => {
            return stageFilter.check;
          })).length;

          const totalStage = project.children.length;

          project.indeterminate = (totalStage !== checkAllStage) && checkAllStage > 0;
          project.check = (totalStage === checkAllStage) || checkAllStage > 0;
        });

        const checkAllProject = (portfolio.children.filter((projectC: Project) => {
          return projectC.check;
        })).length;


        const totalProject = portfolio.children.length;

        portfolio.indeterminate = (totalProject !== checkAllProject) && checkAllProject > 0;
        portfolio.check = (totalProject === checkAllProject) || checkAllProject > 0;
      }
    });

    this.selectedAllProject.check = this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length > 0;
    this.selectedAllProject.indeterminate = !this.selectedAllProject.check ? false :
      this.dataSource.data.length !== this.dataSource.data.filter((portfolioF: Portfolio) => portfolioF.check === true).length;
  }

  public loadMunicipalities(): void {
    this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
      startWith(null),
      map((municpality: string | null) => municpality ? this._filterMunicipalities(municpality) : this.municipalities.slice())
    );
  }

  private _filterMunicipalities(value: any): any[] {
    return this.municipalities.filter(municipality => {
      const municipalityName = this.utils.removeAccents(municipality.name.toLowerCase());

      if (typeof value === 'object') {
        return false;
      }

      value = this.utils.removeAccents(value);
      return municipalityName.indexOf(value) > -1;
    });
  }

  public selectedMunicipality(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.selectedAllProject.check = false;
    const repeated = this.municipalitiesAdded.find(data => data.id === value.id);

    if (!!!repeated) {
      const formMunicipalities = <FormArray>this.form.get('municipalities') as FormArray;
      formMunicipalities.push(new FormControl(value));
      this.municipalitiesAdded.push(value);
      this.municipalityInput.nativeElement.value = '';
      this.municipalitiesCtrl.setValue(null);
    }
    this.filterMunicipalities(event.option.value, 1);

    const municipalitiesSend = [];
    const list = this.municipalitiesAdded;

    list.map(m => {
      municipalitiesSend.push(m.id);
    });

    const allData = JSON.parse(JSON.stringify(this.dataSourceClone.data));
    const data = this.filterPortfoliosWithMunicipalities(allData, municipalitiesSend);
    this.dataSource.data = null;
    this.processPorfolio(data);
  }

  private filterMunicipalities(el, action): void {
    if (action === 1) {
      this.municipalities = this.municipalities.filter(option => {
        return option.id !== el.id;
      });
    } else {
      this.municipalities.push(el);
    }
    this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
      startWith(null),
      map((municipality: string | null) => municipality ? this._filterMunicipalities(municipality) : this.municipalities.slice()));
  }

  public removeMunicipality(municipality: any): void {
    this.selectedAllProject.check = false;
    const formMunicipalities = <FormArray>this.form.get('municipalities') as FormArray;
    const i = formMunicipalities.controls.findIndex(x => x.value.id === municipality.id);
    if (i >= 0) {
      formMunicipalities.removeAt(i);
    }
    const index = this.municipalitiesAdded.indexOf(municipality);
    this.municipalitiesAdded.splice(index, 1);
    this.filterMunicipalities(municipality, 2);

    const municipalitiesSend = [];
    const list = this.municipalitiesAdded;

    list.map(m => {
      municipalitiesSend.push(m.id);
    });

    this.proccess = true;

    if (municipalitiesSend.length > 0) {
      const data = this.filterPortfoliosWithMunicipalities(this.dataSource.data, municipalitiesSend);
      this.dataSource.data = null;
      this.processPorfolio(data);
    } else {
      this.proccess = false;
      this.groupMunicipalities = [];
      this.municiplities = new BehaviorSubject<any[]>([]);
      this.fundingSourceData = [
        {
          source: 'Estatal', total: 0, color: '#00953e'
        },
        {
          source: 'Federal', total: 0, color: '#006f42'
        },
        {
          source: 'Internacional', total: 0, color: '#96c11e'
        },
        {
          source: 'Municipal', total: 0, color: '#a7bac9'
        },
        {
          source: 'Otros', total: 0, color: '#a7bac9'
        }
      ];
      this.fundingSources = new BehaviorSubject<any[]>([]);
      this.dataSource.data = null;
      this.dataSource.data = JSON.parse(JSON.stringify(this.dataSourceClone.data));
    }

    this.contentData();
  }

  public download(): void {
    this.proccessImage = new BehaviorSubject<number>(0);
    this.imgGraph = '';
    this.imgMap = '';
    this.downloading = true;

    const row: any = document.getElementById('imgGraph');
    html2canvas(row).then(canvas => {
      let img = '';
      let svg = document.getElementsByClassName('k-chart-surface')[0].innerHTML;
      if (svg) {
        svg = svg.replace(/\r?\n|\r/g, '').trim();
      }
      const canvasSvg = document.createElement('canvas');
      const context = canvasSvg.getContext('2d');
      context.clearRect(0, 0, canvasSvg.width, canvasSvg.height);
      canvg(canvasSvg, svg);
      img = canvasSvg.toDataURL('image/png');

      this.imgGraph = img;
      this.proccessImage.next(1);
    });

    this.proccessImage.subscribe(value => {

      if (value === 1) {
        leafletImage(this.map, (error, canvas) => {

          if (!!error) {
            this.imgMap = '';
          } else {
            this.imgMap = canvas.toDataURL('image/png');
          }

          this.proccessImage.next(2);
        });
      }

      if (value === 2) {
        console.log(this.populationObject);
        this.service.downlaodMunicipalities(
          this.municiplities.getValue(),
          this.fundingSources.getValue(),
          this.imgGraph,
          this.totalFunding.getValue(),
          this.imgMap,
          this.populationObject,
          this.activeAction
        ).subscribe(
          (response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            saveAs(blob, 'reporte-municipal.pdf');
            this.downloading = false;
            this.proccessImage.next(0);
          },
          e => {
            throwError(e);
            this.downloading = false;
          });
      }
    });

  }

  public replaceStatus(status): string {
    switch (status) {
      case 'created':
        return 'Nueva';
      case 'processing':
        return 'Procesando';
      case 'finished':
        return 'Finalizada';
      case 'cancelled':
        return 'Cancelado';
      case 'paused':
        return 'Pausado';

      default:
        return '';
    }
  }

  public statusColor(semaphore: number, status: string): string {

    if (status === 'Finalizada') {
      return '#3DAD54';
    }

    let response = '';
    const result = semaphore;

    if (result >= 50) {
      response = '#3DAD54';
    } else if (result >= 20 && result <= 49) {
      response = '#F4C904';
    } else {
      response = '#E94E31';
    }

    return response;
  }

  private filterPortfoliosWithMunicipalities(portfolioParam: any, municipalities: any[]): Portfolio[] {
    const port = portfolioParam.filter((portfolio: Portfolio) => {
      portfolio.children = this.filterProjects(portfolio.children, municipalities);
      return portfolio.children.length > 0;
    });

    return port;
  }

  private filterProjects = (projects: Project[], municipalities: any[]): Project[] => {
    return projects.filter((project: Project) => {
      project.children = project.children.filter(
        (stage: Stage) => {
          stage.children = stage.children.filter(
            (action: Action) => {
              action.municipalities = action.municipalities.filter(
                (munic: any) => municipalities.includes(munic.municipality_id));
              return action.municipalities.length > 0;
            }
          );
          return stage.children.length > 0;
        });
      return project.children.length > 0;
    });
  }

  public contentData(): void {
    const data = JSON.parse(JSON.stringify(this.dataSource.data));
    const parsePortfolio = data.filter((portfolio: Portfolio) => {
      portfolio.children = portfolio.children.filter((project: Project) => {
        project.children = project.children.filter((stage: Stage) => {
          stage.children = stage.children.filter((action: Action) => {
            return action.check;
          });
          return stage.check;
        });
        return project.check;
      });
      return portfolio.check;
    });

    this.selectedData = parsePortfolio.length > 0;
  }

  public checkChildren(children: any[], type: number): boolean {
    let result = false;
    if (type === 1) {
      children.forEach((project: Project) => {
        if (project.check) {
          result = true;
        }
        project.children.forEach((stage: Stage) => {
          if (stage.check) {
            result = true;
          }

          stage.children.forEach((action: Action) => {
            if (action.check) {
              result = true;
            }
          });

        });
      });
      return result;
    } else if (type === 2) {
      children.forEach((stage: Stage) => {
        if (stage.check) {
          result = true;
        }
        stage.children.forEach((action: Action) => {
          if (action.check) {
            result = true;
          }
        });
      });

      return result;
    }
    return children.filter(item => item.check).length > 0;
  }
}
