import { Pipe, PipeTransform } from '@angular/core';
import { Portfolio } from '../models/portfolio.model';
import { Project } from '../models/project.model';
import { Utils } from 'app/constants/utils';
import { ReportsService } from '../reports.service';

@Pipe({
  name: 'filterMatTree'
})
export class FilterMatTreePipe implements PipeTransform {

  constructor(
    private service: ReportsService
  ) {

  }

  transform(values: any, search: string): any {
    if (!!!search || search === '' || !!!values) {
      return values;
    }

    const data = JSON.parse(JSON.stringify(values.data));

    const dataFiltered = data.filter((portfolio: Portfolio) => {
      if (new Utils().removeAccents(portfolio.name.toLowerCase()).includes(new Utils().removeAccents(search.toLowerCase()))) {
        return true;
      } else {
        portfolio.children = portfolio.children.filter((project: Project) => {
          if (new Utils().removeAccents( project.dependency.name.toLowerCase()).includes(new Utils().removeAccents(search.toLowerCase()))) {
            return true;
          }
        });
        return portfolio.children.length > 0;
      }
    });
        
    return dataFiltered;
    // filter for text
    return values.data.filter((portfolio: Portfolio) => 
                                portfolio.projects.some((project: Project) => 
                                                        new Utils().removeAccents( project.dependency.name.toLowerCase())
                                                        .includes(new Utils().removeAccents(search.toLowerCase())))
                                
                                ||
                                
                                new Utils().removeAccents(portfolio.name.toLowerCase()).includes(new Utils().removeAccents(search.toLowerCase()))
                            );
  }

}
