import { Component, OnInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../services/auth.service';
import {
  MatSnackBar,
  MatDialogRef,
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort
} from '@angular/material';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { VisualizerService } from '../visualizer.service';
import { Utils } from '../../constants/utils';
import { VisualizerDialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  animations: fuseAnimations
})
export class IndexComponent implements OnInit {

  public columnsTable: string[] = ['name', 'coordination', 'users', 'action'];
  public projects: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authService: AuthService,
    private location: Location,
    private service: VisualizerService,
    private utils: Utils
  ) { }

  ngOnInit(): void{
    this.index();
    this.paginator = this.utils.translatePaginator(this.paginator);
  }

  private index(): void {
    this.service.index().subscribe(data => {
      this.projects = new MatTableDataSource(data);
      this.projects.paginator = this.paginator;
      this.projects.sort = this.sort;
      this.projects.filterPredicate = (dataProjects: any, filter: string) => {
        
        const matchFilter = [];

        Object.keys(dataProjects).forEach(column => {
          if (dataProjects[column] instanceof Object) {

            const firt = dataProjects[column];
            Object.keys(firt).forEach(columnChild => {
              if (!(firt[columnChild] instanceof Object)) {
                matchFilter.push(this.utils.removeAccents(firt[columnChild]).toLowerCase()
                                      .includes(
                                        filter
                                      ));
              }
            });
            
          } else {
            matchFilter.push(this.utils.removeAccents(dataProjects[column]).toLowerCase()
                                  .includes(
                                    filter
                                  ));
          }
        });
        return matchFilter.some((e) => e === true);
      };
    }); 
  }

  public applyFilter(filterValue: string): void {

    this.projects.filter = this.utils.removeAccents(filterValue.toLocaleLowerCase());

    if (this.projects.paginator) {
      this.projects.paginator.firstPage();
    }
  }

  public openDialog(project: any): void {
    const dialogRef = this.dialog.open(VisualizerDialogComponent, {
      panelClass: 'mat-dialog-system',
      data: {
        id: project.id,
        name: project.name,
        dependency: project.dependency ? project.dependency.name : ''
      }
    });
  }

}
