import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from '../../globals';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Stage } from '../../stages/stage';
import { Task } from '../../tasks/task';

@Injectable({
  providedIn: 'root'
})
export class DetailProjectsService {
  module: String = 'proyectos';
  routeParams = new BehaviorSubject<any>({});
  project = new BehaviorSubject<any>({});
  stage = new BehaviorSubject<Stage>(new Stage());
  action = new BehaviorSubject<any>({});
  task = new BehaviorSubject<Task>(new Task());

  current = {
    project: new BehaviorSubject<number>(0),
    stage: new BehaviorSubject<number>(0),
    action: new BehaviorSubject<number>(0),
    task: new BehaviorSubject<number>(0)
  };

  loading = {
    project: new BehaviorSubject<boolean>(false),
    stages: new BehaviorSubject<boolean>(false),
    actions: new BehaviorSubject<boolean>(false),
    tasks: new BehaviorSubject<boolean>(false)
  };

  stages = new BehaviorSubject<any[]>([]);
  tasks = new BehaviorSubject<any[]>([]);
  sub: any;

  entities = ['project', 'stage', 'action', 'task'];

  constructor(
    private http: HttpClient,
    private global: Globals,
    private router: Router,
    private route: ActivatedRoute
  ) {

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const entitiesUrl = ['etapa', 'accion', 'tarea'];
        let isBaseProject = true;

        const match = entitiesUrl.filter(x => event.url.indexOf(x) >= 0);

        if (match.length > 0) {
          isBaseProject = false;
        }

        if (isBaseProject) {
          this.current.stage.next(0);
          this.current.action.next(0);
          this.current.task.next(0);
        }
      });

    this.current.project.subscribe(project => {
      if (!project) {
        return;
      }

      this._getProject().subscribe(projectData => {
        this.project.next(projectData);
        this.loading.project.next(false);
      });

      this.refreshStages();
    });

    this.current.stage.subscribe(stage => {
      if (stage <= 0) {
        return;
      }

      this.setCurrentStage();
      this.tasks.next([]);
    });

    this.current.action.subscribe(action => {
      this.tasks.next([]);

      if (action <= 0) {
        return;
      }

      this.getTasks().subscribe(data => {
        this.loading.tasks.next(false);
        this.tasks.next(<any>data);
        this.setCurrentTask();
      });

      if (!this.loading.stages.value) {
        this.setCurrentAction();
      }
    });

    this.routeParams.subscribe(params => {

      if (params.hasOwnProperty('projectId')) {
        this.current.project.next(+params.projectId);
      } else if (params.hasOwnProperty('taskId')) {

        this.current.task.next(+params.taskId);
        this.current.action.next(+params.actionId);
        this.current.stage.next(+params.stageId);

      } else if (params.hasOwnProperty('actionId')) {

        this.current.task.next(0);
        this.current.action.next(+params.actionId);
        this.current.stage.next(+params.stageId);

      } else if (params.hasOwnProperty('stageId')) {

        this.current.task.next(0);
        this.current.action.next(0);
        this.current.stage.next(+params.stageId);

      }

    });

  }

  resolve(routeSnapshot: ActivatedRouteSnapshot): any {
    const snapshot: any = routeSnapshot.paramMap;
    this.routeParams.next(snapshot.params);
    return true;
  }

  private setCurrentStage(): void {
    if (this.current.stage.value) {
      const stage = this.stages.value.find(x => x.id === this.current.stage.value);

      if (stage) {
        this.stage.next(stage);
        this.setCurrentAction();
      }
    }
  }

  private setCurrentAction(): void {
    if (this.current.action.value) {
      const stage = this.stage.value;
      const action = stage.subprojects.find(x => x.id === this.current.action.value);

      if (action) {
        this.action.next(action);
      }
    }
  }

  private setCurrentTask(): void {
    if (this.current.task.value) {
      const task = this.tasks.value.find(x => x.id === this.current.task.value);

      if (task) {
        this.task.next(task);
      }
    }
  }

  private _getProject(): Observable<any> {
    this.loading.project.next(true);
    return this.http.get(`${this.global.api}projects/${this.current.project.value}`, this.global.httpOptions);
  }

  private getStages(): Observable<any> {
    this.loading.stages.next(true);
    return this.http.get(`${this.global.api}projects/${this.current.project.value}/stages/actions`, this.global.httpOptions);
  }

  getActionsByStage(id: number): Observable<any> {
    return this.http.get(`${this.global.api}stages/${id}/actions`, this.global.httpOptions);
  }

  private getTasks(): Observable<any> {
    this.loading.tasks.next(true);
    return this.http.get(`${this.global.api}subprojects/${this.current.action.value}/tasks`, this.global.httpOptions);
  }

  private getBaseUrlProject(): string {
    return `${this.module}/${this.current.project.value}/`;
  }

  private setUrlCurrentStage(): void {
    this.router.navigate([`${this.getBaseUrlProject()}etapa/`, this.current.stage.value]);
  }

  private setUrlCurrentAction(): void {
    this.router.navigate([`${this.getBaseUrlProject()}etapa/`, this.current.stage.value, 'accion', this.current.action.value]);
  }

  private setUrlCurrentTask(): void {
    this.goToTask(this.current.task.value);
  }

  private selectFirstStage(): void {
    const stages = <any>Object.assign([], this.stages.value);
    stages.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    this.stage.next(<any>stages[0]);
    this.current.stage.next(this.stage.value.id);
    this.setUrlCurrentStage();
  }

  private selectLastStage(): void {
    const stages = <any>Object.assign([], this.stages.value);
    stages.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    if(stages.length > 0){
      this.stage.next(stages[0]);
      this.current.stage.next(this.stage.value.id);
      this.setUrlCurrentStage();
    } else {
      this.router.navigate([`${this.getBaseUrlProject()}`]);
    }
  }

  private selectFirstAction(): void {
    // const actions = <any>Object.assign([], this.actions.value);
    // actions.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    // this.action.next(actions[0]);
    // this.current.action.next(this.action.value.id);
  }

  private selectLastAction(): void {
    const actions = <any>Object.assign([], this.stage.value.subprojects);
    actions.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    if(actions.length > 0){
      this.action.next(actions[0]);
      this.current.action.next(this.action.value.id);
      this.goToAction(this.current.action.value);
    } else {
      this.router.navigate([`${this.getBaseUrlProject()}etapa/`, this.current.stage.value]);
    }
  }

  private selectFirstTask(): void {
    const tasks = <any>Object.assign([], this.tasks.value);
    tasks.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
    this.task.next(tasks[0]);
    this.current.task.next(this.task.value.id);
  }

  private selectLastTask(): void {
    const tasks = <any>Object.assign([], this.tasks.value);
    tasks.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
    this.task.next(tasks[0]);
    this.current.task.next(this.task.value.id);
    this.setUrlCurrentTask();
  }

  public setOrderStage(id_project: Number, id_stage: Number, current_position: Number, new_position: Number): Observable<any> {
    return this.http.put(`${this.global.api}stages/order/${id_project}`, { id_stage, current_position, new_position }, this.global.httpOptions);
  }

  public setOrderSubproject(id_stage: Number, id_subproject: Number, current_position: Number, new_position: Number): Observable<any> {
    return this.http.put(`${this.global.api}subprojects/order/${id_stage}`, { id_subproject, current_position, new_position }, this.global.httpOptions);
  }

  public setOrderTask(id_subproject: Number, id_task: Number, current_position: Number, new_position: Number): Observable<any> {
    return this.http.put(`${this.global.api}tasks/order/${id_subproject}`, { id_task, current_position, new_position }, this.global.httpOptions);
  }

  refreshProject(): void {
    this._getProject().subscribe(data => this.project.next(data));
  }

  refreshStages(selectLastRecord: boolean = false): void {
    this.getStages().subscribe(data => {
      this.refreshProject();
      this.loading.stages.next(false);
      this.stages.next(data);
      if (selectLastRecord) {
        this.selectLastStage();
      } else {
        this.setCurrentStage();
      }

    });
  }

  refreshActions(selectLastRecord: boolean = false): void {
    this.getStages().subscribe(stages => {
      this.refreshProject();
      this.loading.stages.next(false);
      this.stages.next(stages);
      this.current.stage.next(this.current.stage.value);

      if (selectLastRecord) {
        this.selectLastAction();
      }

      this.refreshTasks(false);

    });
  }

  refreshTasks(selectLastRecord: boolean = false): void {
    this.getTasks().subscribe(data => {
      this.loading.tasks.next(false);
      this.tasks.next(data);

      if (selectLastRecord) {
        this.selectLastTask();
      } else {
        this.setCurrentTask();
      }

    });
  }

  statusSidebar(): string {
    return this.current.stage.value === 0 ? 'main' : (this.current.action.value === 0 ? 'stage' : (this.current.task.value === 0 ? 'action' : 'task'));
  }

  goToProject(): void {
    console.log('goToProject');
    this.router.navigate([`/${this.module}/`, this.current.project.value]);
  }

  goToCurrentStage(): void {
    this.setUrlCurrentStage();
  }

  goToStage(stageId): void {
    this.router.navigateByUrl(`etapa/${stageId}`);
  }

  goToAction(actionId): void {
    this.router.navigate([`/${this.module}/`, this.current.project.value, 'etapa', this.current.stage.value, 'accion', actionId]);
  }

  goToCurrentAction(): void {
    this.setUrlCurrentAction();
  }


  goToTask(taskId): void {
    this.router.navigate([`/${this.module}/`, this.current.project.value, 'etapa', this.current.stage.value, 'accion', this.current.action.value, 'actividad', taskId]);
  }

  reset(): void {
    this.routeParams = new BehaviorSubject<any>({});
    this.project = new BehaviorSubject<any>({});
    this.stage = new BehaviorSubject<Stage>(new Stage());
    this.action = new BehaviorSubject<any>({});
    this.task = new BehaviorSubject<Task>(new Task());

    this.current = {
      project: new BehaviorSubject<number>(0),
      stage: new BehaviorSubject<number>(0),
      action: new BehaviorSubject<number>(0),
      task: new BehaviorSubject<number>(0)
    };

    this.loading = {
      project: new BehaviorSubject<boolean>(false),
      stages: new BehaviorSubject<boolean>(false),
      actions: new BehaviorSubject<boolean>(false),
      tasks: new BehaviorSubject<boolean>(false)
    };

    this.stages = new BehaviorSubject<any[]>([]);
    this.tasks = new BehaviorSubject<any[]>([]);    
  }

  actionDepend(id: number): Observable<any> {
    return this.http.get(`${this.global.api}subprojects/action-depend/task/${id}`, this.global.httpOptions);
  }

  validateActionDepend(id: number): void {
    console.log('id', id);
    this.actionDepend(id).subscribe(data => {
      this.refreshActions(true);
    });
  }

}
