import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatAutocomplete } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Utils } from '../../constants/utils';

import { CatalogsService } from '../../services/catalogs.service';
import { ProjectsService } from '../../projects/projects.service';

@Component({
  selector: 'app-dialog-participants',
  templateUrl: './dialog-participants.component.html',
  styleUrls: ['./dialog-participants.component.scss']
})
export class DialogParticipantsComponent implements OnInit {

  public project: any;
  public saving: Boolean = true;
  public form: FormGroup;
  
  public participantsTable: MatTableDataSource<any>;
  public filteredParticipants: Observable<any[]>;
  public participantsInput: FormControl = new FormControl();

  public userInput: FormControl = new FormControl();

  public columnsTable: string[] = ['dependency', 'remove'];
  public icon_exe: String = 'add_circle';

  private participantsObject: any[] = [];
  private participantActive: any;


  @ViewChild('participants', {static: false}) matAutocompleteParticipants: MatAutocomplete;
  @ViewChild('users', {static: false}) matAutocompleteUsers: MatAutocomplete;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogParticipantsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private catalogService: CatalogsService,
    private projectsService: ProjectsService,
    private utils: Utils
  ) { 

    this.project = this.data;

    this.form = this.formBuilder.group({
      participant: [<any>[]]
    });

    this.participantsTable = new MatTableDataSource();
  }

  ngOnInit(): void {
  
    this.catalogService.ups.subscribe(result => {
      this.participantsObject = result.ups.filter(up => {
        return up.id !== this.project.up_id;
      });

      this.filteredParticipants = this.participantsInput.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val, this.participantsObject))
      );

      this.init();
    });
  }

  init(): void {

    this.catalogService.participantsProject(this.project.id).subscribe(result => {

      result.forEach(participant => {
        const user = participant.user;
        const dependency = participant.dependency;

        const obj = {
          id: dependency.id,
          name: dependency.name,
          id_user: !user ? 0 : user.id,
          name_user: !user ? '' : user.name + ' ' + user.last_name,
          type: 'registered',
          portfolio_id: this.project.portfolio_id
        };

        if (this.project.type === 'Transversal' || !!participant.project_id) {

          this.participantsObject = this.participantsObject.filter(o => {
            return o.id !== dependency.id;
          });

          this.participantsTable.data.unshift(obj);
          this.participantsTable.data = this.participantsTable.data;
        }
      });
      this.saving = false;
    }, error => {
      console.log(error);
      this.saving = false;
    });
  }

  private filter(val: any, object: any): string[] {
    if (!val) {
      return object;
    }

    if (!Number.isInteger(val)) {
      return object.filter(option => {
        let value = option.name;
        if (option.last_name){
          value += option.last_name;
        }
        if (option.m_last_name && option.m_last_name !== null) {
          value += option.m_last_name;
        }

        return this.utils.removeAccents(value).toLowerCase().includes(this.utils.removeAccents(val).toLowerCase());
      });
    }
  }

  public onBlurParticipant(event: any): void {
    if (this.participantActive && event.target.value.length === 0) {
      this.clearParticipant();
    }
  }

  private clearParticipant(): void {
    this.participantActive = null;
    this.participantsInput.patchValue('');
  }

  public optionSelectedParticipant(event): any {
    const id = event.option.value;
    const selected = this.participantsObject.find(participant => {
      return participant.id === id;
    });

    if (selected){
      this.participantActive = selected;
    }
  }

  public displayParticipant(item): any {
    return this.matAutocompleteParticipants.options
      .filter(o => o.value === item)
      .map(o => o.viewValue)[0] ? this.matAutocompleteParticipants.options.filter(o => o.value === item)
      .map(o => o.viewValue)[0] : (item && item.name) ? item.name : '';
  }

  public addParticipant(): void  {
    if (this.participantActive) {
      const obj = {
        id: this.participantActive.id,
        name: this.participantActive.name,
        id_user: null,
        name_user: null,
        users: this.participantActive.users,
        portfolio_id: this.project.portfolio_id,
        type: 'new'
      };

      this.participantsTable.data.unshift(obj);
      this.participantsTable.data = this.participantsTable.data; 

      this.form.get('participant').setValue(this.participantsTable.data);

      this.participantsInput.patchValue('');
      this.userInput.patchValue('');


      this.participantsObject = this.participantsObject.filter(o => {
        return o.id !== this.participantActive.id;
      });

      this.filteredParticipants = this.participantsInput.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val, this.participantsObject))
      );
    }
  }

  public removeParticipant(participant: any): void {

    this.participantsObject.push({
      id: participant.id,
      name: participant.name,
      users: participant.users
    });

    const filter = this.participantsTable.data.filter(o => {
      return o.id !== participant.id;
    });

    this.participantsTable.data = filter;

    this.form.get('participant').setValue(this.participantsTable.data);
    this.participantsInput.patchValue('');
    this.userInput.patchValue('');
  }

  private getData(): any{
    return (<Array<any>>this.form.get('participant').value).filter(x => x.type === 'new');
  }

  public save(): void {
    this.saving = true;
    this.projectsService.addParticipants(this.project.id, this.getData()).subscribe(response => {
      this.dialogRef.close(true);
      this.saving = false;
    }, error => {
      console.log(error);
    });
  }

}
