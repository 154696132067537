import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatChipsModule, 
  MatFormFieldModule, 
  MatIconModule,
  MatInputModule, 
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { IndexGanttModule } from './index-gantt/index-gantt.module';

@NgModule({
  imports: [
    CommonModule,    
    IndexGanttModule
  ],
  declarations: []
})
export class GanttModule { }
