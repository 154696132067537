import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PortfoliosService } from '../portfolios.service';
import { ActivatedRoute } from '@angular/router';
import { Portfolio } from '../portfolio';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Lightbox } from 'ngx-lightbox';
import { LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-news-portfolios',
  templateUrl: './news-portfolios.component.html',
  styleUrls: ['./news-portfolios.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class NewsPortfoliosComponent implements OnInit {
  news: any[] = [];
  portfolio: Portfolio = new Portfolio();
  loadingNews: Boolean = false;
  withoutResults: Boolean = false;
  urlImgsNewsCovers = 'https://www.gaimpresos.com/img/portadas/thumbs/thumb-';
  form: FormGroup;
  maxDate = new Date();
  date = { begin: new Date(), end: new Date()};
  showTags: Boolean = false;

  constructor(
    private portfoliosService: PortfoliosService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _lightbox: Lightbox,
    private _lighboxConfig: LightboxConfig
  ) {
    _lighboxConfig.fitImageInViewPort = true;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      termn: [{ 'begin': new Date(), 'end': new Date() }, Validators.required],
      tag: ['']
    });

    this.portfoliosService.getNews(+this.route.snapshot.paramMap.get('id')).subscribe(portfolio => {
      this.portfolio = portfolio;

      if (this.portfolio.tags.length > 0) {
        this.form.patchValue({
          tag: this.portfolio.tags[0].tag
        });
        this.showTags = true;
      }
    });
  }

  getCover(note: any): string {
    return `url("${this.urlImgsNewsCovers}${note.idPeriodico}.jpg")`;
  }

  searchNews(): void{
    const dates = this.form.controls['termn'].value;
    const d1 = this.dateFormat(dates.begin._d);
    const d2 = this.dateFormat(dates.end._d);

    this.loadingNews = true;
    this.withoutResults = false;
    this.news = [];

    const tag = this.form.controls['tag'].value;
    const q = tag !== '' ? tag : this.portfolio.name;

    this.portfoliosService.newsRange(q, d1, d2).subscribe(news => {
      this.news = news;
      this.loadingNews = false;
    }, error => {
      this.loadingNews = false;
      this.withoutResults = true;
    });
  }

  dateFormat(date: any): string{
    const today: any = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;

    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 
    return yyyy + '-' + mm + '-' + dd;
  }

  imageAvailable(note: any): Boolean {
    return note.jpg.search('.pdf') >= 0;
  }

  goToLink(url: string): void{
    window.open(`https://www.gaimpresos.com${url}`, '_blank');
  }

  downloadFile(idEditorial: string): void {
    window.open(`https://www.gaimpresos.com/text/jalorganismos:${idEditorial}`, '_blank');
  }

  open(index: number, url: string): void {
    const urlImage = `https://www.gaimpresos.com${url}.jpg`;
    const album = {
      src: urlImage,
      caption: '',
      thumb: urlImage
   };
   const _albums = [];
   _albums.push(album);
    this._lightbox.open(_albums, index);
  }

  close(): void {
    this._lightbox.close();
  }
}
