import { Component, OnInit, Input } from '@angular/core';
import {
  MatSnackBar,
  MatDialog
} from '@angular/material';
import { Utils } from '../../constants/utils';


@Component({
  selector: 'app-donut-graph',
  templateUrl: './donut-graph.component.html',
  styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {
  @Input() portfolio: any = null;
  @Input() project: any;
  @Input() stage: any = null;
  @Input() subproject: any = null;
  @Input() type: any = null;
  @Input() mini: Boolean = false;
  @Input() style: any = null;
  @Input() styleCenter: any = null;
  @Input() dataArray: any = null;
  @Input() color: any = null;
  data: any = null;
  colorDefault: String = '#9e9e9e';
  avance = 0;
  isPortfoli = false;
  colorCenter = '#eaeaea';
  colorTitleCenter = '#666';

  colorGreen = '#1AAE60';
  colorYellow = '#FFD938';
  colorRed = '#D6264A';

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private utils: Utils
  ) { }

  ngOnInit(): void {
    if (!this.portfolio.projects){
      this.portfolio['projects'] = [this.portfolio];
      if (this.type === 'portfolio'){
        this.portfolio['projects']['project_stages'] = this.portfolio.project_stages;
      }
    }
    let res = 100;
    if (this.getCompletedTasks() !== 0 && this.getTotalTasks() !== 0) {
      this.avance = this.getAvance();
      res = 100 - this.avance;
    }
    if (this.dataArray !== null){
      this.data = this.dataArray;
    } else {
      this.data = [{ name: '', data: []}, { name: '', data: []}, { name: '', data: []}, { name: '', data: []}];
    }

    this.data.push({
      name: '',
      data: [{
        category: '',
        value: this.avance,
        color: this.getColor()
      }, {
        category: '',
        value: res,
        color: this.getColorRes()
      }]
    });
  }

  getColorRes(): string{
    if (this.type === 'subproject' && this.subproject){
      return '#fff';
    }
    return '#ccc';
  }

  isPortfolio(): boolean{
    return this.portfolio.projects.length > 1;
  }

  getProject(): any{
    return this.portfolio.projects[0];
  }

  getNameDependency(): string{
    const project = this.getProject();
    return project.dependency.name;
  }

  getColor(): String {
    if (this.color){
      return this.color;
    }

    if (this.type === 'project' && this.project){
      return this.project.dependency.color ? this.project.dependency.color : this.colorDefault;
    }

    if (this.type === 'subproject' && this.subproject || this.type === 'stage' && this.stage){
      const semaphore = this.getAdvanceSemaphoreTask();

      if (this.avance === 100){
        return this.colorGreen;
      }

      if (semaphore === 0 && ( (this.subproject && this.subproject.tasks_all > 0) || (this.stage && this.stage.tasks_all > 0) ) ) {
        this.colorCenter = this.colorRed;
        this.colorTitleCenter = 'white';
      }
      return (semaphore >= 50) ? this.colorGreen : (semaphore >= 20 && semaphore <= 49) ? this.colorYellow : this.colorRed;
    }
    
    const project = this.getProject();
    return project.dependency.color ? project.dependency.color : this.colorDefault;
  }

  getIcon(): string{
    return this.isPortfolio() ? 'folder' : 'bookmark_border';
  }

  getAdvanceSemaphoreTask(): number{
    let total = 0;
    if (this.type === 'project' && this.project){
      this.project.project_stages.forEach(stage => {
        total += stage.semaphore;
      });
      return total;
    } else if (this.type === 'stage'){
      total = this.stage.semaphore;
      return total;
    } else if (this.type === 'subproject'){
      total = this.subproject.semaphore;
      return total;
    } else {
      this.portfolio.projects.forEach(project => {
        project.project_stages.forEach(stage => {
          total += stage.semaphore;
        });
      });
      return Math.trunc(total);
    }
  }

  getAvance(): number {
    switch (this.type) {
      case 'portfolio':
          return this.utils.getNewPorcent(this.type, this.portfolio);
      case 'project':
          if (this.project === undefined){
            return this.utils.getNewPorcent(this.type, this.portfolio.projects[0]);
          } else {
            return this.utils.getNewPorcent(this.type, this.project);
          }
      case 'stage':
          return this.utils.getNewPorcent(this.type, this.stage);
      case 'subproject':
          return this.utils.getNewPorcent(this.type, this.subproject);
    }
    return 0;
  }

  getCompletedTasks(): number {
    let total = 0;
    if (this.type === 'project' && this.project){
      return this.project.tasks_finished;
    } else if (this.type === 'stage'){
      return this.stage.tasks_finished;
    } else if (this.type === 'subproject'){
      return this.subproject.tasks_finished;
    } else {
      this.portfolio.projects.forEach(project => {
        total += project.tasks_finished;
      });
      return total;
    }
  }

  getTotalTasks(): number {
    let total = 0;
    if (this.type === 'project' && this.project){
      return this.project.tasks_all;
    } else if (this.type === 'stage'){
      return this.stage.tasks_all;
    } else if (this.type === 'subproject'){
      return this.subproject.tasks_all;
    } else {
      this.portfolio.projects.forEach(project => {
        total += project.tasks_all;
      });
      return total;
    }
  }

  styleCenterObject(): any{
    if (this.styleCenter){
      this.styleCenter.background = this.colorCenter;
      this.styleCenter.color = this.colorTitleCenter;
      return this.styleCenter;
    }
    return {};
  }
}
