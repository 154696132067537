import { Component, OnInit, ViewChild } from '@angular/core'
import { MembersService } from '../members.service'
import { fuseAnimations } from '@fuse/animations'
import { AuthService } from '../../services/auth.service'
import {
	MatSnackBar,
	MatDialogRef,
	MatDialog,
	MatTableDataSource,
	MatPaginator,
	MatSort
} from '@angular/material'
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component'
import { DialogAddMembersComponent } from '../dialog-add-members/dialog-add-members.component'
import { Location } from '@angular/common'
import { Utils } from '../../constants/utils'
import { ChangePasswordComponent } from 'app/change-password/change-password.component'
import { Router } from '@angular/router'

@Component({
	selector: 'app-index-members',
	templateUrl: './index-members.component.html',
	styleUrls: ['./index-members.component.scss'],
	animations: fuseAnimations
})
export class IndexMembersComponent implements OnInit {
	displayedColumns: string[] = [
		'name',
		'username',
		'coordination',
		'phone',
		'permissions',
		'updated',
		'reset_pass'
	]
	public dataSource: MatTableDataSource<any>
	dialogForm: any
	passForm: any
	confirmFormDialog: MatDialogRef<FuseConfirmDialogComponent>
	modulesLabels = [
		{ module: 'stages', label: 'Etapas' },
		{ module: 'subprojects', label: 'Acciones' },
		{ module: 'tasks', label: 'Actividades' },
		{ module: 'communication', label: 'Comunicación' },
		{ module: 'coordination', label: 'Coordinación' }
	]

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: false }) sort: MatSort

	constructor(
		private membersService: MembersService,
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		public authService: AuthService,
		private location: Location,
		private utils: Utils,
		private router: Router
	) {}

	ngOnInit(): void {
		/* has permisions*/
		if (this.authService.isGovernment()) {
			this.router.navigate(['/portafolios'])
		}

		location.hash = ''
		this.index()

		const spanishRangeLabel = (
			page: number,
			pageSize: number,
			length: number
		) => {
			if (length === 0 || pageSize === 0) {
				return `0 de ${length}`
			}

			length = Math.max(length, 0)

			const startIndex = page * pageSize

			const endIndex =
				startIndex < length
					? Math.min(startIndex + pageSize, length)
					: startIndex + pageSize

			return `${startIndex + 1} - ${endIndex} de ${length}`
		}

		this.paginator._intl.itemsPerPageLabel = 'Registros por página'
		this.paginator._intl.nextPageLabel = 'Siguiente'
		this.paginator._intl.previousPageLabel = 'Anterior'
		this.paginator._intl.lastPageLabel = 'Ultima página'
		this.paginator._intl.firstPageLabel = 'Primer página'
		this.paginator._intl.getRangeLabel = spanishRangeLabel
	}

	openDialog(): void {
		window.history.pushState({}, '', location.pathname + '#')
		this.dialogForm = this.dialog.open(DialogAddMembersComponent, {
			panelClass: 'mat-dialog-system'
		})

		this.dialogForm.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}

				return false
			}
			this.location.back()

			this.snackBar.open('Miembro agregado', 'OK', {
				duration: 3500
			})

			this.index()
		})
	}

	openChangePassword(member: any): void {
		window.history.pushState({}, '', location.pathname + '#')
		this.passForm = this.dialog.open(ChangePasswordComponent, {
			panelClass: 'dialog-change-password',
			data: {
				member
			}
		})

		this.passForm.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}

				return false
			}
			this.location.back()

			this.snackBar.open('Contraseña actualizada correctamente', 'OK', {
				duration: 3500
			})
		})
	}

	delete($id: number): void {
		this.membersService.delete($id).subscribe((data: any) => {
			this.snackBar.open('Miembro Eliminado', 'Ok', {
				verticalPosition: 'top',
				duration: 2000
			})
		})
	}

	index(): void {
		this.membersService.index().subscribe((data: any) => {
			const members = data.map((member: any) => {
				if (member.permission === 'Etapas') {
					member.permission = 'Colaborador'
				}
				const m_last = member.m_last_name ? member.m_last_name : ''
				member.name_complete = `${member.name} ${member.last_name} ${m_last}`
				return member
			})

			this.dataSource = new MatTableDataSource(members)
			this.dataSource.paginator = this.paginator
			this.dataSource.filterPredicate = this.utils.filterTable()
		})
	}

	applyFilter(filterValue: string): void {
		this.dataSource.filter = this.removeAccents(
			filterValue.trim().toLowerCase()
		)
	}

	validateDependency(member: any): string {
		if (!!member.coordination) {
			return member.coordination.name
		}

		if (
			member.dependency == null ||
			typeof member.dependency.name === 'undefined'
		) {
			return ''
		} else {
			return member.dependency.name
		}
	}

	getPermissions(member: any): string {
		let module = ''
		if (member.modules === null) {
			// module = member.rol_id === 1 ? 'Administrador' : member.rol_id === 2 ? 'Dependencia' : '';
			module = member.rol_id === 1 ? 'Administrador' : ''
		} else {
			const label = this.modulesLabels.find(
				(moduleLabel) => moduleLabel.module === member.modules.module.name
			)
			module = !label ? '' : label.label
		}

		return module
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}

		return strAccentsOut.join('')
	}

	public isAdmin(): boolean {
		return !this.authService.isAdmin() || this.authService.isCoordination()
	}

	public isOnlyAdmin(): boolean {
		return this.authService.isAdmin()
	}

	openEditDialog(member): void {
		window.history.pushState({}, '', location.pathname + '#')
		this.dialogForm = this.dialog.open(DialogAddMembersComponent, {
			panelClass: 'mat-dialog-system',
			data: {
				member: member
			}
		})

		this.dialogForm.afterClosed().subscribe((response) => {
			if (!response) {
				if (response !== undefined) {
					this.location.back()
				}

				return false
			}
			this.location.back()

			this.snackBar.open('Miembro agregado', 'OK', {
				duration: 3500
			})

			this.index()
		})
	}
}
