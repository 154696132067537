import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UpsService } from '../../ups/ups.service'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from 'app/services/auth.service'

@Component({
	selector: 'app-dashboard-grouper-directorates',
	templateUrl: './dashboard-grouper-directorates.component.html',
	styleUrls: ['./dashboard-grouper-directorates.component.scss']
})
export class DashboardGrouperDirectoratesComponent implements OnInit {
	groups = []
	path: string
	coordinacionId: string
	public rol: string = ''

	constructor(
		private router: Router,
		private upService: UpsService,
		private route: ActivatedRoute,
		private authService: AuthService
	) {}

	ngOnInit() {
		this.rol = this.authService.rol
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this._getDirectorates()
	}

	private _getDirectorates() {
		this.upService
			.perSpecificCoordination(+this.coordinacionId)
			.subscribe((result) => {
				this.groups = [...result]
			})
	}

	public handlerDirectorateSelected(direc) {
		this.router.navigate([
			'/dashboard/coordinaciones/' +
				this.coordinacionId +
				'/direcciones/' +
				direc.id
		])
	}

	back() {
		this.router.navigate(['/dashboard/coordinaciones/'])
	}
}
