import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar.component.html',
  styles: [`
    .loading-spinner-snack {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }
    mat-spinner {
      margin-right: 15px;
    }
  `]
})
export class SnackBarComponent{
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
