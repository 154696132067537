import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DetailProjectsService } from '../../projects/detail-projects/detail-projects.service';
import { DialogAddTasksComponent } from '../../tasks/dialog-add-tasks/dialog-add-tasks.component';
import { MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { DialogAddSubprojectComponent } from '../dialog-add-subproject/dialog-add-subproject.component';
import { AuthService } from '../../services/auth.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import * as leaflet from 'leaflet';
import * as leafletDrawJS from 'leaflet-draw';
import * as translate from 'leaflet-draw-locales';
import { MapOptions } from '../dialog-add-subproject/mapOptions';
import { ConfirmComponent } from 'app/dialogs/confirm/confirm.component';
import { SubprojectsService } from '../subprojects.service';
import { Location } from '@angular/common';
import { Action } from 'app/reports/models/action.model';
import { CommunicationService } from '../../services/communication.service';
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component';
import { Globals } from 'app/globals';
import { saveAs } from 'file-saver';
import { Utils } from 'app/constants/utils';
import { RingChartComponent } from 'app/reports/ring-chart/ring-chart.component';
import { FinancingChartComponent } from 'app/reports/financing-chart/financing-chart.component';
import { SnackBarComponent } from 'app/general/components/snack-bar-component/snack-bar.component';
import { ActionDetailComponent } from 'app/dialogs/action-detail/action-detail.component';

@Component({
  selector: 'app-detail-subprojects',
  templateUrl: './detail-subprojects.component.html',
  styleUrls: ['./detail-subprojects.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailSubprojectsComponent implements OnInit {
  @ViewChild('subprojectProgress', {static: true}) subprojectProgress: RingChartComponent;
  @ViewChild('projectProgress', {static: true}) projectProgress: RingChartComponent;
  @ViewChild('stageProgress', {static: true}) stageProgress: RingChartComponent;
  @ViewChild('financingChart', {static: true}) financingChart: FinancingChartComponent;
  imagesProgress = {
    subprojectProgress: '',
    projectProgress: '',
    stageProgress: '',
    financingChart: '',
  };

  loadingTasks = true;  
  action: any;
  project: any;
  stageId: number;
  detailsShown: Boolean = false;
  displayedColumns: string[] = ['name', 'user', 'date_estimated_finished', 'status'];
  dataSource: MatTableDataSource<any[]>;
  status = [
    { code: 'created', value: 'Nueva' },
    { code: 'processing', value: 'Procesando' },
    { code: 'finished', value: 'Finalizada' },
    { code: 'cancelled', value: 'Cancelada' },
    { code: 'paused', value: 'Pausada' },
  ];
  private uri: string;
  public searchTask: string;
  private map: any;
  private mapOptions: any;
  private fundingSources: {
    id: Number,
    name: string
  }[] = [];
  public columnsSplits: string[] = ['split', 'amount'];
  public splitsAction: MatTableDataSource<any>;

  public columnsRisk: string[] = ['type', 'description'];
  public risks: MatTableDataSource<any>;

  private typesIndicator: any[] = [];
  private coverages: any[] = [];
  private dimensionIndicators: any[] = [];
  private measurementFrequencies: any[] = [];
  private measurementUnits: any[] = [];

  public filesFederalSaved: string[] = [];
  public filesInternationalSaved: string[] = [];
  public filesMunicipalSaved: string[] = [];
  public filesOthersSaved: string[] = [];
  public filesImpactSaved: string[] = [];
  public filesResultSaved: string[] = [];
  dataComment: any;
  isResponsibleAction = false;

  centerStyle = {
    'background': '#EAEAEA',
    'border': '8px solid #FFF',
    'padding-top': '25%'
  };

  centerStyleCustom = {
    'background': '',
    'color': '#FFFFFF',
    'border': '8px solid #FFF',
    'padding-top': '25%'
  };

  colorGreen = '#1AAE60';
  colorYellow = '#FFD938';
  colorRed = '#D6264A';
  public downloadingAction = false;

  constructor(
    private detailProjectsService: DetailProjectsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private subprojectsService: SubprojectsService,
    private location: Location,
    private global: Globals,
    private utils: Utils,
    private communicationService: CommunicationService
  ) {
    this.uri = global.api;
    this.splitsAction = new MatTableDataSource();
    this.risks = new MatTableDataSource();
    this.consultCatalog();
  }

  ngOnInit(): void {
    location.hash = '';
    this.mapOptions = new MapOptions();

    this.detailProjectsService.action.subscribe(action => {
      if (action.tasks !== undefined) {
        this.subprojectProgress.porcent = this.utils.getNewPorcent('subproject', action);
      }
      this.action = action;
      this.searchTask = '';
      this.reset();

      if (action.responsable) {
        this.isResponsibleAction = this.isResponsible(action.responsable.id);
      }

      if (action && action.subprojects_financing) {
        const index = action.subprojects_financing.findIndex(fun => fun.funding_sources_id === 1);
        if (index >= 0) {
          this.splitsAction.data = action.subprojects_financing[index].splits_action;
        }
      }

      if (typeof action.risks !== 'undefined') {
        this.risks.data = action.risks;
      }

      if (typeof this.action.id !== 'undefined') {
        this.subprojectsService.getFilesAll(this.action.id).subscribe(files => {
          this.filesFederalSaved = files.federal;
          this.filesInternationalSaved = files.international;
          this.filesMunicipalSaved = files.municipal;
          this.filesOthersSaved = files.others;
          this.filesImpactSaved = files.impact;
          this.filesResultSaved = files.result;
        });
      }

      this.subprojectProgress.centerStyle = this.centerStyle;

      const semaphore = this.action.semaphore;
      const completeAdvance = this.action.tasks_finished === this.action.tasks_all && this.action.tasks_all > 0;
      const centerStyleNew = JSON.parse(JSON.stringify(this.centerStyleCustom));

      if (completeAdvance){
        centerStyleNew.background = this.colorGreen;
        this.subprojectProgress.color = this.colorGreen;
        return;
      } 
      
      if (this.action && this.action.semaphore === 0 && this.action.tasks_all > 0) {
        centerStyleNew.background = this.colorRed;
        this.subprojectProgress.centerStyle = centerStyleNew;
        this.subprojectProgress.color = this.colorRed;
      }

      if (!completeAdvance) {
        this.subprojectProgress.color = (semaphore >= 50) ? this.colorGreen : (semaphore >= 20 && semaphore <= 49) ? this.colorYellow : this.colorRed;
      }
    });

    this.detailProjectsService.project.subscribe((project: any) => {
      if (project.project_stages !== undefined) {
        this.financingChart.portfolio = project.portfolio;
        this.financingChart.project = project;
        this.financingChart.loadInfo();
        this.projectProgress.color = project.dependency.color;
        this.projectProgress.porcent = this.utils.getNewPorcent('project', project);
      }
      this.project = project;
      if (typeof project.fundingSources !== 'undefined') {
        this.fundingSources = project.fundingSources;
      }
    });

    this.detailProjectsService.stage.subscribe((stage: any) => {
      this.stageId = stage.id;
      if (stage.subprojects !== undefined) {
        this.stageProgress.porcent = this.utils.getNewPorcent('stage', stage);

        this.stageProgress.centerStyle = this.centerStyle;

        const semaphore = stage.semaphore;
        const completeAdvance = stage.tasks_finished === stage.tasks_all && stage.tasks_all > 0;
        const centerStyleNew = JSON.parse(JSON.stringify(this.centerStyleCustom));

        if (completeAdvance){
          centerStyleNew.background = this.colorGreen;
          this.stageProgress.color = this.colorGreen;
          return;
        } 
        
        if (stage && stage.semaphore === 0 && stage.tasks_all > 0) {
          centerStyleNew.background = this.colorRed;
          this.stageProgress.centerStyle = centerStyleNew;
          this.stageProgress.color = this.colorRed;
        }

        if (!completeAdvance) {
          this.stageProgress.color = (semaphore >= 50) ? this.colorGreen : (semaphore >= 20 && semaphore <= 49) ? this.colorYellow : this.colorRed;
        }
      }
    });

    this.detailProjectsService.tasks.subscribe(tasks => {
      tasks = tasks.reduce((data, task) => {
        task.status_es = this.getStatus(task);
        task.user_name = task.user.name;
        task.user_lastname = task.user.last_name;
        task.user_email = task.user.email;
        data.push(task);
        return data;
      }, []);
      this.dataSource = new MatTableDataSource(tasks);
    });

    this.detailProjectsService.loading.tasks.subscribe(data => {
      this.loadingTasks = data;
    });
  }

  isResponsible(id): boolean {
    return id === this.auth.id;
  }

  goToCurrentStage(): void {
    this.detailProjectsService.goToCurrentStage();
  }

  toggleDetails(): void {

    this.detailsShown = this.detailsShown ? false : true;

    if (typeof this.map !== 'undefined') {
      this.map.remove();
    }

    this.map = leaflet.map('map2').setView(this.mapOptions.pointInitial, this.mapOptions.zoomInitial - 2);
    const lang = translate('es');
    this.map.drawLocal = lang;
    leaflet.tileLayer(this.mapOptions.templateLayer).addTo(this.map);

    const drawItems = new leaflet.FeatureGroup();
    this.map.addLayer(drawItems);

    this.mapOptions.drawItems = drawItems;

    if (this.detailsShown) {
      if (typeof this.action.geom !== 'undefined' && this.action.geom && this.action.geom.geometries && typeof this.action.geom.geometries !== 'undefined') {
        const radius = (this.action.radius !== null) ? this.action.radius : JSON.parse(this.action.array_radius);
        if (this.action.geom !== null) {        
          let circleElem = 0;
          this.action.geom.geometries.forEach(geoms => {
            let radiusValue = null;          
            if (geoms.type === 'Point' && radius !== null) {
              radiusValue = radius;
              if (radius.length > 0) {
                radiusValue = radius[circleElem];
                circleElem++;
              }
            }
            this.mapOptions.draw(geoms.type, geoms.coordinates, '', this.map, null, null, radiusValue, false, false, true);
          });
        }
      }
    }
  }

  get labelSeeDetails(): string {
    return this.detailsShown ? 'Cerrar detalles' : 'Ver detalles';
  }

  reset(): void {
    this.detailsShown = false;
  }

  openDialogTask(update: boolean, task: any): void {
    update = typeof update !== 'undefined';
    window.history.pushState({}, '', location.pathname + '#');
    const dialogRef = this.dialog.open(DialogAddTasksComponent, {
      panelClass: 'mat-dialog-system',
      width: '80%',
      height: '500px',
      disableClose: true,
      data: {
        subproject: this.action.id,
        update: update,
        task: typeof task === 'undefined' ? null : task.id
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        let msn = update ? 'Actividad Actualizada' : 'Actividad Agregada';

        if (typeof response !== 'undefined') {
          if (response.status !== true) {
            msn = 'Error';
          }

          if (!response) {
            if (response !== undefined) {
              this.location.back();
            }
            return false;
          }

          this.snackBar.open(msn, 'OK', { duration: 2000 });
          this.detailProjectsService.refreshTasks(update ? false : true);
          this.detailProjectsService.refreshProject();
          this.detailProjectsService.refreshStages();
          this.detailProjectsService.refreshActions();

          this.location.back();
        }
      });
  }

  goToTask(task: any): void {
    this.detailProjectsService.goToTask(task.id);
    this.detailProjectsService.refreshTasks();
  }

  openDialogAction(): void {
    window.history.pushState({}, '', location.pathname + '#');
    const dialog = this.dialog.open(DialogAddSubprojectComponent, {
      panelClass: 'mat-dialog-system-md',
      width: '90%',
      height: '450px',
      disableClose: true,
      data: {
        project: {
          id: this.project.id,
          name: this.project.name,
          stageId: this.stageId
        },
        action: {
          id: this.action.id
        }
      }
    });

    dialog.afterClosed()
      .subscribe(response => {
        if (!response || (response && response.length > 0 && response[1] === false)) {
          if (response !== undefined) {
            this.location.back();
          }
          return false;
        }
        this.location.back();

        this.snackBar.open(response[0], 'OK', {
          duration: 3500
        });
        this.detailProjectsService.refreshActions(false);
        this.detailProjectsService.refreshProject();
        this.detailProjectsService.refreshStages();
        this.detailProjectsService.refreshTasks();
      });

  }

  getStatus(task: any): String {

    const index = this.status.findIndex(x => x.code === task.status);

    if (index < 0) {
      return '';
    }

    return this.status[index].value;

  }

  applyFilter(): void {
    this.dataSource.filter = this.searchTask.trim().toLowerCase();
  }

  hasAvatar(user: any): boolean {
    if (!user) {
      return false;
    } else if (!user.avatar) {
      return false;
    }

    return user.avatar.length <= 0 ? false : true;
  }

  getImgAvatar(user: any): string {
    return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none';
  }

  showInitials(user: any): string {
    return user && user.responsable ? `${user.responsable.initials}` : '';
  }

  getUserFullName(user: any): string {
    if (!user) {
      return '';
    } else if (!user.responsable) {
      return '';
    }

    return user.responsable.name + ' ' + user.responsable.last_name;
  }

  drop(event: CdkDragDrop<string[]>): void {
    const tasks: any = this.dataSource.data;
    const task_id = tasks[event.previousIndex].id;
    const current_position = tasks[event.previousIndex].position;
    const new_position = event.currentIndex;
    moveItemInArray(tasks, event.previousIndex, event.currentIndex);
    this.dataSource.data = tasks;
    this.detailProjectsService.setOrderTask(this.action.id, task_id, current_position, new_position)
      .subscribe(result => {
        this.detailProjectsService.refreshTasks();
        this.detailProjectsService.refreshProject();
        this.detailProjectsService.refreshStages();
        this.detailProjectsService.refreshActions();
      }, error => {
        this.snackBar.open('Ocurrió un error', 'OK', {
          duration: 3500
        });
      });
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      panelClass: 'dialog-confirm',
      data: {
        content: `<label class="text-center title-confirm">¿Estás seguro que quieres eliminar la Acción <strong><br>"${this.action.name}"</strong>?<label>`
      }
    });

    dialogRef.afterClosed()
      .subscribe(response => {
        if (response) {
          this.subprojectsService.delete(this.detailProjectsService.action.value.id).subscribe(() => {
            this.snackBar.open('Acción Eliminar', 'Cerrar', {
              duration: 3500
            });
            this.detailProjectsService.refreshActions(true);
            this.detailProjectsService.refreshProject();
            this.detailProjectsService.refreshStages();
            this.detailProjectsService.refreshTasks();
          });
        }
      });
  }

  getAuthorizedAmountTotal(action: any): number {
    return 0;
  }

  getFundingSources(action: any): string {
    const funding = this.fundingSources.find(source => source.id === action.funding_sources_id);
    return funding ? funding.name : '';
  }

  consultCatalog(): void {
    this.subprojectsService.dataShow().subscribe(data => {
      this.typesIndicator = data.typesIndicator;
      this.coverages = data.coverages;
      this.dimensionIndicators = data.dimensionIndicators;
      this.measurementFrequencies = data.measurementFrequencies;
      this.measurementUnits = data.measurementUnits;
    });
  }

  getTypeIndicator(id: number): string {
    const typeIndicator = this.typesIndicator.find(type => type.id === id);
    return typeIndicator ? typeIndicator.name : '';
  }

  getCoverages(id: number): string {
    const coverage = this.coverages.find(cov => cov.id === id);
    return coverage ? coverage.name : '';
  }

  getDimensionIndicators(id: number): string {
    const dimensionIndicator = this.dimensionIndicators.find(dimen => dimen.id === id);
    return dimensionIndicator ? dimensionIndicator.name : '';
  }

  getMeasurementFrequencies(id: number): string {
    const measurementFrequencie = this.measurementFrequencies.find(mFrecu => mFrecu.id === id);
    return measurementFrequencie ? measurementFrequencie.name : '';
  }

  getMeasurementUnits(id: number): string {
    const measurementUnit = this.measurementUnits.find(mUnit => mUnit.id === id);
    return measurementUnit ? measurementUnit.name : '';
  }

  getSenceIndicator(sence: string): string {
    if ((sence ? sence : '').toLowerCase() === 'asc') {
      return 'Ascendente';
    }

    return 'Descendente';
  }

  getNameFile(file: string): string {
    const split = file.split('/');
    return split[split.length - 1];
  }

  toggle(action: Action): void {
    this.dataComment = {
      'name': 'Acción: ' + action.name,
      'level_name': 'action',
      'element_id': action.id
    };
    this.communicationService.emitChange(this.dataComment);
  }

  openUserInfoDialog(action: any): void {
    this.dialog.open(UserInfoComponent, {
      panelClass: 'dialog-user-info',
      data: {
        user: action.project_managers[0].user,
        up: action.project_managers[0].user.up
      }
    });
  }

  detailDepend(data: any): void {
    this.dialog.open(ActionDetailComponent, {
      panelClass: 'dialog-action-detail',
      data: {
        action: data
      }
    });
  }

  async downloadAction(): Promise<void> {
    if (this.downloadingAction){
      return;
    }
    this.downloadingAction = true;
    const snack = this.snackBar.openFromComponent(SnackBarComponent, { 
      data: {
        message: 'Descargando ficha'
      }
    });
    await this.createImages();

    this.subprojectsService.getActionFile(this.action.id, this.imagesProgress).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, 'ficha_accion.pdf');
      snack.dismiss();
      this.downloadingAction = false;
    });
  }

  // tslint:disable-next-line: typedef
  private async createImages() {
  // tslint:disable-next-line: forin
    for (const type in this.imagesProgress) {
      switch (type) {
        case 'subprojectProgress':
          await this.subprojectProgress.createImage(type);
          this.imagesProgress[type] = this.subprojectProgress.imgUrl;
          break;
        case 'projectProgress':
          await this.projectProgress.createImage(type);
          this.imagesProgress[type] = this.projectProgress.imgUrl;
          break;
        case 'stageProgress':
          await this.stageProgress.createImage(type);
          this.imagesProgress[type] = this.stageProgress.imgUrl;
          break;
        case 'financingChart':
          await this.financingChart.createImage(type);
          this.imagesProgress[type] = this.financingChart.imgUrl;
          break;
      }
    }
  }
}

