export class Task {
	id: number
	date_assignment: any
	date_estimated_finished: any
	status: string
	name: string
	responsible: String = ''
	colorStatus = '#3DAD54'
	semaphore = 0
	user_id?: number
	comment?: string

	constructor(
		id?: number,
		date_assignment?: any,
		date_estimated_finished?: any,
		status?: string,
		name?: string,
		responsible?: any
	) {
		this.id = id ? id : 0
		this.date_assignment = date_assignment ? date_assignment : null
		this.date_estimated_finished = date_estimated_finished
			? date_estimated_finished
			: null
		this.status = status ? status : 'created'
		this.name = name ? name : ''
		this.responsible = responsible ? responsible : ''
	}
}
