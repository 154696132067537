import { Task } from './task.model';

export class Action {
    id: number;
    name: string;
    stage_id: number;
    up_id: number;
    up: string;
    check: Boolean = true;
    stageId: number;
    tasks: Task[];
    subprojects_financing: any[];
    municipalities: any[];
    responsable: { id: number, name: string, last_name: string, m_last_name: string };
    totalFinancing = 0;
    benefitedDirect = 0;
    benefitedIndirect = 0;
    geom: any;
    radius: number;
    typeFinancy: string;
    semaphore = 0;

    constructor(
      id: number, 
      name: string, 
      stageId: number, 
      tasks?: Task[],
      stage_id?: number,
      up_id?: number,
      up?: string,
      subprojects_financing?: any[],
      municipalities?: any[],
      responsable?: any,
      semaphore?: number
      ) {
      this.id = id;
      this.name = name;
      this.stageId = stageId;
      this.tasks = tasks ? tasks : [];
      this.stage_id = stage_id ? stage_id : 0;
      this.subprojects_financing = subprojects_financing;
      this.up_id = up_id ? up_id : 0;
      this.up = up ? up : '';
      this.municipalities = municipalities ? municipalities : [];
      this.responsable = <{ id: number, name: string, last_name: string, m_last_name: string }>responsable;
      this.radius = 0;
      this.typeFinancy = '';
      this.semaphore = semaphore;
    }
  }
