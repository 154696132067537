import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, MatSnackBar} from '@angular/material';
import { ProjectsService } from '../projects.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { fuseAnimations } from '@fuse/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import { Dependency } from '../../dependencies/dependency';

@Component({
  selector: 'app-form-projects',
  templateUrl: './form-projects.component.html',
  styleUrls: ['./form-projects.component.scss'],
  animations : fuseAnimations
})
export class FormProjectsComponent implements OnInit {
  action: String = 'create';
  form: FormGroup;
  formErrors: any;  
  addOnBlur = true;
  dependencies: Dependency[] = [];  
  dependenciesOptions: Dependency[] = [];  
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('dependencyInput', {static: false}) dependencyInput: ElementRef<HTMLInputElement>;
  filteredDependencies: Observable<string[]>;
  dependenciesCtrl = new FormControl();  
  dependenciesAdded = <any>[];
  allDependencies: any = [];
  saving: Boolean = false;

  constructor(
    private formBuilder: FormBuilder, 
    private projectsService: ProjectsService,
    private fuseSplashScreen: FuseSplashScreenService,    
    private router: Router,
    public snackBar: MatSnackBar
  ) {    

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({      
      name: ['', Validators.required],           
      dependencies: this.formBuilder.array([], Validators.required)
    });

    this.projectsService.init().subscribe((data: any) => {    
      this.dependencies = data.dependencies;
      this.allDependencies = data.dependencies;

      this.filteredDependencies = this.dependenciesCtrl.valueChanges.pipe(
        startWith(null),
        map((dependency: string | null) => dependency ? this._filter(dependency) : this.allDependencies.slice()));      

    }); 

  }

  removeDependency(dependency: any): void {    
    const formDependencies = <FormArray>this.form.get('dependencies') as FormArray;
    const i = formDependencies.controls.findIndex(x => x.value.id === dependency.id);

    if (i >= 0) {
      formDependencies.removeAt(i);
    }  

    const index = this.dependenciesAdded.indexOf(dependency);
    this.dependenciesAdded.splice(index, 1);
  }

  addDependency(event: MatChipInputEvent): void {  }

  selectedDependency(event: MatAutocompleteSelectedEvent): void {

    const value = event.option.value;               
    const repeated = this.dependenciesAdded.find(data => data.id === value.id);

    if (!!!repeated) {

      const formDependencies = <FormArray>this.form.get('dependencies') as FormArray;
      formDependencies.push(new FormControl(value));      
      this.dependenciesAdded.push(value);      
      this.dependencyInput.nativeElement.value = '';
      this.dependenciesCtrl.setValue(null);

    }            
        
  }

  private _filter(value: any): any[] {        
    return this.allDependencies.filter(dependency => dependency.name.toLowerCase().toLowerCase().indexOf(value) === 0);
  }

  save(): any {

    if (!this.form.valid) {
      return false;
    }

    this.saving = true;

    this.projectsService.store(this.form).subscribe(data => {
      this.router.navigate(['jal/proyectos']);        
    }); 

  }

}
