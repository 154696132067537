export class Project {
    id: number;
    name: string;    
    dependencies: any[];    
    dependency: any;
    subprojects: any[];
    portfolio_id: number;
    stages_finished?: Number;
    stages_all?: Number;
    is_visualizer?: boolean;
    termn_begin?: string;
    termn_end?: string;
    spending_destination?: string;
    description?: string;
    objective?: string;
    allowed_dependency?: string;
    permissions_comments?: string;
    is_admin?: string;
    permissions_general_reports?: string;
    allowed_stages?: string;
    allowed_subprojects?: string;
    permissions_gantt_diagram?: string;
    municipalities: any[];
    regions: any[];
    multiyear_budget: any;
    territorial_scale: string;
    link_communication?: string;
    website?: any;
    website_user?: any;
    website_password?: any;
    portfolio: {
        name: string;
        promise: string;
        projects: any;
        territorial_scale?: string;
        municipalities?: any[];
        regions?: any[];
        multiyear_budget?: any;
    };
    user: {
        id: number;
        name: string;
        last_name: string;
        initials: string;
    };
    responsible: {
        id: number;
        name: string;
        last_name: string;
        initials: string;
    };
    project_stages: any[];
    constructor(
            id: number  = 0, 
            name: string = '', 
            dependencies: any = [], 
            subprojects: any = [],
            portfolio_id: number = 0) {
        this.id = id;
        this.portfolio_id = portfolio_id;
        this.user =  {
            id: 0,
            name: '',
            last_name: '',
            initials: ''
        };
        this.responsible = {
            id: 0,
            name: '',
            last_name: '',
            initials: ''
        };
        this.name = name;
        this.dependencies = dependencies;
        this.subprojects = subprojects;
        this.portfolio = {
            name: '',
            promise: '',
            projects: []
        };
        this.project_stages = [];                
    }
}
