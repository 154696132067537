import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class VisualizerService {

  private url: string;
  private module: string;
  private httpOptions: any;

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { 
    this.url = globals.api;
    this.module = 'visualizer';
    this.httpOptions = globals.httpOptions;
  }

  public index(): Observable<any> {
    return this.http.get(`${this.url}${this.module}`, this.httpOptions);
  }

  public users(): Observable<any> {
    return this.http.get(`${this.url}${this.module}/users`, this.httpOptions);
  }

  public usersByProject(id: Number): Observable<any> {
    return this.http.get(`${this.url}${this.module}/usersByProject/${id}`, this.httpOptions);
  }

  public save(id: Number, user: any): Observable<any> {
    return this.http.post(`${this.url}${this.module}/${id}`, user, this.httpOptions);
  }

  public delete(id: Number, user: any): Observable<any> {
    return this.http.delete(`${this.url}${this.module}/${id}/${user}`, this.httpOptions);
  }
}
