import { Component, OnInit, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateEmpty } from 'app/validations/empty.validator';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ComunicationService } from 'app/comunication/services/comunication.service';
import { Comunication } from 'app/comunication/models/comunication';
import { Up } from 'app/ups/up.model';

@Component({
  selector: 'app-comunication-form',
  templateUrl: './comunication-form.component.html',
  styleUrls: ['./comunication-form.component.scss']
})
export class ComunicationFormComponent implements OnInit {
  comunication: Comunication = null;
  Ups: Up[];
  loading: boolean = true;
  saving: Boolean = false;
  //form: FormGroup;
  form = new FormBuilder().group({
    name: ['', Validators.required],
    lastName: ['', Validators.required],
    mLastName: '',
    email: ['', [Validators.required, Validators.email]],
    up_id: ['', Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<ComunicationFormComponent>,
    private formBuilder: FormBuilder,
    private comunicationService: ComunicationService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { 
    dialogRef.disableClose = true;
    if(data !== undefined && data !== null){
      this.loading = true;
      if(data.comunication !== null){
        this.comunication = data.comunication;
        this.setComunication();
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.getUps();
  }

  getUps(){
    this.comunicationService.getAllUps().subscribe(
      result => {
        this.Ups = result;
      },
      error => {
        console.log("Error: " + error);
      }
    );
  }

  setComunication(){
    this.form.get('name').setValue(this.comunication.name);
    this.form.get('lastName').setValue(this.comunication.last_name);
    this.form.get('mLastName').setValue(this.comunication.m_last_name);
    this.form.get('email').setValue(this.comunication.email);
    this.form.get('up_id').setValue(this.comunication.up_id);
  }

  formToComunication(){
    if(this.comunication === null)
      this.comunication = new Comunication();
    this.comunication.name = this.form.get('name').value;
    this.comunication.last_name = this.form.get('lastName').value;
    this.comunication.m_last_name = this.form.get('mLastName').value;
    this.comunication.email = this.form.get('email').value;
    this.comunication.up_id = this.form.get('up_id').value;
  }

  save(){
    this.formToComunication();
    this.comunicationService.addOrUpdate(this.comunication).subscribe(
      result => {
        this.dialogRef.close(result);
        console.log("Guardado");
      },
      error => {
        console.log("Error: " + error);
      }
    );
  }

}
