import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoordinationListComponent } from './components/coordination-list/coordination-list.component';
import { CoordinationsRoutingModule } from './coordinations.routing.module';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatPaginatorModule, MatTableModule, MatButtonModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    CoordinationListComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    CdkTableModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    NgxMaskModule.forRoot(),
    FuseSharedModule,
    CoordinationsRoutingModule,
  ]
})
export class CoordinationsModule { }
