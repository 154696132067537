import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class CoordinationService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'coordinations';
    this.httpOptions = global.httpOptions;
  }

  // index(): Observable<any> {
  //   return this.http.get(this.url + this.module, this.httpOptions);
  //   return this.http.get(this.uri + this.module + '/all', this.httpOptions);
  // }

  getAll(): Observable<any> {
    return this.http.get(this.uri + this.module + '/all', this.httpOptions);
  }

  createOrUpdate(params): Observable<any> {
    return this.http.post(this.uri + this.module + '/save', params, this.httpOptions);
  }
}
