import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'list'
})
export class ListPipe implements PipeTransform {

  transform(value: any[], filters: string[], column: string): any {
    value = value.filter((item) => {
      return !filters.includes(item[column]);
    });
    return value;
  }

}
