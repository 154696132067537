export class Stage {
    id: number;
    name: string;
    description: string;    
    subprojects: any[];
    position: any;
    date_finished: any;
    date_initial: any;

    constructor() { 
        this.id = 0;
        this.name = '';
        this.description = '';
        this.subprojects = <any>[];
        this.position = '';
        this.date_finished = null;
        this.date_initial = null;
      }
}
