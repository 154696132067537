import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTooltipModule,
  MatMenuModule,
  MatButtonToggleModule
} from '@angular/material';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';

import { IndexComponent } from './index/index.component';
import { VisualizerDialogComponent } from './dialog/dialog.component';

const routes: Routes = [
  {
    path: 'permisos',
    component: IndexComponent,
    canActivate: [AuthGuard]
  }
];  


@NgModule({
  declarations: [IndexComponent, VisualizerDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
    FuseSharedModule,
    CdkTableModule,
    MatMenuModule,
    MatButtonToggleModule
  ], 
  entryComponents: [
    VisualizerDialogComponent
  ],
  exports: [VisualizerDialogComponent]
})
export class VisualizerModule { }
