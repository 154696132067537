import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatChipInputEvent, MatStepper, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Dependency } from 'app/dependencies/dependency';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { GeoService } from 'app/services/geo.service';
import { ProjectsService } from '../projects.service';

export interface TerritorialScale {
  value: string;
  viewValue: string;
}

export interface Responsible {
  id: number;
  name: string;
}

export interface Municipality {
  id: number;
  name: string;
}

export interface Region {
  id: number;
  name: string;
}

@Component({
  selector: 'app-dialog-edit-project',
  templateUrl: './dialog-edit-project.component.html',
  styleUrls: ['./dialog-edit-project.component.scss']
})

export class DialogEditProjectComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  saving: Boolean = false;
  // maxLengthDescription = 250;
  maxLengthObjective = 300; 

  // maxLengthName = 30;
  horizontalStepperStep1: FormGroup;
  horizontalStepperStep2: FormGroup;
  horizontalStepperStep3: FormGroup;
  projectData: any;
  minDate = new Date(2018, 11, 6);
  maxDate = new Date(2024, 11, 5);
  formErrors: any;
  date = { begin: new Date(), end: this.getTomorrowDate() };
  axisCtrl = new FormControl();

  territorialScales: TerritorialScale[] = [
    { value: 'estatal', viewValue: 'Estatal' },
    { value: 'municipal', viewValue: 'Municipal' },
    { value: 'regional', viewValue: 'Regional' }
  ];
  municipalitiesAdded = <any>[];
  @ViewChild('municipalityInput', {static: false}) municipalityInput: ElementRef<HTMLInputElement>;
  municipalitiesCtrl = new FormControl('', [Validators.required]);
  municipalities: Dependency[] = [];
  filteredMunicipalities: Observable<Municipality[]>;

  @ViewChild('regionInput', {static: false}) regionInput: ElementRef<HTMLInputElement>;
  regions: Region[] = [];
  catalogRegions: Region[] = [];
  filteredRegions: Observable<Region[]>;
  regionCtrl = new FormControl('', [Validators.required]);
  regionsAdded = <any>[];

  // Responsible
  responsibleObject: any = [];
  filteredResponsibles: Observable<any[]>;
  responsibleCtrl = new FormControl();

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private projectsService: ProjectsService,
    public dialogRef: MatDialogRef<DialogEditProjectComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private geoService: GeoService,

  ) {
    dialogRef.disableClose = true;
    this.projectData = data.project;
    this.responsibleObject = data.members;
    this.formErrors = {
      name: {},
      termn: {},
      description: {},
      objective: {},
      axisId: {},
      responsible: {},
      dependencies: {},
      uegs: {},
      municipalities: {},
      regions: {}
    };
  }

  ngOnInit(): void {
    this.horizontalStepperStep1 = this.formBuilder.group({
      name: ['', Validators.required],
      termn: [{ 'begin': new Date(), 'end': this.getTomorrowDate() }, Validators.required],
      promise: [false],
      type: ['Estratégico'],
      responsible: [''],
      multiyearBudget: ['']
    });
    this.horizontalStepperStep2 = this.formBuilder.group({
      description: ['', Validators.required],
      objective: ['', Validators.required],
      axisId: ['']
    });
    this.horizontalStepperStep3 = this.formBuilder.group({
      territorialScale: ['', Validators.required],
      municipalities: this.formBuilder.array([]),
      regions: this.formBuilder.array([]),
      benefitedPopulation: [null]
    });

    this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
      startWith(null),
      map((municipality: string | null) => municipality ? this._filterMunicipalities(municipality) : this.municipalities.slice())
    );

    this.geoService.municipalities.subscribe(result => {
      this.municipalities = result;
      this.loadMunicipalities();
    });

    this.geoService.regions.subscribe(result => {
      this.regions = result;
      this.loadRegions();
    });

    this.filteredResponsibles = this.axisCtrl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterResponsible(val))
      );

    this.loadEditProyect();
  }

  getTomorrowDate(): any {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  }

  loadEditProyect(): void {
    // step 1
    this.horizontalStepperStep1.get('name').setValue(this.projectData.portfolio.name);
    this.horizontalStepperStep1.get('termn').setValue({
      'begin': new Date(this.getStringDate(this.projectData.termn_begin)),
      'end': new Date(this.getStringDate(this.projectData.termn_end))
    });
    this.date = {
      begin: new Date(this.getStringDate(this.projectData.termn_begin)),
      end: new Date(this.getStringDate(this.projectData.termn_end))
    };
    if (this.projectData.portfolio.promise) {
      this.horizontalStepperStep1.get('promise').setValue(true);
    }
    this.horizontalStepperStep1.get('responsible').setValue(this.projectData.responsible.id);
    this.responsibleCtrl.patchValue(`${this.projectData.responsible.name} ${this.projectData.responsible.last_name} ${this.projectData.responsible.m_last_name}`);
    this.horizontalStepperStep1.get('type').setValue(this.projectData.type);
    this.horizontalStepperStep1.get('multiyearBudget').setValue(this.projectData.multiyear_budget);

    this.horizontalStepperStep1.get('type').disable();

    // step 2
    this.horizontalStepperStep2.get('description').setValue(this.projectData.description);
    this.horizontalStepperStep2.get('objective').setValue(this.projectData.objective);
    if (this.projectData.axis.name !== undefined) {
      this.axisCtrl.setValue(this.projectData.axis.name);
    }
      
    this.axisCtrl.disable();

    // step 3
    this.horizontalStepperStep3.get('territorialScale').setValue(this.projectData.territorial_scale);
    this.horizontalStepperStep3.get('benefitedPopulation').setValue(this.projectData.benefited_population);
    this.projectData.municipalities.forEach((elem, index) => {
      this.addMunicipalitytoList(elem.municipality);
    });
    
    this.projectData.regions.forEach((elem, index) => {
      this.addRegionToList(elem.region);
    });
    
    this.horizontalStepperStep1.get('name').disable();
    //this.horizontalStepperStep1.get('promise').disable();
  }

  getStringDate(date: string): string {
    const newDate = date.split('/');
    return newDate[1] + '-' + newDate[0] + '-' + newDate[2];
  }

  public changeValueTerritorialScale(event): void {
    if (event.value === 'municipal') {
      this.horizontalStepperStep3.get('municipalities').setValidators(Validators.required);
      this.horizontalStepperStep3.get('municipalities').updateValueAndValidity();
      this.clearValidatorsScale('regions');
    } else if (event.value === 'regional') {
      this.horizontalStepperStep3.get('regions').setValidators(Validators.required);
      this.horizontalStepperStep3.get('regions').updateValueAndValidity();
      this.clearValidatorsScale('municipalities');
    } else {
      this.clearValidatorsScale('municipalities');
      this.clearValidatorsScale('regions');
    }
  }

  public clearValidatorsScale(type: string): void {
    this.horizontalStepperStep3.get(type).clearValidators();
    this.horizontalStepperStep3.get(type).updateValueAndValidity();
  }

  isTerritorialScaleMunicipal(): boolean {
    if (this.horizontalStepperStep3.get('territorialScale').value === 'municipal') {
      return true;
    } else {
      return false;
    }
  }

  isTerritorialScaleRegional(): boolean {
    if (this.horizontalStepperStep3.get('territorialScale').value === 'regional') {
      return true;
    } else {
      return false;
    }
  }

  removeMunicipality(municipality: any): void {
    const formMunicipalities = <FormArray>this.horizontalStepperStep3.get('municipalities') as FormArray;
    const i = formMunicipalities.controls.findIndex(x => x.value.id === municipality.id);
    if (i >= 0) {
      formMunicipalities.removeAt(i);
    }
    const index = this.municipalitiesAdded.indexOf(municipality);
    this.municipalitiesAdded.splice(index, 1);
    this.filterMunicipalities(municipality, 2);
  }

  selectedMunicipality(event: MatAutocompleteSelectedEvent): void {
    this.addMunicipalitytoList(event.option.value);
  }

  addMunicipalitytoList(value: any): void {
    const repeated = this.municipalitiesAdded.find(data => data.id === value.id);
    if (!!!repeated) {
      const formMunicipalities = <FormArray>this.horizontalStepperStep3.get('municipalities') as FormArray;
      formMunicipalities.push(new FormControl(value));
      this.municipalitiesAdded.push(value);
      if (this.municipalityInput) {
        this.municipalityInput.nativeElement.value = '';
      }
      this.municipalitiesCtrl.setValue(null);
    }
    this.filterMunicipalities(value, 1);
  }

  loadMunicipalities(): void {
    this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
      startWith(null),
      map((municipality: string | null) => municipality ? this._filterMunicipalities(municipality) : this.municipalities.slice()));
  }

  loadRegions(): void {
    this.filteredRegions = this.regionCtrl.valueChanges.pipe(
      startWith(null),
      map((region: string | null) => region ? this._filterRegions(region) : this.regions.slice()));
  }

  filterMunicipalities(el, action): void {
    if (action === 1) {
      this.municipalities = this.municipalities.filter(option => {
        return option.id !== el.id;
      });
    } else {
      this.municipalities.push(el);
    }
    this.filteredMunicipalities = this.municipalitiesCtrl.valueChanges.pipe(
      startWith(null),
      map((municipality: string | null) => municipality ? this._filterMunicipalities(municipality) : this.municipalities.slice()));
  }

  private _filterMunicipalities(value: any): any[] {
    return this.municipalities.filter(municipality => {
      const municipalityName = this.removeAccents(municipality.name.toLowerCase());

      if (typeof value === 'object') {
        return false;
      }

      value = this.removeAccents(value);
      return municipalityName.indexOf(value) > -1;
    });
  }

  private _filterRegions(value: any): any[] {
    return this.regions.filter(region => {
      const regionName = this.removeAccents(region.name.toLowerCase());

      if (typeof value === 'object') {
        return false;
      }

      value = this.removeAccents(value);
      return regionName.indexOf(value) > -1;
    });
  }

  filterRegions(el, action): void {
    if (action === 1) {
      this.regions = this.regions.filter(option => {
        return option.id !== el.id;
      });
    } else {
      this.regions.push(el);
    }
    this.filteredRegions = this.regionCtrl.valueChanges.pipe(
      startWith(null),
      map((region: string | null) => region ? this._filterRegions(region) : this.regions.slice()));
  }

  private removeAccents(strAccents: any): string {
    strAccents = strAccents.split('');
    const strAccentsOut = new Array();
    const strAccentsLen = strAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) !== -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }

    return strAccentsOut.join('');
  }

  addMunicipality(event: MatChipInputEvent): void { }

  addRegion(event: MatChipInputEvent): void { }

  removeRegion(region: any): void {
    const formRegions = <FormArray>this.horizontalStepperStep3.get('regions') as FormArray;
    const i = formRegions.controls.findIndex(x => x.value.id === region.id);
    if (i >= 0) {
      formRegions.removeAt(i);
    }
    const index = this.regionsAdded.indexOf(region);
    this.regionsAdded.splice(index, 1);
    this.filterRegions(region, 2);
  }

  selectedRegion(event: MatAutocompleteSelectedEvent): void {
    this.addRegionToList(event.option.value);
  }

  addRegionToList(value: any): void {
    const repeated = this.regionsAdded.find(data => data.id === value.id);
    if (!!!repeated) {
      const formRegions = <FormArray>this.horizontalStepperStep3.get('regions') as FormArray;
      formRegions.push(new FormControl(value));
      this.regionsAdded.push(value);
      if (this.regionInput) {
        this.regionInput.nativeElement.value = '';
      }
      this.regionCtrl.setValue(null);
    }
    this.filterRegions(value, 1);
  }

  back(step: MatStepper): void {
    step.previous();
  }

  next(step: MatStepper): void {
    step.next();
  }

  stepLength(step: MatStepper): number {
    if (typeof step._steps === 'undefined') {
      return 0;
    }
    return (step._steps.length - 1);
  }

  formValid(): boolean {
    if (!this.horizontalStepperStep1.valid || !this.horizontalStepperStep2.valid || !this.horizontalStepperStep3.valid) {
      return false;
    }
    return true;
  }

  saveProject(): any {
    const projectData: any = JSON.parse(JSON.stringify(this.projectData));
    projectData.portfolio.name = this.horizontalStepperStep1.get('name').value;
    projectData.termn_begin = this.horizontalStepperStep1.get('termn').value.begin;
    projectData.termn_end = this.horizontalStepperStep1.get('termn').value.end;
    projectData.portfolio.promise = this.horizontalStepperStep1.get('promise').value;
    projectData.type = this.horizontalStepperStep1.get('type').value;
    projectData.multiyear_budget = this.horizontalStepperStep1.get('multiyearBudget').value;
    projectData.responsible_user = this.horizontalStepperStep1.get('responsible').value;

    // step 2
    projectData.description = this.horizontalStepperStep2.get('description').value;
    projectData.objective = this.horizontalStepperStep2.get('objective').value;

    // step 3
    projectData.territorial_scale = this.horizontalStepperStep3.get('territorialScale').value;
    projectData.benefited_population = this.horizontalStepperStep3.get('benefitedPopulation').value;


    projectData.municipalities = [];
    this.municipalitiesAdded.forEach((elem, index) => {
      const temp = { municipality: elem };
      projectData.municipalities.push(temp);
    });

    projectData.regions = [];
    this.regionsAdded.forEach((elem, index) => {
      const temp = { region: elem };
      projectData.regions.push(temp);
    });

    return projectData;
  }

  save(): any {
    if (!this.formValid()) {
      return false;
    }
    this.saving = true;
    const dataObject = this.saveProject();
    this.projectsService.update(this.projectData.id, dataObject).subscribe(data => {
      this.saving = false;
      this.dialogRef.close(true);
    }, error => {
      this.saving = false;
      const keys = Object.keys(error.error);
      this.snackBar.open(error.error[keys[0]], 'OK', {
        duration: 3500
      });
    });
  }

  optionSelectedResponsible(event): void {
    let selected = [];
    selected = this.responsibleObject.filter(option => {
      return option.name.toLowerCase().includes(event.option.value.toLowerCase());
    });
    if (selected.length > 0){
      this.horizontalStepperStep1.get('responsible').patchValue(selected[0].id);
      this.responsibleCtrl.patchValue(`${selected[0].name} ${selected[0].last_name} ${selected[0].m_last_name}`);
    }
  }

  filterResponsible(val: any): string[] {
    if (!val){
      return this.responsibleObject;
    }
    return this.responsibleObject.filter(option => {
      return option.name.toLowerCase().includes(val.toLowerCase());
    });
  }

  onBlurResponsible(event): void {
    if (event.target.value.length === 0){
      this.horizontalStepperStep1.get('responsible').setValue(this.projectData.responsible.id);
      this.responsibleCtrl.patchValue(`${this.projectData.responsible.name} ${this.projectData.responsible.last_name} ${this.projectData.responsible.m_last_name}`);
    }
  }
}
