import {
	Component,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	ElementRef
} from '@angular/core'
import { GanttEditorComponent } from 'ng-gantt'
import { GanttService } from '../ganttService'
import { ActivatedRoute, Router } from '@angular/router'
import { ProjectsService } from 'app/projects/projects.service'
import { AuthService } from 'app/services/auth.service'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { MatDialog } from '@angular/material'
import { ExcelService } from '../excel.service'
import { Task } from 'app/tasks/task'
import { Utils } from '../../constants/utils'
import { isNullOrUndefined } from 'util'
import * as moment from 'moment'

@Component({
	selector: 'index-gantt',
	templateUrl: './index-gantt.component.html',
	styleUrls: ['./index-gantt.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class IndexGanttComponent implements OnInit {
	@ViewChild('editor', { static: false }) editor: GanttEditorComponent
	public editorOptions: any = {}
	public data: any = []
	vUseSingleCell = '0'
	vShowRes = '0'
	vShowCost = '0'
	vShowComp = '0'
	vShowDur = '0'
	vShowStartDate = '0'
	vShowEndDate = '0'
	vShowPlanStartDate = '0'
	vShowPlanEndDate = '0'
	vShowEndWeekDate = '0'
	vShowTaskInfoLink = '0'
	vDebug = 'false'
	vEditable = 'false'
	vUseSort = 'false'
	vLang = 'es'
	delay = 150
	selected = 'week'

	roundProgressConfig = {
		radius: 30,
		stroke: 8
	}

	project: any = []
	loadingGantt = true

	constructor(
		private ganttService: GanttService,
		private route: ActivatedRoute,
		private projectsService: ProjectsService,
		private router: Router,
		private auth: AuthService,
		public dialog: MatDialog,
		private excelService: ExcelService,
		private utils: Utils
	) {}

	ngOnInit() {
		const idProject = parseInt(
			this.route.snapshot.paramMap.get('projectId'),
			10
		)

		/*Validar Proyecto Existente*/
		this.projectsService.getNew(idProject).subscribe((response) => {
			if (!response.body) {
				return
			}

			if (response.body) {
				if (
					Object.entries(response.body).length === 0 &&
					response.body.constructor === Object
				) {
					this.router.navigate(['/portafolios'])
					return
				}
			}
		})
		/*Validar Proyecto Existente*/
		/*
    if ( ! this.hasModuleAllowed('permissions_gantt') ) {
      const permmision = this.hasModuleAllowed('subprojects');
      if (!permmision){
        this.router.navigate(['/portafolios']);
        return;
      }
    }
    */

		this.ganttService.getProject(idProject).subscribe((data) => {
			this.project = data
			this.data = this.initialData()
			this.configTable()
			this.loadingGantt = false
		})
	}

	hasModuleAllowed(module): boolean {
		return this.auth.hasPermission(module)
	}

	setLanguage(lang) {
		this.editorOptions.vLang = lang
		this.editor.setOptions(this.editorOptions)
	}

	customLanguage() {
		this.editor.setOptions(this.editorOptions)
	}
	/********************************************************************* */

	getInitialsName(): string {
		if (!this.project.user) {
			return ''
		}

		return this.project.user.initials
	}

	getPartialName(): string {
		if (typeof this.project.user !== 'undefined') {
			const name = this.project.user.name + ' ' + this.project.user.last_name
			return name.replace(/(([^\s]+\s\s*){2})(.*)/, '$1…')
		}
		return ''
	}

	getResponsible(): string {
		if (typeof this.project.responsible === 'undefined') {
			return ''
		}
		return this.project.responsible.name
			? `${this.project.responsible.name} ${this.project.responsible.last_name}`
			: ''
	}

	hasAvatar(user: any): boolean {
		if (!user) {
			return false
		}

		if (typeof user.avatar === 'undefined') {
			return false
		}
		return user.avatar.length <= 0 ? false : true
	}

	getImgAvatar(user: any): string {
		return this.hasAvatar(user) ? `url("${user.avatar}")` : 'none'
	}

	getUserFullName(user: any): string {
		return (
			user.name +
			' ' +
			user.last_name +
			(user.m_last_name ? ' ' + user.m_last_name : '')
		)
	}

	getRadiusProgress(): Number {
		return this.roundProgressConfig.radius
	}

	getCompletedStages(): Number {
		return this.project.project_stages.filter(
			(element: any) =>
				element.total_subproject === element.completed_subprojects &&
				element.total_subproject > 0
		).length
	}

	getStagesProgress(): any {
		return this.utils.getNewPorcent('project', this.project)
	}

	getTotalStages(): Number {
		return this.project.project_stages.length
	}

	getStrokeProgress(): Number {
		return this.roundProgressConfig.stroke
	}

	convertDateFormat(date: string, type: number, object: any) {
		if (!date) {
			let tempDate: any
			tempDate = isNullOrUndefined(date) ? '' : date
			if (!isNullOrUndefined(object))
				object.subprojects.forEach((element: any) => {
					let temp: any
					if (type === 1) {
						temp = this.dateFormat(element.date_initial)
						tempDate = this.minorStartDate(tempDate, temp)
					}
					if (type === 2) {
						temp = this.dateFormat(element.date_finished)
						tempDate = this.majorFinishDate(tempDate, temp)
					}
				})
			return tempDate
		}
		let newDate: any = date.split('/')
		newDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
		return newDate
	}

	dateFormat(date: string): string {
		if (date != '' && !isNullOrUndefined(date)) {
			let temp = date.split('/')
			return temp[2] + '-' + temp[1] + '-' + temp[0]
		}
		return ''
	}

	minorStartDate(first: string, second: string): string {
		if (!isNullOrUndefined(second) && second != '')
			return new Date(first) < new Date(second) ? first : second
		return first
	}

	majorFinishDate(first: string, second: string): string {
		if (!isNullOrUndefined(second) && second != '')
			return new Date(first) > new Date(second) ? first : second
		return first
	}

	// getClass(calcSemaphore: number, avance: number, tasksCount) {
	//     if (tasksCount === 0) {
	//         return 'gtaskpurple';
	//     }

	//     if (avance === 100) {
	//         return 'gtaskgreen';
	//     }

	//     if (calcSemaphore === 0) {
	//         return 'gtaskred';
	//     }

	//     return calcSemaphore >= 50
	//         ? 'gtaskgreen'
	//         : calcSemaphore >= 20 && calcSemaphore <= 49
	//         ? 'gtaskyellow'
	//         : 'gtaskred';
	// }

	calcuSemaphoreColor(
		initDate: string,
		endDate: string,
		isFinished: boolean = false
	) {
		let date1 = moment(initDate, 'DD/MM/YYYY')
		let date2 = moment(endDate, 'DD/MM/YYYY')
		let totalDiff = date2.diff(date1, 'days')
		let dateNow = moment()
		let currentDiff = date2.diff(dateNow, 'days')
		let porcent = (currentDiff * 100) / totalDiff

		if (isFinished) {
			return 'ggroupblack'
		} else {
			return this.getColor(porcent)
		}
	}

	getColor(porcent: number) {
		if (porcent <= 20) {
			return 'gtaskred'
		}
		if (porcent > 20 && porcent < 50) {
			return 'gtaskyellow'
		}
		if (porcent >= 50) {
			return 'gtaskgreen'
		}
	}

	initialData() {
		let dataInitial: any = []
		this.project.project_stages.forEach((stage: any) => {
			let isProjectFinished = false
			if (stage.semaphore >= 100) isProjectFinished = true

			dataInitial.push({
				pID: stage.id + '_stage',
				pName: stage.name,
				pStart: this.convertDateFormat(stage.date_initial, 1, stage),
				pEnd: this.convertDateFormat(stage.date_finished, 2, stage),
				pClass: this.calcuSemaphoreColor(
					stage.date_initial,
					stage.date_finished,
					isProjectFinished
				),
				pComp: Math.trunc(this.calculateAdvance('stage', stage)),
				pGroup: 1,
				pParent: 0,
				pLink: '',
				pMile: 0,
				pRes: '',
				pOpen: 1,
				pDepend: '',
				pCaption: '',
				pNotes: ''
			})

			stage.subprojects.forEach((action: any) => {
				let isStageFinished = false
				if (action.semaphore >= 100) isStageFinished = true
				dataInitial.push({
					pID: action.id + '_action',
					pName: '    ' + action.name,
					pStart: this.convertDateFormat(action.date_initial, 0, null),
					pEnd: this.convertDateFormat(action.date_finished, 0, null),
					pClass: this.calcuSemaphoreColor(
						action.date_initial,
						action.date_finished,
						isStageFinished
					),
					pComp: Math.trunc(this.calculateAdvance('subproject', action)),
					pGroup: 1,
					pParent: stage.id + '_stage',
					pLink: '',
					pMile: 0,
					pRes: '',
					pOpen: 1,
					pDepend: '',
					pCaption: '',
					pNotes: ''
				})

				action.tasks.forEach((task: any) => {
					let isTasksFinished = false
					if (task.status === 'finished') isTasksFinished = true
					dataInitial.push({
						pID: task.id + '_task',
						pName: '        ' + task.name,
						pStart: this.convertDateFormat(task.date_assignment, 0, null),
						pEnd: this.convertDateFormat(task.date_estimated_finished, 0, null),
						pClass: this.calcuSemaphoreColor(
							task.date_assignment,
							task.date_estimated_finished,
							isTasksFinished
						),
						pComp: '',
						pGroup: 0,
						pParent: action.id + '_action',
						pLink: '',
						pMile: 0,
						pRes: '',
						pOpen: 1,
						pDepend: '',
						pCaption: '',
						pNotes: ''
					})
				})
			})
		})
		return dataInitial
	}

	calculateAdvance(type: string, object: any): number {
		return this.utils.getNewPorcent(type, object)
	}

	getCompletedTasksAdvance(type: String, object: any): number {
		let total = 0
		if (type === 'stage') {
			object.subprojects.forEach((sub) => {
				total += sub.tasks_finished
			})
			return total
		} else if (type === 'subproject') {
			object.tasks.forEach((task) => {
				if (task.status === 'finished') {
					total += 1
				}
			})
			return total
		}
		return total
	}

	getTotalTasksAdvance(type: String, object: any): number {
		let total = 0
		if (type === 'stage') {
			object.subprojects.forEach((sub) => {
				total += sub.tasks_all
			})
			return total
		} else if (type === 'subproject') {
			object.tasks.forEach((task) => {
				total += 1
			})
			return total
		}
	}

	configTable() {
		const vAdditionalHeaders = {
			pStart: {
				title: 'Inicio'
			},
			pEnd: {
				title: 'Fin'
			}
		}

		this.editorOptions = {
			vCaptionType: 'Complete', // Set to Show Caption : None,Caption,Resource,Duration,Complete,
			vQuarterColWidth: 36,
			vDateTaskDisplayFormat: 'day dd month yyyy', // Shown in tool tip box
			vDayMajorDateDisplayFormat: 'mon yyyy - Week ww', // Set format to display dates in the "Major" header of the "Day" view
			vWeekMinorDateDisplayFormat: 'dd mon', // Set format to display dates in the "Minor" header of the "Week" view
			vLang: this.vLang,
			vUseSingleCell: this.vUseSingleCell,
			vShowRes: parseInt(this.vShowRes, 10),
			vShowCost: parseInt(this.vShowCost, 10),
			vShowComp: parseInt(this.vShowComp, 10),
			vShowDur: parseInt(this.vShowDur, 10),
			vShowStartDate: parseInt(this.vShowStartDate, 10),
			vShowEndDate: parseInt(this.vShowEndDate, 10),
			vShowPlanStartDate: parseInt(this.vShowPlanStartDate, 10),
			vShowPlanEndDate: parseInt(this.vShowPlanEndDate, 10),
			vShowTaskInfoLink: parseInt(this.vShowTaskInfoLink, 10), // Show link in tool tip (0/1)
			vShowEndWeekDate: parseInt(this.vShowEndWeekDate, 10),
			vAdditionalHeaders: vAdditionalHeaders,
			vResources: [],
			vEventClickRow: console.log,
			vTooltipDelay: this.delay,
			vDebug: this.vDebug === 'true',
			vEditable: this.vEditable === 'true',
			vUseSort: this.vUseSort === 'true',
			vFormatArr: [/* 'Day', */ 'Week', 'Month'],
			pFormat: /* 'day' */ 'week'
		}
		this.editor.setOptions(this.editorOptions)
	}

	goBack() {
		window.history.back()
	}

	openUserInfoDialog(project: any): void {
		const dialog = this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: project.responsible,
				up: project.dependency
			}
		})
	}

	generateXls() {
		const namePortfolio =
			this.project && this.project.portfolio ? this.project.portfolio.name : ''

		const weekFormatButtonId = this.editor.id + 'formatweektop'
		const monthFormatButtonId = this.editor.id + 'formatmonthtop'
		const week = document.getElementById(weekFormatButtonId)
		const monthFormatButton = document.getElementById(monthFormatButtonId)

		const gSelected = 'gselected'
		let blockSelected = 'day'
		for (let pointer in week.classList) {
			if (gSelected === week.classList[pointer]) {
				blockSelected = 'week'
			}
		}

		for (let pointer in monthFormatButton.classList) {
			if (gSelected === monthFormatButton.classList[pointer]) {
				blockSelected = 'month'
			}
		}

		switch (blockSelected) {
			case 'day':
				this.excelService.generateExcel(
					this.data,
					namePortfolio,
					this.project.dependency,
					this.getResponsible()
				)
				break
			case 'week':
				this.excelService.generateExcelByWeek(
					this.data,
					namePortfolio,
					this.project.dependency,
					this.getResponsible()
				)
				break
			case 'month':
				this.excelService.generateExcelByMounth(
					this.data,
					namePortfolio,
					this.project.dependency,
					this.getResponsible()
				)
				break
			default:
				this.excelService.generateExcel(
					this.data,
					namePortfolio,
					this.project.dependency,
					this.getResponsible()
				)
				break
		}
	}

	editValue(list, task, event, cell, column) {
		// tslint:disable-next-line:triple-equals
		const found = list.find((item) => item.pID == task.getOriginalID())
		if (!found) {
			return
		} else {
			found[column] = event ? event.target.value : ''
		}
	}
}
