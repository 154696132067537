import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private uri: string;
  private module: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private global: Globals) {
    this.uri = global.api;
    this.module = 'tags';
    this.httpOptions = global.httpOptions;
  }

  getAll(idPortfolio: number): Observable<any> {
    return this.http.get(`${this.uri}portfolios/${idPortfolio}/${this.module}`, this.httpOptions);
  }

  get(id: number): Observable<any> {
    return this.http.get(this.uri + this.module + '/' + id, this.httpOptions);
  }

  store(idPortfolio: number, tag: any): Observable<any> {
    return this.http.post(`${this.uri}portfolios/${idPortfolio}/${this.module}`, { tag: tag }, this.httpOptions);
  }

  show(id): Observable<any> {
    return this.http.get(this.uri + this.module + '/' + id, this.httpOptions);
  }

  update(idPortfolio: number, tags: any): Observable<any> {
    return this.http.put(`${this.uri}portfolios/${idPortfolio}/${this.module}`, { tags: tags }, this.httpOptions);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.uri + this.module + '/' + id, this.httpOptions);
  }

}
