import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { fuseAnimations } from '@fuse/animations'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { UserInfoComponent } from 'app/dialogs/user-info/user-info.component'
import { DashboardService } from '../dashboard.service'

@Component({
	selector: 'app-detail-project-dashboard',
	templateUrl: './detail-project-dashboard.component.html',
	styleUrls: ['./detail-project-dashboard.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None
})
export class DetailProjectDashboardComponent implements OnInit {
	portfolio: any
	id_portfolio: any
	id_project: any
	project: any
	type1 = 'portfolio'
	type2 = 'project'
	type3 = 'stage'
	type4 = 'subproject'
	mini = true
	sizeMini = '110px'
	sizeMini2 = '90px'
	styleMini = { width: this.sizeMini, height: this.sizeMini, fontSize: '16px' }
	styleMini2 = {
		width: this.sizeMini2,
		height: this.sizeMini2,
		fontSize: '14px'
	}
	colorStage = '#243984'
	styleCenter = {
		background: '#eaeaea',
		'border-radius': '50px',
		height: '92%',
		width: '92%',
		display: 'flex',
		'align-items': 'center',
		'justify-content': 'center',
		color: '#656565',
		padding: '0px'
	}

	styleCenterStage = JSON.parse(JSON.stringify(this.styleCenter))

	goLeft: Boolean = false
	goRight: Boolean = false
	interval = null
	coordinacionId = null
	directorateId = null

	constructor(
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private route: ActivatedRoute
	) {
		this.styleCenterStage.height = '100%'
		this.styleCenterStage.width = '100%'
		this.styleCenterStage.padding = '24px'
	}

	ngOnInit(): void {
		this.coordinacionId = this.route.snapshot.paramMap.get('coordinationId')
		this.directorateId = this.route.snapshot.paramMap.get('directorateId')
		this.id_portfolio = this.route.snapshot.paramMap.get('portfolioId')
		this.id_project = parseInt(
			this.route.snapshot.paramMap.get('projectId'),
			10
		)
		this.dashboardService
			.getDetailProject(this.id_portfolio, this.id_project)
			.subscribe((response) => {
				this.portfolio = response
				this.project = this.portfolio.projects.filter((project: any) => {
					return project.id === this.id_project
				})
				this.project = this.project.length === 1 ? this.project[0] : {}

				if (this.project.project_stages.length >= 4) {
					this.goRight = true
				}
			})
	}

	getColorDependency(): string {
		return this.project.dependency.color
	}

	getColorDependencySubproject(user: any): string {
		return user.up ? user.up.color : '#000'
	}

	getData(): any {
		return [
			{ name: '', data: [] },
			{ name: '', data: [] }
		]
	}

	getRouterLink(stage: any, subproject: any): any {
		return [
			'/dashboard',
			'coordinaciones',
			this.coordinacionId,
			'direcciones',
			this.directorateId,
			'portafolio',
			this.id_portfolio,
			'proyecto',
			this.id_project,
			'etapa',
			stage.id,
			'accion',
			subproject.id
		]
	}

	scrollLeft(e: any): void {
		this.scroll(-1)
	}

	scrollRight(e: any): void {
		this.scroll(1)
	}

	scroll(direction: number): void {
		if (this.interval) {
			clearInterval(this.interval)
			this.interval = null
		}
		const elem = document.getElementById('cont-main')
		const last = elem.scrollLeft
		let pos = 0
		this.interval = setInterval(() => {
			if (pos >= 250) {
				clearInterval(this.interval)
				this.interval = null
				this.goLeft = elem.scrollLeft === 0 ? false : true
				this.goRight =
					elem.scrollLeft + elem.offsetWidth === elem.scrollWidth ? false : true
			} else {
				pos += 20
				elem.scrollLeft = last + direction * pos
			}
		})
	}

	openUserInfoDialog(user: any): void {
		this.dialog.open(UserInfoComponent, {
			panelClass: 'dialog-user-info',
			data: {
				user: user,
				up: user.up
			}
		})
	}
}
