import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatTableDataSource
} from '@angular/material'
import { AxisService } from '../axis.service'
import { MatSnackBar } from '@angular/material'
import { Axis } from '../axis.model'
import { SubAxis } from '../subaxis.model'
import { MembersService } from '../../members/members.service'
import { AuthService } from 'app/services/auth.service'

@Component({
	selector: 'app-dialog-axis',
	templateUrl: './dialog-axis.component.html',
	styleUrls: ['./dialog-axis.component.scss']
})
export class DialogAxisComponent implements OnInit {
	color: any
	public form: FormGroup
	public saving: Boolean = false
	public title: String = 'Crear Eje'
	public buttonText: String = 'Crear'
	public sub_axis_platform: any[] = []
	public subAxisTable: MatTableDataSource<SubAxis>
	public displayedColumns = ['nameSubAxis', 'removeSubAxis']
	public coordinations: { id: number; name: string }[] = []

	constructor(
		private formBuilder: FormBuilder,
		private service: AxisService,
		private snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<DialogAxisComponent>,
		private membersService: MembersService,
		public authService: AuthService,
		@Inject(MAT_DIALOG_DATA)
		public data: { axis: Axis; coordinations: { id: number; name: string }[] }
	) {
		dialogRef.disableClose = true
		this.sub_axis_platform = data.axis.sub_axis_platform
		if (this.data.axis.id > 0) {
			this.title = 'Actualizar Eje'
			this.buttonText = 'Actualizar'
		}

		this.subAxisTable = new MatTableDataSource()
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			id: [this.data.axis.id],
			name: [this.data.axis.name, Validators.required],
			topic: [this.data.axis.topic, Validators.required],
			coordination_id: [this.data.axis.coordination_id, Validators.required],
			sub_axis: [''],
			sub_axis_object: []
		})

		this.sub_axis_platform.map((subAxis) => {
			subAxis.remove = 0
		})

		this.subAxisTable.data = this.sub_axis_platform

		this.coordinations = this.data.coordinations

		/*this.membersService.coordinations().subscribe((coordinations) => {
			this.coordinations = <[{ id: number; name: string }]>coordinations
			if (this.authService.isAdmin()) {
				this.coordinations = this.coordinations.filter(
					(coordination) => coordination.name !== 'coordination'
				)
			}
		})*/
	}

	addSubAxis() {
		let index = -1
		let name = this.removeAccents(
			this.form.controls.sub_axis.value.trim().toLowerCase()
		)

		if (this.subAxisTable.data && this.subAxisTable.data.length > 0) {
			index = this.subAxisTable.data.findIndex(
				(subAxis) =>
					this.removeAccents(subAxis.name.trim().toLowerCase()) == name
			)
			if (index == -1) {
				this.subAxisTable.data.unshift({
					id: 0,
					name: this.form.controls.sub_axis.value,
					remove: 0
				})
				this.subAxisTable.data = this.subAxisTable.data
			}
		} else {
			this.subAxisTable.data = [
				{ id: 0, name: this.form.controls.sub_axis.value, remove: 0 }
			]
		}

		this.form.patchValue({
			sub_axis: ''
		})
	}

	removeSubAxis(axis: Axis) {
		this.subAxisTable.data.map((subAxis) => {
			if (subAxis.name == axis.name) {
				subAxis.remove = 1
			}
		})

		this.subAxisTable.data = this.subAxisTable.data.filter((subAxis) => {
			return subAxis.id != 0 || subAxis.remove == 0
		})
	}

	private removeAccents(strAccents: any): string {
		strAccents = strAccents.split('')
		const strAccentsOut = new Array()
		const strAccentsLen = strAccents.length
		const accents =
			'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
		const accentsOut =
			'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'

		for (let y = 0; y < strAccentsLen; y++) {
			if (accents.indexOf(strAccents[y]) !== -1) {
				strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
			} else {
				strAccentsOut[y] = strAccents[y]
			}
		}
		return strAccentsOut.join('')
	}

	save(): void {
		this.saving = true
		let duration = 2500
		this.form.patchValue({
			sub_axis_object: this.subAxisTable.data || []
		})

		if (this.data.axis.id > 0) {
			this.service.update(this.form.value).subscribe(
				(data: any) => {
					this.dialogRef.close(true)
					this.snackBar.open('Eje Actualizado', 'Ok', {
						duration
					})
				},
				(error) => {
					this.saving = false
					const keys = Object.keys(error.error)
					this.snackBar.open(error.error[keys[0]], 'OK', {
						duration
					})
				}
			)
		} else {
			this.service.create(this.form.value).subscribe(
				(data: any) => {
					this.dialogRef.close(true)
					this.snackBar.open('Eje Creado', 'Ok', {
						duration
					})
				},
				(error) => {
					this.saving = false
					const keys = Object.keys(error.error)
					this.snackBar.open(error.error[keys[0]], 'OK', {
						duration
					})
				}
			)
		}
	}
}
